import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({

        dfvTitle: {
            fontFamily: `LibreBaskerville-Bold, Libre Baskerville`,
            fontSize: `16px`,
            fontWeight: `bold`,
            lineSpacing:`24`,
            fill: `#000000`,
            fillOpacity: `0.8`,
        }, 
        dfvTagDesc:{
            fontFamily: `OpenSans-Regular, Open Sans`,
            fontWeight:`normal`, 
            fontSize: `12px`,
            fill:"#000000"
        }, 
        dfvTagBrief:{
            fontFamily: `OpenSans-Bold, Open Sans`,
            fontSize: `12px`,
            fontWeight:`bold`, 
            fill:"#000000",
            fillOpacity: `0.8`
        }
    });
}

export default getSkin;