import React from 'react';
import styles from './empathyMapFollowUpQuestion.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import { filterObjectFromArray, getMinAndMaxValues } from 'util/objectUtils';
import applyWrappers from 'util/ComponentWrapper';
import ActionTick from 'svgComponents/actionTick';
import getColorPalette from './colorPalette';
import MessageIcon from 'svgComponents/messageIcon/MessageIcon';
import QuestionChatBox from 'commonComponents/questionChatBox/QuestionChatBox';
import consts from 'constants/url';
import {getFormattedCurrency} from 'util/currencyFormatter';
import {checkWithBudget} from 'util/utilFunctions';
class EmpathyMapFollowUpQuestion extends React.Component {

    closeFollowupClicked = () => {
        this.props.setUserEmpathizePhaseState({
            isFollowUpQuestionsPopupOpen: false,
            activeQuestionSelected: -1
        })
    }

    doNothing = (response) => {
        return;
    }

    askQuestionClicked = (segmentQuestions, question, userState) => {
        
        const url = consts.get('QUESTION_ASKED')
        const payload = {
            questionId: question.id,
            segmentId: segmentQuestions.get('id'),
            roundId: userState.get('currentRoundId')
        }

        this.props.setUserEmpathizePhaseState({
            isEmpathyQuestionAsked: true
		})

        // this.props.scrollContainer();

        this.props.postRequestQuestionForSegmentAsked(url, payload, this.props.scrollContainer);
    }

    pickResponseForEmpathyMapClicked = (response) => {
        this.props.setResponseClickedToFillQuadrant(response)
    }

    renderRange = (costRange) => {
        if(costRange.min == costRange.max) {
            return getFormattedCurrency(costRange.min, 'USD')
        } 
        return getFormattedCurrency(costRange.min, 'USD') + ' - ' + getFormattedCurrency(costRange.max, 'USD')
    }

    renderQuestionSectionTitle = (segmentQuestion) => {
        const myStyles = getSkin(this.props.skinGuide);
        const costRange = getMinAndMaxValues(segmentQuestion, 'cost');  
        return (
            <div styleName="ask-questions-title-container">
                <div className={css(myStyles.askQuestionsTitle)} styleName="ask-questions-title-cost display-to-start">
                    <MessageIcon {...this.props} />
                    {this.props.getLabel('label_choose_followup_questions')}
                </div>
                <div className={css(myStyles.askQuestionsSubTitle)} styleName="ask-questions-title-cost display-to-end">
                    {this.props.getLabel('label_question_cost')} &nbsp;
                    <span className={css(myStyles.askQuestionsSubTitleCost)}>
                        {this.renderRange(costRange)}
                    </span>
                </div>
            </div>
        )
    }

    renderQuestionChatBox = (segmentQuestions) => {
        const { segments, userPhasesState } = this.props;

        // const segmentQuestions = filterObjectFromArray(segments.get('segmentQuestions'), 'id', userPhasesState.get('userEmpathize').get('activeEmpathySegmentId'));
        
        const followupQuestions = segmentQuestions.get('questions').toJS().filter((question) => question.parentId == userPhasesState.get('userEmpathize').get('activeQuestionSelected'));
        
        return followupQuestions.map((followupQuestion, index) => {
            if(followupQuestion.parentId != null) {
                let isDisabled = true;
                let clickHandler = this.doNothing;
                if(!followupQuestion.isAsked  && !this.props.userPhasesState.get('userEmpathize').get('isEmpathyQuestionAsked')) {
                    isDisabled = false;
                    clickHandler = this.askQuestionClicked;
                }

                if(!checkWithBudget(followupQuestion.cost, this.props.metrics)) {
                    isDisabled = true;
                }

                return (
                    <QuestionChatBox
                        key={index}
                        isDisabled={isDisabled}
                        clickHandlerForIcon={() => clickHandler(segmentQuestions, followupQuestion, this.props.userState)} 
                        clickHandlerForResponse=''
                        showQuestionIcon={true}
                        showSuggestions={true}
                        chatBoxDirection="right"
                        segmentQuestion = {followupQuestion} 
                        {...this.props}
                    />
                );
            }
        });
    }
    
    renderFollowUpQuestions = () => {
        const { segments, userPhasesState } = this.props;
        const segmentList = filterObjectFromArray(segments.get('segmentList'), 'id', userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'));        
        const myColors = getColorPalette(this.props.skinGuide, segmentList.get('colorProfileId'));
        
        return(
            <div styleName="popup-ask-questions-container">
                {this.renderQuestionSectionTitle(this.props.segmentQuestions)}
                <div styleName="ask-questions-chatbox-container">
                    {this.renderQuestionChatBox(this.props.segmentQuestions)}
                </div>
            </div>
        )
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="followup-questions-container">
               <div styleName="followup-questions-close-container">
                    <div className={css(myStyles.closeStyling)} styleName="followup-questions-close" onClick={this.closeFollowupClicked}>
                        {this.props.getLabel('label_close')} X
                    </div>
               </div>
               <div className={css(myStyles.askFollowupQuestionsStyling)} styleName="followup-questions-content-container">
                    {this.renderFollowUpQuestions()}
               </div>
            </div>
        );
    }
}

export default applyWrappers(EmpathyMapFollowUpQuestion, styles);