import kfetch from '../util/kfetch';
import consts from 'constants/url';



export const fetchLeaderboard = () => (dispatch) => kfetch(
    consts.get('GET_LEADERBOARD')
    +'?reducerAction=SET_LEADERBOARD'
    )
    .then((response) => {
        dispatch({
            type: "SET_LEADERBOARD",
            payload: response
        });

    });
