import actionConsts from 'constants/actions';

const setQuestionForSegmentAsked = (payload) => {
    return {
        type: actionConsts.get('SET_QUESTION_FOR_SEGMENT_ASKED'),
        payload
    };
};

const setResponseClickedToFillQuadrant = (payload) => {
    return {
        type: actionConsts.get('SET_RESPONSE_FOR_QUADRANT_FILLED'),
        payload
    }
}

const autoFillResponsesInQuadrant = (payload = {}) => {
    return {
        type: actionConsts.get('SET_AUTO_RESPONSE_FOR_QUADRANT_FILLED'),
        payload
    }
}

export {
    setQuestionForSegmentAsked,
    setResponseClickedToFillQuadrant,
    autoFillResponsesInQuadrant
};
