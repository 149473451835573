import { combineReducers } from 'redux-immutable';

import userObserve from '../userObserve/userObserve';
import userEmpathize from '../userEmpathize/userEmpathize';
import userDefine from '../userDefine/userDefine';
import userIdeate from '../userIdeate/userIdeate';
import userPrototype from '../userPrototype/userPrototype';
import userTestPrototype from '../userTestPrototype/userTestPrototype';

const userPhasesState = combineReducers({
    userObserve,
    userEmpathize,
    userDefine,
    userIdeate,
    userPrototype,
    userTestPrototype
});

export default userPhasesState;
