import { StyleSheet } from 'aphrodite';
import { typography, getCurrentEntityPalette } from 'util/styleUtil';

const getColorPalette = (skinGuide, id) => {
	const entityProfiles = skinGuide.get('entityProfiles');
	const currentEntityPalette = getCurrentEntityPalette(entityProfiles, id);
    
	return StyleSheet.create({
		getSegmentBorder: {
			backgroundImage: `linear-gradient(to right, ${currentEntityPalette.get('primaryColor')}, ${currentEntityPalette.get('secondaryColor')})`,
        }
	});
}

export default getColorPalette;