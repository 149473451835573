import React from 'react';
import styles from './upArrow.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class UpArrow extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skin = this.props.skinGuide.get('globalProfiles').get('palette')
        let color = skin.get('white')
        if(checkIfPresent(this.props.svgColor)) {
            color = this.props.svgColor;
        }
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 5 4">
                    <path fill={color} fill-rule="nonzero" d="M4.945 3.797A.432.432 0 0 1 4.574 4H.426a.432.432 0 0 1-.371-.203.376.376 0 0 1 .006-.402L2.135.192A.433.433 0 0 1 2.5 0c.15 0 .288.073.365.192l2.074 3.203c.08.123.082.277.006.402z"/>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(UpArrow, styles);