import { has } from "immutable";
import { filterObjectFromArray } from 'util/objectUtils';
import { compareOnId } from 'util/utilFunctions';

const getSegmentQuestionFormattedData = (payload) => {
    
    let responseList = [];
    
    payload.segmentQuestions.map((segment, index) => {
        
        segment['shouldShowEmptyChatSection'] = true;
        segment['showInitialAnimation'] = true;
        segment['isEmpathyPopupOpen'] = false;
        segment.questions.map((segmentQuestion) => {
            segmentQuestion['isAsked'] = false;
            segmentQuestion['isResponse'] = false;
            segmentQuestion['segmentId'] = segment['id'];
            segmentQuestion['timeStamp'] = new Date().getTime();
            if (segmentQuestion.parentId == null) {                
                segmentQuestion['isVisible'] = true;
            } else {
                segmentQuestion['isVisible'] = false;
                segmentQuestion['hasFollowUp'] = false;
            }

            const segmentList = segment.questions.filter((question) => segmentQuestion.id == question.parentId);
            /**Check if segment question has follow up questions */
            
            if(segmentList.length > 0) {                
                segmentQuestion['hasFollowUp'] = true;
            } else {
                segmentQuestion['hasFollowUp'] = false;
            }

            segmentQuestion.responses.map((response, index) => {
                /**Adding has follow up to the last response for a question to render button at end of responses if necessary */
                if(segmentQuestion['hasFollowUp']  && index == (segmentQuestion.responses.length - 1)) { 
                    response['hasFollowUp'] = true;
                } else {
                    response['hasFollowUp'] = false;
                }
                response['questionId'] = segmentQuestion['id'];
                response['isResponse'] = true;
                response['isClicked'] = false;
                response['segmentId'] = segment['id'];
                responseList.push(response);
            })
        });
        // segment.questions = segment.questions.sort(compareOnId, 'id');        
    });

    /**Append payload responses for every quadrant */
    payload.quadrants.map((quadrant) => {
        let quadrantResponseList = [];
        responseList.map((response) => {
            if(response.quadrantId == quadrant.id) {
                quadrantResponseList.push(response);
            }
        })
        quadrant['responses'] = quadrantResponseList;
    })

    console.log(payload);
    
    return payload;
}

export {
    getSegmentQuestionFormattedData
}