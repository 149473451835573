import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		title: {
			...myTypography.subtitle,
			fontWeight: `bold`
		},
		content: {
			...myTypography.body1,
			fontSize: `16px`
		},

		cardBg: {
			backgroundColor: 'rgba(255, 106, 0, 0.05)',
			borderTop: 'solid 5px rgba(255, 106, 0, 0.05)'
		},

		revenueLabel: {
			...myTypography.body2,
			color: skin.get('grayColor3'),
			lineHeight: 1.42,
			paddingRight: '5px'
		},

		greenArrow: {
			borderBottom: '5px solid #00b236'
		},

		costValue: {
			...myTypography.body2,
			fontSize: '14px',
			color: skin.get('grayColor2'),
			lineHeight: 1.57
		},

		cardDesc: {
			...myTypography.body2,
			color: skin.get('grayColor2')
		},
		ideaTitle: {
			...myTypography.body1,
			fontWeight: 700,
			color: skin.get('grayColor2')
		},
		ideaDesc: {
			...myTypography.body2,
			color: skin.get('grayColor2')
		},
		unlockDfvContent: {
			textDecoration: 'underline',
			...myTypography.body2,
			lineHeight: 2.08,
			color: skin.get('grayColor1'),
			fontWeight: 600
		},
		tagCostName: {
			...myTypography.body2,
			fontSize: '8px'
		},
		tagCostValue: {
			...myTypography.body2,
			fontWeight: 600
		}
	});
}

export default getSkin;