import React from 'react';
import styles from './actionTick.module.sass';
import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ActionTick extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="action-tick">
                <svg width="100%" height="100%" viewBox="0 0 20 20">
                    <g fill="none" fillRule="evenodd">
                        <circle cx="10" cy="10" r="10" fill={this.props.circleFill? this.props.circleFill : "#A760E4"}/>
                        <path fill={this.props.tickFill ? this.props.tickFill : "#FFF"} fillRule="nonzero"
                            d="M14.737 6.894L13.5 5.656a.222.222 0 0 0-.313 0l-5.222 5.218a.222.222 0 0 1-.314 0L6.415 9.636a.222.222 0 0 0-.314 0l-1.236 1.238a.22.22 0 0 0 0 .313l2.786 2.782a.222.222 0 0 0 .314 0l6.772-6.765a.22.22 0 0 0 0-.31z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(ActionTick, styles);