import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography, getCurrentEntityPalette } from 'util/styleUtil';

const getColorPalette = (skinGuide, id) => {
	const entityProfiles = skinGuide.get('entityProfiles');
    const currentEntityPalette = getCurrentEntityPalette(entityProfiles, id);
    const myTypography = typography(skinGuide.get('fontFamily'));
    
	return StyleSheet.create({
        empathyQuadrantBackground: {
            backgroundColor: `${hexToRgbA(currentEntityPalette.get('solidColor'), 0.05)}`,
        },
		empathyQuadrantTitle: {
            ...myTypography.body1,
            lineHeight: 2,
            fontWeight: 600,
			color: currentEntityPalette.get('solidColor'),
        },
        
        empathyQuadrantIntroTitle: {
            ...myTypography.body2,
            lineHeight: 1.5,
            fontWeight: 600,
            color: currentEntityPalette.get('solidColor'),

        },
		
	});
}

export default getColorPalette;