import Immutable from 'immutable';
import environment from 'util/environment';

const host = environment.REACT_APP_BACKEND_URL;

const urls = Immutable.Map({
    GAME_API: `${host}/game`,
    USER_API: `${host}/user`,
    LABELS_API: `${host}/labels`,
    PERSONA_INTERVIEW_API: `${host}/persona-interviews/?reducerAction=%{ACTION_TYPE}`,
    PERSONA_FEEDBACK_SURVEY_API: `${host}/feedback-surveys`,
    UPDATE_CURRENT_PHASE: `${host}/update-current-phase`,
    GAME_STARTED: `${host}/game-started`,
    FETCH_IDEAS_API: `${host}/ideas?segmentId=%{SEGMENT_ID}&touchPointId=%{TOUCHPOINT_ID}`, 
    UNDERSTAND_TEAM_VIEW_API: `${host}/understand-team-views?reducerAction=%{ACTION_TYPE}`, 
    FETCH_DEFINE_FOCUS_API: `${host}/focus-validation-cost`,
    VALIDATE_FOCUS_API: `${host}/validate-focus`,
    DEFINE_FOCUS_API: `${host}/define-focus`,
    QUESTION_ASKED: `${host}/question-asked`,
    PERSONA_INFO_READ: `${host}/persona-read`,
    PERSONA_INTERVIEW_TAKEN: `${host}/persona-interviews-conducted`,
    FETCH_PHASE_SUGGESTION_API: `${host}/phase-team-suggestions/?roundId=%{ROUND_NUM}&phaseId=%{PHASE_ID}`,
    UNLOCK_IDEA_DFV_SCORE: `${host}/idea-dfv`,
    DIG_DEEP_IDEA_DFV_SCORE: `${host}/idea-dig-deeper`,
    PERSONA_FEEDBACK_TAKEN: `${host}/feedback-surveys-conducted`,
    ACTION_LOG_API: `${host}/action-log`,
    POST_TEST_PROTOTYPE: `${host}/test-prototype`,
    PHASE_INTRO_READ: `${host}/phase-intro-read`,
    POST_PRIORITIZE_IDEA: `${host}/prioritize-idea`,
    POST_PROTOTYPE_IDEA: `${host}/build-prototype`,
    POST_ROUND_STARTED: `${host}/round-started`,
    POST_GAME_STARTED: `${host}/game-started`,
    POST_GAME_COMPLETED: `${host}/game-completed`,
    GET_LEADERBOARD: `${host}/leaderboard`,
    POST_EXECUTE_IDEAS: `${host}/execute`,
    POST_EVENTS: `${host}/event`,
    LOG_OUT: `${host}/logout`,
    KNOLSKAPE_URL: 'https://www.knolskape.com',
    SAVE_NOTES: `${host}/save-notes`,
    USER_REPORT_WEB_URL: `${host}/report/web`,
    USER_REPORT_PDF_URL: `${host}/report/pdf`,
});

export default urls;