import React from 'react';
import styles from './timer.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class Timer extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skin = this.props.skinGuide.get('globalProfiles').get('palette');
        return (
            <div styleName="timer-container">
                <svg width="100%" height="100%" viewBox="0 0 20 24">
                    <g fill={skin.get('white')} fill-rule="nonzero" opacity={this.props.opacity}>
                        <path d="M18.006 8.341a.233.233 0 0 1 .021-.316l1.651-1.585a.935.935 0 0 0 .273-.934.98.98 0 0 0-.714-.686 1.028 1.028 0 0 0-.973.262l-1.656 1.59a.258.258 0 0 1-.329.02 9.662 9.662 0 0 0-4.561-1.815.244.244 0 0 1-.218-.238V2.16c0-.064.026-.125.073-.17a.255.255 0 0 1 .177-.071h1.75c.552 0 1-.43 1-.96s-.448-.96-1-.96h-6c-.552 0-1 .43-1 .96s.448.96 1 .96h1.75c.138 0 .25.107.25.24v2.479c0 .12-.093.223-.218.238-4.279.524-7.654 3.747-8.225 7.851-.57 4.104 1.808 8.064 5.793 9.647 3.986 1.583 8.582.393 11.2-2.899 2.616-3.292 2.598-7.862-.044-11.135zM10.5 21.12c-4.142 0-7.5-3.224-7.5-7.2s3.358-7.2 7.5-7.2c4.142 0 7.5 3.224 7.5 7.2-.004 3.975-3.36 7.196-7.5 7.2z"/>
                        <path d="M10.5 8.16a.49.49 0 0 0-.5.48v4.8H5a.49.49 0 0 0-.5.48c0 3.181 2.686 5.76 6 5.76s6-2.579 6-5.76c0-3.181-2.686-5.76-6-5.76z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(Timer, styles);