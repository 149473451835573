import kfetch from '../util/kfetch';
import consts from 'constants/url';

export const fetchUserDetails = () => (dispatch) => kfetch(consts.get('USER_API'))
    .then((response) => {        
        dispatch({
            type: "INIT_USER_DATA",
            payload: response
        });

    });
