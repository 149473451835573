/**
 * @author: Ujjwal Jain
 * @file: userIdeate.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({
    isIntroPageSeen: false,
    showIntroPage: false,
    activeIdeaId: -1,
    isPhaseAvailableToUser: false,
    isFocusConfirmationSeen: false,
    isUserOnFocusConfirmationScreen: false,
    isIdeateAnimationSeen: false,
    isDFVFirstTimeSeen: false,
    showIdeasLoading: false,
    suggestions: []
});


const userIdeate = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_IDEATE_PHASE_STATE'):
            return state.mergeDeep(Immutable.fromJS(action.payload));
        case actionConsts.get('POPULATE_IDEATE_SUGGESTIONS'):
            return state.set('suggestions', action.payload);
        case 'MAKE_IDEATE_PHASE_AVAILABLE':
            return state.set('isPhaseAvailableToUser', true);
        default:
            return state;
    }
};

export default userIdeate;