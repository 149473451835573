import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		walkthoughText: {
			...myTypography.body1,
			color: skin.get('grayColor1'),
			fontWeight: 600,
			lineHeight: 'normal',
			textShadow: `2px 2px 30px ${skin.get('primaryColor')}`
		}
	});
}

export default getSkin;