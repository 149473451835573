import React from 'react';
import styles from './actionTickWithoutFill.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ActionTickWithoutFill extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 64 64">
                    <g fill={this.props.circleFill} fillRule="nonzero" opacity={this.props.opacity}>
                        <path d="M49.28 17.197a3.33 3.33 0 0 0-4.659.707l-15.797 21.44-10.045-8.037a3.336 3.336 0 0 0-4.168 5.208l12.754 10.202a3.368 3.368 0 0 0 4.766-.626l17.866-24.232a3.333 3.333 0 0 0-.717-4.662z"/>
                        <path d="M32 0C14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32C63.98 14.335 49.665.02 32 0zm0 58.667C17.272 58.667 5.333 46.727 5.333 32 5.333 17.272 17.273 5.333 32 5.333c14.728 0 26.667 11.94 26.667 26.667C58.65 46.72 46.72 58.65 32 58.667z"/>
                    </g>
                </svg>

            </div>
        );
    }
}

export default applyWrappers(ActionTickWithoutFill, styles);