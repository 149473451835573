import React from 'react';
import styles from './personaList.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import ActionTickWithGradient from 'svgComponents/actionTickWithGradient';
import { getFormattedCurrency } from '../../util/currencyFormatter';

class PersonaList extends React.Component {

    personaClicked = (persona, isDisabled) => {
        if(isDisabled) {
            return;
        }
        this.props.personaIconClicked(persona);
    }

    renderPersonaName = (myStyles, persona) => {
        const personaName = this.props.showPersonaLevelAndCost ? myStyles.personaNameBold : myStyles.personaName;
        if(this.props.showPersonaName) {
            return (
                <div className={css(personaName)} styleName="persona-name-container">
                    {this.props.getLabel(persona.name).split(" ")[0]}
                </div>
            )
        }
        return null;
    }

    renderPersonaImage = (isDisabled, persona, personaStyle, personaBorderStyle) => {
        let opacityStyle = {
            opacity: 1
        };

        if(isDisabled) {
            opacityStyle = {
                opacity: 0.5,
                cursor: 'not-allowed',
                border: '1px solid #6a28b0'
            }
        }
        
        const personaTickStyle =  this.props.showPersonaLevelAndCost ? "persona-selected-tick-popup" : "persona-selected-tick";  
        return(
            <div styleName="persona-image-container">
                <div 
                    style={opacityStyle} 
                    styleName="image-container" 
                    className={css(personaStyle, personaBorderStyle)}
                >
                    <img 
                        src={this.props.getImage(persona.image_circular)}
                        styleName="persona-image"
                    />
                </div>
                {(persona.isInfoRead && this.props.isInfoRelated && !this.props.userPhasesState.get('userObserve').get('isPersonaInterviewPopup') && !this.props.uiState.get('isUserBoardOpen')) 
                    || (persona.isSelected && this.props.showPersonaLevelAndCost)
                    || (this.props.userPhasesState.get('userObserve').get('isPersonaInterviewPopup') && persona.noOfInterviewTaken > 0)
                    ? 
                    <div styleName={personaTickStyle}>
                        <ActionTickWithGradient
                            {...this.props} 
                        />
                    </div>
                    :
                    null
                }
            </div>
        )
    }

    getLevelObject = (value, getLevelObj, stringName) => {
        switch (value) {
            case 0:
                return {
                    level: 'label_basic',
                    cost: getFormattedCurrency(getLevelObj['cost'], 'USD')
                }
            case 1:
                return {
                    level: 'label_intermediate',
                    cost: getFormattedCurrency(getLevelObj['cost'], 'USD')
                }
            case 2:
                if(stringName == 'feedback') {
                    return {
                        level: 'label_limit_reached',
                        cost: ''
                    }  
                }
                return {
                    level: 'label_advanced',
                    cost: getFormattedCurrency(getLevelObj['cost'], 'USD')
                }
            case 3: 
                return {
                    level: 'label_limit_reached',
                    cost: ''
                }
            default:
                break;
        }
    }

    getInterviewLevelAndCost = (persona) => {
        const personaLevel = persona.noOfInterviewTaken + 1;        
        const getLevelObj = persona.personaInterviews.filter((personaInterview) => personaInterview.level == personaLevel);    
        const displayObj = this.getLevelObject(persona.noOfInterviewTaken, getLevelObj['0'], 'interview');
        return displayObj;
    }

    getFeedbackLevelAndCost = (persona) => {
        const personaLevel = persona.noOfFeedbackAsked + 1;
        const getLevelObj = persona.feedbackSurveys.filter((personaFeedback) => personaFeedback.level == personaLevel);
        const displayObj = this.getLevelObject(persona.noOfFeedbackAsked, getLevelObj['0'], 'feedback');        
        
        return displayObj;
    }

    getInterviewOrFeedbackLevel = (persona) => {
        let levelAndCost = {
            level: -1,
            cost: 0
        };
        if (this.props.isInterview) {
            levelAndCost = this.getInterviewLevelAndCost(persona);
        } else {
            levelAndCost = this.getFeedbackLevelAndCost(persona);
        }

        return levelAndCost;
    }

    renderPersonaLevelAndCost = (myStyles, persona) => {
        
        if(this.props.showPersonaLevelAndCost) {
            const costAndLevel = this.getInterviewOrFeedbackLevel(persona);                            
            return(
                <div styleName="persona-level-cost-container">
                    <div className={css(myStyles.levelStyle)}>
                        {this.props.getLabel(costAndLevel.level)}
                    </div>
                    <div className={css(myStyles.costStyle)} style={{minHeight: '20px'}}>
                        {this.props.getLabel(costAndLevel.cost)}
                    </div>
                </div>
            )
        }
    }

    getIfPersonaDisabled = (persona) => {
        if(this.props.showPersonaLevelAndCost) {
            if(this.props.isInterview) {
                if(persona.noOfInterviewTaken == persona.personaInterviews.length) {
                    return true;
                }
            } else {
                if(persona.noOfFeedbackAsked == persona.feedbackSurveys.length) {
                    return true;
                }
            }
        }
        return false;
    }

    renderIndividualPersona = (personaStyle, myStyles) => {
        const userState = this.props.userPhasesState.get('userObserve');
        
        const parentStyling=this.props.isCenterAligned ? 'individual-persona-list-container' : 'individual-persona-list-container no-margin';
        return this.props.personas.get('personaList').toJS().map((persona, index) => {
            let personaBorderStyle = '', selectedBorderBottom = '';

            if(this.props.showPersonaReadState && !this.props.uiState.get('isUserBoardOpen')) {
                if(!this.props.isFromDashboard && persona.id == userState.get('currentPersonaSelectedObject').get('id')) {
                    selectedBorderBottom = myStyles.selectedBorderBottom;
                }
                if(persona.isInfoRead && !userState.get('isPersonaInterviewPopup')) {
                    personaBorderStyle =  myStyles.personaBorderStyle;
                }
                if(userState.get('isPersonaInterviewPopup') && persona.noOfInterviewTaken > 0) {
                    personaBorderStyle =  myStyles.personaBorderStyle;   
                }
            } else if(this.props.uiState.get('isUserBoardOpen') && persona.id == this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('id')) {
                selectedBorderBottom = myStyles.selectedBorderBottom;
            } else {
                if(!this.props.isFromDashboard && persona.isSelected) {
                    selectedBorderBottom = myStyles.selectedBorderBottom;
                }
                if(persona.isSelected) {
                    personaBorderStyle =  myStyles.personaBorderStyle;
                }
            }
            
            let isDisabled = this.getIfPersonaDisabled(persona)
            return(
                <div 
                    key={index} 
                    className={css(selectedBorderBottom)} 
                    styleName={parentStyling}
                    onClick={() => this.personaClicked(persona, isDisabled)}
                >
                    {this.renderPersonaImage(isDisabled, persona, personaStyle, personaBorderStyle)}
                    {this.renderPersonaName(myStyles, persona)}
                    {this.renderPersonaLevelAndCost(myStyles, persona)}
                </div>
            )
        });
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const personaBackground = this.props.uiState.get('isOverlayOpen') ? '' : myStyles.personaBoxShadow;
        const parentStyling = this.props.isCenterAligned ? "persona-list-container-center": "persona-list-container-start";
        return (
            <div styleName={parentStyling}>
                {this.renderIndividualPersona(personaBackground, myStyles)}
            </div>
        );
    }
}

export default applyWrappers(PersonaList, styles);