import React from 'react';
import styles from './events.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import images from 'constants/images/dtOneImages';
import CloseIcon from 'svgComponents/closeIcon';
import DownArrow from "svgComponents/downArrow";
import UpArrow from "svgComponents/upArrow";
import {TweenMax} from 'gsap';
import {
    getTeamMember,
    checkIfPresent,
    roundValue
} from 'util/utilFunctions';
import { getFormattedCurrency } from 'util/currencyFormatter';
import {compareOnTimeStampReverse} from 'util/utilFunctions';
import EventBackground from 'svgComponents/eventBackground';
import EventsEmpty from 'svgComponents/eventsEmpty'

class Events extends React.Component {

    constructor(props) {
        super(props);
        this.completeContainer = React.createRef();
    }

    componentDidMount() {
        this.scrollContainer();
    }

    scrollContainer = () => {
        TweenMax.to(this.completeContainer.current, 2, { scrollTop: this.completeContainer.current.scrollHeight });
    }

    closeEvents = () => {
        this.props.setUIState({
            isBluredRightOverlay: false,
            popupComponentBluredOverlay: ''
        })
    }

    getArrow = (value) => {
        if(value < 0) {
            return <DownArrow fillValue="#FFFFFF" />
        } else if (value > 0) {
            return <UpArrow svgColor="#FFFFFF" />
        }
        return null;
    }

    getChangedMetricValue = (event, myUserEvent, key, formatCurreny = false) => {
        const metricsResponse = myUserEvent.metrics;
        if(
            checkIfPresent(metricsResponse)
            && metricsResponse.length > 0
        ) {
            const myMetric = metricsResponse.find((eachMetric) => {
                return eachMetric.key === key
            });
            if(myMetric) {
                return formatCurreny
                        ?   (getFormattedCurrency(myMetric.value, 'USD'))
                        :   myMetric.value;
                
            }
        }
        return null;
    } 

    renderSingleLine = (myStyles) => {
        return (
            <div className={css(myStyles.singleLine)} styleName="single-line"></div>
        );
    }

    getEventColorType = (event) => {
        if(
            (event.revenueScale == 0
            && event.ceScale == 0
            && event.budgetScale == 0)
            || event.isMessage === true
        ) {
            return 'NEUTRAL';
        }

        let type = 'POSITIVE';
        if(event.revenueScale < 0) {
            type = 'NEGATIVE';
        } else if(event.ceScale < 0) {
            type = 'NEGATIVE';
        } else if(event.budgetScale < 0) {
            type = 'NEGATIVE';
        }
        return type;
    }

    renderChatFromTeam = (myStyles) => {
        let events = this.props.events.get('eventList').toJS();
        let userEvents = this.props.userEvents.get('userEventsList').toJS();
        // events = events.sort(compareOnTimeStampReverse)
        let singleEventPresnt = false;
        events = events.map((eachEvent, index) => {
            const myUserEvent = userEvents.find((eachUserEvent) => {
                return (eachUserEvent.eventId === eachEvent.id);
            });
            if(eachEvent.type === 'time' && eachEvent.event_messages.length > 0 && checkIfPresent(myUserEvent)) {
                singleEventPresnt = true;
                const eventColorType = this.getEventColorType(eachEvent);

                let messages = eachEvent.event_messages.map((eventMessage) => {
                    const teamMemberDetails = getTeamMember(this.props.teamMembers.get('teamList'), eventMessage.teamMemberId);
                    return (
                        <div key={`metric-${index}`} styleName="chat-messages">
                            <div>
                                <div styleName="chat-member-image">
                                    <img 
                                        alt="team-member"
                                        src={this.props.getImage(teamMemberDetails.get('image_full'))}
                                        styleName="image"
                                    />
                                </div>
                                <div className={css(myStyles.eventCharName)} styleName="event-chat-text">
                                    {this.props.getLabel(teamMemberDetails.get('short_name'))}
                                </div>
                                <div className={css(myStyles.eventCharRole)} styleName="event-chat-text">
                                    {this.props.getLabel(teamMemberDetails.get('role'))}
                                </div>
                            </div>
                            <div styleName="chat-container">
                                <div
                                    className={
                                        eventColorType === 'POSITIVE'
                                        ?   css(myStyles.arrowTipPositive)
                                        :   eventColorType === 'NEGATIVE'
                                            ?   css(myStyles.arrowTipNegative)
                                            :   css(myStyles.arrowTipNeutral)
                                    }
                                    styleName="arrow-tip"></div>
                                <div
                                    styleName="message-consecutive-bubble" 
                                    className={
                                        eventColorType === 'POSITIVE'
                                        ?   css(myStyles.messageBubble, myStyles.positive)
                                        :   eventColorType === 'NEGATIVE'
                                            ?   css(myStyles.messageBubble, myStyles.negative)
                                            :   css(myStyles.messageBubble, myStyles.neutralTextColor)
                                    }
                                >
                                    {this.props.getLabel(eventMessage.message)}
                                </div>
                            </div>
                        </div>
                    )
                });
                return [
                    messages,
                    this.renderImpactFromEvent(eachEvent, myStyles, eventColorType, myUserEvent),
                    this.renderSingleLine(myStyles)
                ];

            }
            return null;
        });
        if (singleEventPresnt === false) {
            return (
                <div styleName="empty-event-container">
                    <div styleName="empty-event-image">
                        <EventsEmpty />
                    </div>
                    <div className={css(myStyles.emptyEventText)} styleName="empty-event-text">
                        {this.props.getLabel('label_no_events')}
                    </div>
                </div>
            );
        }
        return [events];
    }

    renderImpactFromEvent = (event, myStyles, eventColorType, myUserEvent) => {
        if(
            event.revenueScale == 0
            && event.ceScale == 0
            && event.budgetScale == 0
        ) {
            return null;
        }

        return (
            <div styleName="chat-container-event">
                <div
                    className={eventColorType === 'POSITIVE' ? css(myStyles.arrowTipPositive) : css(myStyles.arrowTipNegative)}
                    styleName="arrow-tip-event"></div>
                <div
                    styleName="message-bubble" 
                    className={eventColorType === 'POSITIVE' ? css(myStyles.messageBubble, myStyles.positive) : css(myStyles.messageBubble, myStyles.negative)}
                >
                    <div className={css(myStyles.headingImpact)}>
                        {this.props.getLabel('label_impact')}:
                    </div>
                    {
                        event.revenueScale != 0
                        ?   <div className={css(myStyles.descImpact)} styleName="single-impact">
                                <div styleName="left">{this.props.getLabel('label_revenue_events')}</div>
                                <div styleName="center">:</div>
                                <div styleName="right">
                                    <div styleName="right-arrow">
                                        {this.getArrow(event.revenueScale)}
                                    </div>
                                    {Math.abs(event.revenueScale)}%
                                    ({this.getChangedMetricValue(event, myUserEvent, 'revenue', true)})
                                </div>
                            </div>
                        :   null
                    }
                    {
                        event.ceScale != 0
                        ?   <div className={css(myStyles.descImpact)} styleName="single-impact">
                                <div styleName="left">{this.props.getLabel('label_customer_satisfaction')}</div>
                                <div styleName="center">:</div>
                                <div styleName="right">
                                    <div styleName="right-arrow">
                                        {this.getArrow(event.ceScale)}
                                    </div>
                                    {Math.abs(event.ceScale)}%
                                    ({roundValue(this.getChangedMetricValue(event, myUserEvent, 'cr', false, myUserEvent), 2)})
                                </div>
                            </div>
                        :   null
                    }
                    {
                        event.budgetScale != 0
                        ?   <div className={css(myStyles.descImpact)} styleName="single-impact">
                                <div styleName="left">{this.props.getLabel('label_metric_budget')}</div>
                                <div styleName="center">:</div>
                                <div styleName="right">
                                    <div styleName="right-arrow">
                                        {this.getArrow(event.budgetScale)}
                                    </div>
                                    {Math.abs(event.budgetScale)}%
                                    ({this.getChangedMetricValue(event, myUserEvent, 'budget', true)})
                                </div>
                            </div>
                        :   null
                    }
                </div>
            </div>
        );
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div className={css(myStyles.container)} styleName="container"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div styleName="event-background">
                    <div styleName="event-background-image">
                        <EventBackground />
                    </div>
                </div>
                <div styleName="event-content">
                    <div styleName="header" className={css(myStyles.header)}>
                        <img alt="team-chat" styleName="team-chat" src={images.get('IMG_TEAM_CHAT')}/>
                        <div styleName="title-close">
                            <div className={css(myStyles.title)}>
                                {this.props.getLabel('label_team_chat')}
                            </div>
                            <div styleName="close">
                                <CloseIcon
                                    closeButtonClicked={this.closeEvents}
                                />
                            </div>
                        </div>
                    </div>
                    <div styleName="content" className={css(myStyles.content)}
                        ref={this.completeContainer}
                    >
                        {this.renderChatFromTeam(myStyles)}
                    </div>
                </div>
            </div>
        );
    }
}

export default applyWrappers(Events, styles);