import React from 'react';
import styles from './executePopupBlob.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ExecutePopupBlob extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        if(this.props.type === 'POSITIVE') {
            return (
                <div styleName="main-component">
                    <svg width="100%" height="100%" viewBox="0 0 394 139">
                        <defs>
                            <path id="blob-positive-b" d="M22.37 10.587c13.459-8.25 18.554-3.704 92.839-3.704 46.578 0 121.897-2.284 241.56-6.852 14.783-.565 27.948 9.283 31.582 23.623 1.587 6.266 2.373 11.669 2.359 16.21-.024 7.25.996 33.198-3.665 60.502a29.338 29.338 0 0 1-25.212 24.166C317.53 130.177 266.595 133 209.032 133c-96.74 0-177.133-3.838-188.798-10.63-4.16-2.421-13.258-9.7-15.753-22.004-4.5-22.196.062-54.928.062-63.734 0-13.687 4.369-17.795 17.827-26.045z"/>
                            <filter id="blob-positive-a" width="102.6%" height="107.5%" x="-1%" y="-3%" filterUnits="objectBoundingBox">
                                <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.835294118 0 0 0 0 0.847058824 0 0 0 0 0.88627451 0 0 0 1 0"/>
                            </filter>
                        </defs>
                        <g fill="none" fill-rule="evenodd" opacity=".1" transform="translate(-1 2)">
                            <use fill="#000" filter="url(#blob-positive-a)" xlinkHref="#blob-positive-b"/>
                            <use fill="#75AF5C" xlinkHref="#blob-positive-b"/>
                        </g>
                    </svg>
                </div>
            );
        }
        if(this.props.type === 'NEGATIVE') {
            return (
                <div styleName="main-component">
                    <svg width="100%" height="100%" viewBox="0 0 394 139">
                        <defs>
                            <path id="blob-negative-b" d="M22.37 10.587c13.459-8.25 18.554-3.704 92.839-3.704 46.578 0 121.897-2.284 241.56-6.852 14.783-.565 27.948 9.283 31.582 23.623 1.587 6.266 2.373 11.669 2.359 16.21-.024 7.25.996 33.198-3.665 60.502a29.338 29.338 0 0 1-25.212 24.166C317.53 130.177 266.595 133 209.032 133c-96.74 0-177.133-3.838-188.798-10.63-4.16-2.421-13.258-9.7-15.753-22.004-4.5-22.196.062-54.928.062-63.734 0-13.687 4.369-17.795 17.827-26.045z"/>
                            <filter id="blob-negative-a" width="102.6%" height="107.5%" x="-1%" y="-3%" filterUnits="objectBoundingBox">
                                <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.835294118 0 0 0 0 0.847058824 0 0 0 0 0.88627451 0 0 0 1 0"/>
                            </filter>
                        </defs>
                        <g fill="none" fill-rule="evenodd" opacity=".045" transform="translate(-1 2)">
                            <use fill="#000" filter="url(#blob-negative-a)" xlinkHref="#blob-negative-b"/>
                            <use fill="#F00" xlinkHref="#blob-negative-b"/>
                        </g>
                    </svg>
                </div>
            );
        }
        if(this.props.type === 'NEUTRAL') {
           return (
                <div styleName="main-component">
                    <svg width="100%" height="100%" viewBox="0 0 260 151">
                        <defs>
                            <path id="blob-neutral-b" d="M11.956 19.762c9.027-15.224 12.444-6.836 62.267-6.836 31.24 0 81.756-4.215 162.014-12.645 8.714-.915 16.577 5.262 17.75 13.945 3.36 24.853 5.03 44.707 5.013 59.562a197.165 197.165 0 0 1-2.657 31.96 35 35 0 0 1-25.301 28.055C203.739 141.268 172.919 145 138.582 145 73.7 145 19.78 139.763 11.956 127.23 4.133 114.699 0 93.083 0 67.825c0-25.259 2.93-32.838 11.956-48.062z"/>
                            <filter id="blob-neutral-a" width="103.9%" height="106.8%" x="-1.5%" y="-2.6%" filterUnits="objectBoundingBox">
                                <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
                                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.835294118 0 0 0 0 0.847058824 0 0 0 0 0.88627451 0 0 0 1 0"/>
                            </filter>
                        </defs>
                        <g fill="none" fill-rule="evenodd" opacity=".05" transform="translate(1 2)">
                            <use fill="#000" filter="url(#blob-neutral-a)" xlinkHref="#blob-neutral-b"/>
                            <use fill="#7A3DBB" xlinkHref="#blob-neutral-b"/>
                        </g>
                    </svg>
                </div>
            );
        }
        return null;
    }
}

export default applyWrappers(ExecutePopupBlob, styles);