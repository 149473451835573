import React from 'react';
import styles from './changeInValue.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import UpArrow from 'svgComponents/upArrow';
import DownArrow from 'svgComponents/downArrow';
import {roundValue} from 'util/utilFunctions';

class ChangeInValue extends React.Component {

    renderValue = (value) => {
        if(value >= 0) {
            return roundValue(value, 1);
        } else {
            return roundValue(value * -1, 1);
        }
    }

    getClassName = (value, myStyles) => {
        if(value > 0) {
            return css(myStyles.green);
        } else if (value < 0) {
            return css(myStyles.red);
        }
        return css(myStyles.changeValueContainer)
    }

    renderArrow = () => {
        switch(this.props.arrowType) {
            case 'UP_ARROW':
                return (
                    <div styleName="arrow">
                        <UpArrow />
                    </div>
                );
            case 'DOWN_ARROW':
                return (
                    <div styleName="arrow">
                        <DownArrow />
                    </div>
                );
            case 'NO_ARROW':
                return null;
            default:
                return null;
        }
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div className={this.getClassName(this.props.changeValue, myStyles)} styleName="change-value-container">
                {this.renderArrow()}
                <div className={css(myStyles.valueText)} styleName="value-text">
                    {this.renderValue(this.props.changeValue)}
                </div>
            </div>
        );
    }
}

export default applyWrappers(ChangeInValue, styles);