import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        personaBoxShadow: {
            boxShadow: `0 4px 4px 0 ${skin.get('grayColor5')}`
        },

        personaName: {
            ...myTypography.body1,
            color: skin.get('grayColor2')
        },

        personaNameBold: {
            ...myTypography.body1,
            fontWeight: 600,
            color: skin.get('grayColor2')
        },

        levelStyle: {
            ...myTypography.body1,
            fontSize: '10px',
            lineHeight: '2.2',
            fontWeight: 'normal',
            color: skin.get('grayColor4')
        },

        costStyle: {
            ...myTypography.body1,
            fontWeight: 600,
            color: skin.get('grayColor2')
        },

        personaBorderStyle: {
            boxSizing: 'border-box',
            border: `solid 2px ${skin.get('primaryColor')}`
        },

        selectedBorderBottom: {
            borderBottom: `solid 4px ${skin.get('primaryColor')}`,
            ':hover': {
                transform: 'scale(1)'
            }
        },

        infoBorder: {
            border: `solid 2px ${skin.get('primaryColor')}`
        }

    });
}

export default getSkin;