import React from 'react';
import ReactDependentScript from 'react-dependent-script';
import styles from './videoComponent.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';
import { getKalturaLink } from 'util/appVariables.js';

let myPlayer;


class VideoPlayerInternal extends React.Component {

    playerReadyCallback = (playerID) => {
        const {
            onUpdateProgress = (progress, is_completed = false) => console.log("onUpdateProgress", progress, is_completed),
            onCompletion = () => console.log("onCompletion"),
            progress = { progress: 0, is_completed: false }
        } = this.props; /*with default props*/

        var kdp = document.getElementById(playerID);
        kdp.kBind('playerReady', function (event) {

            if (progress.progress) {
                kdp.sendNotification("doSeek", parseFloat(progress.progress));
            }

            kdp.kBind('playerPaused', function (event) {
                let progress = kdp.evaluate('{video.player.currentTime}');
                let is_completed = kdp.triggeredEndDone;
                console.log("event: video paused");
                onUpdateProgress(progress, is_completed);
            });

            kdp.kBind('playerPlayEnd', function (event) {
                console.log("event: video end");
                let progress = kdp.evaluate('{video.player.currentTime}');
                let is_completed = kdp.triggeredEndDone;
                onUpdateProgress(progress, true);
            });
        });

    };

    componentDidMount() {
        if (window.kWidget) {
            window.mw.setConfig("EmbedPlayer.DisableContextMenu",true)
            window.kWidget.thumbEmbed({
                'targetId': `${this.props.playerKey}-${this.props.kalturaEntryId}`,
                'wid': '_2413672',
                'uiconf_id': '42816162',
                'entry_id': this.props.kalturaEntryId,
                'flashvars': {
                    'streamerType': 'hdnetworkmanifest'
                },
                // 'readyCallback': this.playerReadyCallback
            });
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.kalturaEntryId != this.props.kalturaEntryId) {
            if (window.kWidget) {   
                window.mw.setConfig("EmbedPlayer.DisableContextMenu",true)
                window.kWidget.thumbEmbed({
                    'targetId': `${this.props.playerKey}-${this.props.kalturaEntryId}`,
                    'wid': '_2413672',
                    'uiconf_id': '42816162',
                    'entry_id': nextProps.kalturaEntryId,
                    'flashvars': {
                        'streamerType': 'hdnetworkmanifest'
                    },
                    'readyCallback': this.playerReadyCallback
                });
            }
        }
    }

    render() {
        return (
            <div
                id={`${this.props.playerKey}-${this.props.kalturaEntryId}`}
                style={{
                    height: this.props.playerHeight || '100%',
                    width: this.props.playerWidth || '100%'
                }}
            >
            </div>
        );
    }
}

class VideoComponent extends React.Component {

    onVideoFinish = () => {
        console.log('finished...');
        
    }

    // componentDidMount() {
    //     console.log(window.amp);

    //     const that = this;
    // 	myPlayer = window.amp(this.vid1, {
    // 		nativeControlsForTouch: false,
    // 		autoplay: false,
    // 		controls: true,
    // 		width: '100%',
    // 		height: '100%',
    // 		poster: ''
    // 	}, function () {
    // 		this.addEventListener('ended', () => {
    // 			that.onVideoFinish();
    // 		});
    // 		myPlayer.addEventListener(window.amp.eventName.play, that.startTime);
    // 	});

    // 	myPlayer.src([{
    // 		src: 'https://aktivlearnmediaservices.streaming.mediaservices.windows.net/a16d0f9c-1e07-4cea-bf8c-923b4699e6e8/ilead_v2_video.ism/manifest',
    // 		type: 'application/vnd.ms-sstr+xml'
    // 		// src: 'https://knolskape-website.s3.amazonaws.com/misc/sourabh_parmar/2018/02/22/85/ilead_v2_video.mp4'
    // 	}]);
    // }

    // componentWillUnmount() {
    //     const currentMediaTime = myPlayer.currentMediaTime();

    // 	myPlayer.src([{
    // 		src: ''
    // 	}]);
    // }

    renderLoader = () => {
        return (
            <div
                style={{
                    height: this.props.playerHeight || '952px',
                    width: this.props.playerWidth || '480px'
                }}
            >
                Loading...
            </div>
        );
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        if (checkIfPresent(this.props.interview.get('video'))) {
            return (
                <div styleName="video-container">
                    {/* <video
                        ref={(vid1) => { this.vid1 = vid1; }}
                        className="azuremediaplayer amp-default-skin amp-big-play-centered"
                    >
                    </video> */}
                    {/* <video key={this.props.propRef} width="100%" height="100%" controls >
                        <source src={this.props.interview.get('video')} type="video/mp4" />
                    </video> */}
                    {/* <embed key={this.props.propRef} src={this.props.interview.get('video')} width="100%" height="100%"></embed> */}
                    <ReactDependentScript
                        loadingComponent={this.renderLoader()}
                        scripts={[getKalturaLink()]}
                    >
                        <div
                            key={this.props.propRef}
                            style={{
                                height: this.props.playerHeight || '100%',
                                width: this.props.playerWidth || '100%'
                            }}
                        >
                            <VideoPlayerInternal
                                {...this.props}
                                kalturaEntryId={this.props.interview.get('video')}
                                playerKey={this.props.propRef}
                            />
                        </div>
                    </ReactDependentScript>
                </div>
            );
        }
        return null;
    }
}

export default applyWrappers(VideoComponent, styles);