import { connect } from 'react-redux';

const mapStateToProps = ((state) => {
    const images = state.get('images');

    return {
    
        getImage: (key, defaultValue = "", vars = {}) => {

            let image = defaultValue;

            if (images !== undefined && images.get(key) !== undefined) {
                image = images.get(key);
            }

            if (key === null || key === undefined) {
                image = defaultValue;
            }

            return image;
        }

    };
})

const mapDispatchToProps = () => ({})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps
})

const applyImages = (WrappedComponent) => connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(WrappedComponent);

export default applyImages;