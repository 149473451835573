import React from 'react';
import styles from './overlay.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import ValidationPopup from 'commonComponents/validationPopup';
import ReadPersonaPopup from 'components/popups/readPersonaPopup';
import UserInterviewPopup from 'components/popups/userInterviewPopup';
import IdeaDfvRefinePopup from 'commonComponents/ideaDfvRefinePopup';
import UserFeedbackPopup from 'components/popups/userFeedbackPopup';
import PrototypeConfirmationPopup from 'commonComponents/prototypeConfirmationPopup';
import WhatDFVScorePopup from 'commonComponents/whatDFVScorePopup';
import FeedbackReport from 'components/game/observation/feedbackReport';
import ExecutePopup from 'components/popups/executePopup';
import TimeUpComponent from '../timeUpComponent/TimeUpComponent';
import PreExecutePopup from 'commonComponents/PreExecutePopup';
import analyticsUtil from 'util/segmentUtil';

class Overlay extends React.Component {

	renderComponent = () => {
		switch(this.props.uiState.get('popupComponent')) {
			case 'VALIDATION_POPUP':
				return <ValidationPopup {...this.props} />;
			case 'READ_PERSONA_POPUP':
				return <ReadPersonaPopup {...this.props} />;
			case 'USER_INTERVIEW_POPUP':
				return <UserInterviewPopup {...this.props} />;
			case 'USER_FEEDBACK_POPUP':
				return <UserFeedbackPopup {...this.props} />;
			case 'IDEA_DFV_REFINE_POPUP':
				return <IdeaDfvRefinePopup {...this.props} />;
			case 'PROTOTYPE_CONFIRMATION_POPUP':
				analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "PROTOTYPE_CONFIRMATION_POPUP" });
				return <PrototypeConfirmationPopup {...this.props} />;
			case 'FEEDBACK_REPORT_POPUP': 
				analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "FEEDBACK_REPORT_POPUP" });
				return <FeedbackReport {...this.props} />;	
			case 'EXECUTE_POPUP':
				return <ExecutePopup {...this.props} />;
			case 'WHAT_DFV_SCORE_POPUP': 
				analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "WHAT_DFV_SCORE_POPUP" });
				return <WhatDFVScorePopup {...this.props} />;
			case 'TIME_UP': 
				if(!this.props.userState.get('isUserOnIntroScreen')) {
					analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "TIME_UP" });
					return <TimeUpComponent {...this.props} />;	
				}
				return;
			case 'PRE_EXECUTE_POPUP':
				analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "PRE_EXECUTE_POPUP" });
				return <PreExecutePopup {...this.props} />;
			default:
				return;
		}
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const overlayStyle = (!this.props.userState.get('isTimerExpired') && !this.props.userState.get('isUserGameCompleted')) ? "popup-container" : "popup-container-timer";
		return (
			<div className={css(myStyles.popupContainer)} styleName={overlayStyle}>
				<div className={css(myStyles.popupBackground)} styleName="popup-background"></div>
				<div styleName="popup-content">
					{this.renderComponent()}
				</div>
			</div>
		);
	}
}

export default applyWrappers(Overlay, styles);
