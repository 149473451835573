import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        textStyle: {
            ...myTypography.body2,
            fontSize: '14px',
            color: skin.get('grayColor2'),
            lineHeight: 1.57,
            fontWeight: 600,
			textShadow: `2px 2px 30px ${skin.get('primaryColor')}`
        }

    });
}

export default getSkin;