import defaultHeaderConfig from './defaultHeaderConfig';

const getDefaultConfigParameters = (props) => {

	const companyName = 'Knolskape';
	const userName = 'Varun Kumar';
	const profileDropDownProps = {
		uiState: props.uiState,
		userState: props.userState
	};

	return { companyName, userName, profileDropDownProps };
} 

const getHeaderConfig = (props) => {

	const configParams = getDefaultConfigParameters(props);
	return defaultHeaderConfig(configParams);
}

export default getHeaderConfig;