import React from 'react';
import styles from './dfvExpanded.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import ChangeInValue from 'commonComponents/changeInValue';
import { getArrowType } from 'util/utilFunctions';

class DfvExpanded extends React.Component {
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const showChange = this.props.showChange;
        
        const {changeDScore, changeFScore, changeVScore} = this.props;

        return (
            <div styleName="dfv-container">
                <div styleName="single-container">
                    <div className={css(myStyles.heading)} styleName="single-heading">{this.props.getLabel('label_desirability_abbreviation')}</div>
                    <div className={css(myStyles.value)} styleName="single-value">
                        {this.props.valueD}
                        {
                            showChange
                            ?   <ChangeInValue arrowType={getArrowType(changeDScore)} changeValue={changeDScore} />
                            :   null
                        }
                    </div>
                </div>
                <div styleName="single-container">
                    <div className={css(myStyles.heading)} styleName="single-heading">{this.props.getLabel('label_feasibility_abbreviation')}</div>
                    <div className={css(myStyles.value)} styleName="single-value">
                        {this.props.valueF}
                        {
                            showChange
                            ?   <ChangeInValue arrowType={getArrowType(changeFScore)} changeValue={changeFScore} />
                            :   null
                        }
                    </div>
                </div>
                <div styleName="single-container">
                    <div className={css(myStyles.heading)} styleName="single-heading">{this.props.getLabel('label_viability_abbreviation')}</div>
                    <div className={css(myStyles.value)} styleName="single-value">
                        {this.props.valueV}
                        {
                            showChange
                            ?   <ChangeInValue arrowType={getArrowType(changeVScore)} changeValue={changeVScore} />
                            :   null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default applyWrappers(DfvExpanded, styles);