import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        popupBackground: {
            borderRadius: '4px',
            boxShadow: '1px 1px 5px 0 #d5d8e2',
            backgroundColor: skin.get('white')
        },

        popupBorderTop: {
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            opacity: '0.5',
            backdropFilter: 'blur(10px)',
            backgroundImage: 'linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd)'
        },

        popupTitle: {
            ...myTypography.subtitle,
            fontWeight: 600,
            color: skin.get('grayColor2')
        },

        popupSubtitle: {
            ...myTypography.body1,
            fontWeight: 400,
            color: skin.get('grayColor2')
        },

        interviewInstruction: {
            ...myTypography.body2,
            fontWeight: 400,
            color: skin.get('grayColor2')
        },

        interviewInstructionNote: {
            ...myTypography.body2,
            lineHeight: 1.67,
            fontWeight: 400,
            color: skin.get('grayColor4')
        },

        interviewInstructionSubtitle: {
            ...myTypography.body2,
            lineHeight: 1.67,
            fontWeight: 400,
            color: skin.get('grayColor2')
        },

        personaListBorder: {
            borderBottom: 'solid 1px #d5d8e2'
        },

        loaderBackground: {
            background: hexToRgbA(skin.get('primaryColor'), 0.5)
        }
    });
}

export default getSkin;