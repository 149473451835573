import React from 'react';
import styles from './feedbackReport.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import CloseIcon from 'svgComponents/closeIcon';
import ColorHeading from 'commonComponents/colorHeading';
import consts from 'constants/url';
import analyticsUtil from 'util/segmentUtil';
import NewTag from 'svgComponents/newTag';

class FeedbackReport extends React.Component {

    // @TODO: Optimise by breaking the map incase you find a true value
    calculateIfAnyFeedbackAvailable = (result) => {

        this.props.personas.get('personaList').map((persona, index) => {
            persona.get('feedbackSurveys').map((feedback, feedbackIndex) => {
                if(feedback.get('isLevelRevealed') == 1){
                    result = true;
                }
            });
        })
        return result;
    }

    closeButtonClicked = () => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "FEEDBACK_REPORT_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
            isProjectBoardOpen: false,
        });

        const url = consts.get('ACTION_LOG_API');
        const responsePayload = {
            roundId: this.props.userState.get('currentRoundId'),
            action: {
                type: 'PERSONA_NEW_FEEDBACK_READ',
                payload: {}
            }
        };
        
        this.props.postRequestActionLog(url, responsePayload);

        // this.props.setNewFeedbackRevealedAsRead();
    }

    renderIndividualPersonaFeedback = (persona, myStyles) => {
        persona = persona.feedbackSurveys.map((feedback, index) => {
            if(feedback.isLevelRevealed == 1){
                return ( 
                    <div key={index} className={css(myStyles.feedbackPoint)} styleName="feedback">
                        <div dangerouslySetInnerHTML={{__html: this.props.getLabel(feedback.content)}}>
                        </div>
                        {
                            persona.feedbackSurveys[persona.noOfFeedbackAsked - 1].isNewInfoRevealed && persona.noOfFeedbackAsked == index + 1 ? 
                            <NewTag></NewTag>
                            : null
                        }
                    </div>
                );
            }
        });

        return [persona];
    }

    renderPersonasFeedback = (myStyles) => {
        let personas = this.props.personas.get('personaList');
        personas = personas.toJS().map((persona, index) => {
            if(persona.noOfFeedbackAsked > 0) {
                return (
                    <div key={index} styleName="persona-feedback" className={css(myStyles.persona)}>
                        <div styleName="image-name">
                            <img styleName="image" src={this.props.getImage(persona.image_circular)}/>
                            <div styleName="persona-name" className={css(myStyles.personaName)}>
                                {this.props.getLabel(persona.name)}
                            </div>
                            
                        </div>
                        {this.renderIndividualPersonaFeedback(persona, myStyles)}
                    </div>
                );
            }
        });
        return [personas];
    }

    renderCloseButton = () => {    
        return(
            <div styleName="close-icon">
                <CloseIcon
                    closeButtonClicked = {this.closeButtonClicked}
                    {...this.props} 
                />
            </div>
        )
    }

    renderPopupBorder = (myStyles) => {
        return(
            <div className={css(myStyles.popupBorderTop)} styleName="user-interview-header-border">
            </div>
        )
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div styleName="container">
                {this.renderCloseButton()}
                {this.renderPopupBorder(myStyles)}

                <div styleName="title" className={css(myStyles.title)}> 
                    <ColorHeading title={this.props.getLabel('label_feedback_report')} isUppercase={false} />
                </div>
                <div styleName="content">
                    <div styleName="helper-text" className={css(myStyles.helperText)}>
                        {this.props.getLabel('label_feedback_from_sources')}
                    </div>
                    <div styleName="personas">
                        {this.renderPersonasFeedback(myStyles)}
                    </div>
                </div>
            </div>
        );
    }
}

export default applyWrappers(FeedbackReport, styles);