import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';
import images from 'constants/images/dtOneImages';

const getSkin = (theme) => {
	const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

	return StyleSheet.create({
		popupContainer: {
			// backgroundColor: skin.get('grayColor4')
		},
		popupBackground: {
			backgroundColor: hexToRgbA(skin.get('black'), 0.6)
		}
	});
}

export default getSkin;