import React from 'react';
import styles from './executePopupCorrectTick.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ExecutePopupCorrectTick extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 34 34">
                    <g fill="none" fill-rule="nonzero">
                        <circle cx="17" cy="17" r="17" fill="#75AF5C" fill-opacity=".2"/>
                        <path fill="#000" fill-opacity=".193" d="M17.129 25.1L29.173 13.16c.33-.76.333-1.63.005-2.393a2.915 2.915 0 0 0-1.726-1.614 2.829 2.829 0 0 0-2.337.227 2.953 2.953 0 0 0-1.4 1.931L13.052 23.72c-.24 1.104.372 2.217 1.42 2.577 1.054.362 2.204-.156 2.657-1.196z"/>
                        <path fill="#000" fill-opacity=".2" d="M13.845 25.97c-4.471-2.092-6.916-3.497-7.334-4.216-.627-1.078-.627-2.117-.27-2.949a3.178 3.178 0 0 1 1.88-1.76c.846-.29 2.732.869 2.927 1.76l2.188 2.307c.176.802.124 2.042-.156 3.719.106.267.203.466.29.597.09.132.247.312.475.542z"/>
                        <path fill="#3D8E1A" d="M12.657 24.57c-3.662-1.744-5.703-2.975-6.12-3.694-.628-1.078-.628-2.118-.27-2.95a3.178 3.178 0 0 1 1.88-1.759c.845-.29 2.731.869 2.926 1.76l6.22 4.699c.263 1.204-.405 2.416-1.547 2.809-.766.263-1.795-.025-3.089-.864z"/>
                        <path fill="#3D8E1A" d="M17.365 24.192l12.044-11.938c.33-.761.333-1.63.005-2.393a2.915 2.915 0 0 0-1.725-1.615 2.829 2.829 0 0 0-2.338.228 2.953 2.953 0 0 0-1.4 1.93L13.289 22.812c-.242 1.105.371 2.217 1.419 2.577 1.054.362 2.205-.155 2.657-1.196z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(ExecutePopupCorrectTick, styles);