import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';
import empathyBackground from 'images/empathy_background.png';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        popupContentShadow: {
            borderRadius: '5px',
            boxShadow: '1px 1px 10px 0 #d8d9da',
            backgroundColor: '#fafbff'
        },

        popupHeaderStyle: {
            ...myTypography.subtitle,
            fontSize: '16px',
            lineHeight: 1,
            color: skin.get('grayColor1')
        },

        empathyBackground: {
            backgroundImage: `url(${empathyBackground})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },

        empathyQuadrantBorder: {
            borderBottom: 'dashed 1px #b8b0df',
            borderRightColor: '#b8b0df'
        },

        discStyle: {
            backgroundColor: skin.get('grayColor1')
        },

        listTextStyle: {
            ...myTypography.caption,
            lineHeight: 'normal',
            color: '#282828'
        },

        autoFillStyle: {
            ...myTypography.body2,
            color: '#e87ad9',
            fontWeight: 600,
            lineHeight: 2.08,
        },

        aboutFillStyle: {
            ...myTypography.body2,
            color: '#e031c8',
            fontWeight: 600,
            lineHeight: 2.08,
        },

        textQuadStyle: {
            ...myTypography.body2,
            lineHeight: 1.83,
            color: skin.get('grayColor3')
        },

        contentStyle: {
            ...myTypography.body2,
            lineHeight: 1.5,
            color: skin.get('grayColor2')    
        },

        initialTitle: {
            ...myTypography.body1,
            color: skin.get('grayColor3'),
            lineHeight: 1.57

        },

        descContainer: {
            backgroundColor: skin.get('white')
        },

        buttonContainer: {
            boxShadow: '1px -3px 5px 0 rgba(0, 0, 0, 0.15)',
            backgroundColor: '#faf6ff'
        },

        initialSubtitle: {
            ...myTypography.body1,
            color: skin.get('grayColor2'),
            lineHeight: 1.57
        },
        mapSubtitle: {
            ...myTypography.body1,
            color: skin.get('grayColor2'),
            fontSize: `8px`,
            fontStyle: 'italic',
            lineHeight: 1.57
        },


    });
}

export default getSkin;