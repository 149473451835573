import React from 'react';
import styles from './dfvAbbreviated.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class DfvAbbreviated extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="dfv-container">
                <div className={css(myStyles.singleContainer)} styleName="single-container">
                    D - {this.props.valueD}
                </div>
                <div className={css(myStyles.singleContainer)} styleName="single-container">
                    F - {this.props.valueF}
                </div>
                <div className={css(myStyles.singleContainer)} styleName="single-container">
                    V - {this.props.valueV}
                </div>
            </div>
        );
    }
}

export default applyWrappers(DfvAbbreviated, styles);