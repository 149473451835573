import React from 'react';
import styles from './chatTalk.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ChatTalk extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="chat-talk-container">
                <svg width="100%" height="100%" viewBox="0 0 12 10">
                    <g fill="#5A5A5A" fill-rule="evenodd">
                        <path fill-rule="nonzero" d="M6.76 5.806c.692-.277 1.239-.655 1.64-1.133.4-.478.6-.999.6-1.562 0-.563-.2-1.083-.6-1.561-.401-.478-.948-.856-1.64-1.134A6.019 6.019 0 0 0 4.5 0a6.02 6.02 0 0 0-2.26.416C1.548.694 1.001 1.072.6 1.55c-.4.478-.6.998-.6 1.561 0 .482.151.936.454 1.362.302.425.718.783 1.246 1.075a3.259 3.259 0 0 1-.13.268 1.534 1.534 0 0 1-.16.233l-.144.177c-.034.042-.09.102-.167.179a4.326 4.326 0 0 0-.2.21c-.003-.003-.012.008-.026.03l-.023.033-.016.03a.123.123 0 0 0-.013.037.226.226 0 0 0-.003.04c0 .014.002.027.007.039a.2.2 0 0 0 .073.127c.04.033.084.049.131.049h.02a5.61 5.61 0 0 0 .549-.097 5.51 5.51 0 0 0 1.777-.778c.384.065.759.097 1.125.097.814 0 1.567-.138 2.26-.416zM3.183 5.3l-.281.188c-.12.077-.251.156-.396.237l.224-.51-.62-.34c-.41-.227-.727-.495-.953-.803-.226-.307-.339-.627-.339-.96 0-.413.168-.8.502-1.16.335-.36.785-.647 1.352-.857A5.207 5.207 0 0 1 4.5.778c.652 0 1.261.105 1.828.316.567.21 1.018.496 1.352.857.335.36.502.747.502 1.16 0 .414-.167.8-.502 1.16-.334.362-.785.647-1.352.858a5.205 5.205 0 0 1-1.828.316c-.32 0-.645-.029-.978-.085l-.339-.061z"/>
                        <path d="M11.457 7.224c.362-.465.543-.965.543-1.5 0-.547-.191-1.059-.574-1.535-.383-.477-.903-.873-1.561-1.189.117.334.176.672.176 1.015 0 .597-.17 1.162-.513 1.697-.342.534-.832 1.006-1.47 1.415a7.225 7.225 0 0 1-2.02.862A9.184 9.184 0 0 1 3 8.263c1.025.587 2.23.881 3.612.881.439 0 .888-.035 1.347-.107a6.907 6.907 0 0 0 2.128.855c.183.04.403.076.658.107a.254.254 0 0 0 .268-.193c-.003-.027 0-.042.007-.044.008-.002.007-.016-.003-.043a6.367 6.367 0 0 0-.016-.04l-.019-.034a.318.318 0 0 0-.057-.07.899.899 0 0 1-.065-.063 4.964 4.964 0 0 0-.176-.167 2.793 2.793 0 0 1-.199-.197 20.93 20.93 0 0 0-.172-.194 1.684 1.684 0 0 1-.192-.257 3.484 3.484 0 0 1-.156-.293c.632-.321 1.13-.714 1.492-1.18z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(ChatTalk, styles);