import React from 'react';
import styles from './newTag.module.sass';
import applyWrappers from 'util/ComponentWrapper';
import { css } from 'aphrodite';
import getSkin from './skin';

class NewTag extends React.Component {


    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                
                <svg width="41px" height="22px" viewBox="0 0 41 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    
                <g id="Observe" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Observe_Project-Board-open" transform="translate(-574.000000, -75.000000)">
                        <g id="Group-16-Copy-2" transform="translate(574.000000, 75.000000)">
                            <polygon id="Star" fill="#DD4848" points="20.5 20.7012184 14.1651516 21.4616217 9.87311106 18.8484505 3.91515162 17.4656378 3.30533249 13.9978413 0 11 3.30533249 8.00215866 3.91515162 4.53436222 9.87311106 3.15154947 14.1651516 0.538378321 20.5 1.29878162 26.8348484 0.538378321 31.1268889 3.15154947 37.0848484 4.53436222 37.6946675 8.00215866 41 11 37.6946675 13.9978413 37.0848484 17.4656378 31.1268889 18.8484505 26.8348484 21.4616217"></polygon>
                            <text id="NEW" font-family="OpenSans-SemiBold, Open Sans" font-size="10" font-weight="500" fill="#FFFFFF">
                                <tspan x="9.06445312" y="15">{this.props.getLabel('label_new')}</tspan>
                            </text>
                        </g>
                    </g>
                </g>
                </svg>
        
            </div>
                   
        );
    }
}

export default applyWrappers(NewTag, styles);