import consts from 'constants/url';
import kfetch from '../util/kfetch';
import { setUserState } from 'actions/userState/actionCreators';
import setUIState from 'actions/uiState/actionCreators';
import updateRoute from 'util/webAppRoutes/updateRoute';
import phaseRouteConstants from 'constants/phaseRouteConstants';
import {postActionLog} from 'actions/apiAction/postActionLog';
import {postGameCompleted} from 'actions/apiAction/postGameCompleted';
import {checkIfPresent} from 'util/utilFunctions';

export const postExecuteIdeas = (payload) => (dispatch) => kfetch(consts.get('POST_EXECUTE_IDEAS'), payload, 'POST')
    .then((response) => {
        dispatch({
            type: 'UPDATE_ROUND',
            payload: {
                id: payload.roundId,
                isReportAvailable: true,
                reportData: response
            }
        });
        dispatch(setUserState({
            showHeader: false,
            showFooter: true,
            isUserGameCompleted: payload.roundId === 2 ? true : false,
            currentPhaseKey: 'endReport'
        }));
        dispatch(setUIState({
            isOverlayOpen: false,
            popupComponent: '',
            showEndReportLoader: true,
        }));
        if(checkIfPresent(response.data)) {
            dispatch({
                type: 'SET_UPDATED_METRICS',
                payload: response.data
            });
        }
        if(payload.roundId === 2) {
            dispatch(postGameCompleted({
                roundId: 2
            }));
            // dispatch(postActionLog({
            //     type: 'SET_USER_STATE',
            //     payload: {
            //         isUserGameCompleted: true
            //     }
            // }))
        }
        payload.ideaIds.map((ideaId) => {
            dispatch(postActionLog({
                type: 'UPDATE_IDEA',
                payload: {
                    id: ideaId,
                    executedRound: payload.roundId,
                    isExecuted: true
                }
            }));
        })
        updateRoute ({
            route: phaseRouteConstants['endReport'],
            params: {},
        });
        dispatch(setUIState({
            executeButtonLoading: false
        }));
    })
    .catch(() => {
        dispatch(setUIState({
            executeButtonLoading: false
        }));
    });