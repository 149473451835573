/**
 * @author: Ujjwal Jain
 * @file: teamMembers.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey } from '../../util/objectUtils';

const initialState = Immutable.fromJS({
    teamList: []
});

const teamMembers = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_TEAM_MEMBERS_DATA'):
            return state.set('teamList', mergeListsBasedOnKey(state.get('teamList'), action.payload, 'id'));
        default:
            return state;
    }
};

export default teamMembers;