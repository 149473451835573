import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        headerContainer: {
            backgroundColor: skin.get('white'),
            boxShadow: `0 1px 0 0 rgba(196, 196, 196, 0.63)`
        },
		logoName: {
            ...myTypography.h5,
            fontWeight: 700,
            color: skin.get('primaryColor')
        },
        individualItemContainer: {
            borderLeft: `2px solid ${hexToRgbA('#D6D6D6', 0.4)}`
        },
        individualItemName: {
            ...myTypography.body1,
            fontSize: '16px',
            fontWeight: 600,
            color: hexToRgbA('#353535', 0.8)
        },
        addCursor: {
            cursor: 'pointer'
        },
        addRelative: {
            position: 'relative'
        },
        quarterText: {
            ...myTypography.body1,
            fontSize: '18px',
            color: skin.get('grayColor2'),
            opacity: 0.7
        },
        metricText: {
            ...myTypography.body1,
            color: skin.get('grayColor2'),
            textTransform: 'uppercase'
        },
        metricValue: {
            ...myTypography.body1,
            fontSize: '18px',
            fontWeight: 600,
            color: skin.get('grayColor1'),
        },
        metricValueTarget: {
            ...myTypography.body2,
            fontWeight: 400,
            color: skin.get('grayColor3'),
        },
        customOption: {
			...myTypography.body2,
			color: skin.get('white'),
			cursor: 'pointer',
			':hover': {
				color: skin.get('white'),
				fontSize: '13px',
                fontWeight: 600,
                backgroundColor: hexToRgbA(skin.get('primaryColor'), 0.8)
			}
        },
        userProfileOptionContainer: {
            backgroundColor: hexToRgbA(skin.get('primaryColor'), 0.9),
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '0px',
        },
        userName: {
            borderBottom: `1px solid ${hexToRgbA(skin.get('white'), 0.2)}`,
            ...myTypography.body1,
			color: skin.get('white'),
        },
        quarterWithBorder: {
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            overflow: 'hidden',
            backdropFilter: 'blur(10px)',
            backgroundImage: 'linear-gradient(64deg, #e458fc, #473af5)',
            opacity: 0.2
        },
        quarterTextAvailable: {
            ...myTypography.body1,
            color: skin.get('grayColor1'),
            ':hover': {
                color: skin.get('primaryColor')
            }
        },
        quarterTextSelected: {
            ...myTypography.body1,
            color: skin.get('primaryColor')
        },
        quarterTextNotAvailable: {
            ...myTypography.body1,
            color: '#B2ADAD'
        }
	});
}

export default getSkin;