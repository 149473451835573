import kfetch from '../util/kfetch';
import consts from 'constants/url';

export const fetchPersonaInterviewsData = () => (dispatch) => kfetch(
        consts.get('PERSONA_INTERVIEW_API').replace(`%{ACTION_TYPE}`, 'SET_PERSONA_INTERVIEW_DATA'))
    .then((response) => {        
        dispatch({
            type: "SET_PERSONA_INTERVIEW_DATA",
            payload: response
        });

    });
