import actionConsts from 'constants/actions';

const setUIState = (payload) => {
    return {
        type: actionConsts.get('SET_UI_STATE'),
        payload
    };
};

export default setUIState;
