import { postActionDataToDB } from 'actions/apiAction/postActionDataToDB';
import { setQuestionForSegmentAsked } from '../segments/actionCreators';
import { setPersonaInfoRead, setPersonaLevelRevealed } from '../personas/actionCreators';
import {checkIfPresent} from 'util/utilFunctions';

const postRequestQuestionForSegmentAsked = (url, payload, callback) => (dispatch)  => {
    
    const responsePayload = {
        ...payload,
        reducerAction: {
            type: "SET_QUESTION_FOR_SEGMENT_ASKED",
            payload: payload
        }
    };
        
    dispatch(postActionDataToDB(url, responsePayload)).then((response) => {
        if(response.isAsked) {
            if(checkIfPresent(response.metrics)) {
                dispatch({
                    type: 'SET_UPDATED_METRICS',
                    payload: response.metrics
                });
            }
            dispatch(setQuestionForSegmentAsked(payload));
            if(checkIfPresent(callback)) {
                callback();
            }
        }
        dispatch({
            type: 'SET_USER_EMPATHIZE_PHASE_STATE',
            payload: {
                isEmpathyQuestionAsked: false
            }
        });
    })
    .catch(() => {
        dispatch({
            type: 'SET_USER_EMPATHIZE_PHASE_STATE',
            payload: {
                isEmpathyQuestionAsked: false
            }
        });
    });
};

const postRequestPersonaInfoRead = (url, payload) => (dispatch) => {

    const responsePayload = {
        ...payload,
        reducerAction: {
            type: "SET_PERSONA_INFO_READ",
            payload: payload
        }
    }

    dispatch(postActionDataToDB(url, responsePayload)).then((response) => {
        if(response.success) {
            dispatch(setPersonaInfoRead(payload));
        }
    });
    
};

const postRequestActionLog = (url, payload) => (dispatch) => {
    const responsePayload = {
        ...payload.payload,
        reducerAction: {
            type: payload.action.type,
            payload: {...payload.action.payload}
        }
    }
    
    dispatch(postActionDataToDB(url, responsePayload)).then((response) => {
        if(response.message) {
            dispatch(responsePayload.reducerAction)
        }
    });
}

const getPersonaSelectedList = (payload) => {
    let selectedPersonaId = [];
    
    payload.personaList.map((persona, index) => {
        if(persona.get('isSelected')) {
            selectedPersonaId.push(persona.get('id'));
        }
    });

    return selectedPersonaId;
}

const postRequestPersonaInterviewTaken = (url, payload) => (dispatch) => {

    const selectedPersonaPayload = getPersonaSelectedList(payload);

    const responsePayload = {
        personaIds: selectedPersonaPayload,
        roundId: payload.userState.get('currentRoundId'),
        isInterview: payload.isInterview,
        reducerAction: {
            type: "SET_PERSONA_LEVEL_REVEALED",
            payload: {
                personaIds: selectedPersonaPayload,
                roundId: payload.userState.get('currentRoundId'),
                isInterview: payload.isInterview,
            }
        }
    }
    
    dispatch(postActionDataToDB(url, responsePayload)).then((response) => {
        if(response.message) {
            if(checkIfPresent(response.metrics)) {
                dispatch({
                    type: 'SET_UPDATED_METRICS',
                    payload: response.metrics
                });
            }
            dispatch(setPersonaLevelRevealed(responsePayload));
        }
    })
}

const postRequestPersonaFeedbackTaken = (url, payload) => (dispatch) => {

    const selectedPersonaPayload = getPersonaSelectedList(payload);

    const responsePayload = {
        personaIds: selectedPersonaPayload,
        roundId: payload.userState.get('currentRoundId'),
        isInterview: payload.isInterview,
        // reducerAction: {
        //     type: "SET_PERSONA_LEVEL_REVEALED",
        //     payload: responsePayload
        // }
    }

    dispatch(postActionDataToDB(url, responsePayload)).then((response) => {
        if(response.message) {            
            dispatch(setPersonaLevelRevealed(responsePayload));
        }
    })
}


export {
    postRequestQuestionForSegmentAsked,
    postRequestPersonaInfoRead,
    postRequestPersonaInterviewTaken,
    postRequestPersonaFeedbackTaken,
    postRequestActionLog
};
