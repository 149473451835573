import React from 'react';
import { connect } from 'react-redux';
import DesktopRouter from 'router/DesktopRouter';
import routes from 'util/routes/routeConstants';
import updateRoute from 'util/webAppRoutes/updateRoute';
import DesktopHeader from 'commonComponents/desktopHeader';
import DesktopFooter from 'commonComponents/desktopFooter';
import OverlaywithTimer from 'commonComponents/overlayWithTimer';
import Overlay from 'commonComponents/overlay';
import WarningOverlay from 'commonComponents/warningOverlay';
import OverlayDropDown from 'commonComponents/overlayDropDown';
import OverlayBluredRight from 'commonComponents/overlayBluredRight';
import { fetchStorylineData } from 'actions/apiAction/fetchStorylineData';
import { fetchUserDetails } from 'actions/apiAction/fetchUserDetails';
import { fetchReportPdf } from 'actions/apiAction/fetchReportPdf';
import { fetchPersonaInterviewsData } from 'actions/apiAction/fetchPersonaInterviewsData';
import { fetchPersonaFeedbackSurveyData } from 'actions/apiAction/fetchPersonaFeedbackSurveyData';
import { fetchLabelsData } from 'actions/apiAction/fetchLabelsData';
import { setUserState, updateBudgetAlertData } from 'actions/userState/actionCreators';
import { resetDefineFocus } from 'actions/define/actionCreator.js';
import { setQuestionForSegmentAsked, setResponseClickedToFillQuadrant, autoFillResponsesInQuadrant } from 'actions/segments/actionCreators';
import { setPersonaSelectedorDeselected, resetAllPersonasSelected, setNewInfoRevealedAsRead, setNewFeedbackRevealedAsRead } from 'actions/personas/actionCreators';
import updateIdea from 'actions/ideas/actionCreators';
import { replayAllReducerActions } from 'actions/replayActions/replayActions';
import setUIState from 'actions/uiState/actionCreators';
import { updateEvent } from 'actions/events/actionCreators';
import {
    setUserObservePhaseState,
    setUserEmpathizePhaseState,
    setUserDefinePhaseState,
    updateDefineFocusValidationData
} from 'actions/userPhasesState/actionCreators';
import {
    postRequestQuestionForSegmentAsked,
    postRequestPersonaInfoRead,
    postRequestPersonaInterviewTaken,
    postRequestPersonaFeedbackTaken,
    postRequestActionLog
} from 'actions/postRequest/actionCreators';
import { updateTimer } from 'actions/timer/actionCreators';
import { postUnlockDFV } from 'actions/apiAction/postUnlockDFV';
import { postDigdeepDFV } from 'actions/apiAction/postDigdeepDFV';
import { postTestPrototype } from 'actions/apiAction/postTestPrototype';
import { postPrioritizeIdea } from 'actions/apiAction/postPrioritizeIdea';
import { postPrototypeIdea } from 'actions/apiAction/postPrototypeIdea';
import { postActionLog } from 'actions/apiAction/postActionLog';
import { postSaveNotes } from 'actions/apiAction/postSaveNotes';
import { moveToIdeate } from 'util/phaseMovement';
import { fetchLeaderboard } from 'actions/apiAction/fetchLeaderboard';
import { postExecuteIdeas } from 'actions/apiAction/postExecuteIdeas';
import Loader from 'svgComponents/loader';
import NotesComponent from 'commonComponents/notesComponent';
import EventsAlert from 'commonComponents/eventsAlert';
import ReportIframe from 'commonComponents/reportIframe';
import {checkIfPresent, getFreshdeskString} from 'util/utilFunctions';
import {appSentryEnabled, getAppEnv} from 'util/appVariables.js';
import {postGameCompleted} from 'actions/apiAction/postGameCompleted';

class DesktopDashboardContainer extends React.Component {

    redirectToIntro = () => {
        const route = routes.get('INTRO');
        updateRoute({
            route
        });

        return null;
    }


    componentDidMount() {
        this.props.fetchStorylineData();
        this.props.fetchUserDetails();
        this.props.fetchLabelsData();
        this.props.fetchLeaderboard();
        this.printViewportDetails();
        // if(appSentryEnabled()) {
        //     this.initializeSentry();
        // }
    }

	componentWillReceiveProps(nextProps) {
		this.printViewportDetails();
		if (this.props.userState.get("isUserStateDataFetched")) {
            if (
				!this.props.uiState.get("freshdeskInitialized") &&
				this.props.userState.get("logoutType").toLowerCase() == "normal"
			) {
				this.initializeFreshDesk(nextProps);
            }
            
			if (
				appSentryEnabled() &&
				!this.props.uiState.get("sentryInitialized")
			) {
				this.initializeSentry(nextProps);
			}
		}
	}

    initializeSentry = (nextProps) => {
        // const sentryObj = {
        //     "emailId": nextProps.userState.get('emailId'),
        //     "groupId": nextProps.userState.get('groupId'),
        //     "firstName": nextProps.userState.get('firstName'),
        //     "lastName": nextProps.userState.get('lastName'),
        //     "uliId": nextProps.userState.get('uliId'),
        // }
        // Sentry.init({
        //     sentry_dsn: process.env.SENTRY_DSN ,  
        //     sentry_environment: process.env.SENTRY_ENVIRONMENT,
        //     sentry_release: process.env.SENTRY_RELEASE,
        // });
        // window.Sentry.configureScope((scope) => {
        //     scope.setUser(sentryObj);
        // });
        nextProps.setUIState({
            sentryInitialized: true
        });
        console.log("Sentry has been initialized");
    }

    initializeFreshDesk = (props) => {
        let freshDeskId = 1000006679;
        let emailID = checkIfPresent(props.userState.get('emailId'))
            ? props.userState.get('emailId')
            : '';

        let simulation = 'Design Thinking';

        const buttonColor = '#F7F7F7';

        const textColor = 'rgb(102, 26, 184)'

        let stringQ = getFreshdeskString(freshDeskId, emailID, simulation);
        

        var freshdeskObj = {
            loadOnEvent: 'immediate',
            queryString: stringQ,
            utf8: 'âœ“',
            widgetType: "popup",
            buttonType: 'text',
            buttonText: 'Support',
            buttonColor: textColor,
            buttonBg: buttonColor,
            alignment: "2",
            offset: "550px",
            formHeight: "550px",
            url: 'https://knolskape.freshdesk.com'
        };
        window.FreshWidget.init('', freshdeskObj);
        this.props.setUIState({
            freshdeskInitialized: true
        })
    }

    printViewportDetails = () => {

        let viewPortWidth;
        let viewPortHeight;

        // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
        if (typeof window.innerWidth != 'undefined') {
            viewPortWidth = window.innerWidth;
            viewPortHeight = window.innerHeight;
        }

        // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
        else if (typeof document.documentElement != 'undefined'
            && typeof document.documentElement.clientWidth !==
            'undefined' && document.documentElement.clientWidth !== 0) {
            viewPortWidth = document.documentElement.clientWidth;
            viewPortHeight = document.documentElement.clientHeight;
        }

        // older versions of IE
        else {
            viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
            viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
        }

        this.props.setUIState({
            viewPortWidth: viewPortWidth,
            viewPortHeight: viewPortHeight
        })
    }

    render() {
        if (
            !this.props.userState.get('isReducerActionReplayed')
            || !this.props.colorProfiles.get('isColorDataFetched')
            || !this.props.labels.get('isLabelsFetched')
            || !this.props.userState.get('isUserStateDataFetched')
        ) {
            return (
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    position: 'relative',
                    minWidth: '1280px',
                    minHeight: '670px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                >
                    <Loader fillColor="#d8d8d8" />
                </div>
            );
        }
        if (this.props.location.pathname === '/') {
            return this.redirectToIntro();
        }
        return (
            <div style={{
                width: '100vw',
                height: this.props.uiState.get('isUserOnIntroScreen') ? '100vh' : '100%',
                position: 'relative',
                minWidth: '1280px',
                minHeight: '670px',
            }}
            >
                {
                    this.props.userState.get('showHeader')
                        ? <DesktopHeader {...this.props} />
                        : null
                }
                <DesktopRouter />
                {
                    this.props.userState.get('showFooter')
                        ? <DesktopFooter {...this.props} />
                        : null
                }
                {
                    this.props.uiState.get('isOverlayWithTimerOpen')
                        ? <OverlaywithTimer {...this.props} />
                        : null
                }
                {
                    this.props.uiState.get('isOverlayOpen') && !this.props.uiState.get('isUserOnIntroScreen')
                        ? <Overlay {...this.props} />
                        : null
                }
                {
                    this.props.uiState.get('showWarningPopup')
                        ? <WarningOverlay {...this.props} />
                        : null
                }
                {
                    this.props.uiState.get('isDropDownOverlayOpen')
                        ? <OverlayDropDown {...this.props} />
                        : null
                }
                {
                    this.props.uiState.get('isBluredRightOverlay')
                        ? <OverlayBluredRight {...this.props} />
                        : null
                }
                {
                    //  this.props.uiState.get('showNotes') && !this.props.userState.get('isUserGameCompleted')
                     this.props.uiState.get('showNotes')
                        ? <NotesComponent {...this.props} />
                        : null
                }
                {
                    this.props.uiState.get('showEventAlert')
                        ? <EventsAlert {...this.props} />
                        : null
                }
                {
                    this.props.uiState.get('openReportIframe')
                        ? <ReportIframe {...this.props} />
                        : null
                }
            </div>
        );
    }
}

DesktopDashboardContainer.propTypes = {};

DesktopDashboardContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        uiState: state.get('uiState'),
        metrics: state.get('metrics'),
        userPhasesState: state.get('userPhasesState'),
        userState: state.get('userState'),
        user: state.get('user'),
        personas: state.get('personas'),
        segments: state.get('segments'),
        rounds: state.get('rounds'),
        teamMembers: state.get('teamMembers'),
        phases: state.get('phases'),
        touchPoints: state.get('touchPoints'),
        ideas: state.get('ideas'),
        testQuadrants: state.get('testQuadrants'),
        colorProfiles: state.get('colorProfiles'),
        labels: state.get('labels'),
        events: state.get('events'),
        userEvents: state.get('userEvents'),
        leaderboard: state.get('leaderboard'),
        storyline: state.get('storyline'),
        timer: state.get('timer'),
        firebase: state.get('firebase'),
        additionalParams: state.get('additionalParams')
    }
};

const mapDispatchToProps = dispatch => ({
    fetchStorylineData: () => {
        dispatch(fetchStorylineData());
    },
    fetchUserDetails: () => {
        dispatch(fetchUserDetails());
    },
    fetchPersonaInterviewsData: () => {
        dispatch(fetchPersonaInterviewsData());
    },
    fetchPersonaFeedbackSurveyData: () => {
        dispatch(fetchPersonaFeedbackSurveyData());
    },
    fetchLabelsData: () => {
        dispatch(fetchLabelsData());
    },
    setUserState: (payload) => {
        dispatch(setUserState(payload))
    },
    resetDefineFocus: () => {
        dispatch(resetDefineFocus())
    },
    setQuestionForSegmentAsked: (payload) => {
        dispatch(setQuestionForSegmentAsked(payload))
    },
    setPersonaSelectedorDeselected: (payload) => {
        dispatch(setPersonaSelectedorDeselected(payload))
    },
    postRequestQuestionForSegmentAsked: (url, payload, callback) => {
        dispatch(postRequestQuestionForSegmentAsked(url, payload, callback))
    },
    postRequestPersonaInfoRead: (url, payload) => {
        dispatch(postRequestPersonaInfoRead(url, payload))
    },
    postRequestPersonaInterviewTaken: (url, payload) => {
        dispatch(postRequestPersonaInterviewTaken(url, payload))
    },
    postRequestPersonaFeedbackTaken: (url, payload) => {
        dispatch(postRequestPersonaFeedbackTaken(url, payload))
    },
    postRequestActionLog: (url, payload) => {
        dispatch(postRequestActionLog(url, payload))
    },
    setResponseClickedToFillQuadrant: (url, payload) => {
        dispatch(setResponseClickedToFillQuadrant(url, payload))
    },
    updateIdea: (payload) => {
        dispatch(updateIdea(payload))
    },
    updateTimer: (payload) => {
        dispatch(updateTimer(payload))
    },
    setUserObservePhaseState: (payload) => {
        dispatch(setUserObservePhaseState(payload))
    },
    setUIState: (payload) => {
        dispatch(setUIState(payload))
    },
    setUserEmpathizePhaseState: (payload) => {
        dispatch(setUserEmpathizePhaseState(payload))
    },
    setUserDefinePhaseState: (payload) => {
        dispatch(setUserDefinePhaseState(payload))
    },
    setNewFeedbackRevealedAsRead: () => {
        dispatch(setNewFeedbackRevealedAsRead())
    },
    updateDefineFocusValidationData: (payload) => {
        dispatch(updateDefineFocusValidationData(payload))
    },
    autoFillResponsesInQuadrant: (payload) => {
        dispatch(autoFillResponsesInQuadrant(payload))
    },
    postUnlockDFV: (payload) => {
        dispatch(postUnlockDFV(payload))
    },
    postDigdeepDFV: (payload) => {
        dispatch(postDigdeepDFV(payload))
    },
    resetAllPersonasSelected: () => {
        dispatch(resetAllPersonasSelected())
    },
    setNewInfoRevealedAsRead: (payload) => {
        dispatch(setNewInfoRevealedAsRead(payload))
    },
    replayAllReducerActions: (payload) => {
        dispatch(replayAllReducerActions(payload))
    },
    postTestPrototype: (payload) => {
        dispatch(postTestPrototype(payload))
    },
    postPrioritizeIdea: (payload) => {
        dispatch(postPrioritizeIdea(payload))
    },
    postPrototypeIdea: (payload) => {
        dispatch(postPrototypeIdea(payload))
    },
    postActionLog: (payload, callback) => {
        dispatch(postActionLog(payload, callback))
    },
    moveToIdeate: () => {
        moveToIdeate(dispatch);
    },
    fetchLeaderboard: () => {
        dispatch(fetchLeaderboard());
    },
    postExecuteIdeas: (payload) => {
        dispatch(postExecuteIdeas(payload))
    },
    postSaveNotes: (payload) => {
        dispatch(postSaveNotes(payload))
    },
    updateBudgetAlertData: (payload) => {
        dispatch(updateBudgetAlertData(payload))
    },
    updateEvent: (payload) => {
        dispatch(updateEvent(payload));
    },
    fetchReportPdf: (payload) => {
        dispatch(fetchReportPdf(payload));
    },
    postGameCompleted: (payload) => {
        dispatch(postGameCompleted(payload));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopDashboardContainer);
