import Immutable from 'immutable';

const header = Immutable.Map({
	COMPANY_NAME: 'Knolskape',
	USERNAME: 'Hello there',
	JOURNEY_SWITCHER_PROPS: {},
	PROFILE_DROPDOWN_PROPS: {},
	NOTIFICATION_PROPS: {},
	IS_DATA_AVAILABLE: true
});

export default header;
