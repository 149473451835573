import React from 'react';
import styles from './tagsList.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class TagsList extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const { tagsList, tagColor } = this.props;

        if(this.props.tagsList.length > 0) {
            const tagDivs = tagsList.map((eachTag) => {
                return (
                    <div
                        className={css(myStyles.eachTag)}
                        styleName="each-tag"
                        style={{
                            backgroundColor: checkIfPresent(tagColor) ? tagColor : null
                        }}
                    >
                        {eachTag}
                    </div>
                );
            });
            return (
                <div styleName="tags-list-container">
                    {tagDivs}
                </div>
            );
        }
        return null;
    }
}

export default applyWrappers(TagsList, styles);