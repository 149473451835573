import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		notesHeading: {
			...myTypography.body2,
			fontWeight: 600
		},
		saveButton: {
			...myTypography.body2,
			color: skin.get('primaryColor'),
			fontWeight: 600,
			':hover': {
				textDecoration: 'underline'
			}
		}
	});
}

export default getSkin;