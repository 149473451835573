import React from 'react';
import styles from './eventBackground.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class EventBackground extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        return (
            <div>
                <svg width="100%" height="100%" viewBox="0 0 181 123">
                    <g fill="#6C63FF" fill-rule="nonzero">
                        <path d="M181.002.145S81.188-6.551 92.966 71.059c0 0-2.335 13.711 8.867 19.941 0 0 .183-5.822 10.23-3.784 3.578.668 7.215.863 10.834.582 4.903-.376 9.562-2.511 13.274-6.084 0 0 28.043-12.867 38.955-63.753 0 0 8.08-11.091 7.87-13.944l-16.841 7.976s5.745 13.478 1.206 24.686c0 0-.524-24.191-3.777-23.609-.656 0-8.735 4.658-8.735 4.658s9.89 23.492 2.413 40.551c0 0 2.833-28.936-5.509-38.834l-11.857 7.657s11.569 24.278 3.725 44.073c0 0 2.02-30.362-6.217-42.181l-10.755 9.286s10.886 23.9 4.25 40.319c0 0-.866-35.34-6.585-37.844 0 0-9.391 9.17-10.808 12.954 0 0 7.424 17.466 2.807 26.52 0 0-2.833-23.55-5.247-23.638 0 0-9.365 15.603-10.335 26.2.451-9.889 3.228-19.473 8.053-27.801-5.397 1.133-10.377 3.997-14.323 8.238 0 0 1.443-11.033 16.632-11.993 0 0 7.87-11.82 9.81-12.547 0 0-15.11-1.398-24.264 3.115 0 0 8.053-10.422 27.045-5.823l10.493-9.606s-19.884-2.911-28.33.32c0 0 9.705-9.228 31.19-2.503l11.542-7.686s-16.946-4.046-27.046-2.59c0 0 10.65-6.376 30.43.553l8.263-4.134s-12.434-2.708-16.054-3.115c-3.62-.408-3.83-1.543-3.83-1.543 7.866-1.453 15.942-.445 23.32 2.911 0 0 17.629-7.22 17.34-8.442z" opacity=".1"/>
                        <ellipse cx="91.5" cy="107" opacity=".04" rx="91.5" ry="16"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(EventBackground, styles);