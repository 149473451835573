/**
 * @author: Ujjwal Jain
 * @file: rounds.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey, setOrUpdateObjectInList } from '../../util/objectUtils';

const initialState = Immutable.fromJS({
    roundList: []
});

const rounds = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_ROUNDS_DATA'):
            return state.set('roundList', mergeListsBasedOnKey(state.get('roundList'), formatRounds(action.payload), 'id'));
        case actionConsts.get('UPDATE_ROUND'):
            return state.set('roundList', setOrUpdateObjectInList(state.get('roundList'), action.payload, 'id', 'id'));
        default:
            return state;
    }
};

const formatRounds = (list) => {
    list.map((eachData) => {
        eachData.isRoundUnlocked = false;
        eachData.isReportAvailable = false;
        eachData.reportData = {};
        return;
    });
    return list;
}

export default rounds;