import consts from 'constants/url';
import kfetch from '../util/kfetch';
import {checkIfPresent} from 'util/utilFunctions';
import setUIState from 'actions/uiState/actionCreators';

export const postDigdeepDFV = (payload) => (dispatch) => {
    kfetch(consts.get('DIG_DEEP_IDEA_DFV_SCORE'), payload, 'POST')
    .then((response) => {        
        dispatch({
            type: 'UPDATE_IDEA',
            payload: {
                id: payload.ideaId,
                dfv_refined: true,
                digdeep_response: response,
            }
        });
        if(checkIfPresent(response.metrics)) {
            dispatch({
                type: 'SET_UPDATED_METRICS',
                payload: response.metrics
            });
        }
        dispatch(setUIState({
            refineIdeaButtonLoading: false
        }));
    })
    .catch(() => {
        dispatch(setUIState({
            refineIdeaButtonLoading: false
        }));
    })
};