import actionConsts from 'constants/actions';

const setUserState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_STATE'),
        payload
    };
};

const updateBudgetAlertData = (payload) => {
    return {
        type: actionConsts.get('UPDATE_BUDGET_ALERT_DATA'),
        payload
    };
}
export {
    setUserState,
    updateBudgetAlertData
};
