import React from 'react';
import styles from './prototypeConfirmationPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import FilledButton from 'commonComponents/buttons/filledButton';
import HollowButton from 'commonComponents/buttons/hollowButton';
import CloseIcon from 'svgComponents/closeIcon';
import {getCurrentIdea} from 'util/utilFunctions';
import {getFormattedCurrency} from 'util/currencyFormatter';
import analyticsUtil from 'util/segmentUtil';
class PrototypeConfirmationPopup extends React.Component {

    onClickOfClosePopup = () => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "PROTOTYPE_CONFIRMATION_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    onClickPrototypeIdea = (currentIdea) => {
        this.props.postPrototypeIdea({
            ideaId: currentIdea.id,
            roundId: this.props.userState.get('currentRoundId'),
            reducerAction: {
                type: 'UPDATE_IDEA',
                payload: {
                    id: currentIdea.id,
                    isShortlistedIdea: false,
                    isShortlistedPrototype: true,
                    timeStamp: new Date().getTime()
                }
            }
        });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    renderBorder = (myStyles) => {
        return (
            <div className={css(myStyles.borderContainer)} styleName="border-container"></div>
        );
    }

    renderHeadingContent = (myStyles, currentIdea) => {
        return (
            <div styleName="heading-container">
                <div className={css(myStyles.headingMain)} styleName="heading-main">
                    {this.props.getLabel('label_prototype_cost', "", {
                        CURRENCY: '',
                        COST: getFormattedCurrency(currentIdea.cost.prototypeCost, 'USD')
                    })}
                </div>
                <div className={css(myStyles.headingDesc)} styleName="heading-desc">
                    {this.props.getLabel('label_prototype_goahead')}
                </div>
            </div>
        );
    }

    renderButton = (myStyles, currentIdea) => {
        return (
            <div className={css(myStyles.buttonsContainer)} styleName="buttons-container">
                <div styleName="buttons-content">
                    <div styleName="action-button">
                        <HollowButton
                            clickFunction={() => {
                                this.onClickOfClosePopup();
                            }}
                            textLabel={this.props.getLabel('label_no_prototype')}
                        />
                    </div>
                    <div styleName="action-button">
                        <FilledButton
                            clickFunction={() => {
                                this.onClickPrototypeIdea(currentIdea);
                            }}
                            textLabel={this.props.getLabel('label_yes_prototype')}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderCloseIcon = (myStyles) => {
        return (
            <div styleName="close-button">
                <CloseIcon closeButtonClicked={this.onClickOfClosePopup} />
            </div>
        );
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);

        const {ideas, userPhasesState} = this.props;
        let currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userPrototype').get('activeIdeaId')).toJS();

        if(this.props.userState.get('currentPhaseKey') === 'ideate') {
            currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userIdeate').get('activeIdeaId')).toJS();
        } else if(this.props.userState.get('currentPhaseKey') === 'prototype') {
            currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userPrototype').get('activeIdeaId')).toJS();
        } else if(this.props.userState.get('currentPhaseKey') === 'test') {
            currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userTestPrototype').get('activeIdeaId')).toJS();
        }
        return (
            <div className={css(myStyles.validationContainer)} styleName="popup-container">
                {this.renderCloseIcon(myStyles)}
                {this.renderBorder(myStyles)}
                {this.renderHeadingContent(myStyles, currentIdea)}
                {this.renderButton(myStyles, currentIdea)}
            </div>
        );
    }
}

export default applyWrappers(PrototypeConfirmationPopup, styles);