import actionConsts from 'constants/actions';

const setUserObservePhaseState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_OBSERVE_PHASE_STATE'),
        payload
    };
};

const setUserEmpathizePhaseState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_EMPATHIZE_PHASE_STATE'),
        payload
    };
};

const setUserDefinePhaseState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_DEFINE_PHASE_STATE'),
        payload
    };
};

const setUserIdeatePhaseState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_IDEATE_PHASE_STATE'),
        payload
    };
};

const setUserPrototypePhaseState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_PROTOTYPE_PHASE_STATE'),
        payload
    };
};

const setUserTestPrototypePhaseState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_TEST_PROTOTYPE_PHASE_STATE'),
        payload
    };
};

const updateDefineFocusValidationData = (payload) => {
    return {
        type: actionConsts.get('UPDATE_DEFINE_FOCUS_DATA'),
        payload
    };
};

export {
    setUserObservePhaseState,
    setUserEmpathizePhaseState,
    setUserDefinePhaseState,
    setUserIdeatePhaseState,
    setUserPrototypePhaseState,
    setUserTestPrototypePhaseState,
    updateDefineFocusValidationData
};
