/**
 * @author: Ujjwal Jain
 * @file: postActionDataToDB.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import kfetch from '../util/kfetch';

export const postActionDataToDB = (url, payload) => (dispatch) => kfetch(url, payload, 'POST')
    .then((response) => {
        return response;
    });
