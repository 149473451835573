import React, { Component } from 'react';
import styles from './hollowButton.module.sass';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class HollowButton extends Component {

    onClickOfFilledButton = () => {
        if(checkIfPresent(this.props.disableButton)) {
            if(this.props.disableButton === true) {
                return;
            } else {
                if(checkIfPresent(this.props.clickFunction)) {
                    this.props.clickFunction();
                }
            }
        } else {
            if(checkIfPresent(this.props.clickFunction)) {
                this.props.clickFunction();
            }
        }
    }

    render() {
        let buttonShadow = true;
        if (this.props.showButtonShadow !== undefined && this.props.showButtonShadow !== null) {
            buttonShadow = this.props.showButtonShadow;
        }
        const myStyles = getSkin(this.props.skinGuide);
        const buttonShadowStyle = buttonShadow
            ? css(myStyles.buttonStyle)
            : css(myStyles.buttonStyleWithoutShadow);

        const isDisabled = checkIfPresent(this.props.disableButton)
            ? this.props.disableButton ? 1 : 0
            : 0;

        const propStyles = checkIfPresent(this.props.propStyles) ? this.props.propStyles : {};
        return (
            <div styleName="table-display"
                style={{
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    opacity: isDisabled ? 0.5 : 1
                }}
            >
                <div
                    className={buttonShadowStyle}
                    styleName="button-style" onClick={() => this.onClickOfFilledButton()}
                    style={{
                        minWidth: checkIfPresent(this.props.minWidth) ? this.props.minWidth : null,
                        ...propStyles,
                        opacity: isDisabled ? 0.5 : 1,
                        cursor: isDisabled ? 'not-allowed' : 'pointer'
                    }}
                >   
                    <div className={css(myStyles.hollowBackground)} styleName="hollow-background"></div>
                    <div className={css(myStyles.buttonText, myStyles.hollowButton)} styleName="hollow-button">
                        {/* {this.props.getLabel(this.props.textLabel)} */}
                        {this.props.textLabel}
                    </div>
                </div>
            </div>
        );
    }
}

export default applyWrappers(HollowButton, styles);
