import React from 'react';
import styles from './reportIframe.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import CloseIcon from 'svgComponents/closeIcon';
import Loader from 'svgComponents/loader';
import consts from 'constants/url';
import HollowButton from 'commonComponents/buttons/hollowButton';

class ReportIframe extends React.Component {

    onClickDownloadReport = () => {
        this.props.setUIState({
            isReportDownloading: true
        });
        this.props.fetchReportPdf({});
    }

    closeReportIframe = () => {
        this.props.setUIState({
            openReportIframe: false
        });
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="iframe-container">
                <div styleName="iframe-header">
                    <div className={css(myStyles.heading)} styleName="iframe-heading">
                        {this.props.getLabel('label_user_report')}
                    </div>
                    <div styleName="iframe-header-download-button">
                        <HollowButton
                            clickFunction={
                                this.props.uiState.get('isReportDownloading')
                                ?   null
                                :   () => {this.onClickDownloadReport();}
                            }
                            disableButton={
                                this.props.uiState.get('isReportDownloading')
                                ?   true
                                :   false
                            }
                            textLabel={
                                this.props.uiState.get('isReportDownloading')
                                ?   this.props.getLabel('label_report_downloading')
                                :   this.props.getLabel('label_download_report')
                            }
                            propStyles={{
                                padding: '5px 12px'
                            }}
                        />
                    </div>
                    <div styleName="iframe-header-close">
                        <div className={css(myStyles.closeButton)}>{this.props.getLabel('label_close_report')}</div>
                        <div styleName="close-button">
                            <CloseIcon
                                closeButtonClicked={this.closeReportIframe}
                            />
                        </div>
                    </div>
                </div>
                <div className={css(myStyles.borderReport)} styleName="header-border"></div>
                <div id="iframe-loader" styleName="iframe-loading-container">
                    <Loader />
                </div>
                <iframe
                    src={consts.get('USER_REPORT_WEB_URL')}
                    styleName="iframe-content"
                    onLoad={
                        () => document.getElementById('iframe-loader').style.display = 'none'
                    }
                />
            </div>
        );
    }
}

export default applyWrappers(ReportIframe, styles);