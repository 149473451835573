import { setUserState } from 'actions/userState/actionCreators';
import updateRoute from 'util/webAppRoutes/updateRoute';
import phaseRouteConstants from 'constants/phaseRouteConstants';

const moveToObserve = (dispatch) => {
	dispatch(setUserState({
		currentPhaseId: 1,
		currentPhaseKey: 'observe'
	}));
	updateRoute({
		route: phaseRouteConstants['observe'],
		params: {},
	});
}

const moveToEmpathize = (dispatch) => {
	dispatch(setUserState({
		currentPhaseId: 2,
		currentPhaseKey: 'empathize'
	}));
	updateRoute({
		route: phaseRouteConstants['empathize'],
		params: {},
	});
}

const moveToDefine = (dispatch) => {
	dispatch(setUserState({
		currentPhaseId: 3,
		currentPhaseKey: 'define'
	}));
	updateRoute({
		route: phaseRouteConstants['define'],
		params: {},
	});
}

const moveToIdeate = (dispatch) => {
	dispatch(setUserState({
		currentPhaseId: 4,
		currentPhaseKey: 'ideate'
	}));
	updateRoute({
		route: phaseRouteConstants['ideate'],
		params: {},
	});
}

export {
	moveToObserve,
	moveToEmpathize,
	moveToDefine,
	moveToIdeate,
};