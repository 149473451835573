import React from 'react';
import styles from './walkthroughArrow.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class WalkthroughArrow extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 38 36">
                    <g fill="none" fill-rule="evenodd" stroke={this.props.stroke} stroke-linecap="round" stroke-width="3.6" opacity=".3">
                        <path stroke-linejoin="round" d="M7.787 2.561l-5.132 11.81 11.74 5.08"/>
                        <path d="M35.019 33.798c2.894-8.153-.318-17.188-7.684-21.616-7.85-4.47-17.666-3.597-24.672 2.195"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(WalkthroughArrow, styles);