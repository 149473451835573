import React from 'react';
import styles from './profileDropDown.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ProfileDropDown extends React.Component {

    onClickProfileItem = () => {
        // this.props
    }

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div onClick={(e) => {e.stopPropagation()}} styleName="profile-dropdown-container">
               <div>Onboarding</div>
               <div>Logout</div>
            </div>
        );
    }
}

export default applyWrappers(ProfileDropDown, styles);