/**
 * @author: Ujjwal Jain
 * @file: userObserve.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({
    isIntroPageSeen: false,
    showIntroPage: false,
    isPhaseAvailableToUser: false,
    actionCardsRevealed: false,
    currentPersonaSelectedObject: {},
    atleastOneInterviewTaken: false,
    atleastOneFeedbackTaken: false,
    understandTeamButtonClicked: false,
    isPersonaInterviewPopup: false,
    showProceedToEmpathizePhase: false,
    isRevealSegmentsClicked: false,
    suggestions: []
});


const userObserve = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_OBSERVE_PHASE_STATE'):
            return state.merge(Immutable.fromJS(action.payload));
        case actionConsts.get('POPULATE_OBSERVE_SUGGESTIONS'):
            return state.set('suggestions', Immutable.fromJS(action.payload));
        case 'MAKE_OBSERVE_PHASE_AVAILABLE':
            return state.set('isPhaseAvailableToUser', true);
        default:
            return state;
    }
};

export default userObserve;