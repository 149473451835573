import Immutable from 'immutable';

const actions = Immutable.Map({

    FETCH_INIT_API: 'FETCH_INIT_API',
    BASE_DATA: 'BASE_DATA',

    /**Initial data actions */
    INIT_INTRO_DATA: 'INIT_INTRO_DATA',
    INIT_PHASE_DATA: 'INIT_PHASE_DATA',
    INIT_SEGMENTS_DATA: 'INIT_SEGMENTS_DATA',
    INIT_TOUCHPOINTS_DATA: 'INIT_TOUCHPOINTS_DATA',
    INIT_PERSONAS_DATA: 'INIT_PERSONAS_DATA',
    INIT_ROUNDS_DATA: 'INIT_ROUNDS_DATA',
    INIT_FIREBASE_DATA: 'INIT_FIREBASE_DATA',

    UPDATE_ROUND: 'UPDATE_ROUND',
    INIT_TEAM_MEMBERS_DATA: 'INIT_TEAM_MEMBERS_DATA',
    INIT_USER_DATA: 'INIT_USER_DATA',
    INIT_USER_STATE_DATA: 'INIT_USER_STATE_DATA',
    INIT_LABELS_DATA: 'INIT_LABELS_DATA',
    INIT_METRICS_DATA: 'INIT_METRICS_DATA',
    INIT_INTRO_BASE_DATA: 'INIT_INTRO_BASE_DATA',
    INIT_USER_NOTES_DATA: 'INIT_USER_NOTES_DATA',
    INIT_BUDGET_ALERT_DATA: 'INIT_BUDGET_ALERT_DATA',
    UPDATE_BUDGET_ALERT_DATA: 'UPDATE_BUDGET_ALERT_DATA',

    INITIALIZE_TIMER: 'INITIALIZE_TIMER',
    INIT_ADDITIONAL_PARAMS: 'INIT_ADDITIONAL_PARAMS',
    INTRO_BUTTON_CLICKED: 'INTRO_BUTTON_CLICKED',
    INIT_COLOR_PROFILE_DATA: 'INIT_COLOR_PROFILE_DATA',

    UPDATE_EVENT: 'UPDATE_EVENT',
    SET_USER_EVENTS: 'SET_USER_EVENTS',
    UPDATE_USER_EVENTS: 'UPDATE_USER_EVENTS',
    
    SET_UPDATED_METRICS: 'SET_UPDATED_METRICS',
    SET_USER_STATE: 'SET_USER_STATE',
    SET_PERSONA_INTERVIEW_DATA: 'SET_PERSONA_INTERVIEW_DATA',
    SET_PERSONA_FEEDBACK_SURVEY_DATA: 'SET_PERSONA_FEEDBACK_SURVEY_DATA',
    SET_IMAGES_FOR_STORYLINE: 'SET_IMAGES_FOR_STORYLINE',
    SET_UNDERSTAND_TEAM_VIEWS_DATA: 'SET_UNDERSTAND_TEAM_VIEWS_DATA',
    SET_IDEAS: 'SET_IDEAS',
    UPDATE_IDEA: 'UPDATE_IDEA',
    UPDATE_IDEA_TEST_REPORT: 'UPDATE_IDEA_TEST_REPORT',
    UPDATE_USER_IDEAS: 'UPDATE_USER_IDEAS',

    SET_DEFINE_FOCUS_DATA: 'SET_DEFINE_FOCUS_DATA',
    UPDATE_DEFINE_FOCUS_DATA: 'UPDATE_DEFINE_FOCUS_DATA',
    SET_SELECTED_SEGMENT_ID: 'SET_SELECTED_SEGMENT_ID',
    SET_SELECTED_TOUCHPOINT_ID: 'SET_SELECTED_TOUCHPOINT_ID',
    VALIDATE_FOCUS: 'VALIDATE_FOCUS',
    RESET_FOCUS: 'RESET_FOCUS',
    SET_IDEATE_STATE: 'SET_IDEATE_STATE',

    SET_QUESTION_FOR_SEGMENT_ASKED: 'SET_QUESTION_FOR_SEGMENT_ASKED',
    SET_QUESTION_FOR_SEGMENT_ASKED: 'SET_QUESTION_FOR_SEGMENT_ASKED',
    SET_RESPONSE_FOR_QUADRANT_FILLED: 'SET_RESPONSE_FOR_QUADRANT_FILLED',
    SET_PERSONA_INFO_READ: 'SET_PERSONA_INFO_READ',

    SET_PERSONA_SELECTED: 'SET_PERSONA_SELECTED',
    SET_PERSONA_LEVEL_REVEALED: 'SET_PERSONA_LEVEL_REVEALED',
    PERSONA_NEW_INFO_READ: 'PERSONA_NEW_INFO_READ',
    PERSONA_NEW_FEEDBACK_READ: 'PERSONA_NEW_FEEDBACK_READ',
    
    INTRO_SCREEN_FOR_PHASE_SEEN: 'INTRO_SCREEN_FOR_PHASE_SEEN',
    RESET_SELECTED_PERSONAS: 'RESET_SELECTED_PERSONAS',
    SET_USER_OBSERVE_PHASE_STATE: 'SET_USER_OBSERVE_PHASE_STATE',
    SET_UI_STATE: 'SET_UI_STATE',
    SET_USER_EMPATHIZE_PHASE_STATE: 'SET_USER_EMPATHIZE_PHASE_STATE',
    SET_USER_DEFINE_PHASE_STATE: 'SET_USER_DEFINE_PHASE_STATE',
    SET_USER_IDEATE_PHASE_STATE: 'SET_USER_IDEATE_PHASE_STATE',
    SET_USER_PROTOTYPE_PHASE_STATE: 'SET_USER_PROTOTYPE_PHASE_STATE',
    SET_USER_TEST_PROTOTYPE_PHASE_STATE: 'SET_USER_TEST_PROTOTYPE_PHASE_STATE',
    SET_AUTO_RESPONSE_FOR_QUADRANT_FILLED: 'SET_AUTO_RESPONSE_FOR_QUADRANT_FILLED',
    SET_USER_EMPATHIZE_ANIMATION_SEEN: 'SET_USER_EMPATHIZE_ANIMATION_SEEN',

    POPULATE_OBSERVE_SUGGESTIONS: 'POPULATE_OBSERVE_SUGGESTIONS',
    POPULATE_EMPATHIZE_SUGGESTIONS: 'POPULATE_EMPATHIZE_SUGGESTIONS',
    POPULATE_DEFINE_SUGGESTIONS: 'POPULATE_DEFINE_SUGGESTIONS',
    POPULATE_IDEATE_SUGGESTIONS: 'POPULATE_IDEATE_SUGGESTIONS',
    POPULATE_PROTOTYPE_SUGGESTIONS: 'POPULATE_PROTOTYPE_SUGGESTIONS',
    POPULATE_TEST_PROTOTYPE_SUGGESTIONS: 'POPULATE_TEST_PROTOTYPE_SUGGESTIONS',
    SET_TEST_QUADRANTS: 'SET_TEST_QUADRANTS',
    REPLAY_REDUCER_ACTIONS: 'REPLAY_REDUCER_ACTIONS',

    SET_TIMER_STATE: 'SET_TIMER_STATE',

    INIT_TIMER_ALERT_DATA: 'INIT_TIMER_ALERT_DATA',
    UPDATE_TIMER_ALERT_DATA: 'UPDATE_TIMER_ALERT_DATA',

    SET_EVENTS: 'SET_EVENTS',
    SET_LEADERBOARD: 'SET_LEADERBOARD'
});

export default actions;
