/**
 * @author: Ujjwal Jain
 * @file: userEmpathize.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({
    isIntroPageSeen: false,
    showIntroPage: false,
    isPhaseAvailableToUser: false,
    isEmpathyAnimationSeen: false,
    isFollowUpQuestionsPopupOpen: false,
    activeQuestionSelected: -1,
    activeEmpathySegmentId: -1,
    activeOverlayWithTimerSegmentId: -1,
    isEmpathyInitialStateSeen: false,
    showProceedToDefinePhase: false,
    isPersonaInterviewPopup: false,
    suggestions: [],
    isEmpathyInitialAnimationSeen: false,
    isEmpathyQuestionAsked: false
});


const userEmpathize = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_EMPATHIZE_PHASE_STATE'):
            return state.mergeDeep(Immutable.fromJS(action.payload));
        case actionConsts.get('POPULATE_EMPATHIZE_SUGGESTIONS'):
            return state.set('suggestions', Immutable.fromJS(action.payload));
        case 'MAKE_EMPATHIZE_PHASE_AVAILABLE':
            return state.set('isPhaseAvailableToUser', true);
        default:
            return state;
    }
};

export default userEmpathize;