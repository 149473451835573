/**
 * @author: Ujjwal Jain
 * @file: phases.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey, onlyMergeListsBasedOnKey } from 'util/objectUtils';

const initialState = Immutable.fromJS({
    phaseList: [],
    currentPhaseId: -1,
    phaseTypes: [],
    isPhasesDataFetched: false
});


const phases = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_PHASE_DATA'):
            return state.set('phaseList', onlyMergeListsBasedOnKey(state.get('phaseList'), action.payload.phaseList, 'id'))
                        .set('phaseTypes', mergeListsBasedOnKey(state.get('phaseTypes'), action.payload.phaseTypes, 'id'))
                        .set('isPhasesDataFetched', true);
        default:
            return state;
    }
};

export default phases;