import React from "react";
import styles from "./empathyMapPopup.module.sass";
import getSkin from "./skin.js";
import { css } from "aphrodite/no-important";
import applyWrappers from "util/ComponentWrapper";
import { filterObjectFromArray } from "util/objectUtils";
import TitleUnderline from "svgComponents/titleUnderline";
import EmpathyMap from "../empathyMap/EmpathyMap";
import EmpathyMapQuestions from "../empathyMapQuestions/EmpathyMapQuestions";
import HollowButton from "commonComponents/buttons/hollowButton";
import consts from "constants/url";
import FilledButton from "commonComponents/buttons/filledButton";
import { checkIfIEOrEdge } from "util/utilFunctions";
import { log } from "util";

class EmpathyMapPopup extends React.Component {
	onCloseClicked = () => {
		this.props.setUIState({
			isOverlayWithTimerOpen: false,
			highlightTimerHeader: false
		});
		this.props.setUserEmpathizePhaseState({
			activeOverlayWithTimerSegmentId: -1,
			isFollowUpQuestionsPopupOpen: false,
			activeQuestionSelected: -1
		});
	};

	animationSeenClicked = segment => {
		const url = consts.get("ACTION_LOG_API");
		const responsePayload = {
			roundId: this.props.userState.get("currentRoundId"),
			action: {
				type: "SET_USER_EMPATHIZE_PHASE_STATE",
				payload: {
					isEmpathyInitialAnimationSeen: true
				}
			}
			// action: {
			//     type: 'SET_USER_EMPATHIZE_ANIMATION_SEEN',
			//     payload: {
			//         id: segment.id
			//     }
			// }
		};
		this.props.postRequestActionLog(url, responsePayload);
	};

	renderPopupHeader = myStyles => {
		const { segments } = this.props;
		const segmentList = filterObjectFromArray(
			segments.get("segmentList"),
			"id",
			this.props.userPhasesState
				.get("userEmpathize")
				.get("activeOverlayWithTimerSegmentId")
		);

		return (
			<div styleName="popup-header-container">
				<div styleName="popup-image-title-container">
					<img
						alt="segment"
						src={this.props.getImage(segmentList.get("image"))}
						styleName="popup-image"
					/>
					<div
						className={css(myStyles.popupHeaderStyle)}
						styleName="popup-header-title"
					>
						{this.props.getLabel(segmentList.get("name"))}
						<div styleName="title-underline-container">
							<TitleUnderline {...this.props} />
						</div>
					</div>
				</div>
				<div styleName="popup-close-container">
					<HollowButton
						{...this.props}
						clickFunction={() => this.onCloseClicked()}
						textLabel={this.props.getLabel("label_close_empathy")}
					/>
				</div>
			</div>
		);
	};

	renderPopupQuestionsSection = () => {
		return <EmpathyMapQuestions {...this.props} />;
	};

	renderPopupEmpathyMapSection = () => {
		return (
			<div styleName="popup-empathy-map-container">
				<EmpathyMap {...this.props} />
			</div>
		);
	};

	renderPopupContent = myStyles => {
		const segmentObject = this.props.segments
			.get("segmentQuestions")
			.toJS()
			.filter(
				segment =>
					segment.id ==
					this.props.userPhasesState
						.get("userEmpathize")
						.get("activeOverlayWithTimerSegmentId")
			)[0];
		let image = checkIfIEOrEdge()
			? "IMG_EMPATHY_INTRO_IE"
			: "IMG_EMPATHY_INTRO_GIF";
		if (
			!this.props.userPhasesState
				.get("userEmpathize")
				.get("isEmpathyInitialAnimationSeen")
		) {
			// if(segmentObject.showInitialAnimation) {
			return (
				<div styleName="popup-questions-map-container empathy-initial-map">
					<div styleName="procedure-container" key={segmentObject.id}>
						<div styleName="step1-container">
							<div
								styleName="step-title-container"
								className={css(myStyles.stepTitle)}
							>
								<div>#1/3</div>
								{this.props.getLabel("label_empathize_step1")}
							</div>
							<div className={css(myStyles.stepSubTitle)}>
								{this.props.getLabel(
									"label_empathize_step_desc1"
								)}
							</div>
						</div>

						<div styleName="step2-container">
							<div
								styleName="step-title-container"
								className={css(myStyles.stepTitle)}
							>
								<div>#2/3</div>
								{this.props.getLabel("label_empathize_step2")}
							</div>
							<div className={css(myStyles.stepSubTitle)}>
								{this.props.getLabel(
									"label_empathize_step_desc2"
								)}
							</div>
						</div>

						<div styleName="step3-container">
							<div
								styleName="step-title-container"
								className={css(myStyles.stepTitle)}
							>
								<div>#3/3</div>
								{this.props.getLabel("label_empathize_step3")}
							</div>
							<div className={css(myStyles.stepSubTitle)}>
								{this.props.getLabel(
									"label_empathize_step_desc3"
								)}
							</div>
						</div>

						<div styleName="empathy-gif-container">
							<img
								styleName="empathy-intro-gif"
								alt="empathy-intro-gif"
								src={this.props.getImage(image)}
							/>
						</div>
					</div>

					<FilledButton
						{...this.props}
						clickFunction={() =>
							this.animationSeenClicked(segmentObject)
						}
						textLabel={this.props.getLabel("label_got_it")}
					/>
				</div>
			);
		}
		return (
			<div styleName="popup-questions-map-container">
				{this.renderPopupQuestionsSection()}
				{this.renderPopupEmpathyMapSection()}
			</div>
		);
	};

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="popup-container">
				{this.renderPopupHeader(myStyles)}
				{this.renderPopupContent(myStyles)}
			</div>
		);
	}
}

export default applyWrappers(EmpathyMapPopup, styles);
