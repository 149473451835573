import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		validationContainer: {
			backgroundColor: skin.get('white'),
			borderRadius: '5px',
			boxShadow: `1px 1px 5px 0 #d5d8e2`
		},
		buttonsContainer: {
			borderTop: `1px solid ${skin.get('grayColor6')}`
		},
		singleHeading: {
			...myTypography.subtitle,
			fontSize: '12px',
			color: skin.get('grayColor1'),
			textTransform: 'uppercase'
		},
		andHeading: {
			...myTypography.title,
			fontSize: '28px',
			color: skin.get('grayColor4')
		},
		responseCard: {
			borderRadius: '4px',
			border: `1px solid ${skin.get('grayColor4')}`
		},
		responseContent: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		},
        buttonHelpTextExhausted: {
            ...myTypography.body2,
            fontSize: '13px',
            color: '#bb4343'
        }
	});
}

export default getSkin;