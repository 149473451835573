import React from 'react';
import styles from './whatDFVScorePopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import CloseIcon from 'svgComponents/closeIcon';
import DfvIcon from 'svgComponents/dfvIcon';
import analyticsUtil from 'util/segmentUtil';
class WhatDFVScorePopup extends React.Component {

    onClickOfClosePopup = () => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "WHAT_DFV_SCORE_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    onClickPrototypeIdea = (currentIdea) => {
        this.props.updateIdea({
            id: currentIdea.id,
            isShortlistedIdea: false,
            isShortlistedPrototype: true
        });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    renderBorder = (myStyles) => {
        return (
            <div className={css(myStyles.borderContainer)} styleName="border-container"></div>
        );
    }

    renderHeadingContent = (myStyles, currentIdea) => {
        return (
            <DfvIcon></DfvIcon>
        );
    }

    renderCloseIcon = (myStyles) => {
        return (
            <div styleName="close-button">
                <CloseIcon closeButtonClicked={this.onClickOfClosePopup} />
            </div>
        );
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div className={css(myStyles.validationContainer)} styleName="popup-container">
                {this.renderCloseIcon(myStyles)}
                {this.renderBorder(myStyles)}
                {this.renderHeadingContent(myStyles)}
            </div>
        );
    }
}

export default applyWrappers(WhatDFVScorePopup, styles);