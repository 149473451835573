import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    timerStatus: 'NOT_STARTED',
    timeUsed: '',
    timeLeft: '',
    initialTimer: ''
});

const timer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INITIALIZE_TIMER'):
            return state.set('timerStatus', 'START_TIMER')
                        .set('timeUsed', 0)
                        .set('timeLeft', Immutable.fromJS(action.payload.initialTimer))
                        .set('initialTimer', Immutable.fromJS(action.payload.initialTimer))
        case actionConsts.get('SET_TIMER_STATE'):
            return state.set('timeUsed', action.payload);        
        default:
            return state;
    }
};

export default timer;