import React from 'react';
import styles from './empathyMap.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import getColorPalette from './colorPalette.js';
import TitleUnderline from 'svgComponents/titleUnderline';
import consts from 'constants/url';
import FilledButton from 'commonComponents/buttons/filledButton';
import WalkthroughComponent from 'commonComponents/walkthroughComponent';

class EmpathyMap extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isWalkthroughSeen: false
        }
    }

    autoFillClickHandler = (currentSegment) => {
        const url = consts.get('ACTION_LOG_API');
        const responsePayload = {
            roundId: this.props.userState.get('currentRoundId'),
            action: {
                type: 'SET_AUTO_RESPONSE_FOR_QUADRANT_FILLED',
                payload: {
                    segmentDetails: currentSegment
                }
            }
        };

        this.props.postRequestActionLog(url, responsePayload);

        if(!this.props.userState.get('isAutoFillWalkthroughSeen')) {
            const url = consts.get('ACTION_LOG_API');
            const responsePayload = {
                roundId: this.props.userState.get('currentRoundId'),
                action: {
                    type: 'SET_USER_STATE',
                    payload: {
                        isAutoFillWalkthroughSeen: true
                    }
                }
            }
            this.props.postRequestActionLog(url, responsePayload);
        }
        // this.props.autoFillResponsesInQuadrant();
    }

    showIntroInitialState = () => {
        this.props.setUserEmpathizePhaseState({
            isEmpathyInitialStateSeen: false
        })
    }

    doNothing = () => {
        return;
    }

    renderEmpathyMapTitle = (myStyles) => {
        let clickHandler = this.autoFillClickHandler;
        const currentSegment = this.props.segments.get('segmentQuestions').toJS().filter((segment) => segment.id == this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'))[0];
        let opacity={opacity: '1', cursor: 'pointer'};
        if(currentSegment.shouldShowEmptyChatSection || !this.props.userPhasesState.get('userEmpathize').get('isEmpathyInitialStateSeen')) {
            clickHandler = this.doNothing;
            opacity={opacity: '0.5', cursor: 'default'};
        }
        return(
            <div styleName="empathy-map-title-container">
                <div styleName="empathy-map-title-flex">
                    <div className={css(myStyles.popupHeaderStyle)} styleName="popup-header-title">
                        {this.props.getLabel('label_empathy_map')}
                        <div styleName="title-underline-container">
                            <TitleUnderline {...this.props} />
                        </div>
                    </div>
                    <div 
                        className={css(myStyles.aboutFillStyle)} 
                        styleName="about-content"
                        onClick={this.showIntroInitialState}    
                    >
                        {this.props.getLabel('label_about')}
                    </div>
                </div>
                <div styleName="auto-fill-container">
                    <div 
                        style={opacity}
                        className={css(myStyles.autoFillStyle)} 
                        styleName="auto-fill-content"
                        onClick={() => clickHandler(currentSegment)}    
                    >
                        {
                            !this.props.userState.get('isAutoFillWalkthroughSeen') && !currentSegment.shouldShowEmptyChatSection ?
                            <WalkthroughComponent
                                arrowType="RIGHT"
                                propStyles={{
                                    top: '-1px',
                                    left: '-234px',
                                    width: '228px',
                                    textTransform: 'none'
                                }}
                                text={this.props.getLabel('label_walkthrough_auto_fill')}
                            />
                            : null
                        }

                        {this.props.getLabel('label_auto_fill')}
                    </div>
                </div>
                {/* <div styleName="about-container">
                    <div 
                        style={opacity}
                        className={css(myStyles.autoFillStyle)} 
                        styleName="auto-fill-content"
                        onClick={this.showIntroInitialState}    
                    >
                        {this.props.getLabel('label_about')}
                    </div>
                </div> */}
            </div>
        )
    }

    renderResponsesForQuadrant = (quadrant, myStyles) => {
        return quadrant.responses.map((response) => {
            if(response.segmentId == this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId') && response.isClicked) {
                return (
                    <div styleName="list-container">
                        <div className={css(myStyles.discStyle)} styleName="list-disc">
                        </div>
                        <div className={css(myStyles.contentStyle)} styleName="response-content">
                            {this.props.getLabel(response.text)}
                        </div>
                    </div>
                )
            }
        })
        
    }

    renderEmpathyQuadrants = (myStyles) => {
        const { segments } = this.props;

        return segments.get('quadrants').toJS().map((quadrant) => {
            
            const myColors = getColorPalette(this.props.skinGuide, quadrant.colorProfileId);
            return(
                <div className={css(myStyles.empathyQuadrantBorder, myColors.empathyQuadrantBackground)} styleName="empathy-quadrant">
                    <div className={css(myColors.empathyQuadrantTitle)} styleName="empathy-quadrant-title">
                        {this.props.getLabel(quadrant.name)}
                    </div>
                    <div className={css(myStyles.listTextStyle)} styleName="list-responses-container">
                        {this.renderResponsesForQuadrant(quadrant, myStyles)}
                    </div>
                </div>
            )
        })
    }

    empathyInitialSeen = () => {
        const url = consts.get('ACTION_LOG_API');
        const responsePayload = {
            roundId: this.props.userState.get('currentRoundId'),
            action: {
                type: 'SET_USER_EMPATHIZE_PHASE_STATE',
                payload: {
                    isEmpathyInitialStateSeen: true
                }
            }
        };
        this.props.postRequestActionLog(url, responsePayload);
    }

    renderQuadrantsInitialInfo = (myStyles) => {

        const { segments } = this.props;

        return segments.get('quadrants').toJS().map((quadrant, index) => {
            
            const myColors = getColorPalette(this.props.skinGuide, quadrant.colorProfileId);
            return(
                <div className={css(myStyles.textQuadStyle)} key={index} styleName="question-container-initial">
                    <span className={css(myColors.empathyQuadrantIntroTitle)} styleName="empathy-quad-title">
                        #{index+1} {this.props.getLabel(quadrant.name)}
                    </span>
                    {this.props.getLabel(quadrant.description)}
                </div>
            )
        })
    }

    renderEmapthyIntroOrMap = (myStyles) => {
        if (!this.props.userPhasesState.get('userEmpathize').get('isEmpathyInitialStateSeen')) {
           return(
               <div styleName="emapthy-initial-container">
                   <div className={css(myStyles.descContainer)} styleName="empathy-map-initial-content">
                        <div 
                            className={css(myStyles.initialTitle)} 
                            styleName="title-container-initial"
                            dangerouslySetInnerHTML={{__html: this.props.getLabel('label_empathy_initial_title')}}
                        >
                        </div>
                        <div styleName="emapthy-initial-image-container">
                            <div className = {css(myStyles.mapSubtitle)} styleName="empathy-map-intial-image">
                                <img src={this.props.getImage('IMG_EMPATHY_INTRO')} width="100%" height="100%" position="absolute"/>
                                <div style = {{position : 'absolute',top: '40px' , left: '20px'}}>{this.props.getLabel('label_eq_1')}</div>
                                <div style = {{position : 'absolute',top: '10px' , left: '50px'}}>{this.props.getLabel('label_eq_2')}</div>
                                <div style = {{position : 'absolute',bottom: '15px' , right: '30px'}}>{this.props.getLabel('label_eq_3')}</div>
                                <div style = {{position : 'absolute',bottom: '15px' , left: '30px'}}>{this.props.getLabel('label_eq_4')}</div>
                                <div style = {{position : 'absolute',top: '40px' , right: '25px'}}>{this.props.getLabel('label_eq_5')}</div>
                                <div style = {{position : 'absolute',bottom: '40px' , left: '65px'}}>{this.props.getLabel('label_eq_6')}</div>

                            </div>
                        </div>
                        <div className={css(myStyles.initialSubtitle)}>
                            {this.props.getLabel('label_empathy_initial_instruction', "", {
                                PLACEHOLDER: 6
                            })}
                        </div>
                        <div styleName="empathy-initial-quadrants-intro">
                            {this.renderQuadrantsInitialInfo(myStyles)}
                        </div>
                   </div>
                   <div className={css(myStyles.buttonContainer)} styleName="empathy-map-filled-button">
                        <FilledButton 
                            {...this.props} 
                            clickFunction={() => this.empathyInitialSeen()}
                            textLabel={this.props.getLabel('label_got_it')}	
                        />
                   </div>
               </div>
           ) 
        }
        return this.renderEmpathyQuadrants(myStyles)

    }

    render() {
            
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div className={css(myStyles.popupContentShadow)} styleName="popup-questions-content">
                {this.renderEmpathyMapTitle(myStyles)}
                <div className={css(myStyles.empathyBackground)} styleName="empathy-map-background">
                </div>
                <div styleName="empathy-quadrant-container">
                    {this.renderEmapthyIntroOrMap(myStyles)}
                </div>
            </div>
        );
    }
}

export default applyWrappers(EmpathyMap, styles);