/**
 * @author: Ujjwal Jain
 * @file: ideas.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey } from 'util/objectUtils';

const initialState = Immutable.fromJS({
    leaderboardList: []
});

const leaderboard = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_LEADERBOARD'):
            return state.set('leaderboardList', mergeListsBasedOnKey(state.get('leaderboardList'), action.payload, 'uliId'));
        default:
            return state;
    }
};

export default leaderboard;