import Immutable from 'immutable';

const fontFamilyObj = {
	"titleFamily": "Libre Baskerville",
	"bodyFamily": "Open Sans"
};

const skinHex = {
	"primaryColor": "#661ab8",
	"primaryVariantColor": "#223b92",
	"secondaryColor": "#d44980",
	"secondaryVariantColor": "#c44175",
	"greyColor1": "#091027",
	"greyColor2": "#2e344b",
	"greyColor3": "#575e79",
	"greyColor4": "#979eb7",
	"greyColor5": "#d5d9e7",
	"greyColor6": "#f2f3f7",
	"black": "#000000",
	"white": "#ffffff"
};

const globalProfiles = {
	'palette': {
		"primaryColor": "#661ab8",
		"primaryVariantColor": "#223b92",
		"secondaryColor": "#d44980",
		"secondaryVariantColor": "#c44175",
		"grayColor1": "#091027",
		"grayColor2": "#2e344b",
		"grayColor3": "#575e79",
		"grayColor4": "#979eb7",
		"grayColor5": "#d5d9e7",
		"grayColor6": "#f2f3f7",
		"black": "#000000",
		"white": "#ffffff"
	}
};

const entityProfiles = []

const skins = Immutable.fromJS({
	fontFamily: fontFamilyObj,
	globalProfiles: globalProfiles,
	entityProfiles: entityProfiles,
	skinHex: skinHex
});

export default skins;
