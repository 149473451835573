import React from 'react';
import styles from './uploadIcon.module.sass';
import getSkin from './skin.js';
import applyWrappers from 'util/ComponentWrapper';

class UploadIcon extends React.Component {
    
    iconClicked = () => {
		if(this.props.clickHandlerForIcon === '') {
			return;
		}
		this.props.clickHandlerForIcon();
	}

    render() {
        return (
            <div styleName="main-component" style={{'cursor': this.props.isDisabled ? 'default' : 'pointer'}} onClick={this.iconClicked}>
                <svg width="100%" height="100%" viewBox="0 0 29 29">
                    <defs>
                        <linearGradient id="c2" x1="0%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="#E87AD9"/>
                            <stop offset="100%" stop-color="#7D4FEA"/>
                        </linearGradient>
                        <path id="b2" d="M10.336.001a10.465 10.465 0 0 1 10.662 10.291 10.55 10.55 0 0 1-.244 2.479c-.298 1.348-.474 2.72-.474 4.1v2.668a.74.74 0 0 1-.74.74H16.87c-1.38 0-2.752.177-4.1.475-.796.175-1.626.26-2.479.244A10.464 10.464 0 0 1 .002 10.337C.087 4.667 4.666.087 10.335 0z"/>
                        <filter id="a2" width="176.2%" height="176.2%" x="-38.1%" y="-19%" filterUnits="objectBoundingBox">
                            <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.201143569 0"/>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd" opacity=".8">
                        <g transform="translate(4)">
                            <use fill="#000" filter="url(#a2)" xlinkHref="#b2"/>
                            <use fill="url(#c2)" xlinkHref="#b2"/>
                        </g>
                        <path fill="#FFF" fill-rule="nonzero" d="M18.1 10.282h-1.799a.45.45 0 0 0-.45.45v4.95a1.35 1.35 0 1 1-2.7 0v-4.95a.45.45 0 0 0-.45-.45h-1.8a.9.9 0 0 1-.703-1.463l3.6-4.5a.934.934 0 0 1 1.407 0l3.6 4.5a.9.9 0 0 1-.704 1.463z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(UploadIcon, styles);