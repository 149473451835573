import React from 'react';
import styles from './warningOverlay.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';
import WarningPopup from 'commonComponents/warningPopup';

class WarningOverlay extends React.Component {

	getHeadingContentLabel = (warningType) => {
		switch(warningType) {
			case 'LESS_BUDGET_TO_PROTOTYPE':
				return {
					heading: 'label_no_budget_prototype_heading',
					content: 'label_no_budget_prototype_content'
				};
			case 'LESS_BUDGET_TO_TEST':
				return {
					heading: 'label_no_budget_test_heading',
					content: 'label_no_budget_test_content'
				};
			case 'DRAGGABLE_CONSTRAINT_FROM_PROTOTYPE_TO_SHORTLIST':
				return {
					heading: 'label_dc_prototype_to_shortlist_heading',
					content: 'label_dc_prototype_to_shortlist_content'
				};
			case 'DRAGGABLE_CONSTRAINT_FROM_EXECUTE_TO_PROTOTYPE':
				return {
					heading: 'label_dc_execute_to_prototype_heading',
					content: 'label_dc_execute_to_prototype_content'
				};
			case 'DRAGGABLE_CONSTRAINT_FROM_EXECUTE_TO_TEST':
				return {
					heading: 'label_dc_execute_to_test_heading',
					content: 'label_dc_execute_to_test_content'
				};
			case 'DRAGGABLE_CONSTRAINT_FROM_TEST_TO_PROTOTYPE':
				return {
					heading: 'label_dc_test_to_prototype_heading',
					content: 'label_dc_test_to_prototype_content'
				};
			default:
				return {
					heading: 'label_no_budget_prototype_heading',
					content: 'label_no_budget_prototype_content'
				};
		}
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);

		const warningId = this.props.uiState.get('warningIdeaId');
		const warningType = this.props.uiState.get('warningType');

		const ideasList = this.props.ideas.get('ideasList').toJS();
        const myIdea = ideasList.find((eachIdea) => {
            return eachIdea.id === warningId;
        });
        if(!checkIfPresent(myIdea) && !checkIfPresent(warningType)) {
			return null;
		}
		const contentObj = this.getHeadingContentLabel(warningType);
		return (
			<div className={css(myStyles.popupContainer)} styleName="popup-container">
				<div className={css(myStyles.popupBackground)} styleName="popup-background"></div>
				<div styleName="popup-content">
					<WarningPopup
						{...this.props}
						heading={contentObj.heading}
						content={contentObj.content}
					/>
				</div>
			</div>
		);
	}
}

export default applyWrappers(WarningOverlay, styles);
