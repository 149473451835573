import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    notes: "",
    isOnBoardingSeenByUser: false,
    showBudgetWarning: false,
    userBudgetData: [],
    userTimerData: [],
    showTimerWarning: false,
    currentPhaseId: -1,
    currentPhaseKey: '',
    currentRoundId: -1,
    selectedRoundId: -1,
    activeOverlayWithTimerSegmentId: -1,
    showHeader: true,
    showFooter: true,
    reducerActions: [],
    isUserGameCompleted: false,
    isUserStateDataFetched: false,
    isReducerActionReplayed: false,
    popupWithTimerComponent: '',
    selectedDefineSegmentId: -1,
    selectedDefineTouchPointId: -1,
    currentPersonaSelectedObject: {},
    showPersonaAndSegmentSideBar: false,
    showEmpathizeWithThemSegment: false,
    atleastOneInterviewTaken: false,
    atleastOneFeedbackTaken: false,
    actionCardsRevealed: false,
    isTimerStarted: false,
    isTimerExpired: false,
    isAutoFillWalkthroughSeen: false,
    isPersonaWalkthroughSeen: false,
    isQuarterReportWalkthroughSeen: false,
    isTeamChatWalkthroughSeen: false,
    logoutType: ''
});

const userState = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_STATE'):
            return state.mergeDeep(Immutable.fromJS(action.payload));
        case actionConsts.get('INIT_USER_NOTES_DATA'):
            return state.mergeDeep(Immutable.fromJS(action.payload));
        case actionConsts.get('INIT_BUDGET_ALERT_DATA'):
            return state.set('userBudgetData', formatBudgetData(action.payload));
        case actionConsts.get('UPDATE_BUDGET_ALERT_DATA'):
            return state.set('userBudgetData', updateBudgetData(action.payload, state));
        case actionConsts.get('INIT_TIMER_ALERT_DATA'):
        return state.set('userTimerData', formatTimerData(action.payload));
        case actionConsts.get('UPDATE_TIMER_ALERT_DATA'):
            return state.set('userTimerData', updateTimerData(action.payload, state));
        case actionConsts.get('INIT_USER_STATE_DATA'):
            return state.mergeDeep(state, Immutable.fromJS(action.payload)) 
                        .set('isUserStateDataFetched', true);
        case 'MAKE_OBSERVE_PHASE_AVAILABLE':
            return state.set('currentPhaseKey', 'observe');
        case 'MAKE_EMPATHIZE_PHASE_AVAILABLE':
            return state.set('currentPhaseKey', 'empathize');
        case 'MAKE_DEFINE_PHASE_AVAILABLE':
            return state.set('currentPhaseKey', 'define');
        case 'MAKE_IDEATE_PHASE_AVAILABLE':
            return state.set('currentPhaseKey', 'ideate');
        case 'MAKE_PROTOTYPE_PHASE_AVAILABLE':
            return state.set('currentPhaseKey', 'prototype');
        case 'MAKE_TEST_PROTOTYPE_PHASE_AVAILABLE':
            return state.set('currentPhaseKey', 'test');
        default:
            return state;
    }
};

const formatBudgetData = (rounds) => {
    let userBudgetArray = [];
    rounds.map((eachRound) => {
        userBudgetArray.push({
            roundId: eachRound.id,
            isSeenByUser: false,
            budgetAlertValue: 10000
        });
        userBudgetArray.push({
            roundId: eachRound.id,
            isSeenByUser: false,
            budgetAlertValue: 5000
        });
    });
    return userBudgetArray;
}

const updateBudgetData = (payload, state) => {
    const userBudgetArray = state.get('userBudgetData');
    userBudgetArray.map((eachData) => {
        payload.map((eachPayload) => {
            if(
                eachData.roundId === eachPayload.roundId
                && eachData.budgetAlertValue === eachPayload.budgetAlertValue
            ) {
                eachData['isSeenByUser'] = true;
            }
        });
    });
    return userBudgetArray;
}

const formatTimerData = (rounds) => {
    let userTimerArray = [];
    userTimerArray.push({
        isSeenByUser: false,
        timerAlertValue: 600
    });
    userTimerArray.push({
        isSeenByUser: false,
        timerAlertValue: 300
    });
    return userTimerArray;
}

const updateTimerData = (payload, state) => {
    const userTimerArray = state.get('userTimerData');
    userTimerArray.map((eachData) => {
        payload.map((eachPayload) => {
            if( eachData.timerAlertValue === eachPayload.timerAlertValue) {
                eachData['isSeenByUser'] = true;
            }
        });
    });
    return userTimerArray;
}

export default userState;