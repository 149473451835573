import consts from 'constants/url';
import kfetch from '../util/kfetch';
import {checkIfPresent} from 'util/utilFunctions';
import setUIState from 'actions/uiState/actionCreators';

export const postUnlockDFV = (payload) => (dispatch) => {
    kfetch(consts.get('UNLOCK_IDEA_DFV_SCORE'), payload, 'POST')
    .then((response) => {
        dispatch({
            type: 'UPDATE_IDEA',
            payload: {
                id: payload.ideaId,
                showDfvLoading: true
            }
        });
        setTimeout(() => {
            dispatch({
                type: 'UPDATE_IDEA',
                payload: {
                    id: payload.ideaId,
                    dfv_unlocked: true,
                    dfv_response: response,
                    showDfvLoading: false
                }
            });
        }, 4000);
        if(checkIfPresent(response.metrics)) {
            dispatch({
                type: 'SET_UPDATED_METRICS',
                payload: response.metrics
            });
        }
        dispatch(setUIState({
            dfvButtonLoading: false
        }));
    })
    .catch((response) => {
        dispatch(setUIState({
            dfvButtonLoading: false
        }));
    });
};