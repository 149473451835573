import React from 'react';
import styles from './questionChatBox.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import UploadIcon from 'svgComponents/uploadIcon';
import getColorPalette from './colorPalette';
import Recommendation from '../recommendation/Recommendation';
import ActionTick from 'svgComponents/actionTick';
import { getFormattedCurrency } from '../../util/currencyFormatter';

class QuestionChatBox extends React.Component {

	chatBoxClicked = () => {

		if(this.props.userPhasesState.get('userEmpathize').get('isEmpathyQuestionAsked') || this.props.isDisabled) {
			return;
		}
		
		if(this.props.clickHandlerForResponse == '') {
			return;
		}
		
		this.props.clickHandlerForResponse();
	}

	renderChatBoxArrow = (myStyles, myColors) => {
		let chatBoxArrowBorder = myStyles.chatBoxRightArrowBorder;
		let chatBoxBackground = '' ;
		let questionChatBoxArrowPosition = "question-chat-box-arrow";
		let boxArrowContainer = 'question-box-arrow-container';
		if(this.props.chatBoxDirection == 'left') {
			chatBoxArrowBorder = myStyles.chatBoxLeftArrowBorder;
			chatBoxBackground = myColors.chatBoxArrowBackground;
			questionChatBoxArrowPosition = "question-chat-box-arrow-left"
			boxArrowContainer = 'question-box-arrow-container-start';
		}
		return(
			<div styleName={boxArrowContainer}>
				<div className={css(chatBoxArrowBorder, chatBoxBackground)} styleName={questionChatBoxArrowPosition}>
				</div>
			</div>
		)
	}

	renderChatBoxContent = (myStyles, myColors) => {

		const backgroundStyle = this.props.chatBoxDirection == 'left' ? myColors.chatBoxBackground : myColors.chatBoxBorder;
		const chatBoxBorder = this.props.chatBoxDirection == 'left' && !this.props.showSuggestions ? myStyles.borderRightNone : ''; 
		// const containerClassStyle = this.props.showSuggestions ? myStyles.borderBottom : '';
		return(
			<div className={css(myStyles.chatBoxBackground, backgroundStyle, chatBoxBorder)} styleName="question-chat-box-content">
				<div styleName="question-content-section-container">
					<div className={css(myStyles.chatBoxCotent)} styleName="question-content-section">
						{this.props.getLabel(this.props.segmentQuestion.text)}
					</div>
					{this.props.showQuestionIcon ? 
						<div styleName="question-icon-section-container">
							{	this.props.segmentQuestion.isAsked
								?
								<div styleName="question-tick-section">
									<ActionTick {...this.props} />
								</div>
								:
								<div styleName="question-icon-container">
									<div styleName="question-icon-section">
										<UploadIcon {...this.props} />
									</div>
									<div className={css(myStyles.askStyle)}>
										{this.props.getLabel('label_ask')}
									</div>
								</div>	
							}
							<div className={css(myStyles.costStyling)}>
								{getFormattedCurrency(this.props.segmentQuestion.cost, 'USD')}
							</div>
						</div>
						: null
					}			
				</div>
			</div>
		)
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const getSegmentDetails = this.props.segments.get('segmentList').toJS().filter((segment) => segment.id == this.props.segmentQuestion.segmentId)[0];
		const myColors = getColorPalette(this.props.skinGuide, getSegmentDetails.colorProfileId);
		
		const questionChatBoxContainer = this.props.chatBoxDirection == 'right' ? 'question-chat-box-container' : 'question-chat-box-container row-reverse';
		return (
			<div style={{'opacity': this.props.isDisabled ? 0.7 : 1}} styleName={questionChatBoxContainer} onClick={this.chatBoxClicked}>
				{this.renderChatBoxContent(myStyles, myColors)}
				{this.renderChatBoxArrow(myStyles, myColors)}
			</div>
		);
	}
}

export default applyWrappers(QuestionChatBox, styles);
