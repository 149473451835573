import React from 'react';
import styles from './overlayBluredRight.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import Events from 'components/game/observation/events/Events.jsx';

class OverlayBluredRight extends React.Component {

	closeOverlay = () => {
		this.props.setUIState({
			isBluredRightOverlay: false,
			popupComponentBluredOverlay: ''
		});
	}

	renderComponent = () => {
		return (
			<Events {...this.props} />
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.popupContainer)} styleName="popup-container">
				<div className={css(myStyles.popupBackground)} styleName="popup-background"></div>
				<div styleName="popup-content"
					onClick={() => {
						this.closeOverlay();
					}}
				>
					{this.renderComponent()}
				</div>
			</div>
		);
	}
}

export default applyWrappers(OverlayBluredRight, styles);
