import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		popupHeaderStyle: {
            ...myTypography.subtitle,
            fontWeight: 600,
            color: skin.get('grayColor1')
        }
	});
}

export default getSkin;