import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		tooltipContent: {
            backgroundColor: skin.get('grayColor2'),
            color: skin.get('white'),
            boxShadow: `5px 5px 10px 0 ${hexToRgbA(skin.get('black'), 0.5)}`,
			...myTypography.caption,
			borderRadius: '6px',
			textTransform: 'none'
        },
        tooltipArrowRight: {
            borderRight: `7px solid ${skin.get('grayColor2')}`,
            borderBottom: `7px solid transparent`,
            borderTop: `7px solid transparent`
		},
		tooltipArrowLeft: {
            borderLeft: `7px solid ${skin.get('grayColor2')}`,
            borderBottom: `7px solid transparent`,
            borderTop: `7px solid transparent`
		},
		tooltipArrowTop: {
            borderTop: `7px solid ${skin.get('grayColor2')}`,
            borderLeft: `7px solid transparent`,
            borderRight: `7px solid transparent`
		},
		tooltipArrowBottom: {
            borderBottom: `7px solid ${skin.get('grayColor2')}`,
            borderLeft: `7px solid transparent`,
            borderRight: `7px solid transparent`
        }
	});
}

export default getSkin;