import { has } from "immutable";
import { filterObjectFromArray } from 'util/objectUtils';
import { compareOnId } from 'util/utilFunctions';

const getPhasesListFromPhaseTypeData = (payload) => {
    const phaseList = [];
    
    payload.map((phaseType, phaseTypeIndex) => {
        phaseType.phases.map((singlePhaseList, index) => {
            singlePhaseList['phaseTypeId'] = phaseType['id'];
            singlePhaseList['phaseType'] = phaseType['name'];
            phaseList.push(singlePhaseList);
        });
    });
        
    return {
        phaseList: phaseList,
        phaseTypes: payload
    };
}

const formatPersonaListData = (payload) => {
    
    payload.map((persona, index) => {
        persona['noOfFeedbackAsked'] = 0;
        persona['noOfInterviewTaken'] = 0;
        persona['isInfoRead'] = false;
        persona['isSelected'] = false;
        persona.feedbackSurveys.map((feedback, index) => {
            feedback['isLevelRevealed'] = 0;
            feedback['isNewInfoRevealed'] = 0;
        })
        persona.personaInterviews.map((feedback, index) => {
            feedback['isLevelRevealed'] = 0;
            feedback['isNewInfoRevealed'] = 0;
        })
    });

    return payload;
}

const formatColorProfiles = (payload, colorProfileId) => {

    const colorProfiles = {
        globalProfiles: {},
        entityProfiles: []
    }

    payload.map((eachProfile) => {
        if(eachProfile.type === 'GLOBAL' && eachProfile.id === colorProfileId) {
            colorProfiles.globalProfiles = eachProfile;
        } else if(eachProfile.type === 'ENTITY') {
            colorProfiles.entityProfiles.push(eachProfile);
        }
    });
    return colorProfiles;
}

const getformattedIdeaList = (payload) => {
    let responseList = [];
    payload.map((eachIdea) => {
        eachIdea['isProcessing'] = false;
        eachIdea['isBeingDragged'] = false;
        eachIdea['timeStamp'] = new Date().getTime()

        responseList.push(eachIdea);
    });
    return responseList;
}

const getformattedUserIdeaList = (payload) => {
    let responseList = [];
    payload.map((eachIdea) => {
        eachIdea['dfv_unlocked'] = false;
        eachIdea['dfv_refined'] = false;
        eachIdea['isNormalIdea'] = true;
        eachIdea['isShortlistedIdea'] = false;
        eachIdea['isShortlistedPrototype'] = false;
        eachIdea['isShortlistedForTesting'] = false;
        eachIdea['isTestingDone'] = false;
        eachIdea['isShortlistedForExecution'] = false;
        eachIdea['dfv_response'] = {};
        eachIdea['digdeep_response'] = {};
        eachIdea['testPrototypeResponse'] = {};
        eachIdea['executedRound'] = -1;
        eachIdea['isExecuted'] = false;

        responseList.push(eachIdea);
    });
    return responseList;
}

const getformattedRoundList = (payload) => {
    let responseList = [];
    payload.map((eachRound) => {
        eachRound['isRoundUnlocked'] = false;
        eachRound['isReportAvailable'] = false;
        eachRound['reportData'] = {};

        responseList.push(eachRound);
    });
    return responseList;
}


export { 
    getPhasesListFromPhaseTypeData,
    formatPersonaListData,
    formatColorProfiles,
    getformattedIdeaList,
    getformattedUserIdeaList,
    getformattedRoundList
};