import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		barContainer: {
			// backgroundColor: hexToRgbA('#FF0000', 0.3),
			...myTypography.body1,
			color: '#bb4343',
		}
	});
}

export default getSkin;