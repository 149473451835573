/**
 * @author: Ujjwal Jain
 * @file: personas.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable, { merge } from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey, onlyMergeListsBasedOnKey } from 'util/objectUtils';

const initialState = Immutable.fromJS({
    personaList: [],
    isPersonaDataFetched: false
});

const setPersonaInfoRead = (payload, state) => {
    const personaList = state.get('personaList').toJS();

    personaList.map((persona) => {
        if(persona.id === payload.personaId) {
            persona['isInfoRead'] = true;
        }
    })
    
    return state.set('personaList', onlyMergeListsBasedOnKey(state.get('personaList'), personaList, 'id'));
}

const setPersonaSelectedorDeselcted = (payload, state) => {
    const personaList = state.get('personaList').toJS();

    personaList.map((persona) => {
        if(persona.id === payload.id) {
            persona['isSelected'] = !persona['isSelected'];
        }
    });
    
    return state.set('personaList', onlyMergeListsBasedOnKey(state.get('personaList'), personaList, 'id'));
}

const resetAllPersonasSelected = (state) => {
    const personaList = state.get('personaList').toJS();

    personaList.map((persona) => {
        persona['isSelected'] = false;
    });
    
    return state.set('personaList', onlyMergeListsBasedOnKey(state.get('personaList'), personaList, 'id'));
}

const setPersonaInfoAsRead = (payload, state) => {

    const personaList = state.get('personaList').toJS();
    console.log(payload);
    
    personaList.map((persona) => {
        if(persona.id === payload.persona.id) {
            if(payload.isInterview && persona.noOfInterviewTaken > 0) {
                persona.personaInterviews[persona.noOfInterviewTaken -1]['isNewInfoRevealed'] = 0
            }
        }
    });

    return state.set('personaList', onlyMergeListsBasedOnKey(state.get('personaList'), personaList, 'id'));

}

const setPersonaFeedbackAsRead = (payload, state) => {

    const personaList = state.get('personaList').toJS();
    
    personaList.map((persona) => {
        if(persona.noOfFeedbackAsked > 0) {
            //if(persona.feedbackSurveys[persona.noOfFeedbackAsked - 1])
                persona.feedbackSurveys[persona.noOfFeedbackAsked - 1]['isNewInfoRevealed'] = 0
        }
    });

    return state.set('personaList', onlyMergeListsBasedOnKey(state.get('personaList'), personaList, 'id'));

}

const setPersonaLevelRevealed = (payload, state) => {
    
    const personaList = state.get('personaList').toJS();
    payload.personaIds.map((personaId) => {
        personaList.map((persona, index) => {
            if(persona.id === personaId) {
                persona['isSelected'] = false;
                if(payload.isInterview) {
                    persona['noOfInterviewTaken'] += 1;
                    if(persona['personaInterviews'] && persona['personaInterviews'].length && 
                    persona['noOfInterviewTaken'] > persona['personaInterviews'].length){
                        persona['noOfInterviewTaken'] = persona['personaInterviews'].length;
                    }
                    persona.personaInterviews.map((personaInterview, index) => {
                        if(personaInterview.level === persona['noOfInterviewTaken']) {
                            persona.personaInterviews[index]['isLevelRevealed'] = 1;
                            persona.personaInterviews[index]['isNewInfoRevealed'] = 1;
                        }
                    })
                } else {
                    persona['noOfFeedbackAsked'] += 1;//change
                    if(persona['feedbackSurveys'] && persona['feedbackSurveys'].length && 
                        persona['noOfFeedbackAsked'] > persona['feedbackSurveys'].length){
                            persona['noOfFeedbackAsked'] = persona['feedbackSurveys'].length;
                    }

                    persona.feedbackSurveys.map((personaFeedback, index) => {
                        if(personaFeedback.level === persona['noOfFeedbackAsked']) {
                            persona.feedbackSurveys[index]['isLevelRevealed'] = 1;
                            persona.feedbackSurveys[index]['isNewInfoRevealed'] = 1;
                        }
                    })
                }
            }
        })
    });

    console.log(personaList);
    return state.set('personaList', onlyMergeListsBasedOnKey(state.get('personaList'), personaList, 'id'));
    
}

const personas = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_PERSONAS_DATA'):
            return state.set('personaList', onlyMergeListsBasedOnKey(state.get('personaList'), action.payload, 'id'))
                        .set('isPersonaDataFetched', true);
        case actionConsts.get('SET_PERSONA_INFO_READ'):
            return setPersonaInfoRead(action.payload, state);
        case actionConsts.get('SET_PERSONA_SELECTED'):
            return setPersonaSelectedorDeselcted(action.payload, state);
        case actionConsts.get('SET_PERSONA_LEVEL_REVEALED'):
            return setPersonaLevelRevealed(action.payload, state);
        case actionConsts.get('RESET_SELECTED_PERSONAS'):
            return resetAllPersonasSelected(state);
        case actionConsts.get('PERSONA_NEW_INFO_READ'):            
            return setPersonaInfoAsRead(action.payload, state);
        case actionConsts.get('PERSONA_NEW_FEEDBACK_READ'):
            return setPersonaFeedbackAsRead(action.payload, state);
        default:
            return state;
    }
};

export default personas;