import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        personaPopupBg: {
            borderRadius: '4px',
            background: skin.get('white'),
            boxShadow: '1px 1px 5px 0 #d5d8e2'
        },

        personaNameStyle: {
            ...myTypography.subtitle,
            fontWeight: 600,
            color: skin.get('grayColor2')
        },

        personaListBackground: {            
            backdropFilter: 'blur(10px)',
            backgroundImage: 'linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd)'
        },

        borderTopStyle: {
            background: skin.get('grayColor2')
        },

        demographicsContainer: {
            borderRadius: '4px',
            background: '#f7f7f7'
        },

        aboutDemographicsContainer:{
            ...myTypography.body2,
            color: skin.get('grayColor2'),
        },
        demographicsText: {
            ...myTypography.body1,
            fontWeight: 600,
            color: skin.get('grayColor2')
        },
        experienceText: {
            ...myTypography.body1,
            fontWeight: 600,
            color: skin.get('grayColor2')
        },
        bio: {
            ...myTypography.body2,
            fontSize: '13px'
        },

        downArrow: {
			borderTop: `5px solid ${skin.get('primaryColor')}`
		},

		sideArrow: {
			borderLeft: `5px solid ${skin.get('primaryColor')}`
        },
        
        title: {
			...myTypography.body1,
			color: skin.get(`grayColor2`)
		},
    });
}

export default getSkin;