/**
 * @author: Ujjwal Jain
 * @file: ideas.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey, onlyMergeListsBasedOnKey, setOrUpdateObjectInList } from 'util/objectUtils';
import { getformattedIdeaList, getformattedUserIdeaList } from 'util/reducerMiddleware/formatReducerData';

const initialState = Immutable.fromJS({
    ideasList: [],
    userIdeasList: [],
    storylineIdeasList: [],
    isIdeasListFetched: false
});

const ideas = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_IDEAS'):
            const a = mergeListsBasedOnKey(state.get('userIdeasList'), getformattedUserIdeaList(action.payload.ideas), 'id');
            const z = mergeListsBasedOnKey(state.get('storylineIdeasList'), getformattedIdeaList(action.payload.ideas), 'id')

            return state.set('storylineIdeasList', z)
                    .set('userIdeasList', a)
                    .set('ideasList', mergeListsBasedOnKey(z, a.toJS(), 'id'))
                    .set('isIdeasListFetched', true);
        case actionConsts.get('UPDATE_IDEA'):
            const b = setOrUpdateObjectInList(state.get('userIdeasList'), action.payload, 'id', 'id');
            return state.set('userIdeasList', b)
                        .set('ideasList', mergeListsBasedOnKey(state.get('storylineIdeasList'), b.toJS(), 'id'))

        case actionConsts.get('UPDATE_IDEA_TEST_REPORT'):
            const c = setOrUpdateObjectInList(state.get('userIdeasList'), action.payload, 'id', 'id');
            return state.set('userIdeasList', c)
                        .set('ideasList', mergeListsBasedOnKey(state.get('storylineIdeasList'), c.toJS(), 'id'))

        case actionConsts.get('UPDATE_USER_IDEAS'):
            const d = mergeListsBasedOnKey(state.get('userIdeasList'), action.payload, 'id');
            return state.set('userIdeasList', d)
                        .set('ideasList', mergeListsBasedOnKey(state.get('storylineIdeasList'), d.toJS(), 'id'))
        default:
            return state;
    }
};

export default ideas;