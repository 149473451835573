/**
 * @author: Ujjwal Jain
 * @file: colorProfiles.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey } from 'util/objectUtils';

const initialState = Immutable.fromJS({
    isColorDataFetched: false,
    globalProfiles: {},
    entityProfiles: []
});


const colorProfiles = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_COLOR_PROFILE_DATA'):
            return state
                    .set('globalProfiles', action.payload.globalProfiles)
                    .set('entityProfiles', mergeListsBasedOnKey(state.get('entityProfiles'), action.payload.entityProfiles, 'id'))
                    .set('isColorDataFetched', true);
        default:
            return state;
    }
};

export default colorProfiles;