import React from 'react';
import styles from './doubleQuotes.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class DoubleQuotes extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skin = this.props.skinGuide.get('globalProfiles').get('palette')
        return (
            <div styleName="quotes-container">
                <svg width="100%" height="100%" viewBox="0 0 22 14">
                    <g fill={skin.get('grayColor5')} fillRule="nonzero" opacity="0.7">
                        <path d="M17.072 0c-2.787 0-4.987 2.257-4.987 5.04s2.289 5.04 5.075 5.04c.028 0 .07-.004.098-.004a6.397 6.397 0 0 1-4 1.404c-.697 0-1.258.564-1.258 1.26 0 .696.448 1.26 1.145 1.26C18.092 14 22 9.98 22 5.04v-.003C22 2.255 19.858 0 17.072 0zM5.072 0C2.289 0 .088 2.257.088 5.04s2.284 5.04 5.067 5.04c.028 0 .069-.004.097-.004a6.384 6.384 0 0 1-3.996 1.404C.561 11.48 0 12.044 0 12.74 0 13.436.455 14 1.15 14 6.09 14 10 9.98 10 5.04v-.003C10 2.255 7.854 0 5.072 0z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(DoubleQuotes, styles);