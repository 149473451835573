import React from 'react';
import styles from './eventAlertBackground.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class EventAlertBackground extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        const eventType = checkIfPresent(this.props.eventType) ? this.props.eventType : 'NEUTRAL';
        if(eventType === 'POSITIVE') {
            return (
                <div styleName="main-component">
                    <svg width="100%" height="100%" viewBox="0 0 315 334">
                        <defs>
                            <path id="event-positive-a" d="M4 0h306a4 4 0 0 1 4 4v325a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"/>
                        </defs>
                        <g fill="none" fill-rule="evenodd" transform="translate(.073 .781)">
                            <mask id="event-positive-b" fill="#fff">
                                <use xlinkHref="#event-positive-a"/>
                            </mask>
                            <use fill="#35C888" xlinkHref="#event-positive-a"/>
                            <g mask="url(#event-positive-b)">
                                <path fill="#07B469" d="M-3 318.747c179.53 6.004 286.002-90.506 319.414-289.528H326v333H-3v-43.472z" opacity=".2"/>
                                <path fill="#0AB76C" d="M-1 243.219c119.905.876 227.238 33.544 322 98.002v21.998H-1v-120z" opacity=".3"/>
                            </g>
                        </g>
                    </svg>
                </div>
            );
        }
        if(eventType === 'NEGATIVE') {
            return (
                <div styleName="main-component">
                    <svg width="100%" height="100%" viewBox="0 0 315 334">
                        <defs>
                            <path id="event-negative-a" d="M4 0h306a4 4 0 0 1 4 4v325a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"/>
                        </defs>
                        <g fill="none" fill-rule="evenodd" transform="translate(.073 .781)">
                            <mask id="event-negative-b" fill="#fff">
                                <use xlinkHref="#event-negative-a"/>
                            </mask>
                            <use fill="#FF5974" xlinkHref="#event-negative-a"/>
                            <g fill="#DC4D64" mask="url(#event-negative-b)" opacity=".3">
                                <path d="M-3 318.747c179.53 6.004 286.002-90.506 319.414-289.528H326v333H-3v-43.472z"/>
                                <path d="M-1 243.219c119.905.876 227.238 33.544 322 98.002v21.998H-1v-120z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            );
        }
        if(eventType === 'NEUTRAL') {
            return (
                <div styleName="main-component">
                    <svg width="100%" height="100%" viewBox="0 0 315 334">
                        <defs>
                            <path id="event-neutral-a" d="M4 0h305a4 4 0 0 1 4 4v340a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"/>
                        </defs>
                        <g fill="none" fill-rule="evenodd" transform="translate(0 -.003)">
                            <mask id="event-neutral-b" fill="#fff">
                                <use xlinkHref="#event-neutral-a"/>
                            </mask>
                            <use fill="#FF735A" xlinkHref="#event-neutral-a"/>
                            <g mask="url(#event-neutral-b)" opacity=".3">
                                <path fill="#E2563D" d="M-2.99 298.986c178.959 5.632 285.09-84.895 318.396-271.579h9.556v320.798H-2.99v-49.219z"/>
                                <path fill="#E06049" d="M-.997 228.14c119.523.823 226.515 31.465 320.975 91.927v29.076H-.997V228.141z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            );
        }
        return null;
    }
}

export default applyWrappers(EventAlertBackground, styles);