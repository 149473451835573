import React from 'react';
import styles from './moveArrow.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class MoveArrow extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 16 16">
                    <g fill="#000" fill-rule="nonzero" opacity=".6">
                        <path d="M8 15.833A7.833 7.833 0 1 0 .167 8 7.843 7.843 0 0 0 8 15.833zm0-14A6.167 6.167 0 1 1 1.833 8 6.173 6.173 0 0 1 8 1.833z"/>
                        <path d="M5.62 6.128h3.439a.163.163 0 0 0 .164-.163v-.82a.655.655 0 0 1 1.113-.458l1.965 1.965a.655.655 0 0 1 0 .926l-1.965 1.965a.655.655 0 0 1-1.113-.467v-.819a.163.163 0 0 0-.164-.164H5.62a.655.655 0 0 0 0 1.31.983.983 0 1 1 0 1.965 2.62 2.62 0 0 1 0-5.24z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(MoveArrow, styles);