/**
 * @author: Ujjwal Jain
 * @file: userDefine.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import {checkIfPresent} from 'util/utilFunctions';

const initialState = Immutable.fromJS({
    isIntroPageSeen: false,
    showIntroPage: true,
    isPhaseAvailableToUser: false,
    activeDefineSegmentId: -1,
    activeDefineTouchpointId: -1,
    focusValidationList: [],
    isFocusValidationFetched: false,
    suggestions: []
});


const userDefine = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_DEFINE_PHASE_STATE'):
            return state.mergeDeep(Immutable.fromJS(action.payload));
        case actionConsts.get ('SET_DEFINE_FOCUS_DATA'):
            return state.set('focusValidationList', formatFocusValidation(action.payload))
                    .set('isFocusValidationFetched', true);
        case actionConsts.get('UPDATE_DEFINE_FOCUS_DATA'):
            return state.set('focusValidationList', updateFocusValidationList(state.get('focusValidationList'), action.payload));
        case actionConsts.get('POPULATE_DEFINE_SUGGESTIONS'):
            return state.set('suggestions', action.payload);
        case 'MAKE_DEFINE_PHASE_AVAILABLE':
            return state.set('isPhaseAvailableToUser', true);
        case actionConsts.get('SET_IDEAS'):
            console.log("Hello the exhaust flag is being set", );
            return state.set('focusValidationList', updateFocusValidationListForIdeasExhausted(state.get('focusValidationList'), action.payload));
        default:
            return state;
    }
};

const formatFocusValidation = (list) => {
    list.map((eachData) => {
        eachData.isValidated = false;
        eachData.isDefined = false;
        eachData.validatedRound = -1;
        eachData.isExhausted = false;
        eachData.timeStamp = new Date().getTime();
        return;
    });
    return list;
}

const updateFocusValidationList = (list, payload) => {
    const {segmentId, touchPointId} = payload;
    const updatedFocusValidationList = list.map((eachFocus) => {
        if(
            eachFocus.segmentId === segmentId
            && eachFocus.touchPointId === touchPointId
        ) {
            return {
                ...eachFocus,
                ...payload
            }
        }
        return eachFocus;
    });
    
    return updatedFocusValidationList;
}

const updateFocusValidationListForIdeasExhausted = (list, payload) => {
    console.log("updateFocusValidationListForIdeasExhausted", payload);
    if(checkIfPresent(payload.segmentId) && checkIfPresent(payload.touchPointId) && payload.isExhausted === true) {
        const updatedFocusValidationList = list.map((eachFocus) => {
            if(
                eachFocus.segmentId === parseInt(payload.segmentId, 10)
                && eachFocus.touchPointId === parseInt(payload.touchPointId, 10)
            ) {
                return {
                    ...eachFocus,
                    ...{
                        isExhausted: true
                    }
                }
            }
            return eachFocus;
        });
        return updatedFocusValidationList;
    }
    return list;
}

export default userDefine;