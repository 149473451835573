import environment from 'util/environment';

const feedbackConfig = {
    'sim': 'designthinking', 		//service name
    'survey': 'DesignThinkingFeedback',
    // 'env': 'test',
    'env': environment.REACT_APP_ENV_FEEDBACK,		// same as environment used for commondb
    'isSurveyMandatory': true
};

const feedbackOptionsConfig = {
    headerTitle: "Feedback",
    headerColor: "#6a28b0",
    headerTitleColor: '#FFFFFF'
};

export {
    feedbackConfig,
    feedbackOptionsConfig
};
