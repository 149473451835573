import React from 'react';
import styles from './actionTickWithGradient.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ActionTickWithGradient extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="action-tick">
                <svg width="100%" height="100%" viewBox="0 0 28 28">
                    <defs>
                        <linearGradient id="c" x1="0%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="#E87AD9"/>
                            <stop offset="100%" stop-color="#7D4FEA"/>
                        </linearGradient>
                        <circle id="b" cx="10" cy="10" r="10"/>
                        <filter id="a" width="180%" height="180%" x="-40%" y="-20%" filterUnits="objectBoundingBox">
                            <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.201143569 0"/>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(4)">
                            <use fill="#000" filter="url(#a)" xlinkHref="#b"/>
                            <use fill="url(#c)" xlinkHref="#b"/>
                        </g>
                        <path fill="#FFF" fill-rule="nonzero" d="M19.573 6.95l-1.396-1.4a.25.25 0 0 0-.354 0l-5.896 5.9a.25.25 0 0 1-.354 0l-1.396-1.4a.25.25 0 0 0-.354 0l-1.396 1.4a.25.25 0 0 0 0 .354l3.146 3.146a.25.25 0 0 0 .354 0l7.646-7.65a.25.25 0 0 0 0-.35z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(ActionTickWithGradient, styles);