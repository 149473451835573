/**
 * @author: Varun Kumar
 * @file: touchPoints.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey } from '../../util/objectUtils';

const initialState = Immutable.fromJS({
    touchPointList: [],
    istouchPointsFetched: false
});


const touchPoints = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_TOUCHPOINTS_DATA'):    
            return state.set('touchPointList', mergeListsBasedOnKey(state.get('touchPointList'), action.payload, 'id'))
                        .set('istouchPointsFetched', true);
        default:
            return state;
    }
};

export default touchPoints;