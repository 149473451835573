import actionConsts from 'constants/actions';

const setSelectedSegmentId = payload => ({
    type: actionConsts.get('SET_SELECTED_SEGMENT_ID'),
    payload
});

const setSelectedTouchPointId = payload => ({
    type: actionConsts.get('SET_SELECTED_TOUCHPOINT_ID'),
    payload
});

const resetDefineFocus = () => ({
    type: actionConsts.get('RESET_FOCUS')
});


export {
    setSelectedSegmentId,
    setSelectedTouchPointId,
    resetDefineFocus
};
