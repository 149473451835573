/**
 * @author: Ujjwal Jain
 * @file: segments.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable, { update } from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey, onlyMergeListsBasedOnKey } from '../../util/objectUtils';
import { filterObjectFromArray } from 'util/objectUtils';
import { getSegmentQuestionFormattedData } from './middleware.js';

const initialState = Immutable.fromJS({
    segmentList: [],
    isSegmentsFetched: false,
    isSegmentQuestionsFetched: false,
    segmentQuestions: [],
    quadrants: [],
});

const segments = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_SEGMENTS_DATA'):    
            return state.set('segmentList', mergeListsBasedOnKey(state.get('segmentList'), action.payload, 'id'))
                        .set('isSegmentsFetched', true);
        case actionConsts.get('SET_UNDERSTAND_TEAM_VIEWS_DATA'):
            const formattedResponseData = getSegmentQuestionFormattedData(action.payload);            
            return state.set('segmentQuestions', onlyMergeListsBasedOnKey(state.get('segmentQuestions'), formattedResponseData.segmentQuestions, 'id'))
                        .set('quadrants', onlyMergeListsBasedOnKey(state.get('quadrants'), formattedResponseData.quadrants, 'id'))
                        .set('isSegmentQuestionsFetched', true);
        case actionConsts.get('SET_QUESTION_FOR_SEGMENT_ASKED'):
            return setQuestionAskedAndVisibility(action.payload, state);
        case actionConsts.get('SET_RESPONSE_FOR_QUADRANT_FILLED'): 
            return setResponseForSegmentEmpathyClicked(action.payload, state);
        case actionConsts.get('SET_AUTO_RESPONSE_FOR_QUADRANT_FILLED'):        
            return autoFillResponsesInQuadrant(state, action.payload);
        case actionConsts.get('SET_USER_EMPATHIZE_ANIMATION_SEEN'):
            return setAnimationSeenForSegment(action.payload, state);
        default:
            return state;
    }
};

const setAnimationSeenForSegment = (payload, state) => {
    const segmentsList = state.get('segmentQuestions').toJS();
    
    segmentsList.map((segmentList) => {
        if(segmentList.id === payload.id) {
            segmentList['showInitialAnimation'] = false;
        }
    });

    return state.set('segmentQuestions', onlyMergeListsBasedOnKey(state.get('segmentQuestions'), segmentsList, 'id'));

}

const setQuestionAskedAndVisibility = (payload, state) => {
    
    const segmentsList = state.get('segmentQuestions').toJS();
    
    let parentQuestion = [];
    segmentsList.map((segmentList) => {
        if(segmentList.id === payload.segmentId) {
            segmentList.questions.map((question) => {
                if(question.id === payload.questionId) {
                    question['isAsked'] = true;
                    segmentList['shouldShowEmptyChatSection'] = false;
                    segmentList['isEmpathyPopupOpen'] = true;
                    question['timeStamp'] = new Date().getTime();
                    parentQuestion = question;
                }
            })
        }
    });
     
    segmentsList.map((segmentList) => {
        if(segmentList.id === payload.segmentId) {
            segmentList.questions.map((question) => {
                if(question.parentId === payload.questionId && parentQuestion.isAsked) {                    
                    question['isVisible'] = true;
                }
            })
        }
    });  
    return state.set('segmentQuestions', onlyMergeListsBasedOnKey(state.get('segmentQuestions'), segmentsList, 'id'));
}

const setResponseForSegmentEmpathyClicked = (payload, state) => {

    const segmentsList = state.get('segmentQuestions').toJS();

    let responseList = [];
    segmentsList.map((segmentList) => {
        if(segmentList.id === payload.segmentId) {
            segmentList.questions.map((segmentQuestion) => {
                if (segmentQuestion.id === payload.questionId) {
                    segmentQuestion.responses.map((response) => {
                        if (response.id === payload.id) {
                            response['isClicked'] = true;
                            responseList.push(response);
                        }
                    })
                }
            })
        }
    })
    
    const quadrantsList = state.get('quadrants').toJS();
    quadrantsList.map((quadrant) => {
        quadrant.responses.map((quadrantResponse, index) => {
            responseList.map((response) => {
                if (quadrantResponse.id === response.id && quadrantResponse.segmentId === response.segmentId) {
                    quadrant.responses[index] = response;
                }
            })
        })
    });

    return state.set('segmentQuestions', onlyMergeListsBasedOnKey(state.get('segmentQuestions'), segmentsList, 'id'))
                .set('quadrants', onlyMergeListsBasedOnKey(state.get('quadrants'), quadrantsList, 'id'));
    
    
}

const autoFillResponsesInQuadrant = (state, payload) => {
    const segmentsList = state.get('segmentQuestions').toJS();
    
    let responseList = [];
    segmentsList.map((segmentList) => {
        if(segmentList.id == payload.segmentDetails.id) {
            segmentList.questions.map((segmentQuestion) => {
                if (segmentQuestion.isAsked) {
                    segmentQuestion.responses.map((response) => {
                        response['isClicked'] = true;
                        responseList.push(response);
                    })
                }
            })
        }
    })

    
    const quadrantsList = state.get('quadrants').toJS();
    quadrantsList.map((quadrant) => {
        quadrant.responses.map((quadrantResponse, index) => {
            responseList.map((response) => {
                if (quadrantResponse.id === response.id && quadrantResponse.segmentId === response.segmentId) {
                    quadrant.responses[index] = response;
                }
            })
        })
    });    

    return state.set('segmentQuestions', onlyMergeListsBasedOnKey(state.get('segmentQuestions'), segmentsList, 'id'))
                .set('quadrants', onlyMergeListsBasedOnKey(state.get('quadrants'), quadrantsList, 'id'));
}

export default segments;