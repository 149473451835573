import { StyleSheet } from 'aphrodite';
import { typography, getCurrentEntityPalette } from 'util/styleUtil';

const getColorPalette = (skinGuide, id) => {
	const entityProfiles = skinGuide.get('entityProfiles');
	const currentEntityPalette = getCurrentEntityPalette(entityProfiles, id);
    
	return StyleSheet.create({
		chatBoxBackground: {
			backgroundColor: currentEntityPalette.get('solidColor'),
        },

        chatBoxBorder: {
            boxShadow: `0 0px 4px 1px ${currentEntityPalette.get('solidColor')}`
        },
        
        chatBoxArrowBackground: {
            borderBottom: `25px solid ${currentEntityPalette.get('solidColor')}`
        }
	});
}

export default getColorPalette;