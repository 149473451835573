import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		popupContainer: {
			// backgroundColor: skin.get('grayColor4')
		},
		popupBackground: {
			backgroundColor: hexToRgbA(skin.get('black'), 0.6)
		},
		container: {
			boxShadow: `0 5px 15px 5px rgba(0, 0, 0, 0.2)`,
			borderRadius: '5px'
		},
		openButton: {
			boxShadow: `0 5px 10px 0 rgba(0, 0, 0, 0.2)`
		},
		alertMessage: {
			...myTypography.subtitle,
			fontSize: '17px',
			fontWeight: 600,
			color: skin.get('white')
		},
		opensInMessage: {
			...myTypography.body2,
			color: skin.get('white')
		},
		buttonText: {
			...myTypography.body1,
			textTransform: 'uppercase',
			color: skin.get('primaryColor'),
			fontWeight: 600,
			cursor: 'pointer',
			':hover': {
				fontWeight: 600
			}
		},
		ceoEventHeading: {
			...myTypography.subtitle,
			fontSize: '20px',
			color: skin.get('white'),
			fontWeight: 600
		},
		ceoDetailsName: {
			...myTypography.subtitle,
			fontSize: '16px',
			color: skin.get('white'),
			fontWeight: 600
		},
		ceoDetailsDesg: {
			...myTypography.subtitle,
			fontSize: '14px',
			color: skin.get('white'),
		},
		ceoMessageText: {
			...myTypography.body1,
			color: skin.get('white'),
		}
	});
}

export default getSkin;