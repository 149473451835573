import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			borderBottom: `1px solid ${skin.get('grayColor6')}`
		},
		title: {
			...myTypography.body1,
			color: skin.get(`grayColor2`)
		},
		content: {
			...myTypography.body2,
			color: skin.get(`grayColor2`)
		},

		downArrow: {
			borderTop: `5px solid ${skin.get('primaryColor')}`
		},

		sideArrow: {
			borderLeft: `5px solid ${skin.get('primaryColor')}`
		},

		upArrow: {
			borderBottom: `5px solid ${skin.get('primaryColor')}`
		},

		transcriptStyle: {
			...myTypography.body2,
			lineHeight: 2.08,
			color: `${hexToRgbA(skin.get('primaryColor'), 0.7)}`
		}
	});
}

export default getSkin;