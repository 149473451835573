import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './app/router/AppRouter';
import store from 'store/store';
import * as Sentry from '@sentry/browser';

Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE
});

const AppProvider = () => (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

export default AppProvider;
