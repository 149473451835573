import React from 'react';
import styles from './walkthroughComponent.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import WalkthoughArrow from 'svgComponents/walkthroughArrow';
import {checkIfPresent} from 'util/utilFunctions';

class WalkthroughComponent extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const arrowType = checkIfPresent(this.props.arrowType) ? this.props.arrowType : 'LEFT';

        if(arrowType === 'BOTTOM') {
            return (
                <div styleName="walkthrough-container"
                    style={{
                        ...this.props.propStyles
                    }}
                >
                    <div
                        styleName={arrowType === 'BOTTOM' ? "walkthrough-background-bottom" : "walkthrough-background-bottom"}
                    >
                        <div styleName={arrowType === 'BOTTOM' ? "walkthrough-arrow-image-container rotate-bottom" : "walkthrough-arrow-image-container rotate-bottom"}>
                            <div styleName="walkthrough-arrow-image">
                                <WalkthoughArrow 
                                    stroke={checkIfPresent(this.props.stroke) ?  this.props.stroke : "#661AB8"}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        styleName={arrowType === 'LEFT' ?  "walkthrough-content-bottom" : "walkthrough-content-bottom"}
                        className={css(myStyles.walkthoughText)}
                    >
                        {this.props.text}
                    </div>
                </div>
            );
        }

        return (
            <div styleName="walkthrough-container"
                style={{
                    ...this.props.propStyles
                }}
            >
                <div
                    styleName={arrowType === 'LEFT' ? "walkthrough-background-left" : "walkthrough-background-right"}
                >
                    <div styleName={arrowType === 'LEFT' ? "walkthrough-arrow-image-container" : "walkthrough-arrow-image-container rotate-right"}>
                        <div styleName="walkthrough-arrow-image">
                            <WalkthoughArrow 
                                stroke={checkIfPresent(this.props.stroke) ?  this.props.stroke : "#661AB8"}
                            />
                        </div>
                    </div>
                </div>
                <div
                    styleName={arrowType === 'LEFT' ?  "walkthrough-content-left" : "walkthrough-content-right"}
                    className={css(myStyles.walkthoughText)}
                >
                    {this.props.text}
                </div>
            </div>
        );
    }
}

export default applyWrappers(WalkthroughComponent, styles);