import React from 'react';
import styles from './ceoEventImage.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class CeoEventImage extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-container">
                <svg width="100%" height="100%" viewBox="0 0 81 61">
                    <defs>
                        <linearGradient id="event-ceo-image" x1="50.001%" x2="50.001%" y1="100.006%" y2="-.001%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <ellipse cx="43.13" cy="52.171" fill="#F5F5F5" fill-rule="nonzero" rx="29.165" ry="8.296"/>
                        <path fill="#35C888" fill-rule="nonzero" d="M70.879 19.898s-5.167 5.144-2.25 12.776c2.916 7.633-5 20.326-5 20.326s.083-.012.243-.04c10.61-1.865 15.883-13.947 9.982-22.923-2.19-3.337-3.994-7.252-2.975-10.14z"/>
                        <path stroke="#FF735A" stroke-width=".5" d="M70.879 19.898s-2.917 6.305 0 11.449c2.916 5.143.5 19.579-7.25 21.653"/>
                        <path fill="#25B375" fill-rule="nonzero" d="M80.08 40.702s-5.896-.935-6.433 4.326c-.538 5.26-11.333 5.99-11.333 5.99l.203.134c8.916 5.827 17.44 3.711 16.156-4.027-.481-2.876-.443-5.765 1.407-6.423z"/>
                        <path stroke="#FF735A" stroke-width=".5" d="M80.08 40.702s-4.576 1.001-4.2 5.144c.375 4.143-6.8 9.108-13.57 5.17"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M12.7.09c-.672-.024-1.36 0-1.99.234C8.995.96 8.183 3.04 8.447 4.844c.264 1.803 1.35 3.369 2.478 4.803 1.128 1.434 2.35 2.85 2.968 4.563.308.855.452 1.813.139 2.667-.483 1.317-1.94 2.053-3.34 2.205-1.4.153-2.804-.145-4.208-.249-.59-.041-1.195-.046-1.75.146-.61.21-1.117.638-1.583 1.085-1.821 1.75-3.278 4.14-3.043 6.648.267 2.844 2.645 5.093 5.25 6.298.863.4 1.775.724 2.534 1.294 2 1.505 2.45 4.325 2.25 6.815-.2 2.49-.877 4.982-.5 7.45.444 2.87 2.41 5.463 5.062 6.674 2.362 1.079 5.318 1.012 7.416-.514 1.022-.747 1.796-1.776 2.468-2.844a24.499 24.499 0 0 0 2.454-5.134c.293-.855.568-1.775 1.25-2.37.417-.366.957-.57 1.49-.73 1.61-.48 3.307-.611 4.932-1.038 1.624-.426 3.26-1.221 4.143-2.642 1.25-2.017.666-4.596.672-6.97.01-5.405 3.26-10.245 4.458-15.513.392-1.723.538-3.628-.337-5.164-.595-1.048-1.598-1.794-2.652-2.375-2.316-1.281-4.95-1.851-7.54-2.406-3.6-.77-7.973-.87-10.764-3.36C20.016 1.793 16.352.09 12.7.09z" opacity=".2"/>
                        <path fill="#000" fill-rule="nonzero" d="M14.785 40.287l.016.033c.005-.033.01-.068.013-.1a.711.711 0 0 1-.029.067z" opacity=".1"/>
                        <path fill="#A1616A" fill-rule="nonzero" d="M25.765 28.218s.804 2.987-1.608 3.415a.294.294 0 0 1-.107-.053s-1.393-2.721-.322-3.788l2.037.426z"/>
                        <path fill="#5A5773" fill-rule="nonzero" d="M21.05 46.305s-.644-1.654.964-1.707c1.608-.054.804 1.493.804 1.493l-1.769.214z"/>
                        <path fill="#000" fill-rule="nonzero" d="M27.426 41.921a.125.125 0 1 0 0-.25.125.125 0 0 0 0 .25z"/>
                        <path fill="url(#event-ceo-image)" fill-rule="nonzero" d="M45.581 17.55v-.068L22.846.076a.084.084 0 0 0-.1 0L.01 17.482v26.98h.043v.043h45.573v-26.98l-.045.026z" opacity=".5" transform="translate(20.332 9.873)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M65.129 53.582H21.131v-26.05L43.13 10.69 65.129 27.53z"/>
                        <path fill="#E0E0E0" fill-rule="nonzero" d="M63.546 52.038H22.714V27.862l20.416-15.63 20.416 15.63z"/>
                        <path fill="#FFD700" fill-rule="nonzero" d="M25.149 18.373l24.907-6.501 8.305 31.538-24.908 6.501z"/>
                        <path fill="#F5F5F5" fill-rule="nonzero" d="M30.38 16.663h25.749v32.604H30.38z"/>
                        <path fill="#000" fill-rule="nonzero" d="M21.34 27.573l21.998 13.025L21.34 53.623z" opacity=".05"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M21.172 27.573l22 13.025-22 13.025z"/>
                        <path fill="#000" fill-rule="nonzero" d="M65.004 27.573l-22 13.025 22 13.025z" opacity=".05"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M65.17 27.573L43.173 40.598 65.17 53.623z"/>
                        <path fill="#000" fill-rule="nonzero" d="M21.172 53.208l22-17.09 21.999 17.09z" opacity=".05"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M21.172 53.623l22-17.09 21.999 17.09z"/>
                        <path stroke="#B0B0B0" stroke-width=".5" d="M25.643 55.602s-1.105-3.734.208-6.483a5.874 5.874 0 0 0 .479-3.7 9.887 9.887 0 0 0-.526-1.737"/>
                        <path fill="#35C888" fill-rule="nonzero" d="M26.62 41.945c0 .458-.834 1.785-.834 1.785s-.833-1.328-.833-1.785c0-.458.373-.83.833-.83.46 0 .834.372.834.83zM27.999 44.48c-.25.385-1.673 1.048-1.673 1.048s.025-1.564.275-1.948a.836.836 0 0 1 1.131-.215.827.827 0 0 1 .267 1.116zM27.936 48.812c-.411.206-1.976.064-1.976.064s.816-1.337 1.226-1.543a.836.836 0 0 1 .853.023.829.829 0 0 1-.103 1.457zM27.149 52.08c-.37.272-1.937.395-1.937.395s.577-1.455.947-1.727a.836.836 0 0 1 1.132.196.827.827 0 0 1-.142 1.136zM24.575 45.616c.333.319 1.866.65 1.866.65s-.376-1.52-.706-1.838a.836.836 0 0 0-1.15.044.827.827 0 0 0-.01 1.145zM23.703 49.45c.411.206 1.976.064 1.976.064s-.816-1.337-1.226-1.543a.835.835 0 0 0-1.087.382.828.828 0 0 0 .337 1.098zM23.37 53.291c.37.272 1.936.395 1.936.395s-.576-1.455-.946-1.727a.836.836 0 0 0-1.357.562c-.03.304.111.6.367.77z"/>
                        <path fill="#8261C9" fill-rule="nonzero" d="M31.88 18.156h10.333v1H31.88zM31.88 21.475h22.332v1H31.88zM31.88 23.632h22.332v1H31.88z" opacity=".5"/>
                        <path fill="#8261C9" fill-rule="nonzero" d="M31.88 25.789h22.332v1H31.88zM31.88 27.946h22.332v1H31.88z" opacity=".4"/>
                        <path fill="#8261C9" fill-rule="nonzero" d="M31.88 30.103h12.749v1H31.88zM31.88 32.26h17.416v1H31.88z" opacity=".3"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(CeoEventImage, styles);