import {checkIfPresent} from 'util/utilFunctions';

const replayAllReducerActions = (payload) => (dispatch) => {
    if(!checkIfPresent(payload)) {
        dispatch({
            type: 'SET_USER_STATE',
            payload: {
                isReducerActionReplayed: true
            }
        });
        return;
    }
    let reducerActions = payload.reducerActions;
    
    if(checkIfPresent(reducerActions) && reducerActions.length > 0) {
        reducerActions.map((reducerAction) => {                        
            dispatch({
                type: reducerAction.type,
                payload: reducerAction.payload
            })
        })
    }
    dispatch({
        type: 'SET_USER_STATE',
        payload: {
            isReducerActionReplayed: true
        }
    });
}

export {
    replayAllReducerActions
}
        

