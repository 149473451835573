import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		recContent: {
			...myTypography.body2,
			fontSize: '13px',
			color: skin.get('grayColor1')
		},
		recContainer: {
            borderBottom: `1px solid transparent`
        },
        recContainerHighlight: {
            borderBottom: `1px solid ${skin.get('grayColor5')}`
        }
	});
}

export default getSkin;