import React from 'react';
import styles from './desktopHeader.module.sass';
import { css } from 'aphrodite';
import applyWrappers from 'util/ComponentWrapper';
import getSkin from './skin.js';
import updateRoute from 'util/webAppRoutes/updateRoute';
import phaseRouteConstants from 'constants/phaseRouteConstants';
import RightArrow from 'svgComponents/rightArrow';
import { hexToRgbA } from 'util/styleUtil';
import Budget from 'svgComponents/budget';
import Event from 'svgComponents/event';
import {appTimerEnabled} from 'util/appVariables';
import {checkIfPresent} from 'util/utilFunctions';
import { getFormattedCurrency } from 'util/currencyFormatter';
import ChangeInMetric from 'commonComponents/changeInMetric';
import FilledButton from 'commonComponents/buttons/filledButton';
import ColorHeading from 'commonComponents/colorHeading';
import TimerComponent from 'commonComponents/timerComponent';
import BudgetAlert from 'svgComponents/budgetAlert';
import {showBlur} from 'util/utilFunctions';
import WalkthroughComponent from 'commonComponents/walkthroughComponent';

class DesktopHeader extends React.Component {

	constructor(props) {
		super(props);
		this.state={
			hoverPhase: -1
		}
	}

	closeBudgetWarning = () => {
		this.props.setUserState({
			showBudgetWarning: false
		});
		this.props.setUIState({
			isOverlayOpen: false
		})
	}

	onClickBudgetAlert = (alertBudgetData) => {
		this.props.postActionLog({
			type: 'UPDATE_BUDGET_ALERT_DATA',
			payload: alertBudgetData
		}, this.closeBudgetWarning);
	}

	onMouseEnterPhase = (id, isPhaseAvailableToUser) => {
		if(isPhaseAvailableToUser){
			this.setState({
				hoverPhase: id
			});
		}
	}
	
	openEvents = () => {
		this.props.setUIState({
			isBluredRightOverlay: true,
			popupComponentBluredOverlay: 'EVENTS'
		});
		if(this.props.uiState.get('atleastOneEventSeen') && !this.props.userState.get('isTeamChatWalkthroughSeen')) {
			this.props.postActionLog({
				type: 'SET_USER_STATE',
				payload: {
					isTeamChatWalkthroughSeen: true
				}
			})
			
        }
	}

	onMouseEnterPhase = (id) => {
		this.setState({
			hoverPhase: id
		});
	}

	onMouseLeavePhase = (id,isPhaseAvailableToUser) => {
		if(isPhaseAvailableToUser){
			this.setState({
				hoverPhase: -1
			});
		}
	}

    onPhaseClick = (id, phaseKey, isPhaseAvailableToUser) => {
		if(isPhaseAvailableToUser){
			this.props.setUserState({
				currentPhaseKey: phaseKey
			});
			updateRoute({
				route: phaseRouteConstants[phaseKey],
				params: {}
			});
		}
	}
	
	executeClicked = (id, phaseKey, isPhaseAvailableToUser) => {
		const ideaCount = this.getIdeasForExecution().size;
		if(ideaCount == 0 || ideaCount == 1){
			if(isPhaseAvailableToUser){
				this.props.setUIState({
					isOverlayOpen: true,
					popupComponent: 'PRE_EXECUTE_POPUP'
				})
			}
		}else{
			if(isPhaseAvailableToUser){
				this.props.setUIState({
					isOverlayOpen: true,
					popupComponent: 'EXECUTE_POPUP'
				})
			}
		}
	}

	getIdeasForExecution = () => {
		const ideasList = this.props.ideas.get('ideasList');
		const myIdeasList = ideasList.filter((eachIdea) => {
			return eachIdea.get('isShortlistedForExecution') === true
					&& eachIdea.get('isShortlistedPrototype') === false
					&& eachIdea.get('isNormalIdea') === false
					&& eachIdea.get('isExecuted') === false
		});
		return myIdeasList;
	}

	getIfPhaseAvailable = (eachPhase) => {

		const userPhasesState = this.props.userPhasesState;
		switch(eachPhase.key){
			case 'observe' :
				return userPhasesState.get('userObserve').get('isPhaseAvailableToUser');

			case 'empathize' :
				return userPhasesState.get('userEmpathize').get('isPhaseAvailableToUser');

			case 'define' :
				return userPhasesState.get('userDefine').get('isPhaseAvailableToUser');

			case 'ideate' :
				return userPhasesState.get('userIdeate').get('isPhaseAvailableToUser');

			case 'prototype' :
				return userPhasesState.get('userPrototype').get('isPhaseAvailableToUser');

			case 'test':
				return userPhasesState.get('userTestPrototype').get('isPhaseAvailableToUser');

			case 'execute':
				return userPhasesState.get('userTestPrototype').get('isPhaseAvailableToUser')
						&& !this.props.userState.get('isUserGameCompleted');

			default:
				return false;

		}	
	}

	getStyleForExecute = (isPhaseAvailableToUser, myStyles) => {
		if(isPhaseAvailableToUser){
			return css(myStyles.enabledRoundBorder, myStyles.unlockedPhase)
		}
		else{
			return css(myStyles.roundBorder, myStyles.lockedPhase)
		}
	}


	getStyleForPhases = (isPhaseAvailableToUser, myStyles, isOnboarding, isCurrentPhase) => {
		if (isOnboarding) {
			return css(myStyles.unlockedPhase);
		} else if(!isPhaseAvailableToUser){
			return css(myStyles.lockedPhase);
		} else {
			if(isCurrentPhase){
				return css(myStyles.selectedPhase);
			}
			else{
				return css(myStyles.unlockedPhase);
			}
		}
	}

    renderPhases = (myStyles, phaseList) => {
        const { userState, uiState } = this.props;
		const skin = this.props.skinGuide.get('globalProfiles').get('palette');

        if(phaseList.size > 0) {
			const phaseDivs = phaseList.toJS().map((eachPhase, index) => {
				const isPhaseAvailableToUser = this.getIfPhaseAvailable(eachPhase);
				if(eachPhase.key === 'execute') {
					return (
						<div
							key={index}
							styleName={
								userState.get('currentPhaseKey') === eachPhase.key && isPhaseAvailableToUser
								?   "each-selected-header-phase-execute"
								:   "each-header-phase-execute"
							}
							className={
								this.getStyleForExecute(isPhaseAvailableToUser, myStyles)
							}
							onClick={() => {
								this.executeClicked(eachPhase.id, eachPhase.key, isPhaseAvailableToUser);
							}}
							onMouseEnter={() => {this.onMouseEnterPhase(eachPhase.id, isPhaseAvailableToUser)}}
							onMouseLeave={() => {this.onMouseLeavePhase(eachPhase.id, isPhaseAvailableToUser)}}
						>
							{this.props.getLabel(eachPhase.name)}
						</div>
					);
				}
				return (
					<div
                        key={index}
                        styleName={
                            userState.get('currentPhaseKey') === eachPhase.key && !uiState.get('isUserOnIntroScreen') && isPhaseAvailableToUser
                            ?   "each-selected-header-phase"
                            :   "each-header-phase"
                        }
						className={
							this.getStyleForPhases(
								isPhaseAvailableToUser,
								myStyles,
								uiState.get('isUserOnIntroScreen'),
								(userState.get('currentPhaseKey') === eachPhase.key)
							)
						}
						onClick={() => {
							this.onPhaseClick(eachPhase.id, eachPhase.key, isPhaseAvailableToUser);
						}}
						onMouseEnter={() => {this.onMouseEnterPhase(eachPhase.id, isPhaseAvailableToUser)}}
						onMouseLeave={() => {this.onMouseLeavePhase(eachPhase.id, isPhaseAvailableToUser)}}
					>
						<div styleName="phase-name">
							{this.props.getLabel(eachPhase.name)}
						</div>
						<div styleName="phase-arrow">
							<RightArrow
								color={
									(userState.get('currentPhaseKey') === eachPhase.key)
									?	skin.get('primaryColor')
									:	(this.state.hoverPhase === eachPhase.id) && isPhaseAvailableToUser
										?	skin.get('white')
										:	hexToRgbA(skin.get('white'), 0.3)
								}
							/>
						</div>
					</div>
				);
			});
			return phaseDivs;
		}
		return null;
	}

	renderBudgetAlert = (myStyles, budgetObj) => {
		let alertBudgetData = [];
		const budgetData = this.props.userState.get('userBudgetData');
		const currentRoundNotSeenBudgetData = budgetData.filter((eachData) => {
			return (
				eachData.roundId === this.props.userState.get('currentRoundId')
				&& eachData.isSeenByUser === false
			)
		});
		if(currentRoundNotSeenBudgetData.length > 0) {
			alertBudgetData = currentRoundNotSeenBudgetData.filter((eachData) => {
				return(budgetObj.value <= eachData.budgetAlertValue)
			});
			if(alertBudgetData.length > 0) {
				this.props.setUserState({
					showBudgetWarning: true
				});
				this.props.setUIState({
					isOverlayOpen: true
				})
			}
		}

		const initialBudget = checkIfPresent(this.props.storyline.get('baseValues').initialBudget)
								?   this.props.storyline.get('baseValues').initialBudget
								:   this.props.storyline.get('baseDefaultValues').get('initialBudget');

		const budgetLeft = budgetObj.value;

		const budgetSpent = initialBudget - budgetLeft;

		if(this.props.userState.get('showBudgetWarning') && !this.props.uiState.get('isUserOnIntroScreen')) {
			return (
				<div styleName="budget-text-firsttime-message-container"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className={css(myStyles.upOnboardingArrow)} styleName="what-budget-arrow"></div>
					<div styleName="budget-alert-container">
						<div styleName="budget-alert-background">
							<div styleName="background-image-container">
								<BudgetAlert />
							</div>
						</div>
						<div styleName="budget-alert-content">
							<div styleName="what-budget-first-content">
								<div styleName="what-budget-first-content-text">
									<div styleName="budget-alert-heading">
										<ColorHeading
											title={this.props.getLabel('label_budget_alert_heading')}
											isUppercase={false}
											fontSize="20px"
											imgBottom="0px"
										/>
									</div>
									<div className={css(myStyles.budgetFirstTimeText)} styleName="budget-alert-desc">
										{this.props.getLabel('label_budget_alert_desc')}
									</div>
									<div styleName="budget-values-container">
										<div styleName="budget-values">
											<div styleName="budget-value-content">
												<div className={css(myStyles.valueHeading)} styleName="value-heading">{this.props.getLabel('label_budget_total_spend')}</div>
												<div className={css(myStyles.valueNumber)} styleName="value-number">
													{getFormattedCurrency(budgetSpent, 'USD')}
													<span className={css(myStyles.valueNumberDenominator)}>
														/{getFormattedCurrency(initialBudget, 'USD')}
													</span>
												</div>
											</div>
											<div className={css(myStyles.valueSeparator)} styleName="values-separator"></div>
											<div styleName="budget-value-content">
												<div className={css(myStyles.valueHeading)} styleName="value-heading">{this.props.getLabel('label_budget_left')}</div>
												<div className={css(myStyles.valueNumber, myStyles.red)} styleName="value-number">
													{getFormattedCurrency(budgetLeft, 'USD')}
												</div>
											</div>
										</div>
										<div styleName="budget-progress-bar-container">
											<div className={css(myStyles.progressBarBack)} styleName="budget-progress-bar-back">
												<div
													className={css(myStyles.progressBarFront)}
													styleName="budget-progress-bar-front"
													style={{
														minWidth: '2%',
														maxWidth: '100%',
														width: Math.round(budgetSpent*100/initialBudget) < 0 ? '2%' : Math.round(budgetSpent*100/initialBudget)
													}}
												></div>
											</div>
										</div>
									</div>
									<div styleName="budget-buttons-container">
										<FilledButton textLabel={this.props.getLabel('label_got_it')}
											clickFunction={() => {
												this.onClickBudgetAlert(alertBudgetData);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	
	renderBudgetAndTime = (myStyles) => {
		const metrics = this.props.metrics.get('metrics').toJS();
		const defaultBudgetObj = {
			value: 0,
			diff: 0
		}
		let budgetObj = metrics.find((eachMetric) => {
			return (eachMetric.key === 'budget');
		});
		if(!checkIfPresent(budgetObj)) {
			budgetObj = defaultBudgetObj
		}
		return (
			<div styleName="budget-time-container">
				<div styleName="budget-container">
					<div styleName="budget-image"><Budget /></div>
					<div className={css(myStyles.budgetTimerFont)} styleName="budget-value">
						{
							!this.props.uiState.get('isUserOnIntroScreen')
							?	<ChangeInMetric
									metricValue={budgetObj.value}
									changeValue={Math.round(budgetObj.diff)}
									styleObj={{
										top: '25px',
										left: '0px'
									}}
									formatValue={true}
									animateObj={{
										top: '0px'
									}}
								/>
							:	null
						}
						
						{getFormattedCurrency(budgetObj.value,'USD')}
					</div>
					{this.renderBudgetAlert(myStyles, budgetObj)}
				</div>
				{
					appTimerEnabled()
					?	<TimerComponent {...this.props} />
					:	null
				}
			</div>
		);
	}

	renderTeamAndEvents = (myStyles) => {
		return (
			<div styleName="team-events-container">
				<div styleName="team-content-container">
					<div className={css(myStyles.teamLabel)} styleName="team-label">{this.props.getLabel('label_team')}</div>
					{this.renderTeamMembers(myStyles)}
				</div>
				<div styleName="event-container">
					{
						!this.props.userState.get('isTeamChatWalkthroughSeen') && this.props.uiState.get('atleastOneEventSeen') ? 
							<WalkthroughComponent
								arrowType="TOP"
								stroke="#fff"
								propStyles={{
									top: '14px',
									left: '-144px',
								}}
								text={this.props.getLabel('label_walkthrough_team_chat')}
							/>
						: null
					}
					<div className={css(myStyles.eventContent)} styleName="event-content">
						<div styleName="event-image" onClick={this.openEvents}>
							<Event/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderTeamMembers = (myStyles) => {
		const teamMembers = this.props.teamMembers.get('teamList');
		const teamDivs = teamMembers.toJS().map((eachMember) => {
			if(eachMember.isShownOnHeader) {
				return (
					<div styleName="tm-container" key={`teamMember-${eachMember.id}`}>
						<div styleName="tm-image">
							<img src={this.props.getImage(eachMember.image_full)} alt="team" width="100%" height="100%" />
						</div>
						<div styleName="tm-details">
							<div className={css(myStyles.tmName)} styleName="tm-name">{this.props.getLabel(eachMember.name).split(" ", 1)}</div>
							<div className={css(myStyles.roleName)} styleName="tm-role">{this.props.getLabel(eachMember.role)}</div>
						</div>
					</div>
				);
			}
			return null;
		});
		return(
			<div styleName="team-members">
				{teamDivs}
			</div>
		);
	}

	render() {
        const {phases} = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		const {uiState} = this.props;
		
		const isPhaseHeaderBlur = showBlur(uiState);
		const isPhaseTimerBlur = showBlur(uiState);
		const isPhaseTeamBlur = showBlur(uiState);

        return (
            <div styleName="header-container" style={{
				// pointerEvents: uiState.get('isUserOnIntroScreen') ? 'none' : 'all',
				position: uiState.get('isProjectBoardOpen') || uiState.get('isLeaderBoardOpen') ? 'fixed' : 'absolute',
				zIndex: uiState.get('isProjectBoardOpen') || uiState.get('isLeaderBoardOpen') ? '10' : 'auto'	
			}}
			>
                <div styleName="header-content">
                    <div
						className={css(myStyles.headerBox)}
						styleName="phases-container"
						style={{
							pointerEvents: uiState.get('isUserOnIntroScreen') ? 'none' : 'all',
							filter: isPhaseHeaderBlur ? 'blur(4px)' : null,
							zIndex: uiState.get('highlightPhaseHeader') ? 31 : 29
						}}
					>
                        {this.renderPhases(myStyles, phases.get('phaseList'))}
                    </div>
                    <div
						className={css(myStyles.headerBox)}
						styleName="metrics-container"
						style={{
							filter: isPhaseTimerBlur && uiState.get('isOverlayOpen') === false ? 'blur(4px)' : null,
							zIndex: uiState.get('highlightTimerHeader') || (uiState.get('isOverlayOpen') && !uiState.get('isUserOnIntroScreen'))
									? 42 : 29
						}}
					>
						{this.renderBudgetAndTime(myStyles)}
					</div>
                    <div
						className={css(myStyles.headerBox)}
						styleName="team-container"
						style={{
							pointerEvents: uiState.get('isUserOnIntroScreen') ? 'none' : 'all',
							filter: isPhaseTeamBlur ? 'blur(4px)' : null,
							zIndex: uiState.get('highlightTeamHeader') ? 31 : 29
						}}
					>
						{this.renderTeamAndEvents(myStyles)}
					</div>
                </div>
            </div>
        );
	}
}

DesktopHeader.propTypes = {};
DesktopHeader.defaultProps = {};

export default applyWrappers(DesktopHeader, styles);
