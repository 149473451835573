import React from 'react';
import styles from './overlayDropDown.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class OverlayDropDown extends React.Component {

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.popupContainer)} styleName="popup-container">
				<div className={css(myStyles.popupBackground)} styleName="popup-background"></div>
				<div styleName="popup-content"
					onClick={() => {
						this.props.setUIState({
							isDropDownOverlayOpen: false,
							closePopovers: true
						})
					}}
				>
				</div>
			</div>
		);
	}
}

export default applyWrappers(OverlayDropDown, styles);
