import Immutable from 'immutable';
import { compareOnTimeStampReverse } from 'util/utilFunctions';
const filterObjectFromArray = (list, key, id) => {
    const filteredList = list.filter((element) => element.get(key) === id);

    if (filteredList.size === 0) {
        return Immutable.fromJS({});
    }

    return filteredList.get(0);
}

const isEmptyObject = (obj = {}) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const getIndexOfValueInList = (list, key, value) => {
    return list.findIndex(
        listItem => listItem.get(key) === value
    );
}

const setOrUpdateObjectInListMerge = (list, object, listKey, objectKey) => { 
    
    const toBeUpdatedListItemIndex = getIndexOfValueInList(list, listKey, object[objectKey]);
    
    if (toBeUpdatedListItemIndex === -1) {
        return list.push(Immutable.fromJS(object));
    }

    return list.update(
        toBeUpdatedListItemIndex,
        (oldListItem) => oldListItem.merge(Immutable.fromJS(object))
    );
}

const setOrUpdateObjectInList = (list, object, listKey, objectKey) => { 
    
    const toBeUpdatedListItemIndex = getIndexOfValueInList(list, listKey, object[objectKey]);
    
    if (toBeUpdatedListItemIndex === -1) {
        return list.push(Immutable.fromJS(object));
    }

    return list.update(
        toBeUpdatedListItemIndex,
        (oldListItem) => oldListItem.mergeDeep(Immutable.fromJS(object))
    );
}

const setOrReplaceObjectInList = (list, object, listKey, objectKey) => {    
    const toBeUpdatedListItemIndex = getIndexOfValueInList(list, listKey, object[objectKey]);

    if (toBeUpdatedListItemIndex === -1) {
        return list.push(Immutable.fromJS(object));
    }

    return list.set(toBeUpdatedListItemIndex,(Immutable.fromJS(object)));
}

const setOrUpdateObjectInMap = (inputMap, key, object) => {
    if (inputMap.has(key)) {
        return inputMap.set(
            key,
            inputMap.get(key).merge(Immutable.fromJS(object))
        );
    }
    return inputMap.set(key, Immutable.fromJS(object));
}

const mergeListsBasedOnKey = (oldList, newArray, key) => {
    let updatedList = oldList;

    newArray.map((arrayItem) =>
        updatedList = setOrUpdateObjectInList(updatedList, arrayItem, key, key));
    
    return updatedList;
}

const onlyMergeListsBasedOnKey = (oldList, newArray, key) => {
    let updatedList = oldList;

    newArray.map((arrayItem) =>
        updatedList = setOrUpdateObjectInListMerge(updatedList, arrayItem, key, key));
    
    return updatedList;
}

const extend = (defaultObject, overrideObject) => {
    for (var key in overrideObject) {
        if (defaultObject.hasOwnProperty(key) && ((overrideObject[key] !== ''))) {
            defaultObject[key] = overrideObject[key];
        }
    }
    return defaultObject;
}

const removeObjectFromList = (list, key, value) => {
    const toBeDeletedListItemIndex = getIndexOfValueInList(list, key, value);

    if (toBeDeletedListItemIndex === -1) {
        return list;
    }

    return list.delete(toBeDeletedListItemIndex);
}

const getQuestionResponseArray = (questions) => {

    let questionResponseList = [];
    
    const questionOrder = questions.sort(compareOnTimeStampReverse);    
    
    questionOrder.map((question) => {
        if(question['isAsked']) {        
            questionResponseList.push(question);
            question['responses'].map((response) => {
                questionResponseList.push(response);
            })
        }
    });


    
    return questionResponseList;
}

const getMinAndMaxObject = (list) => {
    const minValue = Math.min(...list);
    const maxValue = Math.max(...list);

    return {
        min: minValue,
        max: maxValue
    }
}

const getMinAndMaxValues = (arrayOfValues, key) => {
    let list = [];
    arrayOfValues.get('questions').map((question) => {
        list.push(question.get('cost'));
    });
    
    return getMinAndMaxObject(list);
}

const getMinAndMaxValuesNested = (arrayOfValues, nestedKey) => {
    let list = [];

    arrayOfValues.map((persona) => {
        persona.get(nestedKey).map((object) => {
            list.push(object.get('cost'));
        })
    });
    
    return getMinAndMaxObject(list);
}

export {
    filterObjectFromArray,
	isEmptyObject,
    setOrUpdateObjectInList,
    setOrUpdateObjectInMap,
    mergeListsBasedOnKey,
    setOrReplaceObjectInList,
    removeObjectFromList,
    extend,
    getIndexOfValueInList,
    onlyMergeListsBasedOnKey,
    getQuestionResponseArray,
    getMinAndMaxValues,
    getMinAndMaxValuesNested,
    getMinAndMaxObject
};