import React from 'react';
import styles from './detail.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class Detail extends React.Component {

    renderValues = () => {
        let result = "";
        if(typeof(this.props.value) == 'object'){
            this.props.value.map((element, index) => {
                if(index != 0){
                    result += " " +this.props.getLabel(element) + ",";
                }
                else{
                    result += this.props.getLabel(element) + ",";
                }
            });
            result = result.substring(0, result.length - 1);            
        }
        else{
            result = this.props.getLabel(this.props.value);            
        }
        return result === null ? 'NA' : result;
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="detail">
                <p styleName="title" className={css(myStyles.title)}>
                    {this.props.title}
                </p>
                <p styleName="value" className={css(myStyles.value)}>
                    {this.renderValues()}
                </p>
            </div>
        );
    }
}

export default applyWrappers(Detail, styles);