import consts from 'constants/url';
import kfetch from '../util/kfetch';
import {postActionLog} from 'actions/apiAction/postActionLog';

export const postGameCompleted = (payload) => (dispatch) => kfetch(consts.get('POST_GAME_COMPLETED'), payload, 'POST')
    .then((response) => {
        dispatch(postActionLog({
            type: 'SET_USER_STATE',
            payload: {
                isUserGameCompleted: true
            }
        }))
    });