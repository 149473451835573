import React from 'react';
import styles from './notesComponent.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import DownArrow from 'svgComponents/downArrow';
import UpArrow from 'svgComponents/upArrow';
import Draggable from 'react-draggable';
import ReactQuill from 'react-quill';
import _ from 'lodash';

class NotesComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNotesOpen: false,
            notesText: props.userState.get('notes')
        }
        this.handleNotesChange.bind(this);
    }

    toggleNotes = () => {
        this.setState({
            isNotesOpen: !this.state.isNotesOpen
        });
    }

    handleNotesChange = (value) => {
        this.setState({
            notesText: value
        });
        this.saveNotes();
    }

    saveNotes = () => {
        this.props.postSaveNotes({
            notes: this.state.notesText
        })
    }

    getEditorModules = () => {
        return {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean']
            ]
        };
    }

    getEditorFormats = () => {
        return [
            'bold', 'italic', 'underline', 'strike',
            'list', 'bullet'
        ];
    }

    renderNotes = (myStyles) => {
        return (
            <strong styleName="no-drag">
                <div styleName="notes-quill-content" className="no-cursor">
                    <ReactQuill
                        theme='snow'
                        onChange={_.debounce(this.handleNotesChange, 1000)}
                        value={this.state.notesText}
                        modules={this.getEditorModules()}
                        formats={this.getEditorFormats()}
                        placeholder= {this.props.getLabel('label_notes_placeholder')}
                    />
                    {/* <div styleName="notes-save-button-container">
                        <div className={css(myStyles.saveButton)} styleName="save-button"
                            onClick={this.saveNotes}
                        >
                            {this.props.getLabel('label_save')}
                        </div>
                    </div> */}
                </div>
            </strong>
        );
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <Draggable
                axis="both"
                defaultPosition={{
                    x: 40,
                    y: 60
                }}
                bounds={{
                    top: 0,
                    left: 0,
                    right: this.props.uiState.get('viewPortWidth') - 400,
                    bottom: this.props.uiState.get('viewPortHeight') - 400
                }}
                cancel="strong"
            >
                <div styleName="notes-main-container">
                    <div styleName="notes-container">
                        <div styleName="notes-content">
                            <div styleName="notes-header" onClick={this.toggleNotes}>
                                <div className={css(myStyles.notesHeading)} styleName="notes-header-text">
                                    {this.props.getLabel('label_notes')}
                                </div>
                                <div styleName="notes-header-arrow-container">
                                    <div styleName="notes-header-arrow">
                                        {
                                            this.state.isNotesOpen
                                            ?   <UpArrow svgColor='#773ab9' />
                                            :   <DownArrow fillValue='#773ab9' />
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.isNotesOpen
                                    ? this.renderNotes(myStyles)
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </Draggable>
        );
    }
}

export default applyWrappers(NotesComponent, styles);