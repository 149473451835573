import React from 'react';
import styles from './demographics.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import Detail from './detail';

class Demographics extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="demographice-container">  
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_age')}
                        value={this.props.demographics.get('age')}
                    />
                </div>
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_gender')}
                        value={this.props.demographics.get('gender')}
                    />
                </div>
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_career')}
                        value={this.props.demographics.get('career')}
                    />
                </div>
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_price_consciousness')}
                        value={this.props.demographics.get('priceConsciousness')}
                    />
                </div>
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_net_purchases')}
                        value={this.props.demographics.get('netPurchases')}
                    />
                </div>
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_earning_potential')}
                        value={this.props.demographics.get('earningPotential')}
                    />
                </div>
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_peer_influence')}
                        value={this.props.demographics.get('peerInfluence')}
                    />
                </div>
                <div styleName="element">
                    <Detail 
                        title={this.props.getLabel('label_influenced_by')}
                        value={this.props.demographics.get('influencedBy')}
                    />
                </div>
            </div>
        );
    }
}

export default applyWrappers(Demographics, styles);