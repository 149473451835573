import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        popupHeaderStyle: {
            ...myTypography.subtitle,
            fontWeight: 600,
            color: skin.get('grayColor1')
        },
        
        popupContentShadow: {
            borderRadius: '5px',
            boxShadow: '1px 1px 10px 0 #d8d9da',
            backgroundColor: '#fafbff'
        },

        askQuestionsTitle: {
            ...myTypography.subtitle,
            lineHeight: 1,
            color: skin.get('grayColor1')
        },

        askQuestionsSubTitle: {
            ...myTypography.subtitle,
            fontSize: '12px',
            lineHeight: 'normal',
            color: skin.get('grayColor1')
        },

        askQuestionsSubTitle1: {
            ...myTypography.subtitle,
            fontSize: '12px',
            lineHeight: 'normal',
            color: skin.get('grayColor4')
        },
        askQuestionsSubTitleCost: {
            ...myTypography.subtitle,
            fontSize: '14px',
            lineHeight: 1,
            fontWeight: 600,
            color: skin.get('grayColor1')
        }, 

        stepTitle:{
            ...myTypography.subtitle,
            lineHeight: 1,
            color: skin.get('grayColor1'),
            fontSize: '20px'

        }, 
        stepSubTitle:{
            ...myTypography.subtitle,
            fontSize: '16px',
            lineHeight: 'normal',
            color: skin.get('grayColor1')
        }
    });
}

export default getSkin;