import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        popupBg: {
            borderRadius: '5px',
            boxShadow: '1px 1px 5px 0 #d5d8e2',
            backgroundColor: skin.get('white')
        },

        headerGradient: {
            backdropFilter: 'blur(10px)',
            backgroundImage: 'linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd)'
        },

        descStyle: {
            ...myTypography.subtitle,
            fontSize: '20px',
            lineHeight: 1.4,
            fontWeight: 600,
            color: skin.get('white')
        },
        proceedStyle: {
            ...myTypography.body2,
            color: skin.get('grayColor2')
        },
        footerBorder: {
            borderTop: `1px solid ${skin.get('grayColor4')}`
        },
        borderLeft: {
            borderLeft: `1px solid ${skin.get('grayColor4')}`
        },
        costBudgetStyle: {
            ...myTypography.body1,
            fontSize: '35px',
            lineHeight: 1.5,
            color: skin.get('grayColor2'),
            fontWeight: 400
        },
        costBudgetHeadingStyle: {
            ...myTypography.body1,
            fontSize: '14px',
            color: skin.get('grayColor2'),
            opacity: 0.7
        },
        costStyle: {
            ...myTypography.body1,
            fontSize: '15px',
            lineHeight: 1.22,
            color: skin.get('grayColor2'),
            textAlign: 'left'
        },
        positiveColor: {
            color: '#3d8e1a',
            ...myTypography.body1
        },
        negativeColor: {
            color: '#FF0000',
            ...myTypography.body1
        },
        helpText: {
            ...myTypography.body2,
            fontSize: '13px',
            color: skin.get('grayColor2')
        }
    });
}

export default getSkin;