import React from 'react';
import styles from './overlayWithTimer.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import EmpathyMapPopup from '../../components/game/empathize/empathyMapPopup/EmpathyMapPopup';
import { filterObjectFromArray, getQuestionResponseArray } from 'util/objectUtils';
import getColorPalette from './colorPalette';

class OverlayWithTimer extends React.Component {

	renderPopupContent = () => {
		return(
			<EmpathyMapPopup {...this.props} />
		)
	}

	render() {
		const { segments } = this.props;
        const segmentList = filterObjectFromArray(segments.get('segmentList'), 'id', this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'));
        const myColors = getColorPalette(this.props.skinGuide, segmentList.get('colorProfileId'));
		return (
			<div styleName="popup-timer-container">
				<div className={css(myColors.getSegmentBorder)} styleName="popup-timer-border">

				</div>
				{this.renderPopupContent()}
			</div>
		);
	}
}

export default applyWrappers(OverlayWithTimer, styles);
