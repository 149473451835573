import consts from 'constants/url';
import kfetch from '../util/kfetch';
import {checkIfPresent} from 'util/utilFunctions';

export const postActionLog = (payload, callback) => (dispatch) => kfetch(
        consts.get('ACTION_LOG_API'),
        {reducerAction: payload},
        'POST'
    )
    .then((response) => {
        if(response.message) {
            dispatch(payload);
        }
        if(checkIfPresent(callback)) {
            callback();
        }
    });