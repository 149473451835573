import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        popupHeaderStyle: {
            ...myTypography.subtitle,
            fontWeight: 600,
            color: skin.get('grayColor1')
        },
        
        popupContentShadow: {
            borderRadius: '5px',
            boxShadow: '1px 1px 10px 0 #d8d9da',
            backgroundColor: '#fafbff'
        },

        askQuestionsTitle: {
            ...myTypography.subtitle,
            lineHeight: 1,
            color: skin.get('grayColor1')
        },

        askQuestionsSubTitle: {
            ...myTypography.subtitle,
            fontSize: '12px',
            lineHeight: 'normal',
            color: skin.get('grayColor1')
        },

        askQuestionsSubTitle1: {
            ...myTypography.subtitle,
            fontSize: '12px',
            lineHeight: 'normal',
            color: skin.get('grayColor4')
        },
        askQuestionsSubTitleCost: {
            ...myTypography.subtitle,
            fontSize: '14px',
            lineHeight: 1,
            fontWeight: 600,
            color: skin.get('grayColor1')
        },
        
        askFollowupQuestionsStyling: {
            backgroundColor: 'rgba(102, 26, 184, 0.03)',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
        },

        closeStyling: {
            backgroundImage: 'linear-gradient(to right, #e87ad9, #7d4fea)',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            ...myTypography.body2,
            lineHeight: 'normal',
            fontWeight: 500,
            letterSpacing: '0.5px',
            color: skin.get('white')
        }
    });
}

export default getSkin;