import React from 'react';
import styles from './userProfile.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class UserProfile extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skin = this.props.skinGuide.get('globalProfiles').get('palette')
        let color = skin.get('grayColor2')
        if(checkIfPresent(this.props.svgColor)) {
            color = this.props.svgColor;
        }
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 26 26">
                    <g fill="none" fill-rule="evenodd" stroke={color} transform="translate(1 1)">
                        <circle cx="12" cy="12" r="12" fill="#FAFAFA"/>
                        <g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" transform="translate(6 5)">
                            <ellipse cx="5.875" cy="3.771" rx="3.808" ry="3.771"/>
                            <path d="M11.75 13.029c-.88-2.467-3.234-4.116-5.875-4.116-2.64 0-4.995 1.65-5.875 4.116"/>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(UserProfile, styles);