import Immutable from 'immutable';
import headerConstants from 'constants/header';
import ProfileDropDown from 'commonComponents/profileDropDown';

const defaultHeaderConfig = (otherConfigs) => (
	Immutable.fromJS({
		showHeader: true,
		showLogo: true,
		showLeftComponents: true,
		showMiddleComponents: true,
		showRightComponents: true,
		logo: {
			imagePresent: true,
			imagePath: 'IMG_DT_LOGO',
			showName: false,
			dropDownPresent: false
		},
		headerComponents: {
			leftComponents: [],
			middleComponents: [],
			rightComponents: [
				{
					imagePresent: false,
					imagePath: 'IMG_USER_PROFILE',
					showName: true,
					name: 'label_username',
					downArrowPresent: true,
					dropDownPresent: true,
					dropDownComponent: ProfileDropDown,
					dropDownComponentProps: otherConfigs.profileDropDownProps
												? otherConfigs.profileDropDownProps
												: headerConstants.get('PROFILE_DROPDOWN_PROPS')
				}
			]
		}
	})
)

export default defaultHeaderConfig;