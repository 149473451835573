import React from 'react';
import styles from './timeUpComponent.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import FilledButton from 'commonComponents/buttons/filledButton';
import { initializeFeedback, showFeedbackFormWithoutPopup, checkIfFeedbackIsCompleted } from 'util/feedback';
import analyticsUtil from 'util/segmentUtil';
class TimeUpComponent extends React.Component {

    componentDidMount() {
        const uliId = this.props.userState.get('uliId');
		const grpId = this.props.userState.get('groupId');
		if (uliId != null) {
			initializeFeedback('commonDB', uliId,
			grpId, this.setFeedbackCompleted);
		}
		checkIfFeedbackIsCompleted(this.setFeedbackCompleted);
    }

    setFeedbackCompleted = () => {
        this.props.setUIState({
            feedbackSubmitInProgress: false,
            isFeedbackSubmitted: true
        })
    }

    viewReportClicked = () => {
        analyticsUtil.track("VIEW_REPORT",{ popup: "VIEW_REPORT_CLICKED" });
        this.props.setUIState({
            openReportIframe: true
        });
    }

    feedbackClicked = () => {
        analyticsUtil.track("FEEDBACK",{ popup: "FEEDBACK_OPEN" });
        this.props.setUIState({
			feedbackSubmitInProgress: true
        });
        
		showFeedbackFormWithoutPopup(this.setFeedbackCompleted);
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const textLabel = this.props.userState.get('isTimerExpired') ? 'label_your_game_ended' : 'label_download_report_info';
        const feedbackDisable = this.props.uiState.get('feedbackSubmitInProgress');
        return (
            <div styleName="time-up-component">
                <div className={css(myStyles.container)} styleName="container">
                    <div styleName="wrapper">
                        <div styleName="text" className={css(myStyles.description)}>
                            {this.props.getLabel(textLabel)}
                        </div>
                        {
                            this.props.additionalParams.get('isFeedbackEnabled') && !this.props.uiState.get('isFeedbackSubmitted') ?
                            <div styleName="feedback-button">
                                <FilledButton 
                                    {...this.props} 
                                    disableButton={feedbackDisable}
                                    clickFunction={() => this.feedbackClicked()}
                                    textLabel={this.props.getLabel('label_submit_feedback')}
                                />
                            </div>
                            : null
                        }
                        <FilledButton 
                            {...this.props} 
                            clickFunction={() => this.viewReportClicked()}
                            textLabel={this.props.getLabel('label_view_report')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default applyWrappers(TimeUpComponent, styles);