import React from 'react';
import styles from './eventAlertPositive.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class EventAlertPositive extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 174 151">
                    <defs>
                        <linearGradient id="a" x1="50%" x2="50%" y1="100%" y2="0%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="b" x1="0%" y1="49.99%" y2="49.99%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="c" x1="0%" y1="49.99%" y2="49.99%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="d" x1="0%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="e" x1="0%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="f" x1="50.01%" x2="50.01%" y1="100%" y2="-.016%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="g" x1="-.016%" x2="99.984%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="h" x1="0%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="i" x1="50%" x2="50%" y1="100%" y2="0%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="j" x1="0%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="k" x1="0%" y1="49.99%" y2="49.99%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="l" x1="-.986%" x2="100.978%" y1="50.02%" y2="50.02%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="m" x1="-3.336%" x2="103.362%" y1="49.995%" y2="49.995%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="n" x1="-3.584%" x2="103.369%" y1="50.019%" y2="50.019%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="o" x1="50%" x2="50%" y1="100%" y2=".164%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                        <linearGradient id="p" x1="50.004%" x2="50.004%" y1="99.994%" y2="-.006%">
                            <stop offset="0%" stop-color="gray" stop-opacity=".25"/>
                            <stop offset="54%" stop-color="gray" stop-opacity=".12"/>
                            <stop offset="100%" stop-color="gray" stop-opacity=".1"/>
                        </linearGradient>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <path fill="url(#a)" fill-rule="nonzero" d="M81.164 93.42l20.996.013.008 12.878-20.996-.013z" transform="rotate(-16.6 91.666 99.865)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M80.098 96.92l19.443-5.783 3.441 11.517-19.443 5.783z"/>
                        <g fill="#857FE7" fill-rule="nonzero" opacity=".6">
                            <path d="M84.234 98.615l-2.474.736-.546-1.827 2.474-.736zM94.349 99.475L84.85 102.3l-.177-.592 9.498-2.825zM94.733 100.758l-6.925 2.06-.118-.395 6.925-2.06z"/>
                        </g>
                        <path fill="url(#b)" fill-rule="nonzero" d="M133.878 59.27l12.877.005.009 20.999-12.877-.006z" transform="rotate(-78.98 140.32 69.771)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M128.826 73.784l2.289-11.778 19.883 3.846-2.29 11.778z"/>
                        <g fill="#857FE7" fill-rule="nonzero" opacity=".6">
                            <path d="M134.294 65.243l-2.535-.492.366-1.871 2.534.492zM142.855 70.684l-9.728-1.89.119-.607 9.728 1.89zM142.6 72l-7.094-1.378.08-.405 7.093 1.378z"/>
                        </g>
                        <path fill="url(#c)" fill-rule="nonzero" d="M135.866 88.089l12.901.01.018 20.96-12.901-.01z" transform="rotate(-23.45 142.325 98.574)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M132.824 91.548l11.051-4.783 8.074 18.57-11.051 4.783z"/>
                        <g fill="#47E6B1" fill-rule="nonzero" opacity=".6">
                            <path d="M142.652 91.058l-1.028-2.364 1.753-.758 1.027 2.363zM143.002 101.18l-3.944-9.071.568-.246 3.944 9.071zM141.769 101.714l-2.876-6.615.379-.164 2.876 6.615z"/>
                        </g>
                        <path fill="url(#a)" fill-rule="nonzero" d="M67.842 32.722l20.996.013.008 12.878L67.85 45.6z" transform="rotate(-16.6 78.344 39.167)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M66.776 36.224l19.443-5.783 3.441 11.517-19.443 5.783z"/>
                        <g fill="#F55F44" fill-rule="nonzero" opacity=".6">
                            <path d="M70.9 37.923l-2.474.736-.545-1.827 2.474-.736zM81.017 38.782l-9.497 2.825-.177-.592 9.497-2.825zM81.4 40.066l-6.926 2.06-.117-.395 6.925-2.06z"/>
                        </g>
                        <path fill="url(#d)" fill-rule="nonzero" d="M120.55-1.425l12.878.005.009 20.999-12.877-.006z" transform="rotate(-78.98 126.994 9.077)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M115.81 12.916l2.303-11.797 19.914 3.87-2.302 11.796z"/>
                        <g fill="#857FE7" fill-rule="nonzero" opacity=".6">
                            <path d="M120.966 4.55l-2.534-.493.365-1.87 2.534.492zM129.528 9.99L119.8 8.1l.118-.607 9.728 1.89zM129.273 11.305l-7.094-1.379.08-.404 7.093 1.378z"/>
                        </g>
                        <path fill="url(#e)" fill-rule="nonzero" d="M122.537 27.396l12.9.01.018 20.96-12.9-.01z" transform="rotate(-23.45 128.996 37.88)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M119.497 30.854l11.05-4.783 8.074 18.57-11.05 4.783z"/>
                        <g fill="#47E6B1" fill-rule="nonzero" opacity=".6">
                            <path d="M129.325 30.365L128.298 28l1.752-.758 1.028 2.363zM129.673 40.487l-3.944-9.071.569-.246 3.944 9.071zM128.441 41.022l-2.876-6.614.38-.164 2.875 6.615z"/>
                        </g>
                        <path fill="url(#f)" fill-rule="nonzero" d="M11.301 78.4l20.997.013.008 12.879-20.996-.013z" transform="rotate(-16.6 21.804 84.846)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M10.237 81.902L29.68 76.12l3.442 11.517-19.443 5.783z"/>
                        <g fill="#857FE7" fill-rule="nonzero" opacity=".6">
                            <path d="M14.372 83.609l-2.475.736-.545-1.827 2.474-.736zM24.488 84.468l-9.497 2.825-.177-.592 9.497-2.825zM24.873 85.751l-6.926 2.06-.118-.395 6.926-2.06z"/>
                        </g>
                        <path fill="url(#g)" fill-rule="nonzero" d="M64.017 44.257l12.877.005.009 20.999-12.877-.006z" transform="rotate(-78.98 70.46 54.759)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M59.069 58.68l2.289-11.778 19.882 3.846-2.288 11.777z"/>
                        <g fill="#F55F44" fill-rule="nonzero" opacity=".6">
                            <path d="M64.43 50.23l-2.534-.493.365-1.87 2.535.492zM72.994 55.67l-9.728-1.89.118-.606 9.728 1.89zM72.738 56.987l-7.093-1.379.079-.404 7.093 1.378z"/>
                        </g>
                        <path fill="url(#h)" fill-rule="nonzero" d="M66.003 73.077l12.901.01.018 20.96-12.9-.01z" transform="rotate(-23.45 72.463 83.562)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M62.963 76.535l11.051-4.783 8.074 18.57-11.05 4.783z"/>
                        <g fill="#47E6B1" fill-rule="nonzero" opacity=".6">
                            <path d="M72.79 76.046l-1.027-2.363 1.752-.759 1.028 2.363zM73.14 86.166l-3.944-9.071.569-.246 3.944 9.071zM71.908 86.7l-2.876-6.614.38-.164 2.875 6.615z"/>
                        </g>
                        <path fill="url(#i)" fill-rule="nonzero" d="M13.67 42.155l20.996.013.008 12.878-20.996-.013z" transform="rotate(-16.6 24.172 48.6)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M12.93 45.55l19.478-5.87L35.9 51.217l-19.477 5.87z"/>
                        <g fill="#857FE7" fill-rule="nonzero" opacity=".6">
                            <path d="M16.734 47.364l-2.474.736-.546-1.827 2.474-.736zM26.849 48.224l-9.498 2.825-.177-.592 9.498-2.825zM27.233 49.507l-6.925 2.06-.118-.395 6.925-2.06z"/>
                        </g>
                        <path fill="url(#j)" fill-rule="nonzero" d="M66.216 8.157l12.855.006.009 20.961-12.855-.005z" transform="rotate(-79.028 72.648 18.64)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M61.64 22.353l2.303-11.796 19.915 3.869-2.303 11.796z"/>
                        <g fill-rule="nonzero" opacity=".6">
                            <path fill="#857FE7" d="M66.797 13.986l-2.534-.492.365-1.87 2.534.492zM75.359 19.425l-9.728-1.89.118-.607 9.728 1.89z"/>
                            <path fill="#F55F44" d="M75.101 20.741l-7.093-1.378.079-.404 7.093 1.378z"/>
                        </g>
                        <path fill="url(#k)" fill-rule="nonzero" d="M68.369 36.833l12.9.01.018 20.96-12.9-.01z" transform="rotate(-23.45 74.828 47.318)"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M65.327 40.292l11.05-4.782 8.075 18.57L73.4 58.862z"/>
                        <g fill="#47E6B1" fill-rule="nonzero" opacity=".6">
                            <path d="M75.157 39.802l-1.028-2.363 1.753-.759 1.027 2.363zM75.505 49.924l-3.944-9.071.568-.246 3.944 9.071zM74.274 50.455l-2.876-6.614.379-.164 2.876 6.614z"/>
                        </g>
                        <ellipse cx="12.809" cy="12.252" fill="#535461" fill-rule="nonzero" opacity=".25" rx="1.097" ry="1.094"/>
                        <ellipse cx="127.285" cy="108.072" fill="#535461" fill-rule="nonzero" opacity=".25" rx="1.097" ry="1.094"/>
                        <ellipse cx="30.428" cy="102.632" fill="#535461" fill-rule="nonzero" opacity=".25" rx="1.097" ry="1.094"/>
                        <ellipse cx="137.478" cy="87.404" fill="#535461" fill-rule="nonzero" opacity=".25" rx="1.097" ry="1.094"/>
                        <ellipse cx="147.796" cy="37.22" fill="#535461" fill-rule="nonzero" opacity=".25" rx="1.097" ry="1.094"/>
                        <path fill="#535461" fill-rule="nonzero" d="M66.842.974h-.975V0h-.398v.974h-.975v.396h.975v.972h.398V1.37h.975zM29.325 15.88h-.974v-.972h-.399v.972h-.974v.398h.974v.972h.4v-.972h.973zM14.064 71.426h-.974v-.972h-.399v.972h-.974v.398h.974v.972h.4v-.972h.973zM11.873 31.814h-.976v-.974H10.5v.974h-.976v.396h.976v.974h.397v-.974h.976zM2.347 59.034h-.974v-.974H.976v.974H0v.396h.976v.972h.397v-.972h.974zM173.838 44.998h-.975v-.972h-.399v.972h-.974v.398h.974v.972h.4v-.972h.974zM139.874 57.652h-.975v-.974h-.399v.974h-.974v.396h.974v.974h.4v-.974h.974zM110.87 23.524h-.975v-.972h-.397v.972h-.977v.398h.977v.972h.397v-.972h.976z" opacity=".25"/>
                        <path stroke="url(#l)" stroke-width=".5" d="M54.906 79.148c-.701 2.272.351 4.958 2.406 6.154.86.5 1.852.754 2.688 1.296 1.814 1.178 2.53 3.438 3.12 5.516.675 2.39 1.354 4.916.735 7.32-.401 1.532-1.293 2.888-1.804 4.388a9.136 9.136 0 0 0-.435 3.908c.074.7.25 1.428.73 1.944.693.746 1.803.858 2.806 1.038a13.665 13.665 0 0 1 9.822 7.6"/>
                        <path stroke="#535461" stroke-width=".5" d="M55.12 79.148c-.7 2.272.352 4.958 2.406 6.154.86.5 1.853.754 2.688 1.296 1.815 1.178 2.53 3.438 3.12 5.516.675 2.39 1.355 4.916.735 7.32-.4 1.532-1.293 2.888-1.804 4.388a9.136 9.136 0 0 0-.435 3.908c.074.7.25 1.428.73 1.944.693.746 1.804.858 2.806 1.038a13.665 13.665 0 0 1 9.823 7.6"/>
                        <path stroke="url(#m)" stroke-width=".5" d="M81.676 62.172c-1.73 2.4-3.526 5-3.675 7.954-.226 4.546 3.408 8.264 5.046 12.516a14.845 14.845 0 0 1-.501 11.63c-.802 1.638-1.927 3.264-1.788 5.078.084 1.108.64 2.114 1.086 3.132a17.622 17.622 0 0 1-1.026 15.944"/>
                        <path stroke="#535461" stroke-width=".5" d="M81.89 62.172c-1.73 2.4-3.526 5-3.674 7.954-.227 4.546 3.408 8.264 5.045 12.516a14.845 14.845 0 0 1-.499 11.63c-.802 1.638-1.926 3.264-1.788 5.078.084 1.108.64 2.114 1.087 3.132a17.622 17.622 0 0 1-1.027 15.944"/>
                        <path stroke="url(#n)" stroke-width=".5" d="M105.964 83.824l.836 6.8c.11.904.2 1.89-.281 2.66-.461.73-1.325 1.078-2.005 1.618-2.205 1.77-2.004 5.17-1.25 7.896.753 2.726 1.86 5.654.801 8.276-.437 1.082-1.203 2-1.786 3a9.196 9.196 0 0 0-1.104 5.716"/>
                        <path stroke="#535461" stroke-width=".5" d="M106.164 83.824l.836 6.8c.11.904.2 1.89-.28 2.66-.462.73-1.326 1.078-2.005 1.618-2.205 1.77-2.005 5.17-1.251 7.896s1.86 5.654.802 8.276c-.437 1.082-1.203 2-1.786 3a9.196 9.196 0 0 0-1.105 5.716"/>
                        <path fill="url(#o)" fill-rule="nonzero" d="M93.178 43.05a20.165 20.165 0 0 0-8.342-16.313 20.265 20.265 0 0 0-18.129-2.865c.168-1.134.254-2.278.259-3.424C67.033 9.544 58.4.561 47.479.168c-10.923-.391-20.18 7.95-20.898 18.83a20.265 20.265 0 0 0-18.135 2.86A20.165 20.165 0 0 0 .1 38.173c0 10.12 7.47 24.514 17.21 27.064l1.513.864-.822 2.812h4.872l-1.059-2.816 1.508-.86c7.525-1.97 13.693-11.01 16.11-19.688a14.786 14.786 0 0 0 4.313 1.958l1.513.864-.822 2.812h4.871l-1.058-2.816 1.507-.86c1.119-.3 2.196-.737 3.208-1.3 1.3 9.73 8.219 21.612 16.987 23.906l1.513.864-.822 2.812h4.872l-1.059-2.816 1.508-.86c9.754-2.55 17.215-16.944 17.215-27.064z" opacity=".7" transform="translate(34.48 12.8)"/>
                        <path fill="#F55F44" fill-rule="nonzero" d="M58.12 80.682h-4.713l.824-2.822h2.828zM75.207 50.938c0 10.778-8.758 26.57-19.561 26.57s-19.561-15.8-19.561-26.57c0-10.778 8.758-19.516 19.56-19.516 10.804 0 19.562 8.738 19.562 19.516z"/>
                        <path fill="#535461" fill-rule="nonzero" d="M57.06 77.978h-2.83l-1.65-.94h6.129z"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M48.576 35.772s-8.25 4.468-9.422 13.4" opacity=".1"/>
                        <path fill="#4D8AF0" fill-rule="nonzero" d="M83.7 63.526h-4.712l.823-2.822h2.829zM100.788 33.78c0 10.778-8.758 26.57-19.561 26.57S61.662 44.56 61.662 33.78c0-10.778 8.757-19.516 19.56-19.516 10.804 0 19.562 8.738 19.562 19.516h.004z"/>
                        <path fill="#535461" fill-rule="nonzero" d="M82.64 60.822h-2.829l-1.65-.942h6.129z"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M74.156 18.614s-8.248 4.468-9.421 13.4" opacity=".1"/>
                        <path fill="#3AD29F" fill-rule="nonzero" d="M109.067 85.4h-4.715l.826-2.82h2.826z"/>
                        <path fill="#FDE45E" fill-rule="nonzero" d="M126.154 55.656c0 10.778-8.758 26.57-19.561 26.57s-19.56-15.8-19.56-26.57c0-10.778 8.757-19.516 19.56-19.516 10.803 0 19.561 8.738 19.561 19.516z"/>
                        <path fill="#535461" fill-rule="nonzero" d="M108.004 82.696h-2.826l-1.65-.94h6.126z"/>
                        <path fill="#FFF" fill-rule="nonzero" d="M99.52 40.49s-8.248 4.468-9.42 13.4" opacity=".1"/>
                        <path fill="url(#p)" fill-rule="nonzero" d="M59.993 116.318l54.164.006.003 33.212-54.163-.006z" transform="rotate(-2.862 87.077 132.927)"/>
                        <path fill="#EEE" fill-rule="nonzero" d="M59.927 118.206l52.194-2.665 1.586 30.915-52.194 2.666z"/>
                        <path fill="#857FE7" fill-rule="nonzero" d="M69.191 125.015l-6.64.34-.252-4.904 6.64-.34zM93.986 133.315l-25.5 1.302-.081-1.59 25.5-1.302zM94.161 136.76l-18.594.95-.055-1.06 18.595-.95z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(EventAlertPositive, styles);