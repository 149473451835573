import React from 'react';
import styles from './walkthroughElement.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class WalkthroughElement extends React.Component {

    getArrowImage = () => {
        switch (this.props.arrowPosition) {
            case 'TOP':
                return <img src={this.props.getImage('IMG_WALKTHROUGH_ARROW_UP')} width="100%" height="100%" />;        
            
            case 'LEFT':
                return <img src={this.props.getImage('IMG_WALKTHROUGH_ARROW_LEFT')} width="100%" height="100%" />;        

            default:
                break;
        }
    }

    getWalkThroughElement = (myStyles) => {
        const arrowContainerStyle = this.props.arrowPosition == 'LEFT' ? "arrow-text-container" : "arrow-text-container-flex"
        return(
            <div styleName={arrowContainerStyle}>
                <div styleName="walkthrough-arrow">
                    {this.getArrowImage()}
                </div>
                <div className={css(myStyles.textStyle)} styleName="text">
                    {this.props.getLabel(this.props.walkthroughText)}
                </div>
            </div>
        )
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return this.getWalkThroughElement(myStyles)
    }
}

export default applyWrappers(WalkthroughElement, styles);