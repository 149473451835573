/**
 * @author: Ujjwal Jain
 * @file: additionalParams.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey } from 'util/objectUtils';

const initialState = Immutable.fromJS({
    isFeedbackEnabled: true
});


const additionalParams = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_ADDITIONAL_PARAMS'):
            return state.set('isFeedbackEnabled', Immutable.fromJS(action.payload));
        default:
            return state;
    }
};

export default additionalParams;