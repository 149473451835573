import React, { Component } from 'react';
import styles from './titleUnderline.module.sass';
import applyWrappers from 'util/ComponentWrapper';

class TitleUnderline extends Component {

	constructor(props){
		super(props);
		this.state= {
			onHover: false
		};
	}

	setHoverTrue = () => {
		this.setState({
			onHover: true
		});
	}

	setHoverFalse = () => {
		this.setState({
			onHover: false
		});
	}

	render() {
		// const skinGuide = this.props.skinGuide.get('globalProfiles').get('palette');
		return (
			<div styleName="main-component">
				<svg width="100%" height="100%" viewBox="0 0 193 20">
					<defs>
						<linearGradient id="a" x1="0%" x2="102%" y1="49.463%" y2="50.548%">
							<stop offset="0%" stopColor="#3023AE"/>
							<stop offset="100%" stopColor="#C86DD7"/>
						</linearGradient>
					</defs>
					<path fill="url(#a)" fillRule="evenodd" d="M148.185 34.233c-2.913 4.556-2.913 8.556 0 12 2.913 3.443 33.309 3.673 91.187.688l2.342-.133-1.862-.555h11.593l-13.25-1.283h15.412L339 42.903l-2.198-2.867-4.528-.524 1.557-.604-2.837-.594 3.966-.668-5.562-1.162 2.404-1.345-.422-.906h-3.368l.621-1.486h-.621v-1.086l-14.758.844 9.85-2.183-12.808 1.34-3.441-.56L321.89 29c-35.777 1.688-60.847 2.937-75.21 3.747-25.579 1.443-43.715 1.189-54.208 1.486-25.845.73-39.486-1.486-40.312-1.486-1.753 0-3.079.495-3.976 1.486z" opacity=".3" transform="translate(-146 -29)"/>
				</svg>

			</div>
		);
	}
}



export default applyWrappers(TitleUnderline, styles)
