/**
 * @author: Ujjwal Jain
 * @file: userPrototype.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({
    isIntroPageSeen: false,
    showIntroPage: false,
    activeIdeaId: -1,
    isPhaseAvailableToUser: false,
    suggestions: []
});


const userPrototype = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_PROTOTYPE_PHASE_STATE'):
            return state.mergeDeep(Immutable.fromJS(action.payload));
        case actionConsts.get('POPULATE_PROTOTYPE_SUGGESTIONS'):
            return state.set('suggestions', action.payload);
        case 'MAKE_PROTOTYPE_PHASE_AVAILABLE':
            return state.set('isPhaseAvailableToUser', true);
        default:
            return state;
    }
};

export default userPrototype;