import Immutable from 'immutable';

import IMG_DT_LOGO from 'images/dt-1/sim/logo.png';
import IMG_SEGMENT_YOUTH from 'images/dt-1/segments/youth.png';
import IMG_SEGMENT_EXECUTIVE from 'images/dt-1/segments/executive.png';
import IMG_SEGMENT_URBAN from 'images/dt-1/segments/urban.png';
import IMG_PERSONA_1_CIRCULAR from 'images/dt-1/personas/persona_1.png';
import IMG_PERSONA_1_FULL from 'images/dt-1/personas/persona_1.png';
import IMG_PERSONA_1_HALF from 'images/dt-1/personas/persona_1.png';
import IMG_PERSONA_2_CIRCULAR from 'images/dt-1/personas/persona_2.png';
import IMG_PERSONA_2_FULL from 'images/dt-1/personas/persona_2.png';
import IMG_PERSONA_2_HALF from 'images/dt-1/personas/persona_2.png';
import IMG_PERSONA_3_CIRCULAR from 'images/dt-1/personas/persona_3.png';
import IMG_PERSONA_3_FULL from 'images/dt-1/personas/persona_3.png';
import IMG_PERSONA_3_HALF from 'images/dt-1/personas/persona_3.png';
import IMG_PERSONA_4_CIRCULAR from 'images/dt-1/personas/persona_4.png';
import IMG_PERSONA_4_FULL from 'images/dt-1/personas/persona_4.png';
import IMG_PERSONA_4_HALF from 'images/dt-1/personas/persona_4.png';
import IMG_PERSONA_5_CIRCULAR from 'images/dt-1/personas/persona_5.png';
import IMG_PERSONA_5_FULL from 'images/dt-1/personas/persona_5.png';
import IMG_PERSONA_5_HALF from 'images/dt-1/personas/persona_5.png';
import IMG_PERSONA_6_CIRCULAR from 'images/dt-1/personas/persona_6.png';
import IMG_PERSONA_6_FULL from 'images/dt-1/personas/persona_6.png';
import IMG_PERSONA_6_HALF from 'images/dt-1/personas/persona_6.png';
import IMG_PRE_PURCHASE from 'images/dt-1/touchPoints/pre_purchase.png';
import IMG_DURING_PURCHASE from 'images/dt-1/touchPoints/during_purchase.png';
import IMG_POST_PURCHASE from 'images/dt-1/touchPoints/post_purchase.png';
import IMG_TM_1_FULL from 'images/dt-1/teamMembers/team_1.png';
import IMG_TM_2_FULL from 'images/dt-1/teamMembers/team_2.png';
import IMG_TM_3_FULL from 'images/dt-1/teamMembers/team_ceo.png';
import IMG_READ_PERSONA_BG from 'images/dt-1/popups/read_persona.png';
import IMG_USER_INTERVIEW_BG from 'images/dt-1/popups/user_interview.png';
import IMG_USER_FEEDBACK_BG from 'images/dt-1/popups/user_feedback.png';

import IMG_USER_INTERVIEW_GIF from 'images/dt-1/popups/user_interview_1.png';
import IMG_USER_FEEDBACK_GIF from 'images/dt-1/popups/user_feedback_1.png';

import IMG_USER_SUMMARY_BG from 'images/dt-1/phases/observe/summary_bg.png';
import IMG_USER_PERSONA_BG from 'images/dt-1/phases/observe/persona_bg.png';
import IMG_BULLET_POINT from 'images/bullet.svg';
import IMG_RIGHT_SIDE_TAG from 'images/dt-1/phases/rightSideTag.svg';
import IMG_BOTTOM_SIDE_TAG from 'images/dt-1/phases/bottomSideTag.png';

import IMG_OBSERVE_PHASE from 'images/dt-1/phases/observe/bg.png';
import IMG_OBSERVE_PHASE_IE from 'images/dt-1/phases/observe/observe_ie.png';
import IMG_OBSERVE_PHASE_CORNER from 'images/dt-1/phases/observe/corner_bg.svg';

import IMG_EMPATHIZE_PHASE from 'images/dt-1/phases/empathize/bg.png';
import IMG_EMPATHIZE_PHASE_IE from 'images/dt-1/phases/empathize/empathize_ie.png';
import IMG_EMPATHIZE_PHASE_CORNER from 'images/dt-1/phases/empathize/corner_bg.svg';

import IMG_DEFINE_PHASE from 'images/dt-1/phases/define/bg.png';
import IMG_DEFINE_PHASE_IE from 'images/dt-1/phases/define/define_ie.png';
import IMG_DEFINE_PHASE_CORNER from 'images/dt-1/phases/define/corner_bg.svg';

import IMG_IDEATE_PHASE from 'images/dt-1/phases/ideate/bg.png';
import IMG_IDEATE_PHASE_IE from 'images/dt-1/phases/ideate/ideate_ie.png';
import IMG_IDEATE_PHASE_CORNER from 'images/dt-1/phases/ideate/corner_bg.svg';

import IMG_PROTOTYPE_PHASE from 'images/dt-1/phases/prototype/bg.png';
import IMG_PROTOTYPE_PHASE_IE from 'images/dt-1/phases/prototype/prototype_ie.png';
import IMG_PROTOTYPE_PHASE_CORNER from 'images/dt-1/phases/prototype/corner_bg.svg';

import IMG_TEST_PROTOTYPE_PHASE from 'images/dt-1/phases/testPrototype/bg.png';
import IMG_TEST_PROTOTYPE_PHASE_IE from 'images/dt-1/phases/testPrototype/testPrototype_ie.png';
import IMG_TEST_PROTOTYPE_PHASE_CORNER from 'images/dt-1/phases/testPrototype/corner_bg.svg';

import IMG_ACTION_FEEDBACK from 'images/dt-1/actions/feedback/image.svg';
import IMG_ACTION_INTERVIEW from 'images/dt-1/actions/interview/image.svg';

import IMG_COMPANY_REVENUE from 'images/dt-1/onboarding/company/revenue/icon.svg';
import IMG_COMPANY_CSAT from 'images/dt-1/onboarding/company/csat/icon.svg';
import IMG_COMPANY_DOWN_ARROW from 'images/dt-1/onboarding/company/downArrow.svg';

import IMG_EMPATHIZE_UNDERSTAND from 'images/dt-1/phases/empathize/understand_bg.png';
import IMG_TEAM_CHAT from 'images/team-chat.png';

import IMG_EXECUTE_PHASE_RIGHT_CORNER from 'images/dt-1/phases/execute/corner_right_bg.svg';
import IMG_EXECUTE_PHASE_LEFT_CORNER from 'images/dt-1/phases/execute/corner_left_bg.svg';

import IMG_EXECUTE_HEADER_RIGHT from 'images/dt-1/phases/execute/header_right.svg';
import IMG_EXECUTE_HEADER_LEFT from 'images/dt-1/phases/execute/header_left.svg';
import IMG_EMPATHY_INTRO from 'images/dt-1/phases/empathize/empathy_map_new.png';
import IMG_EMPATHY_INTRO_GIF from 'images/dt-1/phases/empathize/empathy_intro_cropped.png';
import IMG_EMPATHY_INTRO_IE from 'images/dt-1/phases/empathize/empathy_intro_ie.png';

import IMG_REPORT_HEADER_BG from 'images/dt-1/report/header-bg.png';
import IMG_REPORT_HEADER_RIGHT from 'images/dt-1/report/header-right.png';
import IMG_REPORT_LEADERBOARD from 'images/dt-1/report/leaderboard.png';
import IMG_REPORT_COMPETENCY from 'images/dt-1/report/competency.png';

import IMG_PROTOTYPE_WIREFRAME from 'images/dt-1/phases/prototype/prototype.png';
import IMG_KNOLSKAPE_LOGO from 'images/dt-1/sim/knolskape.png';
import IMG_ANALYTICS_AVAILABLE from 'images/dt-1/sim/report.png';

import IMG_IDEAS_EXECUTING from 'images/dt-1/phases/execute/end_report.png';
import IMG_IDEAS_EXECUTING_IE from 'images/dt-1/phases/execute/end_report_ie.png';
import IMG_DFV_LOADING from 'images/dt-1/phases/dfv_loading.png';
import IMG_DFV_LOADING_IE from 'images/dt-1/phases/dfv_loading_ie.png';
import IMG_TEST_REPORT_LOADING from 'images/dt-1/phases/test_loading.png';
import IMG_TEST_CONTAINER_LOADING from 'images/dt-1/phases/test_loading_loop.png';
import IMG_TEST_CONTAINER_LOADING_IE from 'images/dt-1/phases/test_loading_loop_ie.png';
import IMG_PROTOTYPE_LOADING from 'images/dt-1/phases/prototype_loading.png';
import IMG_PROTOTYPE_LOADING_IE from 'images/dt-1/phases/prototype_loading_ie.png';
import IMG_IDEAS_LOADING from 'images/dt-1/phases/ideas_loading.png';
import IMG_IDEAS_LOADING_IE from 'images/dt-1/phases/ideas_loading_ie.png';

import IMG_SCROLL_ARROW from 'images/scroll.svg';
import IMG_METRICS_BG from 'images/metrics.png';

import IMG_NO_IDEAS from 'images/dt-1/phases/execute/no_ideas.png';

import IMG_LEADERBOARD_POPUP from 'images/leaderboard.png';

import IMG_WALKTHROUGH_ARROW_LEFT from 'images/arrow_left.svg';
import IMG_WALKTHROUGH_ARROW_UP from 'images/arrow_up.svg';

import IMG_PROHIBITTED from 'images/prohibited.png';
import IMG_INSIGHT from 'images/insight.png';

import IMG_OBJECTIVE from 'images/target.svg';

const images = Immutable.Map({
    IMG_DT_LOGO,
    IMG_SEGMENT_YOUTH,
    IMG_SEGMENT_EXECUTIVE,
    IMG_SEGMENT_URBAN,
    IMG_PERSONA_1_CIRCULAR,
    IMG_PERSONA_1_FULL,
    IMG_PERSONA_1_HALF,
    IMG_PERSONA_2_CIRCULAR,
    IMG_PERSONA_2_FULL,
    IMG_PERSONA_2_HALF,
    IMG_PERSONA_3_CIRCULAR,
    IMG_PERSONA_3_FULL,
    IMG_PERSONA_3_HALF,
    IMG_PERSONA_4_CIRCULAR,
    IMG_PERSONA_4_FULL,
    IMG_PERSONA_4_HALF,
    IMG_PERSONA_5_CIRCULAR,
    IMG_PERSONA_5_FULL,
    IMG_PERSONA_5_HALF,
    IMG_PERSONA_6_CIRCULAR,
    IMG_PERSONA_6_FULL,
    IMG_PERSONA_6_HALF,
    IMG_PRE_PURCHASE,
    IMG_DURING_PURCHASE,
    IMG_POST_PURCHASE,
    IMG_DEFINE_PHASE,
    IMG_DEFINE_PHASE_CORNER,
    IMG_EMPATHIZE_PHASE,
    IMG_EMPATHIZE_PHASE_CORNER,
    IMG_IDEATE_PHASE,
    IMG_IDEATE_PHASE_CORNER,
    IMG_OBSERVE_PHASE,
    IMG_OBSERVE_PHASE_CORNER,
    IMG_PROTOTYPE_PHASE,
    IMG_PROTOTYPE_PHASE_CORNER,
    IMG_TEST_PROTOTYPE_PHASE,
    IMG_TEST_PROTOTYPE_PHASE_CORNER,
    IMG_TM_1_FULL,
    IMG_TM_2_FULL,
    IMG_TM_3_FULL,
    IMG_READ_PERSONA_BG,
    IMG_USER_INTERVIEW_BG,
    IMG_ACTION_FEEDBACK,
    IMG_ACTION_INTERVIEW,
    IMG_COMPANY_REVENUE,
    IMG_COMPANY_CSAT,
    IMG_COMPANY_DOWN_ARROW,
    IMG_USER_FEEDBACK_BG,
    IMG_USER_SUMMARY_BG,
    IMG_USER_PERSONA_BG,
    IMG_EMPATHIZE_UNDERSTAND,
    IMG_TEAM_CHAT,
    IMG_EXECUTE_PHASE_RIGHT_CORNER,
    IMG_EXECUTE_PHASE_LEFT_CORNER,
    IMG_EXECUTE_HEADER_RIGHT,
    IMG_EXECUTE_HEADER_LEFT,
    IMG_REPORT_COMPETENCY,
    IMG_REPORT_HEADER_BG,
    IMG_REPORT_HEADER_RIGHT,
    IMG_REPORT_LEADERBOARD,
    IMG_BULLET_POINT,
    IMG_PROTOTYPE_WIREFRAME,
    IMG_KNOLSKAPE_LOGO,
    IMG_ANALYTICS_AVAILABLE,
    IMG_RIGHT_SIDE_TAG,
    IMG_BOTTOM_SIDE_TAG,
    IMG_EMPATHY_INTRO,
    IMG_USER_INTERVIEW_GIF,
    IMG_USER_FEEDBACK_GIF,
    IMG_IDEAS_EXECUTING,
    IMG_DFV_LOADING,
    IMG_TEST_REPORT_LOADING,
    IMG_PROTOTYPE_LOADING,
    IMG_IDEAS_LOADING,
    IMG_EMPATHY_INTRO_GIF,
    IMG_EMPATHY_INTRO_IE,
    IMG_LEADERBOARD_POPUP,
    IMG_NO_IDEAS,
    IMG_WALKTHROUGH_ARROW_LEFT,
    IMG_WALKTHROUGH_ARROW_UP,
    IMG_SCROLL_ARROW,
    IMG_METRICS_BG,
    IMG_PROHIBITTED,
    IMG_INSIGHT,
    IMG_OBJECTIVE,
    IMG_TEST_CONTAINER_LOADING,
    IMG_OBSERVE_PHASE_IE,
    IMG_EMPATHIZE_PHASE_IE,
    IMG_DEFINE_PHASE_IE,
    IMG_IDEATE_PHASE_IE,
    IMG_PROTOTYPE_PHASE_IE,
    IMG_TEST_PROTOTYPE_PHASE_IE,
    IMG_IDEAS_EXECUTING_IE,
    IMG_DFV_LOADING_IE,
    IMG_TEST_CONTAINER_LOADING_IE,
    IMG_PROTOTYPE_LOADING_IE,
    IMG_IDEAS_LOADING_IE,
});

export default images;
