import actionConsts from 'constants/actions';

const updateIdea = (payload) => {
    console.log('payload', payload)
    return {
        type: actionConsts.get('UPDATE_IDEA'),
        payload
    };
};

export default updateIdea;
