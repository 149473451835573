import consts from 'constants/url';
import kfetch from '../util/kfetch';
import {checkIfPresent} from 'util/utilFunctions';
import setUIState from 'actions/uiState/actionCreators';

export const fetchReportPdf = (payload) => (dispatch) => kfetch(consts.get('USER_REPORT_PDF_URL'))
    .then((response) => {
        console.log(response);
        if(checkIfPresent(response.url)) {
            dispatch(setUIState({
                reportUrl: response.url
            }));
            var link = document.createElement('a');
            link.href = response.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        dispatch(setUIState({
            isReportDownloading: false
        }));
    });