import kfetch from '../util/kfetch';
import consts from 'constants/url';

export const fetchPersonaFeedbackSurveyData = () => (dispatch) => kfetch(consts.get('PERSONA_FEEDBACK_SURVEY_API'))
    .then((response) => {        
        dispatch({
            type: "SET_PERSONA_FEEDBACK_SURVEY_DATA",
            payload: response
        });

    });
