import React from 'react';
import styles from './executePopupWrongTick.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ExecutePopupWrongTick extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 34 34">
                    <g fill="none" fill-rule="nonzero">
                        <circle cx="17" cy="17" r="17" fill="#F00" fill-opacity=".2"/>
                        <ellipse cx="19.412" cy="24.252" fill="#F00" rx="2.999" ry="3.071"/>
                        <path fill="#F00" d="M17.887 19.69c-1.15.396-2.403-.169-2.896-1.303l-3.251-7.476a3.295 3.295 0 0 1-.007-2.61 3.178 3.178 0 0 1 1.881-1.759c.846-.29 1.771-.2 2.548.249a3.218 3.218 0 0 1 1.526 2.104l1.745 7.987c.263 1.204-.405 2.417-1.546 2.809z"/>
                        <ellipse cx="19.412" cy="24.252" fill="#000" opacity=".1" rx="2.999" ry="3.071"/>
                        <path fill="#000" d="M17.887 19.69c-1.15.396-2.403-.169-2.896-1.303l-3.251-7.476a3.295 3.295 0 0 1-.007-2.61 3.178 3.178 0 0 1 1.881-1.759c.846-.29 1.771-.2 2.548.249a3.218 3.218 0 0 1 1.526 2.104l1.745 7.987c.263 1.204-.405 2.417-1.546 2.809z" opacity=".1"/>
                        <ellipse cx="19.301" cy="23.914" fill="#F00" rx="2.406" ry="2.464"/>
                        <path fill="#F00" d="M17.6 18.81c-1.054.362-2.204-.156-2.656-1.196l-2.983-6.86a3.023 3.023 0 0 1-.006-2.394 2.915 2.915 0 0 1 1.726-1.614 2.829 2.829 0 0 1 2.338.227 2.953 2.953 0 0 1 1.4 1.931l1.6 7.329c.242 1.104-.37 2.217-1.418 2.577z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(ExecutePopupWrongTick, styles);