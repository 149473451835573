import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		heading: {
			...myTypography.subtitle,
			fontSize: '12px',
			color: skin.get('grayColor1')
		},
		value: {
			...myTypography.body1,
			fontSize: '20px',
			color: skin.get('grayColor1'),
			lineHeight: 1
		}
	});
}

export default getSkin;