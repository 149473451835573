import React from 'react';
import styles from './cardRecommendation.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class CardRecommendation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOnHover: false
        }
    }

    getStyleForRec = (recLength) => {
        switch(this.props.alignRecKey) {
            case 'TOP':
                return {
                    top: `-${(recLength - 1)*(33)}px`,
                    left: 0,
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    boxShadow: `0px -1px 16px 0 rgba(0, 0, 0, 0.3)`,
                    flexDirection: 'column-reverse' 
                };
            case 'BOTTOM':
                return {
                    top: 0,
                    left: 0,
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                    boxShadow: `0px 1px 10px 0 rgba(0, 0, 0, 0.3)`,
                    flexDirection: 'column'
                };
            default:
                return {
                    top: 0,
                    left: 0,
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                    boxShadow: `0px 1px 10px 0 rgba(0, 0, 0, 0.3)`,
                    flexDirection: 'column'
                };
        }
    }

    renderRecommendation = (myStyles, recommendation) => {
        return (
            <div
                className={css(myStyles.recContainer)}
                styleName="single-recommendation"
            >
                <div styleName="rec-image">
                    <img alt="User" src={this.props.getImage(recommendation.image)} width="100%" height="100%" />
                </div>
                <div className={css(myStyles.recContent)} styleName="rec-content">{recommendation.text}</div>
            </div>
        );
    }

    renderAllRecommendations = (myStyles, recommendationList) => {
        if(recommendationList.length > 0) {
            const allRecs = recommendationList.map((singleRec) => {
                return this.renderRecommendation(myStyles, singleRec);
            });
            return (
                <div styleName="all-recs">
                    {allRecs}
                </div>
            );
        }
        return null;
    }

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const {recommendationList} = this.props;
        if(recommendationList.length > 0) {
            return (
                <div styleName="recommendation-container">
                    {this.renderAllRecommendations(myStyles, recommendationList)}
                </div>
            );
        }
        return null;
    }
}

export default applyWrappers(CardRecommendation, styles);