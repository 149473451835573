import React from 'react';
import styles from './notAllowed.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class NotAllowed extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 16 16">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#000" fill-rule="nonzero" d="M7.833 15.667A7.833 7.833 0 1 0 0 7.833a7.843 7.843 0 0 0 7.833 7.834zm0-14a6.167 6.167 0 1 1-6.166 6.166 6.173 6.173 0 0 1 6.166-6.166z" opacity=".6"/>
                        <path stroke="#666" stroke-width="1.3" d="M13.044 3.049L3.036 13.057"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(NotAllowed, styles);