/**
 * @author: Ujjwal Jain
 * @file: ideas.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey, setOrUpdateObjectInList } from 'util/objectUtils';

const initialState = Immutable.fromJS({
    eventList: []
});

const events = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_EVENTS'):
            return state.set('eventList', mergeListsBasedOnKey(state.get('eventList'), formatEvents(action.payload), 'id'));
        case actionConsts.get('UPDATE_EVENT'):
            return state.set('eventList', setOrUpdateObjectInList(state.get('eventList'), action.payload, 'id', 'id'));
        default:
            return state;
    }
};

const formatEvents = (list) => {
    list.map((eachData) => {
        eachData.sentForExecution = false;
        eachData.preparingToShowToUser = false;
        eachData.timeStamp = new Date().getTime();
        return 1;
    });
    return list;
}

export default events;