import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
			background: `#faf6ff`,
			boxShadow: '1px -3px 5px 0 rgba(0, 0, 0, 0.15)'
		},
		description: {
			...myTypography.body1
		}
    });
}

export default getSkin;