import React from 'react';
import styles from './messageIcon.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class MessageIcon extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 18 20">
                    <path fill="none" fillRule="evenodd" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 1h12a2 2 0 0 1 2 2v10.444l-5.333 5.334v-5.334H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" opacity=".7"/>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(MessageIcon, styles);