import actionConsts from 'constants/actions';

const setPersonaInfoRead = (payload) => {
    return {
        type: actionConsts.get('SET_PERSONA_INFO_READ'),
        payload
    };
};

const setPersonaSelectedorDeselected = (payload) => {
    return {
        type: actionConsts.get('SET_PERSONA_SELECTED'),
        payload
    };
}

const setPersonaLevelRevealed = (payload) => {
    return {
        type: actionConsts.get('SET_PERSONA_LEVEL_REVEALED'),
        payload
    }
}

const setNewFeedbackRevealedAsRead = (payload = {}) => {
    return {
        type: actionConsts.get('PERSONA_NEW_FEEDBACK_READ'),
        payload
    }
}

const resetAllPersonasSelected = (payload = {}) => {
    return {
        type: actionConsts.get('RESET_SELECTED_PERSONAS'),
        payload
    }
}

const setNewInfoRevealedAsRead = (payload) => {
    return {
        type: actionConsts.get('PERSONA_NEW_INFO_READ'),
        payload
    }
}

export {
    setPersonaInfoRead,
    setPersonaSelectedorDeselected,
    setPersonaLevelRevealed,
    setNewFeedbackRevealedAsRead,
    resetAllPersonasSelected,
    setNewInfoRevealedAsRead
};
