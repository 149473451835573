import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		validationContainer: {
			backgroundColor: skin.get('white'),
			borderRadius: '5px',
			boxShadow: `1px 1px 5px 0 #d5d8e2`
		},
		borderContainer: {
			background: `linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd)`,
			backdropFilter: `blur(10px)`,
			opacity: 0.5
		},
		headingMain: {
			...myTypography.subtitle,
			color: skin.get('grayColor1'),
			fontWeight: 600
		},
		headingDesc: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		},
		buttonsContainer: {
			borderTop: `1px solid ${skin.get('grayColor6')}`
		},
		dfvCard: {
			borderRadius: '4px',
			border: `1px solid ${skin.get('grayColor5')}`
		},
		notUnlockDesc: {
			...myTypography.body2,
			color: skin.get('grayColor1'),
			textAlign: 'center'
		},
		userFeedbackContainer: {
			borderLeft: `1px dashed ${skin.get('grayColor5')}`
		},
		dfvContainer: {
			borderBottom: `1px solid ${skin.get('grayColor5')}`
		},
		feedbackHeading: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		},
		refineDesc: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		},
		shortlistDesc: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		},
		refinedTagContent: {
			...myTypography.body2,
			fontStyle: 'italic',
			color: skin.get('white'),
			borderRadius: '4px',
			fontWeight: 600,
			backgroundColor: skin.get('primaryColor')
		},
		responseHeaderText: {
			...myTypography.subtitle,
			fontSize: '12px',
			color: skin.get('grayColor1')
		},
		responseText: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		},
		eachTab: {
			borderBottom: `3px solid ${skin.get('white')}`,
			color: skin.get('grayColor2'),
			cursor: 'pointer',
			...myTypography.body2
		},
		eachTabHighlight: {
			borderBottom: `3px solid ${skin.get('primaryColor')}`,
			color: skin.get('primaryColor'),
			cursor: 'pointer',
			...myTypography.body2,
			fontSize: '14px',
			fontWeight: 600
		},
		tq_1: {
			...myTypography.subtitle,
			fontSize: '12px',
			color: '#4143d1',
			fontWeight: 600
		},
		tq_2: {
			...myTypography.subtitle,
			fontSize: '12px',
			color: '#e8892b',
			fontWeight: 600
		},
		tq_3: {
			...myTypography.subtitle,
			fontSize: '12px',
			color: '#0681ce',
			fontWeight: 600
		},
		tq_4: {
			...myTypography.subtitle,
			fontSize: '12px',
			color: '#d03561',
			fontWeight: 600
		},
		responseContent: {
			...myTypography.body2,
			color: skin.get('grayColor2')
		},
		eachQuadrantContainer: {
			borderColor: skin.get('grayColor5'),
			':nth-child(2n+1)': {
				borderRightWidth: '2px',
				borderRightStyle: 'dashed'
			},
			':nth-last-child(2)': {
				borderTopWidth: '2px',
				borderTopStyle: 'dashed'
			},
			':nth-last-child(1)': {
				borderTopWidth: '2px',
				borderTopStyle: 'dashed'
			}
		},
		charName: {
			...myTypography.body2,
			color: skin.get('grayColor2'),
			lineHeight: 'normal'
		},
	});
}

export default getSkin;