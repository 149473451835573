import React from 'react';
import styles from './downArrow.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class DownArrow extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide); 
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 5 4">
                    <path fill={checkIfPresent(this.props.fillValue) ? this.props.fillValue : '#FFFFFF'} fill-rule="nonzero" d="M4.945.203A.432.432 0 0 0 4.574 0H.426a.432.432 0 0 0-.371.203.376.376 0 0 0 .006.402l2.074 3.203c.077.12.216.192.365.192.15 0 .288-.073.365-.192L4.939.605a.376.376 0 0 0 .006-.402z"/>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(DownArrow, styles);