import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		eachTag: {
			...myTypography.body2,
			fontStyle: 'italic',
			color: skin.get('grayColor1')
		}
	});
}

export default getSkin;