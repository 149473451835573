import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    isLabelsFetched: false
});

const batches = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_LABELS_DATA'):
            return state.mergeDeep(state, Immutable.fromJS(action.payload))
                    .set('isLabelsFetched', true)
        default:
            return state;
    }
};

export default batches;