import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';
import backgroundNewImage from 'images/new_bg.png'

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		title: {
			...myTypography.subtitle,
			fontWeight: `bold`
		},
		helperText: {
			...myTypography.body1,
			color: skin.get(`grayColor2`)
		},
		feedbackPoint: {
			...myTypography.body2,
			fontSize: '14px',
			color: skin.get(`grayColor2`)
		},
		persona: {
			borderBottom: `1px solid ${skin.get('grayColor6')}`
		},
		personaName: {
			...myTypography.subtitle,
			fontSize: `14px`,
			fontWeight: 600,
			color: skin.get(`grayColor2`)
		},
		popupBorderTop: {
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            opacity: '0.5',
            backdropFilter: 'blur(10px)',
            backgroundImage: 'linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd)'
		},
		
		backgroundNew: {
            backgroundImage: `url(${backgroundNewImage})`,
            backgroundSize: '100% 100%'
        }
	});
}

export default getSkin;