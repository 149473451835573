import React, { Component } from 'react';
import styles from './emptyChatSection.module.sass';
import applyWrappers from 'util/ComponentWrapper';

class EmptyChatSection extends Component {

	constructor(props){
		super(props);
		this.state= {
			onHover: false
		};
	}

	render() {

		const skinGuide = this.props.skinGuide.get('globalProfiles').get('palette');
		return (
			<div styleName="main-component">
				<svg width="100%" height="100%" viewBox="0 0 190 137">
					<g fill="none" fillRule="evenodd" transform="translate(1 1)">
						<path fill="#F2F2F2" fillRule="nonzero" d="M90.8 3.226c-18.362 0-33.247 9.906-33.247 22.126 0 5.456 2.97 10.45 7.889 14.308v16.06l10.54-10.557A47.22 47.22 0 0 0 90.8 47.479c18.362 0 33.248-9.907 33.248-22.127 0-12.22-14.886-22.126-33.248-22.126z"/>
						<g opacity=".3">
							<path fill="#57B894" fillRule="nonzero" d="M146.667 113.36a3.618 3.618 0 0 0 4.157-.534 3.633 3.633 0 0 0 .873-4.105 3.622 3.622 0 0 0-3.578-2.185l-.172 1.862-.65-1.708a3.61 3.61 0 0 0-2.127 1.762 3.63 3.63 0 0 0 1.497 4.907z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M147.958 135.422c-.086-1.617 1.447-2.822 2.895-3.541 1.448-.72 3.116-1.391 3.784-2.865.96-2.119-.682-4.417-1.936-6.375a21.862 21.862 0 0 1-2.249-4.643c-.226-.654-.423-1.333-.409-2.025.02-.996.473-1.927.921-2.816a211.153 211.153 0 0 1 4.692-8.783"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M146.882 112.904a3.618 3.618 0 0 0 4.157-.533 3.633 3.633 0 0 0 .873-4.105 3.622 3.622 0 0 0-3.578-2.185l-.172 1.862-.65-1.708a3.61 3.61 0 0 0-2.127 1.762 3.63 3.63 0 0 0 1.497 4.907z"/>
							<path fill="#57B894" fillRule="nonzero" d="M152.063 103.213a3.618 3.618 0 0 1 2.904-1.912l.047 1.8.808-1.769a3.63 3.63 0 0 1 1.058 6.83 3.618 3.618 0 0 1-4.213-.712 3.633 3.633 0 0 1-.604-4.237z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M152.35 102.606a3.618 3.618 0 0 1 2.903-1.912l.047 1.8.809-1.769a3.63 3.63 0 0 1 1.058 6.83 3.618 3.618 0 0 1-4.213-.712 3.633 3.633 0 0 1-.605-4.237z"/>
							<path fill="#57B894" fillRule="nonzero" d="M152.066 122.587a3.619 3.619 0 0 0 4.686-1.148 3.633 3.633 0 0 0-.608-4.793l-.621 1.36-.047-1.822a3.618 3.618 0 0 0-3.632.12 3.63 3.63 0 0 0 .222 6.283z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M152.328 121.969a3.619 3.619 0 0 0 4.685-1.149 3.633 3.633 0 0 0-.607-4.793l-.622 1.36-.047-1.822a3.618 3.618 0 0 0-3.63.121 3.63 3.63 0 0 0 .22 6.283z"/>
							<path fill="#57B894" fillRule="nonzero" d="M147.489 128.025a3.62 3.62 0 1 0 6.979 1.23 3.63 3.63 0 0 0-1.347-4.366 3.617 3.617 0 0 0-4.544.42l1.258 2.636-2.003-1.588a3.6 3.6 0 0 0-.343 1.668z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M147.704 127.57a3.62 3.62 0 1 0 6.979 1.23 3.63 3.63 0 0 0-1.347-4.366 3.617 3.617 0 0 0-4.544.42l1.258 2.636-2.003-1.588a3.6 3.6 0 0 0-.343 1.668z"/>
						</g>
						<g opacity=".3">
							<path fill="#FF6584" fillRule="nonzero" d="M50.887 113.883a3.618 3.618 0 0 1-4.157-.534 3.633 3.633 0 0 1-.873-4.105 3.622 3.622 0 0 1 3.579-2.184l.171 1.862.65-1.71a3.61 3.61 0 0 1 2.127 1.764 3.63 3.63 0 0 1-1.497 4.907z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M49.596 135.945c.086-1.617-1.447-2.822-2.895-3.54-1.448-.72-3.116-1.392-3.783-2.866-.96-2.119.682-4.416 1.936-6.375a21.862 21.862 0 0 0 2.248-4.643c.226-.654.423-1.333.41-2.025-.021-.996-.473-1.927-.922-2.816a211.153 211.153 0 0 0-4.692-8.783"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M50.672 113.427a3.618 3.618 0 0 1-4.157-.533 3.633 3.633 0 0 1-.873-4.105 3.622 3.622 0 0 1 3.579-2.185l.171 1.862.65-1.708a3.61 3.61 0 0 1 2.127 1.762 3.63 3.63 0 0 1-1.497 4.907z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M45.492 103.736a3.618 3.618 0 0 0-2.904-1.912l-.047 1.8-.809-1.769a3.63 3.63 0 0 0-1.058 6.83 3.618 3.618 0 0 0 4.213-.712 3.633 3.633 0 0 0 .605-4.237z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M45.205 103.13a3.618 3.618 0 0 0-2.904-1.913l-.047 1.8-.808-1.769a3.63 3.63 0 0 0-1.058 6.83 3.618 3.618 0 0 0 4.212-.712 3.633 3.633 0 0 0 .605-4.236z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M45.488 123.11a3.619 3.619 0 0 1-4.685-1.148 3.633 3.633 0 0 1 .607-4.793l.622 1.36.047-1.822a3.618 3.618 0 0 1 3.63.12 3.63 3.63 0 0 1-.22 6.283z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M45.227 122.492a3.619 3.619 0 0 1-4.686-1.149 3.633 3.633 0 0 1 .608-4.793l.621 1.36.047-1.822a3.618 3.618 0 0 1 3.63.121 3.63 3.63 0 0 1-.22 6.283z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M50.066 128.548a3.62 3.62 0 1 1-6.979 1.23 3.63 3.63 0 0 1 1.347-4.366 3.617 3.617 0 0 1 4.543.42l-1.258 2.636 2.003-1.588a3.6 3.6 0 0 1 .344 1.668z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M49.85 128.093a3.62 3.62 0 1 1-6.979 1.23 3.63 3.63 0 0 1 1.348-4.366 3.617 3.617 0 0 1 4.543.42l-1.258 2.636 2.003-1.588a3.6 3.6 0 0 1 .344 1.668z"/>
						</g>
						<g opacity=".3">
							<path fill="#FF6584" fillRule="nonzero" d="M141.406 113.708a3.618 3.618 0 0 1-4.157-.533 3.633 3.633 0 0 1-.873-4.105 3.622 3.622 0 0 1 3.578-2.185l.172 1.862.65-1.709a3.61 3.61 0 0 1 2.127 1.763 3.63 3.63 0 0 1-1.497 4.907z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M140.115 135.77c.086-1.616-1.447-2.821-2.895-3.54-1.448-.72-3.116-1.391-3.784-2.865-.96-2.12.682-4.417 1.936-6.375a21.862 21.862 0 0 0 2.249-4.644c.226-.654.423-1.332.409-2.024-.02-.996-.473-1.927-.921-2.817a211.15 211.15 0 0 0-4.692-8.782"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M141.19 113.253a3.618 3.618 0 0 1-4.156-.533 3.633 3.633 0 0 1-.873-4.106 3.622 3.622 0 0 1 3.578-2.184l.172 1.862.65-1.709a3.61 3.61 0 0 1 2.127 1.763 3.63 3.63 0 0 1-1.497 4.907z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M136.01 103.562a3.618 3.618 0 0 0-2.904-1.912l-.047 1.8-.808-1.77a3.63 3.63 0 0 0-1.058 6.83 3.618 3.618 0 0 0 4.213-.712 3.633 3.633 0 0 0 .604-4.236z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M135.724 102.955a3.618 3.618 0 0 0-2.904-1.912l-.047 1.8-.809-1.769a3.63 3.63 0 0 0-1.058 6.83 3.618 3.618 0 0 0 4.213-.712 3.633 3.633 0 0 0 .605-4.237z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M136.007 122.936a3.619 3.619 0 0 1-4.686-1.148 3.633 3.633 0 0 1 .608-4.793l.621 1.359.047-1.821a3.618 3.618 0 0 1 3.63.12 3.63 3.63 0 0 1-.22 6.283z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M135.745 122.317a3.619 3.619 0 0 1-4.685-1.148 3.633 3.633 0 0 1 .607-4.793l.622 1.36.047-1.822a3.618 3.618 0 0 1 3.63.121 3.63 3.63 0 0 1-.22 6.282z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M140.584 128.373a3.62 3.62 0 1 1-6.979 1.23 3.63 3.63 0 0 1 1.347-4.365 3.617 3.617 0 0 1 4.544.42l-1.258 2.636 2.003-1.589a3.6 3.6 0 0 1 .343 1.668z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M140.37 127.918a3.62 3.62 0 1 1-6.979 1.23 3.63 3.63 0 0 1 1.346-4.365 3.617 3.617 0 0 1 4.544.42l-1.258 2.636 2.003-1.589a3.6 3.6 0 0 1 .343 1.668z"/>
						</g>
						<g opacity=".3">
							<path fill="#FF6584" fillRule="nonzero" d="M12.59 113.534A3.618 3.618 0 0 1 8.435 113a3.633 3.633 0 0 1-.873-4.106 3.622 3.622 0 0 1 3.578-2.184l.172 1.862.65-1.709a3.61 3.61 0 0 1 2.127 1.763 3.63 3.63 0 0 1-1.497 4.907z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M11.3 135.596c.086-1.617-1.447-2.821-2.895-3.54-1.448-.72-3.116-1.392-3.784-2.866-.96-2.118.682-4.416 1.936-6.375a21.862 21.862 0 0 0 2.249-4.643c.226-.654.423-1.332.409-2.024-.02-.997-.472-1.927-.921-2.817a211.157 211.157 0 0 0-4.692-8.782"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M12.376 113.079a3.618 3.618 0 0 1-4.157-.534 3.633 3.633 0 0 1-.873-4.105 3.622 3.622 0 0 1 3.578-2.184l.172 1.862.65-1.71a3.61 3.61 0 0 1 2.127 1.764 3.63 3.63 0 0 1-1.497 4.907z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M7.195 103.388a3.618 3.618 0 0 0-2.903-1.913l-.047 1.8-.809-1.768a3.63 3.63 0 0 0-1.058 6.83 3.618 3.618 0 0 0 4.213-.713 3.633 3.633 0 0 0 .604-4.236z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M6.909 102.78a3.618 3.618 0 0 0-2.904-1.912l-.047 1.8-.809-1.768a3.63 3.63 0 0 0-1.058 6.83 3.618 3.618 0 0 0 4.213-.713 3.633 3.633 0 0 0 .605-4.236z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M7.192 122.762a3.619 3.619 0 0 1-4.686-1.149 3.633 3.633 0 0 1 .608-4.793l.621 1.36.047-1.821a3.618 3.618 0 0 1 3.63.12 3.63 3.63 0 0 1-.22 6.283z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M6.93 122.143a3.619 3.619 0 0 1-4.685-1.148 3.633 3.633 0 0 1 .608-4.793l.62 1.359.048-1.821a3.618 3.618 0 0 1 3.63.121 3.63 3.63 0 0 1-.22 6.282z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M11.77 128.2a3.62 3.62 0 1 1-6.979 1.23 3.63 3.63 0 0 1 1.346-4.366 3.617 3.617 0 0 1 4.544.42l-1.258 2.636 2.003-1.589a3.6 3.6 0 0 1 .344 1.668z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M11.555 127.744a3.62 3.62 0 1 1-6.979 1.23 3.63 3.63 0 0 1 1.346-4.366 3.617 3.617 0 0 1 4.544.42l-1.258 2.636 2.003-1.588a3.6 3.6 0 0 1 .344 1.668z"/>
						</g>
						<g opacity=".3">
							<path fill="#57B894" fillRule="nonzero" d="M11.618 97.434a6.215 6.215 0 0 0 7.14-.917 6.24 6.24 0 0 0 1.5-7.052 6.222 6.222 0 0 0-6.147-3.752l-.295 3.199-1.115-2.935a6.202 6.202 0 0 0-3.655 3.027 6.236 6.236 0 0 0 2.571 8.43z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M13.835 135.33c-.148-2.776 2.486-4.846 4.973-6.081 2.487-1.236 5.352-2.39 6.5-4.922 1.648-3.64-1.172-7.586-3.326-10.95a37.554 37.554 0 0 1-3.863-7.976c-.388-1.124-.726-2.289-.702-3.478.035-1.711.811-3.31 1.582-4.838A362.706 362.706 0 0 1 27.059 82"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M11.987 96.652a6.215 6.215 0 0 0 7.14-.916 6.24 6.24 0 0 0 1.5-7.052 6.222 6.222 0 0 0-6.147-3.753l-.294 3.199-1.116-2.935a6.202 6.202 0 0 0-3.654 3.028 6.236 6.236 0 0 0 2.571 8.43v-.001z"/>
							<path fill="#57B894" fillRule="nonzero" d="M20.886 80.005a6.215 6.215 0 0 1 4.988-3.285l.08 3.092 1.39-3.038a6.235 6.235 0 0 1 1.818 11.733 6.215 6.215 0 0 1-7.238-1.225 6.24 6.24 0 0 1-1.038-7.277z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M21.378 78.963a6.215 6.215 0 0 1 4.988-3.285l.08 3.092 1.39-3.039a6.235 6.235 0 0 1 1.818 11.733 6.215 6.215 0 0 1-7.237-1.224 6.24 6.24 0 0 1-1.039-7.277z"/>
							<path fill="#57B894" fillRule="nonzero" d="M20.892 113.285a6.216 6.216 0 0 0 8.048-1.973 6.24 6.24 0 0 0-1.044-8.233l-1.067 2.335-.08-3.128a6.214 6.214 0 0 0-6.234.21 6.236 6.236 0 0 0-2.921 5.503 6.234 6.234 0 0 0 3.298 5.286z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M21.341 112.222a6.216 6.216 0 0 0 8.048-1.973 6.24 6.24 0 0 0-1.043-8.233l-1.067 2.335-.081-3.128a6.214 6.214 0 0 0-6.234.21 6.236 6.236 0 0 0-2.921 5.503 6.234 6.234 0 0 0 3.298 5.286z"/>
							<path fill="#57B894" fillRule="nonzero" d="M13.028 122.624a6.218 6.218 0 1 0 11.988 2.114 6.236 6.236 0 0 0-2.313-7.5 6.212 6.212 0 0 0-7.804.722l2.16 4.528-3.44-2.729a6.183 6.183 0 0 0-.59 2.865z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M13.398 121.843a6.218 6.218 0 1 0 11.988 2.114 6.236 6.236 0 0 0-2.314-7.5 6.212 6.212 0 0 0-7.804.721l2.16 4.528-3.44-2.729a6.183 6.183 0 0 0-.59 2.866z"/>
						</g>
						<g opacity=".3">
							<path stroke="#3F3D56" strokeWidth=".5" d="M160.612 135.699c-.192-3.595 3.218-6.274 6.437-7.873 3.22-1.6 6.929-3.093 8.414-6.371 2.134-4.711-1.517-9.82-4.305-14.176a48.614 48.614 0 0 1-5-10.325c-.503-1.454-.94-2.962-.91-4.501.045-2.216 1.05-4.285 2.048-6.264 3.324-6.59 6.802-13.1 10.434-19.528"/>
							<path fill="#FF6584" fillRule="nonzero" d="M181.921 59.331l-3.505 1.003.244-1.625c-.31-.02-.622-.031-.94-.031-4.567 0-8.269 2.107-8.269 4.707 0 2.6 3.702 4.708 8.269 4.708 4.566 0 8.268-2.108 8.268-4.708 0-1.726-1.633-3.235-4.067-4.054z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M181.921 58.808l-3.505 1.003.244-1.625c-.31-.02-.622-.031-.94-.031-4.567 0-8.269 2.107-8.269 4.707 0 2.6 3.702 4.708 8.269 4.708 4.566 0 8.268-2.108 8.268-4.708 0-1.726-1.633-3.235-4.067-4.054z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M171.65 119.136l-3.504 1.003.243-1.625c-.308-.02-.622-.031-.94-.031-4.566 0-8.268 2.108-8.268 4.708s3.702 4.707 8.268 4.707c4.567 0 8.269-2.107 8.269-4.707 0-1.727-1.633-3.235-4.067-4.055z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M171.65 118.613l-3.504 1.003.243-1.625c-.308-.02-.622-.031-.94-.031-4.566 0-8.268 2.107-8.268 4.707 0 2.6 3.702 4.708 8.268 4.708 4.567 0 8.269-2.108 8.269-4.708 0-1.726-1.633-3.235-4.067-4.054z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M183.836 106.582l-3.505 1.003.243-1.625c-.308-.02-.621-.031-.94-.031-4.566 0-8.268 2.108-8.268 4.708s3.702 4.707 8.269 4.707c4.566 0 8.268-2.107 8.268-4.707 0-1.727-1.633-3.235-4.067-4.055z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M183.836 106.06l-3.505 1.002.243-1.625c-.308-.02-.621-.031-.94-.031-4.566 0-8.268 2.108-8.268 4.708s3.702 4.707 8.269 4.707c4.566 0 8.268-2.107 8.268-4.707 0-1.727-1.633-3.235-4.067-4.055z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M169.428 74.547l-3.58-.693.952-1.34c-.266-.157-.54-.309-.824-.453-4.073-2.069-8.326-1.866-9.5.452-1.175 2.32 1.175 5.876 5.248 7.945 4.072 2.07 8.326 1.867 9.5-.452.78-1.54.004-3.625-1.796-5.459z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M169.664 74.08l-3.579-.693.951-1.339c-.266-.157-.54-.31-.824-.453-4.073-2.07-8.326-1.867-9.5.452-1.174 2.319 1.175 5.876 5.248 7.945s8.326 1.866 9.5-.452c.78-1.54.005-3.626-1.796-5.46z"/>
							<path fill="#FF6584" fillRule="nonzero" d="M177.261 92.855l-3.579-.693.951-1.34c-.266-.157-.54-.31-.824-.453-4.072-2.07-8.326-1.867-9.5.452-1.174 2.319 1.175 5.876 5.248 7.945s8.326 1.867 9.5-.452c.78-1.54.005-3.625-1.796-5.459z"/>
							<path stroke="#3F3D56" strokeWidth=".5" d="M177.497 92.389l-3.579-.694.951-1.34c-.266-.157-.54-.309-.824-.453-4.072-2.069-8.326-1.866-9.5.452-1.174 2.32 1.176 5.876 5.248 7.945 4.073 2.07 8.326 1.867 9.5-.452.78-1.54.005-3.625-1.796-5.458z"/>
						</g>
						<path stroke="#3F3D56" strokeWidth=".5" d="M1.557 135.657h183.126M93.15.087c-18.362 0-33.247 9.907-33.247 22.127 0 5.456 2.97 10.45 7.889 14.308v16.06l10.54-10.558A47.22 47.22 0 0 0 93.15 44.34c18.362 0 33.248-9.906 33.248-22.126S111.513.087 93.15.087z"/>
						<path fill="#A0616A" fillRule="nonzero" d="M78.704 57.34s-.668-9.021-.354-10.67c.315-1.65.611-6.348-1.12-4.804-1.731 1.544-1.116 5.047-1.116 5.047s-1.643 9.534-.124 10.828c1.52 1.293 2.714-.4 2.714-.4z"/>
						<path fill="#857FE7" fillRule="nonzero" d="M79.951 66.262s-5.153-4.604-4.874-8.51c0 0 3.064-2.371 3.76-1.116.696 1.256 5.758 6.154 4.899 7.82-.86 1.666-3.785 1.806-3.785 1.806z"/>
						<path fill="#A0616A" fillRule="nonzero" d="M98.196 58.031s3.065-8.51 3.204-10.184c.14-1.674 1.114-6.277 2.368-4.324 1.253 1.953-.279 5.161-.279 5.161s-.975 9.626-2.786 10.463c-1.81.837-2.507-1.116-2.507-1.116zM89.84 100.72h8.217l.418 4.184-1.95.419s-4.596-1.395-7.242-.977c-2.646.419.557-3.627.557-3.627z"/>
						<path fill="#2F2E41" fillRule="nonzero" d="M95.272 100.161s3.9-2.65 4.596-.418c.696 2.232 3.203 10.323 2.367 11.16-.835.837-1.81-.697-1.81-.697s-1.671-3.488-2.925-3.627c-1.253-.14-1.95-1.256-1.95-1.256s3.621-2.79-.278-5.162z"/>
						<path fill="#A0616A" fillRule="nonzero" d="M87.75 121.784s1.533 6.278 1.254 7.255c-.278.976 4.457.418 4.457.418l.279-1.813s-2.786-4.464-2.786-6.418c0-1.953-3.203.558-3.203.558z"/>
						<path fill="#2F2E41" fillRule="nonzero" d="M93.461 126.388s3.76 2.79 1.81 4.185c-1.95 1.395-9.192 5.022-9.192 5.022s-2.646.419-.696-1.953c1.95-2.371 2.507-2.79 2.646-4.045a6.13 6.13 0 0 1 .86-2.356s1.926 3.89 4.572-.853zM82.04 92.907s-1.532 14.509.836 19.112c2.368 4.604 5.153 10.882 5.014 11.16-.14.28 4.04-.418 4.04-1.674 0-1.255-4.597-13.81-4.597-13.81s1.95-16.322 3.482-17.438c1.532-1.116-8.775 2.65-8.775 2.65z"/>
						<path fill="#000" fillRule="nonzero" d="M82.04 92.907s-1.532 14.509.836 19.112c2.368 4.604 5.153 10.882 5.014 11.16-.14.28 4.04-.418 4.04-1.674 0-1.255-4.597-13.81-4.597-13.81s1.95-16.322 3.482-17.438c1.532-1.116-8.775 2.65-8.775 2.65z" opacity=".1"/>
						<path fill="#2F2E41" fillRule="nonzero" d="M85.188 51.963c-.995.18-1.982.728-2.477 1.664-.461.872-.49 2.04-1.226 2.666-.438.374-1.034.46-1.523.754a2.352 2.352 0 0 0-1.08 2.094c.04.77.445 1.487.436 2.257-.011 1.002-.703 1.83-1.353 2.555l2.157.401c.326.087.668.096.999.027.372-.111.675-.418 1.052-.512.54-.134 1.074.195 1.557.487 1.372.831 2.843 1.458 4.309 2.083.431.184.92.368 1.346.17.343-.16.557-.535.88-.737.58-.363 1.306-.09 1.957.09 1.47.4 3.036.266 4.418-.378-.298-.348-.688-.592-1.01-.917-.32-.325-.58-.784-.496-1.247.087-.471.492-.786.767-1.167a2.745 2.745 0 0 0 .417-2.079 3.795 3.795 0 0 0-.994-1.883 9.94 9.94 0 0 1-.812-.858c-.508-.687-.634-1.596-.796-2.452-.162-.856-.419-1.767-1.078-2.288-1.05-.83-2.755-.548-3.983-.63-1.17-.079-2.299-.1-3.467-.1zM78.28 83.002l2.646 6.557s-12.117 13.253-5.292 15.066c6.824 1.814 9.192-1.395 13.51 1.117 0 0 2.089-3.21 1.81-5.162 0 0-5.989-1.116-9.192-.977 0 0 8.635-7.533 9.47-7.812.836-.279 3.622-4.743-.696-8.37-4.317-3.627-3.203-5.58-3.203-5.58l-9.053 5.161z"/>
						<path fill="#A0616A" fillRule="nonzero" d="M84.547 60.263s.418 4.325-1.392 4.883c-1.81.558 7.103.418 7.103.418s-2.09-1.255-1.114-3.906c.974-2.65-4.597-1.395-4.597-1.395z"/>
						<path fill="#000" fillRule="nonzero" d="M84.547 60.263s.418 4.325-1.392 4.883c-1.81.558 7.103.418 7.103.418s-2.09-1.255-1.114-3.906c.974-2.65-4.597-1.395-4.597-1.395z" opacity=".1"/>
						<path fill="#857FE7" fillRule="nonzero" d="M94.018 67.796s-.835-4.324-2.507-4.045c-1.671.279-5.014.558-5.432.837-.417.279-2.646.85-2.646.85s.836-.99 0-1.13c-.835-.139-3.482.559-3.76 1.117-.279.558-1.393 1.674-1.393 3.627 0 1.953-.836 5.301-.557 6.696.278 1.395-.14 4.604-.975 6.417-.836 1.814-.14 3.627 1.253 1.814 1.393-1.814.697 0 1.811.14 1.114.139 6.128-5.023 7.52-3.767 1.394 1.255 2.786 2.092 3.065 1.395.279-.698-1.114-1.953-1.114-2.511s-2.229 1.813.14-3.07c2.367-4.882.138-6.277 4.595-8.37z"/>
						<path fill="#857FE7" fillRule="nonzero" d="M92.904 67.517l1.114.28s4.04-2.93 5.153-5.023a179.862 179.862 0 0 0 1.95-3.766s-1.81-2.232-2.925-1.395c-1.114.837-6.128 6.556-6.128 6.556l.836 3.348z"/>
						<ellipse cx="87.751" cy="58.868" fill="#A0616A" fillRule="nonzero" rx="4.318" ry="4.325"/>
						<path fill="#2F2E41" fillRule="nonzero" d="M83.931 57.615a3.788 3.788 0 0 1 2.637-.868c1.655.102 3.112 1.283 4.77 1.32a1.715 1.715 0 0 1 .143-1.059c.128-.263.331-.52.304-.811a.99.99 0 0 0-.358-.587c-.958-.91-2.283-1.38-3.602-1.437-.885-.039-2.573-.018-3.259.653-.587.574-.519 2.045-.635 2.79z"/>
						<ellipse cx="93.151" cy="26.334" fill="#857FE7" fillRule="nonzero" rx="9.052" ry="9.067"/>
						<ellipse cx="90.939" cy="22.918" fill="#444053" fillRule="nonzero" rx="1" ry="1.139"/>
						<ellipse cx="95.425" cy="22.918" fill="#444053" fillRule="nonzero" rx="1" ry="1.139"/>
						<path fill="#3F3D56" fillRule="nonzero" d="M98.577 26.5c0 1.635-2.727 4.39-5.478 4.39s-5.374-2.817-5.374-4.452c0-1.636 2.566-.14 5.317-.14 2.752 0 5.535-1.433 5.535.202z"/>
						<path fill="#F6F8FB" fillRule="nonzero" d="M88.592 26.78s4.945 1.102 9.1.03c0 0 .508 0 .161.59a.937.937 0 0 1-.38.356c-.802.41-3.766 1.621-8.506.076a.933.933 0 0 1-.615-.631c-.056-.202-.032-.394.24-.422z"/>
					</g>
				</svg>

			</div>
		);
	}
}



export default applyWrappers(EmptyChatSection, styles)
