import React from 'react';
import styles from './executePopupBackground.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class ExecutePopupBackground extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 373 641">
                    <g fill="#452667" fill-rule="evenodd">
                        <path d="M372 640.432V0H0l1 641c195.433-.379 319.1-.568 371-.568z" opacity=".9"/>
                        <path d="M1 478.37c9.047 41.9 42.374 67.777 99.982 77.63 86.411 14.78 103.455-7 157.569 9 54.114 16 49.815 66.794 113.449 50.81V.37H1v478z" opacity=".7"/>
                        <path d="M1 390.063C26.944 469.354 77.918 509 153.921 509c38.363 0 99.286-11 162.279 19.756 41.995 20.504 60.595.94 55.8-58.693V.277H1v389.786z" opacity=".9"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(ExecutePopupBackground, styles);