import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
	let globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);
	return StyleSheet.create({
		chatBoxBackground: {
			background: skin.get('white'),
			borderRadius: '15px'
		},

		borderRightNone: {
			borderTopRightRadius: '0px'
		},

		chatBoxRightArrowBorder: {
			borderBottom: `25px solid ${skin.get('white')}`,
			borderRight: `25px solid transparent`
		},

		chatBoxLeftArrowBorder: {
			borderBottom: `25px solid ${skin.get('white')}`,
			borderLeft: `25px solid transparent`
		},

		chatBoxCotent: {
			...myTypography.body2,
			lineHeight: 'normal',
			color: `${hexToRgbA(skin.get('grayColor1'), 0.7)}`
		},

		borderBottom: {
			borderBottom: 'solid 1px #e8e8e8'
		},

		costStyling: {
			...myTypography.body2,
			fontSize: '11px',
			color: skin.get('grayColor2')
		},

		askStyle: {
			...myTypography.body2,
			fontSize: '8px',
			lineHeight: 1.25,
			fontWeight: 600,
			color: '#e87ad9'
		}
	});
}

export default getSkin;