import { StyleSheet } from 'aphrodite';
import { typography, getCurrentEntityPalette } from 'util/styleUtil';

const getColorPalette = (skinGuide, id) => {
	const entityProfiles = skinGuide.get('entityProfiles');
	const currentEntityPalette = getCurrentEntityPalette(entityProfiles, id);
    
	return StyleSheet.create({
		askQuestionsStyling: {
			backgroundColor: currentEntityPalette.get('solidColor'),
			borderRadius: '5px'
		}
		
	});
}

export default getColorPalette;