import React from 'react';
import styles from './personaInterview.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import VideoComponent from 'commonComponents/videoComponent/VideoComponent';
import {appInterviewVideoEnabled} from 'util/appVariables';
import {checkIfPresent} from 'util/utilFunctions';

class PersonaInterview extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            expand: this.props.shouldExpand,
            showTranscript: true
        }
    }

    // componentDidMount(nextProps) {
    //     const shouldExpand = (this.props.index == (this.props.personaObject.get('noOfInterviewTaken') - 1)) ? true : false
    //     this.setState({
    //         expand: shouldExpand
    //     })
    // }

    componentWillReceiveProps(nextProps) {
        const shouldExpand = (nextProps.index == (nextProps.personaObject.get('noOfInterviewTaken') - 1)) ? true : false
        this.setState({
            expand: shouldExpand
        })
    }

    showTranscript = () => {
        this.setState({
            showTranscript: !this.state.showTranscript,
        })
        
    }

    renderTranscript = (myStyles) => {
        if(this.state.expand && !this.state.showTranscript) {
            return(
                <div className={css(myStyles.transcriptStyle)} styleName="show-transcript-message">
                    <div style={{cursor: 'pointer', paddingRight: '5px'}} onClick={this.showTranscript} >
                        {this.props.getLabel('label_show_script')}
                    </div>
                    <div className={css(myStyles.downArrow)} styleName="down-arrow">
                    </div>
                </div>
            )
        } else if(this.state.expand && this.state.showTranscript) {
            return(
                <div className={css(myStyles.transcriptStyle)} styleName="show-transcript-message">
                    <div style={{cursor: 'pointer', paddingRight: '5px'}} onClick={this.showTranscript} >
                        {this.props.getLabel('label_hide_script')}
                    </div>
                    <div className={css(myStyles.upArrow)} styleName="down-arrow">
                    </div>
                </div>
            )
        }
        return null;
    }
     
    renderContentOfInterview = (myStyles) => {
        if(this.state.expand && this.state.showTranscript){
            return (
                <div 
                    styleName="content" 
                    className={css(myStyles.content)}
                    dangerouslySetInnerHTML={{__html: this.props.getLabel(this.props.interview.get('content'))}}
                >
                </div>
            );
        }
        else{
            return null;
        }
    }

    toggleView = () => {
        
        let currentState = this.state.expand;
        this.setState({
            expand: !currentState
        });
    }

    renderVideoPlayer = (myStyles) => {
        if(this.state.expand && checkIfPresent(this.props.interview.get('video'))) {
            return(
                <div styleName="video-component">
                    <VideoComponent {...this.props}
                        propRef={`video-persona-${this.props.personaObject.get('id')}`}
                    />
                </div>
            )
        }
        return null;
    }

    getLevelString = (index) => {
        switch (index) {
            case 1:
                return 'label_basic';
            case 2:
                return 'label_intermediate';
            case 3:
                return 'label_advanced';
            default:
                break;
        }
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div 
                index = {this.props.index}
                className={css(myStyles.container)} 
                styleName="interview"
            >
                <div styleName="title" className={css(myStyles.title)}>
                    <div onClick={this.toggleView} styleName="arrow-title-container">
                        {
                            this.state.expand 
                            ? 
                            <div className={css(myStyles.downArrow)} styleName="down-arrow"></div>
                            :
                            <div className={css(myStyles.sideArrow)} styleName="side-arrow"></div>

                        }
                        {this.props.getLabel('label_level')} - {this.props.getLabel(this.getLevelString(this.props.interview.get('level')))}
                    </div>
                </div>
                {
                    appInterviewVideoEnabled()
                    ?   this.renderVideoPlayer(myStyles)
                    :   null
                }
                {this.renderTranscript(myStyles)}
                {this.renderContentOfInterview(myStyles)}
            </div>
        );
    }
}

export default applyWrappers(PersonaInterview, styles);