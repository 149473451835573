const phaseRouteConstants = {
    observe: '/game/observe',
    empathize: '/game/empathize',
	define: '/game/define',
	ideate: '/game/ideate',
	prototype: '/game/prototype',
	test: '/game/test',
	execute: '/game/execute',
	endReport: '/game/round/endReport'
}

export default phaseRouteConstants;
