import React from 'react';
import styles from './changeInMetric.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import UpArrow from 'svgComponents/upArrow';
import DownArrow from 'svgComponents/downArrow';
import {checkIfPresent} from 'util/utilFunctions';
import { getFormattedCurrency } from 'util/currencyFormatter';
import {TweenMax} from 'gsap';

class ChangeInMetric extends React.Component {

    constructor(props) {
        super(props);
        this.completeContainer = React.createRef();
    }

    componentDidMount() {
        if(this.props.changeValue != 0) {
            this.animateValue(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.changeValue != 0 && this.props.metricValue !== nextProps.metricValue) {
            this.animateValue(nextProps);
        }
    }

    animateValue = (nextProps) => {
        TweenMax.fromTo(
            this.completeContainer.current,
            5,
            {
                ...nextProps.styleObj,
                opacity: 1
            },
            {
                ...nextProps.animateObj,
                opacity: 0
            }
        );
    }
    
    renderValue = (value) => {
        if(value >= 0) {
            return value;
        } else {
            return value * -1;
        }
    }

    renderArrow = (value) => {
        if(value > 0) {
            return (
                <div styleName="arrow">
                    <UpArrow />
                </div>
            );
        } else if (value < 0) {
            return (
                <div styleName="arrow">
                    <DownArrow />
                </div>
            );
        }
        return null;
    }

    getContainerClass = (value, myStyles) => {
        if(value > 0) {
            return css(myStyles.changeValueContainer, myStyles.green);
        } else if (value < 0) {
            return css(myStyles.changeValueContainer, myStyles.red);
        }
        return null;
    }

    renderContent = (myStyles, formatChangeValue) => {
        if(this.props.changeValue == 0) {
            return null;
        }
        return [
            this.renderArrow(this.props.changeValue),
            <div className={css(myStyles.valueText)} styleName="value-text">
                {
                    formatChangeValue
                    ?   getFormattedCurrency(Math.abs(this.props.changeValue), 'USD')
                    :   Math.abs(this.props.changeValue)
                }
            </div>
        ];
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        let formatChangeValue = false;
        if(checkIfPresent(this.props.formatValue)) {
            formatChangeValue = this.props.formatValue;
        }

        return (
            <div
                className={this.getContainerClass(this.props.changeValue, myStyles)}
                styleName="change-value-container"
                style={{
                    ...this.props.styleObj
                }}
                ref={this.completeContainer}
            >
                {this.renderContent(myStyles, formatChangeValue)}
            </div>
        );
    }
}

export default applyWrappers(ChangeInMetric, styles);