/**
 * @author: Ujjwal Jain
 * @file: uiState.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({
	isUserOnIntroScreen: false,
	highlightPhaseHeader: false,
	highlightTeamHeader: false,
	highlightTimerHeader: false,
	highlightFooter: false,
	isProjectBoardOpen: false,
	isUserBoardOpen: false,
	isLeaderBoardButtonVisible: false,
	isLeaderBoardOpen: false,
	popupComponent: '',
	isOverlayOpen: false,
	isOverlayWithTimerOpen: false,
	isOverlayWithHeaderOpen: false,
	isOverlayWithTeamOpen: false,
	isOverlayWithFooterOpen: false,
	isOnboardingSeen: false,
	isDropDownOverlayOpen: false,
	closePopovers: false,
	isBluredRightOverlay: false,
	popupComponentBluredOverlay: '',
	showEndReportLoader: true,
	isEndReportFromFooter: false,
	showNotes: true,
	showEventAlert: false,
	atleastOneEventSeen: false,
	viewPortWidth: 1280,
	viewPortHeight: 670,
	openReportIframe: false,
	isReportDownloading: false,
	freshdeskInitialized: false,
	sentryInitialized: false,
	showWarningPopup: false,
	warningIdeaId: null,
	warningType: '',
	dfvButtonLoading: false,
	refineIdeaButtonLoading: false,
	executeButtonLoading: false,
	ideaContainerLoadingStates: {
		ideateContent: false,
		ideateShortlist: false,
		prototypeContent: false,
		prototypeShortlist: false,
		testContent: false,
		testShortlist: false,
		executionShortlist: false
	},
	isFeedbackSubmitted: false,
	feedbackSubmitInProgress: false
});


const uiState = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.get('SET_UI_STATE'):
			return state.mergeDeep(Immutable.fromJS(action.payload));
		case actionConsts.get('UPDATE_EVENT'):
			return state.set('atleastOneEventSeen', true);
		default:
			return state;
	}
};

export default uiState;
