import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		heading: {
			...myTypography.body1,
			fontSize: '20px',
			color: skin.get('primaryColor')
		},
		closeButton: {
			...myTypography.body1,
			color: skin.get('primaryColor')
		},
		borderReport: {
			opacity: 0.5,
			backdropFilter: `blur(10px)`,
			backgroundImage: `linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd)`
		}
	});
}

export default getSkin;