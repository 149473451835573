import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey } from '../../util/objectUtils';

const initialState = Immutable.fromJS({
    quadrantList: [],
    isQuadrantListFetched: false
});

const testQuadrants = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_TEST_QUADRANTS'):
            return state.set('quadrantList', mergeListsBasedOnKey(state.get('quadrantList'), action.payload, 'id'))
                .set('isQuadrantListFetched', true);
        default:
            return state;
    }
};

export default testQuadrants;