import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		singleContainer: {
			...myTypography.body1,
			fontSize: '16px',
			fontWeight: 600,
			color: skin.get('grayColor1'),
			lineHeight: 1,
			borderRight: `1px solid ${skin.get('grayColor1')}`,
			':nth-last-child(1)': {
				borderRight: 'none'
			}
		}
	});
}

export default getSkin;