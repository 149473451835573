import React from 'react';
import styles from './timerComponent.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import Timer from 'svgComponents/timer';
import DB_CONFIG from './config.js';
import Rebase from 're-base';
import firebase from 'firebase';
import TimerAlert from 'svgComponents/timerAlert/TimerAlert';
import FilledButton from 'commonComponents/buttons/filledButton';
import ColorHeading from 'commonComponents/colorHeading';
import {checkIfPresent} from 'util/utilFunctions';
import consts from 'constants/url';

let app = null;
let base = null;
let timer;

class TimerComponent extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			localTimer: parseInt(props.timer.get('initialTimer'), 10),
			completion: null,
			minutes: null,
			seconds: null,
			uliId: props.userState.get('uliId') ? props.userState.get('uliId') : null,
			totalTimer: parseInt(props.timer.get('initialTimer'), 10),
			isTimerUpdated: false
		};		

		if (!firebase.apps.length && this.props.firebase.get('isFirebaseCredentialsAvailable')) {
			app = firebase.initializeApp(this.props.firebase.get('credentials').toJS());
			base = Rebase.createClass(app.database());
		}

		if (parseInt(props.timer.get('initialTimer'), 10) && props.userState.get('uliId')) {
			this.fetchUserDataOnDataManager();
		}
	}

	setUserDataOnDataManager = () => {
		base.push(`users/${this.state.uliId}/time`, {
			data: {
				time: this.state.completion
			}
		}).then(() => {
			if (this.props.userState.get('isTimerStarted')) {
				this.progressUserTimer();
			}
		}).catch((err) => {
			console.error(err);
		});
	}

	buildTimerLabels = () => {

		let minutes = Math.floor(this.state.localTimer / 60);
		let seconds = this.state.localTimer % 60;
		if (minutes < 10) {
			minutes = (`0${minutes}`).slice(-2);
		}
		seconds = (`0${seconds}`).slice(-2);
		this.setState({
			minutes,
			seconds
		});
	}

	formatTimerData = (timerData) => {
		let minutes = Math.floor(timerData / 60);
		let seconds = timerData % 60;
		if (minutes < 10) {
			minutes = (`0${minutes}`).slice(-2);
		}
		seconds = (`0${seconds}`).slice(-2);
		return {
			'second': seconds,
			'minutes': minutes
		}
	}

	componentWillReceiveProps = (nextProps) => {
		// if (parseInt(nextProps.storyline.get('baseValues').initialTimer, 10)
		// 	&& (this.state.totalTimer !== parseInt(nextProps.storyline.get('baseValues').initialTimer, 10))) {
		// 	this.setState({
		// 		uliId: nextProps.userState.get('uliId'),
		// 		totalTimer: parseInt(nextProps.storyline.get('baseValues').initialTimer, 10),
		// 		localTimer: parseInt(nextProps.storyline.get('baseValues').initialTimer, 10)
		// 	}, this.fetchUserDataOnDataManager);
		// }
		// else {
		if (nextProps.userState.get('isTimerStarted') && !this.props.userState.get('isTimerStarted')) {
			this.progressUserTimer();
		}
		// }
	}

	componentWillUnmount = () => {
		clearTimeout(timer);
		// clearTimeout(this.timer);
	}

	updateUserDataOnDataManager = () => {
		if(this.state.completion){
			base.post(`users/${this.state.uliId}`, {
				data: {
					time: this.state.completion
				}
			}).then(() => {
				if(this.props.userState.get('isUserGameCompleted')) {
					clearTimeout(timer);
					return;
				}
				this.buildTimerLabels();
				this.progressUserTimer();
			}).catch((err) => {
				console.error(err);
			});
		}
	}

	updateTimer = (simTimeUsed) => {
		
		this.setState({
			isTimerUpdated: true
		});
		if (this.props.userState.get('isTimerStarted')) {
			this.props.updateTimer(simTimeUsed);
		}
		// if (!this.props.loader.active) {
		// 	this.endOfSimulation();
		// }
		// else {
		// 	setTimeout(() => {
		// 		this.endOfSimulation();
		// 	}, 3000);
		// }
	}

	checkAndUpdateTimer = (simTimeUsed) => {
		if (!this.state.isTimerUpdated) {
			this.updateTimer(simTimeUsed);
		}
	}

	timerExpired = () => {
		
		if(!this.props.userState.get('isUserGameCompleted')) {			
			this.props.postGameCompleted({
				roundId: this.props.userState.get('currentRoundId')
			});
			const url = consts.get('ACTION_LOG_API');
			const responsePayload = {
				roundId: this.props.userState.get('currentRoundId'),
				action: {
					type: 'SET_USER_STATE',
					payload: {
						isTimerExpired: true,
						showTimerWarning: false
					}
				}
			};
			this.props.postRequestActionLog(url, responsePayload);
			this.props.setUserState({
				isTimerExpired: true,
				showTimerWarning: false,
				showFooter: true
			});
		}
		this.props.setUIState({
			isOverlayOpen: true,
			popupComponent: 'TIME_UP'
		})
	}

	progressUserTimer = () => {
		
		// if (parseInt(this.props.storyline.get('baseValues').initialTimer, 10) === 1) {
		// 	if (this.state.completion <= (this.state.totalTimer - 2)) {
		// 		this.setState({
		// 			completion: this.state.completion,
		// 			localTimer: this.state.localTimer
		// 		});
		// 	}
		// 	else {
		// 		this.setState({
		// 			minutes: '00',
		// 			seconds: '00'
		// 		});
		// 	}
		// 	this.checkAndUpdateTimer(this.state.completion);
		// 	return;
		// }
		timer = setTimeout(() => {
			if (this.state.completion <= (this.state.totalTimer - 2)) {

				this.setState({
					completion: this.state.completion + 1,
					localTimer: this.state.localTimer - 1
				});
				this.updateUserDataOnDataManager();
			}
			else {
				this.setState({
					minutes: '00',
					seconds: '00'
				});
				this.timerExpired();
				this.checkAndUpdateTimer(this.state.totalTimer);
			}
		}, 1000);
	}

	fetchUserDataOnDataManager = () => {
		base.fetch(`users/${this.state.uliId}/time`, {
			context: this
		}).then((data) => {
			// this.props.updateTimerInitialisedStatus();
			if (
				(!data || (Object.keys(data).length === 0 && data.constructor === Object))
			) {
				if (this.props.userState.get('isTimerStarted')) {
					this.setState({
						localTimer: this.state.totalTimer,
						completion: 0
					});
					this.setUserDataOnDataManager();
				} else {
					this.buildTimerLabels();
				}
			}
			else if (this.props.userState.get('isTimerStarted')) {
				this.setState({
					localTimer: this.state.totalTimer - data,
					completion: data
				});
				this.buildTimerLabels();
				this.progressUserTimer();
			}
		}).catch((err) => {
			console.error(err);
		});
	}

	closeTimerWarning = () => {
		this.props.setUserState({
			showTimerWarning: false
		});
		this.props.setUIState({
			isOverlayOpen: false
		});
	}

	onClickTimerAlert = (alertTimerData) => {
		this.props.postActionLog({
			type: 'UPDATE_TIMER_ALERT_DATA',
			payload: alertTimerData
		}, this.closeTimerWarning);
	}

	renderTimerAlert = (myStyles) => {
		let alertTimerData = [];
		const timerData = this.props.userState.get('userTimerData');
		const currentTimerNotSeenData = timerData.filter((eachData) => eachData.isSeenByUser === false);
		if (currentTimerNotSeenData.length > 0) {
			
			alertTimerData = currentTimerNotSeenData.filter((eachData) => this.state.localTimer <= eachData.timerAlertValue);			
			if (alertTimerData.length > 0) {
				this.props.setUserState({
					showTimerWarning: true
				});
				this.props.setUIState({
					isOverlayOpen: true
				});
			}
		}

		const initialTimer = this.props.timer.get('initialTimer');

		const timerLeft = this.state.localTimer;

		const timeSpent = initialTimer - timerLeft;

		const formatTimeSpent = this.formatTimerData(timeSpent);
		const formatInitialTime = this.formatTimerData(initialTimer);
		const formatTimeLeft = this.formatTimerData(timerLeft);

		if (this.props.userState.get('showTimerWarning')) {
			return (
				<div styleName="budget-text-firsttime-message-container"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className={css(myStyles.upOnboardingArrow)} styleName="what-budget-arrow"></div>
					<div styleName="budget-alert-container">
						<div styleName="budget-alert-background">
							<div styleName="background-image-container">
								<TimerAlert />
							</div>
						</div>
						<div styleName="budget-alert-content">
							<div styleName="what-budget-first-content">
								<div styleName="what-budget-first-content-text">
									<div styleName="budget-alert-heading">
										<ColorHeading
											title={this.props.getLabel('label_timer_alert_heading')}
											isUppercase={false}
											fontSize="20px"
											imgBottom="0px"
										/>
									</div>
									<div className={css(myStyles.budgetFirstTimeText)} styleName="budget-alert-desc">
										{this.props.getLabel('label_timer_alert_desc')}
									</div>
									<div styleName="budget-values-container">
										<div styleName="budget-values">
											<div styleName="budget-value-content">
												<div className={css(myStyles.valueHeading)} styleName="value-heading">{this.props.getLabel('label_timer_total_spend')}</div>
												<div className={css(myStyles.valueNumber)} styleName="value-number">
													{formatTimeSpent.minutes}:{formatTimeSpent.second} 
													<span className={css(myStyles.valueNumberDenominator)}>
														/{formatInitialTime.minutes}:{formatInitialTime.second}
													</span>
												</div>
											</div>
											<div className={css(myStyles.valueSeparator)} styleName="values-separator"></div>
											<div styleName="budget-value-content">
												<div className={css(myStyles.valueHeading)} styleName="value-heading">{this.props.getLabel('label_timer_left')}</div>
												<div className={css(myStyles.valueNumber, myStyles.red)} styleName="value-number">
													{formatTimeLeft.minutes}:{formatTimeLeft.second}
												</div>
											</div>
										</div>
										<div styleName="budget-progress-bar-container">
											<div className={css(myStyles.progressBarBack)} styleName="budget-progress-bar-back">
												<div
													className={css(myStyles.progressBarFront)}
													styleName="budget-progress-bar-front"
													style={{
														minWidth: '2%',
														maxWidth: '100%',
														width: Math.round(timeSpent * 100 / initialTimer) < 0 ? '2%' : Math.round(timeSpent * 100 / initialTimer)
													}}
												></div>
											</div>
										</div>
									</div>
									<div styleName="budget-buttons-container">
										<FilledButton textLabel={this.props.getLabel('label_got_it')}
											clickFunction={() => {
												this.onClickTimerAlert(timerData);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		const background = this.props.userState.get('showTimerWarning') ? myStyles.backgroundRed : '';
		return (
			<div className={css(background)} styleName="timer-container">
				{this.renderTimerAlert(myStyles)}
				<div styleName="timer-image">
					<Timer 
						opacity={this.props.userState.get('showTimerWarning') ? '1' : '.305'}
					/>
				</div>
				<div className={css(myStyles.budgetTimerFont)} styleName="timer-value">
					{this.state.minutes}:{this.state.seconds}
				</div>
			</div>
		);
	}
}

export default applyWrappers(TimerComponent, styles);