import React from 'react';
import styles from './infoTooltip.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import InfoIcon from 'svgComponents/infoIcon';
import NotAllowed from 'svgComponents/notAllowed';

import {checkIfPresent} from 'util/utilFunctions';

class InfoTooltip extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
			showTooltip: false
		}
	}

	openTooltip = () => {
		this.setState({
			showTooltip: true
		});
	}

	closeTooltip = () => {
		this.setState({
			showTooltip: false
		});
    }
    
    renderTooltip = (myStyles) => {
        switch(this.props.alignTooltipKey) {
            case 'LEFT':
                return (
                    <div styleName="tooltip-container-left">
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{__html: this.props.tooltipText}}
                        ></div>
                        <div className={css(myStyles.tooltipArrowLeft)} styleName="tooltip-arrow-left-right"></div>
                    </div>
                );
            case 'RIGHT':
                return (
                    <div styleName="tooltip-container-right">
                        <div className={css(myStyles.tooltipArrowRight)} styleName="tooltip-arrow-left-right"></div>
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{__html: this.props.tooltipText}}
                        ></div>
                    </div>
                );
            case 'TOP':
                return (
                    <div styleName="tooltip-container-top">
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{__html: this.props.tooltipText}}
                        ></div>
                        <div className={css(myStyles.tooltipArrowTop)} styleName="tooltip-arrow-top-bottom"></div>
                    </div>
                );
            case 'BOTTOM':
                return (
                    <div styleName="tooltip-container-bottom">
                        <div className={css(myStyles.tooltipArrowBottom)} styleName="tooltip-arrow-top-bottom"></div>
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{__html: this.props.tooltipText}}
                        ></div>
                    </div>
                );
            default:
                return (
                        <div styleName="tooltip-container-right">
                        <div className={css(myStyles.tooltipArrowRight)} styleName="tooltip-arrow"></div>
                        <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                            dangerouslySetInnerHTML={{__html: this.props.tooltipText}}
                        ></div>
                    </div>
                );
        }
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const notAllowed = checkIfPresent(this.props.notAllowed) ? true : false;
        const propStyles = checkIfPresent(this.props.propStyles) ? this.props.propStyles : {}
        return (
            <div
                styleName="info-container"
                style={{
                    ...propStyles
                }}
            >
				<div
					styleName="info-icon"
					onMouseEnter={() => {this.openTooltip()}}
					onMouseLeave={() => {this.closeTooltip()}}
				>
                    {
                        notAllowed 
                        ? <NotAllowed />
                        : <InfoIcon />

                    }
				</div>
				{
					(this.state.showTooltip)
					?	this.renderTooltip(myStyles)
					:	null
				}
			</div>
		);
    }
}

export default applyWrappers(InfoTooltip, styles);