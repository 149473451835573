import React from 'react';

import Header from 'commonComponents/header';
import getHeaderConfig from 'config/header/getHeaderConfig';

import styles from './desktopFooter.module.sass';
import applyWrappers from 'util/ComponentWrapper';
import {showBlur} from 'util/utilFunctions';

class DesktopFooter extends React.Component {

	render() {
        const headerConfig = getHeaderConfig(this.props);
        const {uiState, userState} = this.props;

        const isBlur = showBlur(uiState);

        return (
            <div styleName="footer-container" style={{
                filter: isBlur && (!userState.get('isTimerExpired') && !userState.get('isUserGameCompleted') ) ? 'blur(4px)' : null,
                position: uiState.get('isUserOnIntroScreen') ? 'absolute' : 'static',
                bottom: 0,
                left: 0,
                zIndex: uiState.get('highlightFooter') || uiState.get('isOverlayDropDownOpen') || userState.get('isTimerExpired') ? 50 : 'auto'
			}}>
                <div styleName="footer-border"></div>
                <Header
                    headerConfig={headerConfig}
                    {...this.props}
                />
            </div>
        );
	}
}

DesktopFooter.propTypes = {};
DesktopFooter.defaultProps = {};

export default applyWrappers(DesktopFooter, styles);
