import { StyleSheet } from 'aphrodite';
import { typography, getCurrentEntityPalette } from 'util/styleUtil';

const getColorPalette = (skinGuide, id) => {
	const entityProfiles = skinGuide.get('entityProfiles');
	const currentEntityPalette = getCurrentEntityPalette(entityProfiles, id);
    
	return StyleSheet.create({
		askQuestionsStyling: {
			backgroundColor: currentEntityPalette.get('solidColor'),
			borderRadius: '5px',
			borderTopRightRadius: 0
		},

		actionTickBgSegment: {
			backgroundColor: currentEntityPalette.get('solidColor'),
			borderTopRightRadius: '14px',
			borderBottomRightRadius: '14px',
		},

		actionTickBgSegmentWhite: {
			backgroundColor: '#ffffff',
			borderTopRightRadius: '14px',
			borderBottomRightRadius: '14px',
		}

		
	});
}

export default getColorPalette;