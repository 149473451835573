import React from 'react';
import styles from './warningPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import FilledButton from 'commonComponents/buttons/filledButton';
import HollowButton from 'commonComponents/buttons/hollowButton';
import CloseIcon from 'svgComponents/closeIcon';
import {getFormattedCurrency} from 'util/currencyFormatter';

class WarningPopup extends React.Component {

    onClickOfClosePopup = () => {
        this.props.setUIState({
            showWarningPopup: false,
            warningIdeaId: null,
            warningType: ''
        });
    }

    renderBorder = (myStyles) => {
        return (
            <div className={css(myStyles.borderContainer)} styleName="border-container"></div>
        );
    }

    renderHeadingContent = (myStyles) => {
        return (
            <div styleName="heading-container">
                <div className={css(myStyles.headingMain)} styleName="heading-main">
                    {this.props.getLabel(this.props.heading)}
                </div>
                <div className={css(myStyles.headingDesc)} styleName="heading-desc">
                    {this.props.getLabel(this.props.content)}
                </div>
            </div>
        );
    }

    renderButton = (myStyles) => {
        return (
            <div className={css(myStyles.buttonsContainer)} styleName="buttons-container">
                <div styleName="buttons-content">
                    <div styleName="action-button">
                        <FilledButton
                            clickFunction={() => {
                                this.onClickOfClosePopup();
                            }}
                            textLabel={this.props.getLabel('label_got_it')}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderCloseIcon = (myStyles) => {
        return (
            <div styleName="close-button">
                <CloseIcon closeButtonClicked={this.onClickOfClosePopup} />
            </div>
        );
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div className={css(myStyles.validationContainer)} styleName="popup-container">
                {this.renderCloseIcon(myStyles)}
                {this.renderBorder(myStyles)}
                {this.renderHeadingContent(myStyles)}
                {this.renderButton(myStyles)}
            </div>
        );
    }
}

export default applyWrappers(WarningPopup, styles);