import React from 'react';
import styles from './executePopupIdeaCard.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import { calculateAverageScore } from 'util/utilFunctions';
import DfvAbbreviated from 'commonComponents/dfvAbbreviated';
import {checkIfPresent} from 'util/utilFunctions';
import {getFormattedCurrency} from 'util/currencyFormatter';

class ExecutePopupIdeaCard extends React.Component {

    renderCostTag = (myStyles, currentIdea, costTagObj) => {
        const tagDivs = costTagObj.tags.map((eachTag, index) => {
            return (
                <div key={`tag-${index}`} styleName="single-cost-container">
                    <div className={css(myStyles.tagCostName)} styleName="single-cost-heading">{eachTag.name}:</div>
                    <div className={css(myStyles.tagCostValue)} styleName="single-cost-value">
                        {getFormattedCurrency(eachTag.cost, 'USD')}
                    </div>
                </div>
            );
        });
        if(costTagObj.imageType === 'RIGHT_SIDE') {
            return (
                <div
                    styleName="right-side-tag"
                >
                    <div styleName="background-tag-container">
                        <img alt="tag" src={this.props.getImage('IMG_RIGHT_SIDE_TAG')} width="100%" height="100%" />
                    </div>
                    <div styleName="tag-content-container">
                        <div styleName="tag-content-container-content-right">
                            {tagDivs}
                        </div>
                    </div>
                </div>
            );
        } else if (costTagObj.imageType === 'BOTTOM_SIDE') {
            return (
                <div
                    styleName="bottom-side-tag"
                >
                    <div styleName="background-tag-container">
                        <img alt="tag" src={this.props.getImage('IMG_BOTTOM_SIDE_TAG')} width="100%" height="100%" />
                    </div>
                    <div styleName="tag-content-container">
                        <div styleName="tag-content-container-content-bottom">
                            {tagDivs}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    getCostTagObj = (currentIdea) => {

        const costTagObj = {
            showTag: true,
            imageSrc: 'IMG_TAG_BELOW',
            imageType: 'BOTTOM_SIDE',
            tags: [
                {
                    name: this.props.getLabel('label_execution_cost'),
                    cost: currentIdea.cost.executionCost
                }
            ]
        }

        return costTagObj;
    }

    getDFVScoreObj = (currentIdea) => {
        return {
            dScore: calculateAverageScore(currentIdea.dfv_response.dScores, 'dScore'),
            fScore: calculateAverageScore(currentIdea.dfv_response.fScore, 'fScore'),
            vScore: calculateAverageScore(currentIdea.dfv_response.vScore, 'vScore')
        }
    }

    getDFVDDScoreObj = (currentIdea) => {
        return {
            dScoreDD: calculateAverageScore(currentIdea.digdeep_response.dScores, 'dScoreDD'),
            fScoreDD: calculateAverageScore(currentIdea.digdeep_response.fScore, 'fScoreDD'),
            vScoreDD: calculateAverageScore(currentIdea.digdeep_response.vScore, 'vScoreDD')
        }
    }

    renderDfvContent = (myStyles, currentIdea) => {
        let ideaType = 'NOT_UNLOCKED';
        let dfvScoreObj = {
            dScore: 0,
            fScore: 0,
            vScore: 0
        };
        let dfvDDScoreObj = {
            dScoreDD: 0,
            fScoreDD: 0,
            vScoreDD: 0
        }

        if(currentIdea.isTestingDone && checkIfPresent(currentIdea.testPrototypeResponse.dfv)) {
            ideaType = 'TESTED';
        } else if(currentIdea.dfv_unlocked === false) {
            ideaType = 'NOT_UNLOCKED';
        } else if(currentIdea.dfv_unlocked === true && currentIdea.dfv_refined === false) {
            ideaType = 'NOT_REFINED';
            dfvScoreObj = this.getDFVScoreObj(currentIdea);
        } else if(currentIdea.dfv_unlocked === true && currentIdea.dfv_refined === true) {
            ideaType = 'REFINED';
            dfvDDScoreObj = this.getDFVDDScoreObj(currentIdea);
        }

        switch(ideaType) {
            case 'NOT_UNLOCKED':
                return (
                    <div className={css(myStyles.unlockDfvContent)} styleName="unlock-dfv-content">
                        {this.props.getLabel('label_dfv_not_unlocked')}
                    </div>
                );
            case 'NOT_REFINED':
                return (
                    <div styleName="dfv-value-content">
                        <DfvAbbreviated
                            valueD={dfvScoreObj.dScore}
                            valueF={dfvScoreObj.fScore}
                            valueV={dfvScoreObj.vScore}
                        />
                    </div>
                );
            case 'REFINED':
                return (
                    <div styleName="dfv-value-content">
                        <DfvAbbreviated
                            valueD={dfvDDScoreObj.dScoreDD}
                            valueF={dfvDDScoreObj.fScoreDD}
                            valueV={dfvDDScoreObj.vScoreDD}
                        />
                    </div>
                );
            case 'TESTED':
                return (
                    <div styleName="dfv-value-content">
                        <DfvAbbreviated
                            valueD={currentIdea.testPrototypeResponse.dfv.Dscore}
                            valueF={currentIdea.testPrototypeResponse.dfv.Fscore}
                            valueV={currentIdea.testPrototypeResponse.dfv.Vscore}
                        />
                    </div>
                );
            default:
                return (
                    <div className={css(myStyles.unlockDfvContent)} styleName="unlock-dfv-content">
                        {this.props.getLabel('label_dfv_not_unlocked')}
                    </div>
                )
        }
    }

    renderListOfExecutedIdeas = (ideaList, myStyles) => {

        if (ideaList.length == 0) {            
            return(
                <div styleName="no-idea-card-content">
                    <div styleName="no-ideas-image">
                        <img src={this.props.getImage('IMG_NO_IDEAS')} width="100%" height="100%" />
                    </div>
                </div>
            )
        }
        return ideaList.map((ideaExecuted, index) => {
            // const revenueForIdea = currentRoundList[0].reportData.ideaRevenues.filter((idea) => idea.ideaId === ideaExecuted.id)
            // const ceForIdea = currentRoundList[0].reportData.ideaCR.filter((idea) => idea.ideaId === ideaExecuted.id)
            console.log(ideaExecuted)
            const costTagObj = this.getCostTagObj(ideaExecuted);  
            return(
                <div key={`executed-idea-${index}`} className={css(myStyles.cardBg)} styleName="idea-card-content">
                    <div className={css(myStyles.cardDesc)} styleName="idea-card-description">
                        <div styleName="dfv-prototype-container">
                            <div styleName="prototype-image">
                                <img src={this.props.getImage('IMG_PROTOTYPE_WIREFRAME')} width="100%" height="100%" />
                            </div>
                            <div styleName="dfv-value-content-prototype">
                                {this.renderDfvContent(myStyles, ideaExecuted)}
                            </div>
                        </div>
                        <div className={css(myStyles.ideaTitle)}>
                            {this.props.getLabel(ideaExecuted.name)}
                        </div>
                        {/* <div className={css(myStyles.ideaDesc)}>
                            {this.props.getLabel(ideaExecuted.description)}
                        </div> */}
                    </div>
                    {this.renderCostTag(myStyles, ideaExecuted, costTagObj)}
                </div>
            )
        })
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        if(this.props.ideasList.length === 0) {
            return null;
        }
        return this.renderListOfExecutedIdeas(this.props.ideasList, myStyles)
    }
}

export default applyWrappers(ExecutePopupIdeaCard, styles);