import kfetch from '../util/kfetch';
import consts from 'constants/url';
import { getImagesPathFile } from 'config/images/imageConfig.js' ;
import { getPhasesListFromPhaseTypeData, 
        formatPersonaListData, 
        formatColorProfiles,
        getformattedRoundList
} from '../../util/reducerMiddleware/formatReducerData';
import { replayAllReducerActions } from 'actions/replayActions/replayActions';
import {checkIfPresent} from 'util/utilFunctions';
import analyticsUtil from 'util/segmentUtil';

export const fetchStorylineData = () => (dispatch) => kfetch(consts.get('GAME_API'))
    .then((response) => {
        console.log("response", response);
        if(response && response.userState){
            analyticsUtil.identify(response.userState.uliId,response.userState);
            analyticsUtil.group(response.userState.groupId,response.userState);
        } 
        dispatch({
            type: "INIT_INTRO_DATA",
            payload: response.storyline.storylineIntro
        });

        dispatch({
            type: "INIT_INTRO_BASE_DATA",
            payload: response.baseValues
        });

        dispatch({
            type: 'INIT_FIREBASE_DATA',
            payload: response.firebase
        })

        dispatch({
            type: 'INIT_BUDGET_ALERT_DATA',
            payload: response.storyline.rounds
        })

        dispatch({
            type: 'INIT_TIMER_ALERT_DATA',
            payload: response.storyline.rounds
        })

        dispatch({
            type: 'INITIALIZE_TIMER',
            payload: response.timer
        })

        dispatch({
            type: "INIT_USER_NOTES_DATA",
            payload: {
                notes: response.notes
            }
        });

        dispatch({
            type: 'SET_TEST_QUADRANTS',
            payload: response.testQuadrants
        });

        dispatch({
            type: 'INIT_METRICS_DATA',
            payload: response.metricsData
        });

        const phasesFormattedData = getPhasesListFromPhaseTypeData(response.storyline.phaseTypes);

        dispatch({
            type: "INIT_PHASE_DATA",
            payload: phasesFormattedData
        });
        
        dispatch({
            type: "INIT_SEGMENTS_DATA",
            payload: response.storyline.segments
        });

        dispatch({
            type: "INIT_TOUCHPOINTS_DATA",
            payload: response.storyline.touchPoints
        });

        const personaFormattedData = formatPersonaListData(response.storyline.personas);
        
        dispatch({
            type: "INIT_PERSONAS_DATA",
            payload: personaFormattedData
        });
        
        dispatch({
            type: "INIT_ROUNDS_DATA",
            payload: getformattedRoundList(response.storyline.rounds)
        });
        
        dispatch({
            type: "INIT_TEAM_MEMBERS_DATA",
            payload: response.storyline.teamMembers
        });
        if(response.userState.isGameCompleted){
            dispatch({
                type: "SET_USER_STATE",
                payload: {
                    isUserGameCompleted: response.userState.isGameCompleted
                }
            });
        }
        dispatch({
            type: "INIT_USER_STATE_DATA",
            payload: {
                ...response.userState,
                selectedRoundId: response.userState.currentRoundId
            }
        });
        
        const colorProfileFormattedData = formatColorProfiles(response.colorProfiles, response.storyline.colorProfileId);
        dispatch({
            type: "INIT_COLOR_PROFILE_DATA",
            payload: colorProfileFormattedData
        });

        const imagesPathFile = getImagesPathFile('DT-1');
        dispatch({
            type: "SET_IMAGES_FOR_STORYLINE",
            payload: imagesPathFile
        });

        dispatch({
            type: 'SET_EVENTS',
            payload: response.eventsData
        });

        dispatch({
            type: 'SET_USER_EVENTS',
            payload: response.userEvents
        });

        dispatch({
            type: "UPDATE_USER_IDEAS",
            payload: checkIfPresent(response.userIdeas) ? response.userIdeas : []
        });

        dispatch(replayAllReducerActions(response.userState))

        // dispatch({
        //     type: 'REPLAY_REDUCER_ACTIONS',
        //     payload: {}
        // });

    });
