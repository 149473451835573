import React from 'react';
import styles from './insufficientFundsBar.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class InsufficientFundsBar extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        let style = {};
        if(checkIfPresent(this.props.propStyles)) {
            style = this.props.propStyles;
        }

        return (
            <div className={css(myStyles.barContainer)} styleName="bar-container"
                style={{
                    ...style
                }}
            >
                {this.props.getLabel('label_insufficient_funds')}
            </div>
        );
    }
}

export default applyWrappers(InsufficientFundsBar, styles);