import React from 'react';
import styles from './eventAlertNegative.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class EventAlertNegative extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 165 123">
                    <g fill="none" fill-rule="nonzero" transform="translate(-.1 .781)">
                        <path fill="#FFF" d="M1.926 58.257C4.532 68.31 10.043 77 18.752 81.213c18.104 8.755 58.936 4.24 89.41-.944 15.763-2.68 29.619-10.078 39.684-20.489L1.926 58.257zM161.73 10.937h-16.72c-.794 0-1.445-.588-1.445-1.305s.651-1.306 1.445-1.306h2.892c-.795 0-1.445-.587-1.445-1.306 0-.718.65-1.304 1.445-1.304h-2.064c-.796 0-1.446-.587-1.446-1.306 0-.719.65-1.304 1.446-1.304h8.727c-1.937-.813-4.2-1.391-6.809-1.695-23.797-2.76-32.293-.935-35.29.942a35.212 35.212 0 0 1-12.231 4.843c-14.683 2.736-42.14 6.027-67.026-1.049C20.839 2.624 11.646 8.57 6.136 18.511h158.498c-.207-2.889-.826-5.557-1.963-7.892-.27.208-.6.32-.941.318zM.508 37.274a60.183 60.183 0 0 0 .649 17.539l146.802 4.85a62.183 62.183 0 0 0 10.2-14.05L.508 37.273zm58.063 12.512H55.68c.795 0 1.446.588 1.446 1.305 0 .718-.651 1.305-1.446 1.305H38.96c-.794 0-1.445-.587-1.445-1.305 0-.717.651-1.305 1.444-1.305h2.892c-.794 0-1.444-.587-1.444-1.305 0-.717.65-1.306 1.445-1.306h-2.066c-.795 0-1.446-.587-1.446-1.304 0-.717.651-1.306 1.446-1.306h16.72c.795 0 1.445.587 1.445 1.306 0 .719-.65 1.304-1.444 1.304h2.064c.794 0 1.444.587 1.444 1.306 0 .72-.65 1.305-1.444 1.305zM164.64 18.6L4.368 22.12c-1.95 4.527-3.243 9.646-3.839 14.95h161.303a56.723 56.723 0 0 0 2.328-9.529c.482-3.116.673-6.13.48-8.94zM33.07 30.14h-2.89c.795 0 1.446.588 1.446 1.305 0 .718-.651 1.306-1.446 1.306H13.46c-.794 0-1.445-.588-1.445-1.306 0-.717.651-1.306 1.444-1.306h2.893c-.796 0-1.445-.587-1.445-1.304 0-.717.65-1.306 1.445-1.306h-2.066c-.795 0-1.446-.587-1.446-1.304 0-.717.651-1.306 1.446-1.306h16.72c.796 0 1.445.587 1.445 1.306 0 .719-.65 1.304-1.445 1.304h2.065c.794 0 1.444.589 1.444 1.306 0 .717-.65 1.304-1.444 1.304z" opacity=".2"/>
                        <ellipse cx="81.912" cy="110.812" fill="#FFF" opacity=".2" rx="67.047" ry="6.158"/>
                        <path fill="#000" d="M126.86 42.554c.03-.059.057-.12.08-.181.17-.47.095-.987.097-1.487 0-.167.015-.334.046-.497-.138.295-.206.62-.197.946-.001.403.047.825-.026 1.219zM126.362 67.53l-.468 2.079c.107-1.05.257-1.804.468-2.08z" opacity=".1"/>
                        <ellipse cx="122.002" cy="63.457" fill="#575A89" rx="1.35" ry="1"/>
                        <ellipse cx="77.999" cy="68.106" fill="#FFF8F9" rx="46.275" ry="46.199"/>
                        <path fill="#FF5974" d="M102.279 91.323c0 7.293-11.484.623-23.796.623s-24.765 6.394-24.765-.899c0-4.158 3.969-9.942 9.663-14.142 4.29-3.166 9.558-5.434 14.85-5.434 5.442 0 10.773 2.455 15.026 5.824 5.367 4.249 9.022 9.957 9.022 14.028z"/>
                        <path fill="#F6F8FB" d="M93.255 77.292c-6.544-.984-18.381-2.183-29.874-.39 4.29-3.166 9.558-5.434 14.85-5.434 5.44 0 10.772 2.455 15.024 5.824z"/>
                        <path fill="#000" d="M53.718 35.495s-13.94 8.896-13.826 22.813c.114 13.917 13.826-22.813 13.826-22.813z" opacity=".05"/>
                        <path fill="#FFF" d="M124.255 10.367s-12.63-.759-11.14 8.077c0 0-.3 1.56 1.122 2.27 0 0 .023-.654 1.294-.433a5.86 5.86 0 0 0 1.373.066 2.852 2.852 0 0 0 1.677-.692s3.55-1.463 4.932-7.257c0 0 1.021-1.264.981-1.587l-2.132.907s.728 1.535.15 2.811c0 0-.069-2.754-.478-2.695-.083.013-1.107.531-1.107.531s1.252 2.675.3 4.617c0 0 .358-3.295-.699-4.424l-1.5.875s1.464 2.761.471 5.015c0 0 .255-3.456-.786-4.802l-1.36 1.058s1.375 2.723.537 4.592c0 0-.11-4.024-.832-4.328 0 0-1.189 1.048-1.37 1.478 0 0 .941 1.975.358 3.017 0 0-.359-2.68-.653-2.695 0 0-1.184 1.776-1.309 2.995 0 0 .052-1.812 1.022-3.164 0 0-1.146.196-1.814.939 0 0 .184-1.257 2.1-1.366 0 0 .98-1.348 1.242-1.429 0 0-1.912-.16-3.072.354 0 0 1.02-1.185 3.422-.647l1.35-1.093s-2.517-.345-3.585.035c0 0 1.228-1.048 3.946-.284l1.463-.873s-2.147-.461-3.427-.3c0 0 1.35-.726 3.854.062l1.05-.469s-1.574-.308-2.034-.356c-.46-.048-.484-.177-.484-.177a5.46 5.46 0 0 1 2.959.343s2.217-.831 2.18-.97zM111.302 6.44s-5.7-.345-5.035 3.647c0 0-.133.706.508 1.026 0 0 .008-.3.584-.194.205.033.413.043.62.03.28-.02.546-.13.759-.313 0 0 1.603-.662 2.228-3.28 0 0 .462-.57.45-.717l-.963.41s.33.693.07 1.27c0 0-.031-1.245-.216-1.215-.038 0-.501.24-.501.24s.567 1.208.14 2.086c0 0 .162-1.489-.316-2l-.677.396s.661 1.25.212 2.267c0 0 .114-1.562-.355-2.17l-.615.478s.621 1.23.243 2.074c0 0-.05-1.818-.377-1.956 0 0-.537.474-.619.668 0 0 .426.899.162 1.365 0 0-.162-1.212-.3-1.216 0 0-.535.8-.591 1.347a2.914 2.914 0 0 1 .46-1.43 1.607 1.607 0 0 0-.819.424s.083-.567.951-.617c0 0 .443-.608.561-.645 0 0-.864-.072-1.387.16 0 0 .46-.536 1.546-.3l.6-.492s-1.136-.15-1.62.015c0 0 .556-.474 1.784-.128l.66-.395s-.969-.208-1.548-.133c0 0 .61-.328 1.742.028l.472-.212s-.711-.14-.918-.16c-.207-.022-.22-.08-.22-.08.45-.075.912-.023 1.334.15 0 0 1.007-.366.99-.429z"/>
                        <ellipse cx="161.113" cy="108.64" fill="#FFF" opacity=".4" rx="4.04" ry="1"/>
                        <ellipse cx="7.165" cy="119.975" fill="#FFF" opacity=".4" rx="4.04" ry="1"/>
                        <ellipse cx="30.615" cy="120.83" fill="#FFF" opacity=".4" rx="4.04" ry="1"/>
                        <ellipse cx="152.273" cy="115.942" fill="#FFF" rx="6.032" ry="1.018"/>
                        <path fill="#FFF" d="M154.766 114.279c.275-.225.476-.527.576-.868.073-.342-.074-.756-.403-.882-.368-.14-.763.116-1.061.373-.299.258-.642.551-1.034.497.41-.37.594-.928.486-1.469a.617.617 0 0 0-.135-.3c-.206-.218-.576-.125-.822.047-.78.548-.998 1.606-1.002 2.558-.078-.343-.012-.7-.014-1.048-.001-.348-.099-.749-.395-.933a1.202 1.202 0 0 0-.6-.14c-.351-.014-.742.02-.981.276-.3.32-.221.852.038 1.198.26.346.653.57 1.014.812.291.173.54.408.727.69.021.04.04.081.054.125h2.197c.492-.25.947-.565 1.355-.936zM9.051 101.722s.823 1.075-.381 2.695c-1.205 1.62-2.197 2.995-1.8 4.008 0 0 1.817-3.016 3.3-3.06 1.483-.043.507-1.83-1.119-3.643z"/>
                        <path fill="#000" d="M9.051 101.722c.072.104.128.217.168.337 1.443 1.692 2.211 3.272.825 3.31-1.293.038-2.85 2.344-3.215 2.927.013.045.028.09.046.133 0 0 1.816-3.016 3.3-3.06 1.483-.043.502-1.834-1.124-3.647z" opacity=".1"/>
                        <path fill="#FFD037" d="M10.582 103.092c0 .379-.043.686-.096.686-.052 0-.094-.3-.094-.686 0-.386.053-.2.107-.2.053 0 .083-.179.083.2z"/>
                        <path fill="#FFD037" d="M11.101 103.544c-.333.181-.622.29-.648.244-.025-.046.226-.229.559-.41.332-.181.2-.05.226 0 .026.05.203-.015-.136.166z"/>
                        <path fill="#FFF" d="M4.698 101.722s-.824 1.075.38 2.695c1.202 1.62 2.197 2.995 1.8 4.008 0 0-1.819-3.016-3.3-3.06-1.482-.043-.507-1.83 1.12-3.643z"/>
                        <path fill="#000" d="M4.698 101.722a1.292 1.292 0 0 0-.168.337c-1.443 1.692-2.213 3.272-.825 3.31 1.292.038 2.85 2.344 3.213 2.927-.012.045-.027.09-.043.133 0 0-1.819-3.016-3.3-3.06-1.483-.043-.505-1.834 1.123-3.647z" opacity=".1"/>
                        <path fill="#FFF" d="M3.167 103.092c0 .379.042.686.094.686s.095-.3.095-.686c0-.386-.053-.2-.105-.2-.053 0-.084-.179-.084.2z"/>
                        <path fill="#FFD037" d="M2.64 103.544c.333.181.624.29.65.244.025-.046-.226-.229-.558-.41-.334-.181-.203-.05-.227 0-.024.05-.198-.015.135.166z"/>
                        <path fill="#ECECEC" d="M3.429 108.288s2.304-.07 3-.564c.696-.494 3.544-1.083 3.717-.3.172.784 3.462 3.939.861 3.96-2.601.02-6.043-.406-6.736-.827-.693-.42-.842-2.269-.842-2.269z"/>
                        <path fill="#000" d="M11.05 111.116c-2.6.02-6.043-.406-6.736-.827-.528-.32-.738-1.472-.808-2.004h-.077s.15 1.856.839 2.277c.688.42 4.135.847 6.736.826.75 0 1.01-.274.996-.668-.105.241-.39.391-.95.396z" opacity=".2"/>
                        <path fill="#FF5974" d="M61.547 62.049c.88-.05 5.025-.361 7.657-1.86.423-.248.9-.389 1.39-.409.838-.024 1.8.325 1.184 2.278-.999 3.194-6.8 6.788-10.4 6.59-3.462-.193-7.569-1.585-10.28-6.482a2.991 2.991 0 0 1-.371-1.541c.032-.989.392-2.227 2.25-.846 2.511 1.864 7.275 2.218 8.23 2.27.113.006.227.006.34 0zM94.847 62.049c.88-.05 5.025-.361 7.657-1.86.423-.248.9-.389 1.39-.409.837-.024 1.8.325 1.184 2.278-.999 3.194-6.8 6.788-10.4 6.59-3.462-.193-7.569-1.585-10.28-6.482a2.991 2.991 0 0 1-.371-1.541c.031-.989.391-2.227 2.25-.846 2.511 1.864 7.275 2.218 8.23 2.27.113.006.227.006.34 0z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(EventAlertNegative, styles);