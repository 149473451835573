import React from 'react';
import styles from './eventsAlert.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import EventAlertBackground from 'svgComponents/eventAlertBackground';
import EventAlertPositive from 'svgComponents/eventAlertPositive';
import EventAlertNegative from 'svgComponents/eventAlertNegative';
import {getCeoDetails, checkIfPresent} from 'util/utilFunctions';
import CeoEventImage from 'svgComponents/ceoEventImage';

class EventsAlert extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            timer: 5
        }
        this.timerFunction = null;
    }

    renderCeoTag = (myStyles) => {
        const teamMemberDetails = getCeoDetails(this.props.teamMembers.get('teamList'));
        if(checkIfPresent(teamMemberDetails)) {
            return (
                <div styleName="ceo-tag-container">
                    <div  styleName="ceo-image-container">
                        <div styleName="ceo-image">
                            <img alt={teamMemberDetails.get('name')} src={this.props.getImage(teamMemberDetails.get('image_full'))} width="100%" height="100%" />
                        </div>
                    </div>
                    <div styleName="ceo-details-container">
                        <div className={css(myStyles.ceoDetailsName)} styleName="ceo-name">{this.props.getLabel(teamMemberDetails.get('name'))}</div>
                        <div className={css(myStyles.ceoDetailsDesg)} styleName="ceo-designation">
                            {this.props.getLabel(teamMemberDetails.get('role'))}
                            {/* {this.props.getLabel('label_designation_with_company', '', {
                                DESIGNATION: this.props.getLabel(teamMemberDetails.get('role'))
                            })} */}
                        </div>
                    </div>
                </div>
            );
        }
        return (this.props.getLabel('label_ceo_with_company'));
    }

    componentDidMount()  {
        this.timerFunction = setInterval(() => {
            this.setState({
                timer: this.state.timer - 1
            });
            if(this.state.timer <= 0) {
                let eventsToShow = this.props.events.get('eventList').toJS();
                
                eventsToShow = eventsToShow.filter((eachEvent) => {
                    return (
                        eachEvent.type === 'time'
                        && eachEvent.event_messages.length > 0
                        && eachEvent.preparingToShowToUser
                        // && eachEvent.isShownToUser === false
                    );
                });
                if(eventsToShow.length > 0) {
                    
                    this.props.updateEvent({
                        id: eventsToShow[0].id,
                        preparingToShowToUser: false,
                        isShownToUser: true
                    });
                    this.props.setUIState({
                        showEventAlert: false,
                        isBluredRightOverlay: true,
                        popupComponentBluredOverlay: 'EVENTS'
                    });
                }
                clearInterval(this.timerFunction);
            }
        }, 1000);
    }

    onClickOpenMessage = (event) => {
        clearInterval(this.timerFunction);
        this.props.updateEvent({
            id: event.id,
            preparingToShowToUser: false,
            isShownToUser: true
        });
        this.props.setUIState({
            showEventAlert: false,
            isBluredRightOverlay: true,
            popupComponentBluredOverlay: 'EVENTS'
        });
    }

    getEventColorType = (event) => {
        if(
            (event.revenueScale == 0
            && event.ceScale == 0
            && event.budgetScale == 0)
            || event.isMessage == true
        ) {
            return 'NEUTRAL';
        }

        let type = 'POSITIVE';
        if(event.revenueScale < 0) {
            type = 'NEGATIVE';
        } else if(event.ceScale < 0) {
            type = 'NEGATIVE';
        } else if(event.budgetScale < 0) {
            type = 'NEGATIVE';
        }
        return type;
    }
    
    renderEventAlert = () => {
        const myStyles = getSkin(this.props.skinGuide);

        let eventsToShow = this.props.events.get('eventList').toJS();
        let eventType = "";
        
        eventsToShow = eventsToShow.filter((eachEvent) => {
            return (eachEvent.type === 'time' && eachEvent.event_messages.length > 0 && eachEvent.preparingToShowToUser)
        });
        if(eventsToShow.length > 0) {
            eventType = this.getEventColorType(eventsToShow[0]);

            if(eventType === 'NEUTRAL') {

                let ceoEventMessage = '';
                eventsToShow[0].event_messages.map((eventMessage) => {
                    if(checkIfPresent(eventMessage.message)) {
                        ceoEventMessage += this.props.getLabel(eventMessage.message);
                    }
                });

                return (
                    <div className={css(myStyles.container)} styleName="event-alert-container">
                        <div styleName="event-alert-background">
                            <EventAlertBackground eventType={eventType} />
                        </div>
                        <div styleName="event-alert-content padding-ceo">
                            <div className={css(myStyles.ceoEventHeading)} styleName="ceo-message-heading">
                                {this.props.getLabel('label_ceo_message_heading')}
                            </div>
                            <div styleName="ceo-container">
                                <div styleName="ceo-details">
                                    {this.renderCeoTag(myStyles)}
                                </div>
                                <div styleName="ceo-event-image">
                                    <CeoEventImage />
                                </div>
                            </div>
                            <div className={css(myStyles.ceoMessageText)} styleName="ceo-event-message-content">
                                {ceoEventMessage}
                            </div>
                            <div className={css(myStyles.opensInMessage)} styleName="event-alert-opensin-message">
                                {this.props.getLabel('label_message_opens_in', '', {
                                    TIMER: this.state.timer
                                })}
                            </div>
                            <div styleName="event-alert-button-container">
                                <div className={css(myStyles.openButton, myStyles.buttonText)} styleName="event-alert-open-button"
                                    onClick={() => {
                                        this.onClickOpenMessage(eventsToShow[0])
                                    }}
                                >
                                    {this.props.getLabel('label_open_message')}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div className={css(myStyles.container)} styleName="event-alert-container">
                    <div styleName="event-alert-background">
                        <EventAlertBackground eventType={eventType} />
                    </div>
                    <div styleName="event-alert-content">
                        <div styleName="event-alert-image">
                            {
                                eventType === 'POSITIVE'
                                ?   <EventAlertPositive />
                                :   <EventAlertNegative />
                            }
                        </div>
                        <div className={css(myStyles.alertMessage)} styleName="event-alert-message1">
                            {
                                eventType === 'POSITIVE'
                                ?   this.props.getLabel('label_event_alert_positive_message1')
                                :   this.props.getLabel('label_event_alert_negative_message1')
                            }
                        </div>
                        <div className={css(myStyles.alertMessage)} styleName="event-alert-message2">
                            {
                                eventType === 'POSITIVE'
                                ?   this.props.getLabel('label_event_alert_positive_message2')
                                :   this.props.getLabel('label_event_alert_negative_message2')
                            }
                        </div>
                        <div className={css(myStyles.opensInMessage)} styleName="event-alert-opensin-message">
                            {this.props.getLabel('label_message_opens_in', '', {
                                TIMER: this.state.timer
                            })}
                        </div>
                        <div styleName="event-alert-button-container">
                            <div className={css(myStyles.openButton, myStyles.buttonText)} styleName="event-alert-open-button"
                                onClick={() => {
                                    this.onClickOpenMessage(eventsToShow[0])
                                }}
                            >
                                {this.props.getLabel('label_open_message')}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.popupContainer)} styleName="popup-container">
				<div className={css(myStyles.popupBackground)} styleName="popup-background"></div>
				<div styleName="popup-content">
					{this.renderEventAlert()}
				</div>
			</div>
		);
	}
}

export default applyWrappers(EventsAlert, styles);