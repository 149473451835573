import dtOneImages from 'constants/images/dtOneImages';
import dtTwoImages from 'constants/images/dtTwoImages';

const getImagesPathFile = (storylineFile) => {
    switch(storylineFile){
        case 'DT-1':
            return dtOneImages;
        case 'DT-2':
            return dtTwoImages;
        default:
            return dtOneImages;
    }
}

export {
    getImagesPathFile
}