import consts from 'constants/url';
import kfetch from '../util/kfetch';
import updateIdea from 'actions/ideas/actionCreators';

export const postPrioritizeIdea = (payload) => (dispatch) => kfetch(consts.get('POST_PRIORITIZE_IDEA'), payload, 'POST')
    .then((response) => {
        dispatch(updateIdea({
                id: payload.ideaId,
            }
        ));
    });