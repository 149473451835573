import { StyleSheet } from 'aphrodite';
import { typography, getCurrentEntityPalette } from 'util/styleUtil';

const getColorPalette = (skinGuide, id) => {
	const entityProfiles = skinGuide.get('entityProfiles');
	const currentEntityPalette = getCurrentEntityPalette(entityProfiles, id);

	return StyleSheet.create({
		cardGradientBackground: {
			backgroundImage: `linear-gradient(to right, ${currentEntityPalette.get('primaryColor')}, ${currentEntityPalette.get('secondaryColor')})`,
			borderTopLeftRadius: '5px',
			borderTopRightRadius: '5px',

		}
		
	});
}

export default getColorPalette;