import React from 'react';
import styles from './ideaDfvRefinePopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import TagsList from 'commonComponents/tagsList';
import CardRecommendation from 'commonComponents/cardRecommendation';
import FilledButton from 'commonComponents/buttons/filledButton';
import DoubleQuotes from 'svgComponents/doubleQuotes';
import HollowButton from 'commonComponents/buttons/hollowButton';
import NotUnlockedDfv from 'svgComponents/notUnlockedDfv';
import DfvExpanded from  'commonComponents/dfvExpanded';
import RefineIdea from 'svgComponents/refineIdea';
import CloseIcon from 'svgComponents/closeIcon';
import InsufficientFundsBar from 'commonComponents/insufficientFundsBar';
import ChangeInValue from 'commonComponents/changeInValue';
import {
    checkIfPresent,
    getSegment,
    getTouchPoint,
    getCurrentIdea,
    calculateAverageScore,
    getTeamMember,
    getArrowType,
    getPersona,
    checkWithBudget,
    checkIfIEOrEdge
} from 'util/utilFunctions';
import TestingLoading from 'svgComponents/testingLoading';
import {getFormattedCurrency} from 'util/currencyFormatter';
import analyticsUtil from 'util/segmentUtil';
class IdeaDfvRefinePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'TEST_REPORT' //'DFV_SCORE' or 'TEST_REPORT'
        }
    }

    onClickOfClosePopup = () => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "IDEA_DFV_REFINE_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    onClickShortListIdea = (currentIdea) => {
        if(currentIdea.isNormalIdea) {
            this.props.postActionLog({
                type: 'UPDATE_IDEA',
                payload: {
                    id: currentIdea.id,
                    isNormalIdea: false,
                    isShortlistedIdea: true,
                    timeStamp: new Date().getTime()
                }
            });
        } else {
            this.props.postActionLog({
                type: 'UPDATE_IDEA',
                payload: {
                    id: currentIdea.id,
                    isNormalIdea: true,
                    isShortlistedIdea: false,
                    timeStamp: new Date().getTime()
                }
            });
        }
        this.props.setUIState({
            popupComponent: '',
            isOverlayOpen: false
        });
    }

    onClickUnlockDfv = (currentIdea) => {
        this.props.setUIState({
            dfvButtonLoading: true
        });
        this.props.postUnlockDFV({
            ideaId: currentIdea.id,
            roundId: this.props.userState.get('currentRoundId'),
            reducerAction: {
                type: 'UPDATE_IDEA',
                key: 'dfv_response',
                payload: {
                    id: currentIdea.id,
                    dfv_unlocked: true
                }
            }
        });
    }

    onClickRefineIdea = (currentIdea) => {
        this.props.setUIState({
            refineIdeaButtonLoading: true
        });
        this.props.postDigdeepDFV({
            ideaId: currentIdea.id,
            roundId: this.props.userState.get('currentRoundId'),
            reducerAction: {
                type: 'UPDATE_IDEA',
                key: 'digdeep_response',
                payload: {
                    id: currentIdea.id,
                    dfv_refined: true
                }
            }
        });
    }

    onClickPrototypeIdea = (currentIdea) => {
        this.props.setUIState({
            isOverlayOpen: true,
            popupComponent: 'PROTOTYPE_CONFIRMATION_POPUP'
        });
    }

    onClickMayBeLaterPrototype = () => {
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: ''
        });
    }

    onClickMoveToTestingIdea = (currentIdea) => {
        if(currentIdea.isShortlistedForExecution) {
            this.props.postTestPrototype({
                ideaId: currentIdea.id,
                roundId: this.props.userState.get('currentRoundId'),
                reducerAction: {
                    type: 'UPDATE_IDEA_TEST_REPORT',
                    key: 'testPrototypeResponse',
                    payload: {
                        id: currentIdea.id,
                        isShortlistedPrototype: false,
                        isShortlistedForTesting: true,
                        isTestingDone: true,
                        roundId: this.props.userState.get('currentRoundId')
                    }
    
                }
            });
        } else {
            this.props.postTestPrototype({
                ideaId: currentIdea.id,
                roundId: this.props.userState.get('currentRoundId'),
                reducerAction: {
                    type: 'UPDATE_IDEA_TEST_REPORT',
                    key: 'testPrototypeResponse',
                    payload: {
                        id: currentIdea.id,
                        isShortlistedPrototype: false,
                        isShortlistedForTesting: true,
                        isTestingDone: true,
                        timeStamp: new Date().getTime()
                    }
    
                }
            });
        }
    }

    onClickMoveToExecutionIdea = (currentIdea) => {
        this.props.postActionLog({
            type: 'UPDATE_IDEA',
            payload: {
                id: currentIdea.id,
                isShortlistedPrototype: false,
                isShortlistedForTesting: false,
                isShortlistedForExecution: true,
                timeStamp: new Date().getTime()
            }
        });
        // this.props.updateIdea({
        //     id: currentIdea.id,
        //     isShortlistedPrototype: false,
        //     isShortlistedForTesting: false,
        //     isShortlistedForExecution: true
        // });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: ''
        });
    }

    onClickRemoveFromExecutionList = (currentIdea) => {
        if(currentIdea.isTestingDone) {
            this.props.postActionLog({
                type: 'UPDATE_IDEA',
                payload: {
                    id: currentIdea.id,
                    isShortlistedForExecution: false,
                    isShortlistedForTesting: true,
                    timeStamp: new Date().getTime()
                }
            });
        } else {
            this.props.postActionLog({
                type: 'UPDATE_IDEA',
                payload: {
                    id: currentIdea.id,
                    isShortlistedForExecution: false,
                    isShortlistedPrototype: true,                
                    timeStamp: new Date().getTime()
                }
            });
        }
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: ''
        });
    }

    getTagArray = (idea) => {
        const tagArray = [];
        const {segments, touchPoints} = this.props;
        const segmentName = this.props.getLabel(getSegment(segments.get('segmentList'), idea.segmentId).get('name'));
        const touchPointName = this.props.getLabel(getTouchPoint(touchPoints.get('touchPointList'), idea.touchPointId).get('name'));
        tagArray.push(segmentName);
        tagArray.push(touchPointName);
        tagArray.push(this.props.getLabel('label_quarter_number', "", {
            NUMBER: idea.roundId
        }));

        return tagArray;
    }

    renderBorder = (myStyles) => {
        return (
            <div className={css(myStyles.borderContainer)} styleName="border-container"></div>
        );
    }

    renderRefinedImage = (myStyles, currentIdea) => {
        if(currentIdea.dfv_refined === true || currentIdea.isTestingDone === true) {
            return (
                <div styleName="refined-image">
                    <RefineIdea />
                </div>
            );
        }
        return null;
    }

    renderRefinedTag = (myStyles, currentIdea) => {
        if(currentIdea.dfv_refined === true || currentIdea.isTestingDone === true) {
            return (
                <div styleName="refined-tag-container">
                    <div className={css(myStyles.refinedTagContent)} styleName="refined-tag-content">
                        {
                            currentIdea.dfv_refined === true
                            ?   currentIdea.isTestingDone === true
                                ?   this.props.getLabel('label_idea_refined_tested')
                                :   this.props.getLabel('label_idea_refined')
                            :   currentIdea.isTestingDone === true
                                ?   this.props.getLabel('label_idea_tested')
                                :   ''
                        }
                    </div>
                </div>
            );
        }
        return null;
    }

    renderHeadingContent = (myStyles, currentIdea) => {

        let suggestions = [];

        if(this.props.userState.get('currentPhaseKey') === 'ideate') {
            suggestions = this.props.userPhasesState.get('userIdeate').get('suggestions').ideaSuggestions;
        } else if(this.props.userState.get('currentPhaseKey') === 'prototype') {
            suggestions = this.props.userPhasesState.get('userPrototype').get('suggestions').ideaSuggestions;
        } else if(this.props.userState.get('currentPhaseKey') === 'test') {
            suggestions = this.props.userPhasesState.get('userTestPrototype').get('suggestions').ideaSuggestions;
        }


        let ideaSuggestions = [];

        if(checkIfPresent(suggestions)) {
            ideaSuggestions = suggestions.filter((eachSuggestion) => {
                return (eachSuggestion.ideaId === currentIdea.id);
            });
        }

        let suggestionArray = [];
        ideaSuggestions.map((eachIdeaSuggestion) => {
            const teamMemberDetails = getTeamMember(this.props.teamMembers.get('teamList'), eachIdeaSuggestion.teamMemberId);
            if(checkIfPresent(teamMemberDetails)) {
                suggestionArray.push({
                    image: teamMemberDetails.get('image_full'),
                    text: this.props.getLabel(eachIdeaSuggestion.suggestion),
                });
            }
            return 1;
        });

        const dontShowSuggestions = currentIdea.isTestingDone || currentIdea.isShortlistedForExecution;

        return (
            <div styleName="heading-container">
                <div className={css(myStyles.headingMain)} styleName="heading-main">
                    {this.props.getLabel(currentIdea.name)}
                </div>
                <div className={css(myStyles.headingDesc)} styleName="heading-desc">
                    {this.props.getLabel(currentIdea.description)}
                </div>
                <div styleName="heading-tags">
                    <TagsList
                        tagsList={this.getTagArray(currentIdea)}
                        tagColor='#ededed'
                        />
                </div>
                {
                    dontShowSuggestions
                    ?   null
                    :   <div styleName="heading-recs">
                            <CardRecommendation
                                recommendationList={suggestionArray}
                                />
                        </div>
                }
            </div>
        );
    }

    renderDfvScoreBorder = (myStyles, currentIdea) => {
        return (
            <div styleName="dfv-card-container">
                <div className={css(myStyles.dfvCard)} styleName="dfv-card">
                    <div styleName="double-quotes-top">
                        <DoubleQuotes/>
                    </div>
                    <div styleName="double-quotes-bottom">
                        <DoubleQuotes/>
                    </div>
                    {this.renderDfvScoreComponent(myStyles, currentIdea)}
                </div>
            </div>
        );
    }

    renderTestReportComponent = (myStyles, currentIdea) => {
        if(currentIdea.showTestReportLoading === true) {
            let image = checkIfIEOrEdge() ? "IMG_TEST_CONTAINER_LOADING_IE" : "IMG_TEST_REPORT_LOADING";
            return (
                <div styleName="test-report-container-loading">
                    <div styleName="not-unlocked-image">
                        <img src={this.props.getImage(image)} width="100%" height="100%" />
                        
                    </div>
                </div>
            );
        }
        if(currentIdea.isTestingDone === true) {
            const quadrants = this.props.testQuadrants.get('quadrantList').toJS().map((eachQuadrant) => {
                const responses = currentIdea.testPrototypeResponse.testReport.map((eachResponse) => {
                    if(eachResponse.testQuadrantId === eachQuadrant.id) {
                        const personaDetails = getPersona(this.props.personas.get('personaList'), eachResponse.personaId)
                        return (
                            <div styleName="each-response-container">
                                <div styleName="each-response-image">
                                    <img src={this.props.getImage(personaDetails.get('image_full'))} width="100%" height="100%" />
                                </div>
                                <div className={css(myStyles.responseContent)} styleName="each-response-content">
                                    {this.props.getLabel(eachResponse.suggestion)}
                                </div>
                            </div>
                        );
                    }
                    return null;
                });
                return (
                    <div key={eachQuadrant.key} className={css(myStyles.eachQuadrantContainer)} styleName="each-quadrant-container">
                        <div className={css(myStyles[eachQuadrant.key])} styleName="each-quadrant-heading">
                            {this.props.getLabel(eachQuadrant.name)}
                        </div>
                        <div styleName="each-quadrant-response-content">
                            {responses}
                        </div>
                    </div>
                );
            });
            const dScoreTest = currentIdea.testPrototypeResponse.dfv.Dscore;
            const fScoreTest = currentIdea.testPrototypeResponse.dfv.Fscore;
            const vScoreTest = currentIdea.testPrototypeResponse.dfv.Vscore;
            let dScore = 0;
            let fScore = 0;
            let vScore = 0;
            let ideaType = 'NOT_UNLOCKED';
            if(currentIdea.dfv_unlocked === true && currentIdea.dfv_refined === false) {
                ideaType = 'NOT_REFINED';
                dScore = calculateAverageScore(currentIdea.dfv_response.dScores, 'dScore');
                fScore = calculateAverageScore(currentIdea.dfv_response.fScore, 'fScore');
                vScore = calculateAverageScore(currentIdea.dfv_response.vScore, 'vScore');
            } else if(currentIdea.dfv_unlocked === true && currentIdea.dfv_refined === true) {
                ideaType = 'REFINED';
                dScore = calculateAverageScore(currentIdea.digdeep_response.dScores, 'dScoreDD');
                fScore = calculateAverageScore(currentIdea.digdeep_response.fScore, 'fScoreDD');
                vScore = calculateAverageScore(currentIdea.digdeep_response.vScore, 'vScoreDD');
            }
            return (
                <div styleName="test-report-container">
                    <div className={css(myStyles.dfvContainer)} styleName="dfv-container">
                        {
                            ideaType === 'NOT_UNLOCKED'
                            ?   <DfvExpanded
                                    valueD={dScoreTest}
                                    valueF={fScoreTest}
                                    valueV={vScoreTest}
                                    showChange={false}
                                />
                            :   ideaType === 'NOT_REFINED'
                                ?   <DfvExpanded
                                        valueD={dScoreTest}
                                        valueF={fScoreTest}
                                        valueV={vScoreTest}
                                        showChange={true}
                                        changeDScore={dScoreTest - dScore}
                                        changeFScore={fScoreTest - fScore}
                                        changeVScore={vScoreTest - vScore}
                                    />
                                :   <DfvExpanded
                                        valueD={dScoreTest}
                                        valueF={fScoreTest}
                                        valueV={vScoreTest}
                                        showChange={true}
                                        changeDScore={dScoreTest - dScore}
                                        changeFScore={fScoreTest - fScore}
                                        changeVScore={vScoreTest - vScore}
                                    />
                        }
                    </div>
                    <div styleName="test-report-content">
                        {quadrants}
                    </div>
                </div>
            );
        } else {
            return (
                <div styleName="not-unlocked-container">
                    <div styleName="not-unlocked-image"><TestingLoading /></div>
                    <div className={css(myStyles.notUnlockDesc)} styleName="not-unlocked-desc">
                        {this.props.getLabel('label_test_report_users_desc')}
                    </div>
                    <div styleName="unlock-button">
                        {
                            checkWithBudget(currentIdea.cost.testingCost, this.props.metrics)
                            ?   null
                            :   <InsufficientFundsBar />
                        }
                        <HollowButton
                            clickFunction={() => {
                                this.onClickMoveToTestingIdea(currentIdea);
                            }}
                            textLabel={this.props.getLabel('label_test_report_button', "", {
                                CURRENCY: '',
                                COST: getFormattedCurrency(currentIdea.cost.testingCost, 'USD')
                            })}
                            disableButton={checkWithBudget(currentIdea.cost.testingCost, this.props.metrics) ? false : true}
                        />
                    </div>
                </div>
            );
        }
    }

    renderDfvScoreWithTestReportBorder = (myStyles, currentIdea) => {
        return (
            <div styleName="dfv-test-card-container">
                <div styleName="tab-container">
                    <div
                        className={this.state.currentTab === 'DFV_SCORE' ? css(myStyles.eachTabHighlight) : css(myStyles.eachTab)}
                        styleName="each-tab"
                        onClick={() => {
                            this.setState({
                                currentTab: 'DFV_SCORE'
                            });
                        }}
                    >
                        {this.props.getLabel('label_dfv_score')}
                    </div>
                    <div
                        className={this.state.currentTab === 'TEST_REPORT' ? css(myStyles.eachTabHighlight) : css(myStyles.eachTab)}
                        styleName="each-tab"
                        onClick={() => {
                            this.setState({
                                currentTab: 'TEST_REPORT'
                            });
                        }}
                    >
                        {this.props.getLabel('label_test_report')}
                    </div>
                </div>
                <div styleName="tab-border"></div>
                {
                    this.state.currentTab === 'TEST_REPORT'
                    ?   this.renderTestReportComponent(myStyles, currentIdea)
                    :   null
                }
                {
                    this.state.currentTab === 'DFV_SCORE'
                    ?   <div className={css(myStyles.dfvCard)} styleName="dfv-card">
                            {this.renderDfvScoreComponent(myStyles, currentIdea)}
                        </div>
                    :   null
                }
            </div>
        );
    }

    renderDScoresRefined = (myStyles, scoreList, scoreKey, suggestionKey, oldScoreList, oldScoreKey, oldSuggestionKey) => {
        const responseDivs = scoreList.map((eachScore) => {
            const oldScore = oldScoreList.find((eachOldScore) => {
                return (eachOldScore.personaId === eachScore.personaId)
            });
            let change = 0;
            if(checkIfPresent(oldScore)) {
                change = eachScore[scoreKey] - oldScore[oldScoreKey];
            }
            return (
                <div>
                    <div styleName="persona-score-header">
                        {this.renderPersonaResponseHeader(myStyles, eachScore, scoreKey, this.props.getLabel('label_desirability_abbreviation'), change, true)}
                    </div>
                    <div className={css(myStyles.responseText)} styleName="persona-response-container">
                        {this.props.getLabel(eachScore[suggestionKey])}
                    </div>
                </div>
            );
        });
        return responseDivs;
    }

    renderDScores = (myStyles, scoreList, scoreKey, suggestionKey) => {
        const responseDivs = scoreList.map((eachScore) => {
            return (
                <div>
                    <div styleName="persona-score-header">
                        {this.renderPersonaResponseHeader(myStyles, eachScore, scoreKey, this.props.getLabel('label_desirability_abbreviation'), 0 , false)}
                    </div>
                    <div className={css(myStyles.responseText)} styleName="persona-response-container">
                        {this.props.getLabel(eachScore[suggestionKey])}
                    </div>
                </div>
            );
        });
        return responseDivs;
    }

    renderTeamResponseHeader = (myStyles, responseObj, scoreKey, text, changeValue = 0, showChange = false) => {
        const teamMemberDetails = getTeamMember(this.props.teamMembers.get('teamList'), responseObj.teamMemberId);
        if(checkIfPresent(teamMemberDetails)) {
            return (
                <div styleName="response-header-container">
                    <div styleName="column-flex-center">
                        <div styleName="response-image">
                            <img src={this.props.getImage(teamMemberDetails.get('image_full'))} width="100%" height="100%" />
                        </div>
                        <div className={css(myStyles.charName)}>
                            {this.props.getLabel(teamMemberDetails.get('short_name'))}
                        </div>
                    </div>
                    <div className={css(myStyles.responseHeaderText)} styleName="response-text-score">{text}&nbsp;-&nbsp;{responseObj[scoreKey]}</div>
                    {
                        showChange
                        ?   <div styleName="response-score-change">
                                <ChangeInValue arrowType={getArrowType(changeValue)} changeValue={changeValue} />
                            </div>
                        :   null
                    }
                </div>
            );
        }
        return null;
    }

    renderPersonaResponseHeader = (myStyles, responseObj, scoreKey, text, changeValue = 0, showChange = false) => {
        const personaDetails = getPersona(this.props.personas.get('personaList'), responseObj.personaId);
        if(checkIfPresent(personaDetails)) {
            return (
                <div styleName="response-header-container">
                    <div styleName="column-flex-center">
                        <div styleName="response-image">
                            <img src={this.props.getImage(personaDetails.get('image_full'))} width="100%" height="100%" />
                        </div>
                        <div className={css(myStyles.charName)}>
                            {this.props.getLabel(personaDetails.get('name')).split(" ", 1)}
                        </div>
                    </div>
                    <div className={css(myStyles.responseHeaderText)} styleName="response-text-score">{text}&nbsp;-&nbsp;{responseObj[scoreKey]}</div>
                    {
                        showChange
                        ?   <div styleName="response-score-change">
                                <ChangeInValue arrowType={getArrowType(changeValue)} changeValue={changeValue} />
                            </div>
                        :   null
                    }
                </div>
            );
        }
        return null;
    }

    renderDfvScoreComponent = (myStyles, currentIdea) => {
        let ideaType = 'NOT_UNLOCKED';
        let dScore = 0;
        let dScoreDD = 0;
        let changeDScore = 0;
        let fScore = 0;
        let fScoreDD = 0;
        let changeFScore = 0;
        let vScore = 0;
        let vScoreDD = 0;
        let changeVScore = 0;

        if(currentIdea.showDfvLoading === true) {
            ideaType = 'DFV_LOADING';
        } else if(currentIdea.dfv_unlocked === false) {
            ideaType = 'NOT_UNLOCKED';
        } else if(currentIdea.dfv_unlocked === true && currentIdea.dfv_refined === false) {
            ideaType = 'NOT_REFINED';
            dScore = calculateAverageScore(currentIdea.dfv_response.dScores, 'dScore');
            fScore = calculateAverageScore(currentIdea.dfv_response.fScore, 'fScore');
            vScore = calculateAverageScore(currentIdea.dfv_response.vScore, 'vScore');
        } else if(currentIdea.dfv_unlocked === true && currentIdea.dfv_refined === true) {
            ideaType = 'REFINED';
            dScore = calculateAverageScore(currentIdea.dfv_response.dScores, 'dScore');
            fScore = calculateAverageScore(currentIdea.dfv_response.fScore, 'fScore');
            vScore = calculateAverageScore(currentIdea.dfv_response.vScore, 'vScore');
            dScoreDD = calculateAverageScore(currentIdea.digdeep_response.dScores, 'dScoreDD');
            fScoreDD = calculateAverageScore(currentIdea.digdeep_response.fScore, 'fScoreDD');
            vScoreDD = calculateAverageScore(currentIdea.digdeep_response.vScore, 'vScoreDD');
            changeDScore = dScoreDD - dScore;
            changeFScore = fScoreDD - fScore;
            changeVScore = vScoreDD - vScore;
        }

        switch(ideaType) {
            case 'DFV_LOADING':
                let image = checkIfIEOrEdge() ? "IMG_DFV_LOADING_IE" : "IMG_DFV_LOADING";
                return (
                    <div styleName="not-unlocked-container">
                        <div styleName="not-unlocked-image">
                            <img src={this.props.getImage(image)} width="100%" height="100%" />
                        </div>
                    </div>
                );
            case 'NOT_UNLOCKED':
                return (
                    <div styleName="not-unlocked-container">
                        <div styleName="not-unlocked-image"><NotUnlockedDfv /></div>
                        <div className={css(myStyles.notUnlockDesc)} styleName="not-unlocked-desc">
                            {this.props.getLabel('label_unlock_dfv_score_desc')}
                        </div>
                        <div styleName="unlock-button">
                            {
                                checkWithBudget(currentIdea.cost.dfvCost, this.props.metrics)
                                ?   null
                                :   <InsufficientFundsBar />
                            }
                            <HollowButton
                                clickFunction={() => {
                                    this.onClickUnlockDfv(currentIdea);
                                }}
                                textLabel={this.props.getLabel('label_unlock_dfv', "", {
                                    CURRENCY: '',
                                    COST: getFormattedCurrency(currentIdea.cost.dfvCost, 'USD')
                                })}
                                disableButton={
                                    checkWithBudget(currentIdea.cost.dfvCost, this.props.metrics) &&  (this.props.uiState.get('dfvButtonLoading') === false)
                                    ?   false
                                    :   true
                                }
                            />
                        </div>
                    </div>
                );
            case 'NOT_REFINED':
                return (
                    <div styleName="dfv-feedback-container">
                        <div className={css(myStyles.dfvContainer)} styleName="dfv-container">
                            <DfvExpanded
                                valueD={dScore}
                                valueF={fScore}
                                valueV={vScore}
                                showChange={false}
                            />
                        </div>
                        <div styleName="feedback-container">
                            <div styleName="user-feedback-container">
                                <div className={css(myStyles.feedbackHeading)} styleName="feedback-heading">{this.props.getLabel('label_user_feedback')}:</div>
                                {this.renderDScores(myStyles, currentIdea.dfv_response.dScores, 'dScore', 'dScoreSuggestion')}
                            </div>
                            <div className={css(myStyles.userFeedbackContainer)} styleName="team-feedback-container">
                                <div className={css(myStyles.feedbackHeading)} styleName="feedback-heading">{this.props.getLabel('label_team_feedback')}:</div>
                                <div styleName="team-score-header">
                                    {this.renderTeamResponseHeader(myStyles, currentIdea.dfv_response.fScore[0], 'fScore', this.props.getLabel('label_feasibility_abbreviation'), 0 , false)}
                                    {this.renderTeamResponseHeader(myStyles, currentIdea.dfv_response.vScore[0], 'vScore', this.props.getLabel('label_viability_abbreviation'), 0 , false)}
                                </div>
                                <div styleName="team-response-container">
                                    <div className={css(myStyles.responseText)}>
                                        {this.props.getLabel(currentIdea.dfv_response.fScore[0].fScoreSuggestion)}
                                    </div>
                                    <div className={css(myStyles.responseText)}>
                                        {this.props.getLabel(currentIdea.dfv_response.vScore[0].vScoreSuggestion)}
                                    </div>
                                </div>
                                <div styleName="refine-idea-container">
                                    <div className={css(myStyles.refineDesc)} styleName="refine-idea-desc">{this.props.getLabel('label_refine_idea_desc')}</div>
                                    <div styleName="refine-idea-button">
                                        {
                                            checkWithBudget(currentIdea.cost.digDeeperCost, this.props.metrics)
                                            ?   null
                                            :   <InsufficientFundsBar />
                                        }
                                        <HollowButton
                                            clickFunction={() => {
                                                this.onClickRefineIdea(currentIdea);
                                            }}
                                            textLabel={this.props.getLabel('label_refine_idea', "", {
                                                CURRENCY: '',
                                                COST: getFormattedCurrency(currentIdea.cost.digDeeperCost, 'USD')
                                            })}
                                            disableButton={
                                                checkWithBudget(currentIdea.cost.digDeeperCost, this.props.metrics) && (this.props.uiState.get('refineIdeaButtonLoading') === false)
                                                ?   false
                                                :   true
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'REFINED':
                return (
                    <div styleName="dfv-feedback-container">
                        <div className={css(myStyles.dfvContainer)} styleName="dfv-container">
                            <DfvExpanded
                                valueD={dScoreDD}
                                valueF={fScoreDD}
                                valueV={vScoreDD}
                                showChange={true}
                                changeDScore={changeDScore}
                                changeFScore={changeFScore}
                                changeVScore={changeVScore}
                            />
                        </div>
                        <div styleName="feedback-container">
                            <div styleName="user-feedback-container">
                                <div className={css(myStyles.feedbackHeading)} styleName="feedback-heading">{this.props.getLabel('label_user_feedback')}:</div>
                                {this.renderDScoresRefined(
                                    myStyles,
                                    currentIdea.digdeep_response.dScores,
                                    'dScoreDD',
                                    'dScoreDDSuggestion',
                                    currentIdea.dfv_response.dScores,
                                    'dScore',
                                    'dScoreSuggestion',
                                )}
                            </div>
                            <div className={css(myStyles.userFeedbackContainer)} styleName="team-feedback-container">
                                <div className={css(myStyles.feedbackHeading)} styleName="feedback-heading">{this.props.getLabel('label_team_feedback')}:</div>
                                <div styleName="team-score-header">
                                    {this.renderTeamResponseHeader(
                                        myStyles,
                                        currentIdea.digdeep_response.fScore[0],
                                        'fScoreDD',
                                        this.props.getLabel('label_feasibility_abbreviation'),
                                        currentIdea.digdeep_response.fScore[0].fScoreDD - currentIdea.dfv_response.fScore[0].fScore,
                                        true
                                    )}
                                    {this.renderTeamResponseHeader(
                                        myStyles,
                                        currentIdea.digdeep_response.vScore[0],
                                        'vScoreDD',
                                        this.props.getLabel('label_viability_abbreviation'),
                                        currentIdea.digdeep_response.vScore[0].vScoreDD - currentIdea.dfv_response.vScore[0].vScore,
                                        true
                                    )}
                                </div>
                                <div styleName="team-response-container">
                                    <div className={css(myStyles.responseText)}>
                                        {this.props.getLabel(currentIdea.digdeep_response.fScore[0].fScoreDDSuggestion)}
                                    </div>
                                    <div className={css(myStyles.responseText)}>
                                        {this.props.getLabel(currentIdea.digdeep_response.vScore[0].vScoreDDSuggestion)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div styleName="not-unlocked-container">
                        <div styleName="not-unlocked-image"><NotUnlockedDfv /></div>
                        <div className={css(myStyles.notUnlockDesc)} styleName="not-unlocked-desc">
                            Cras quis nulla commodo, aliquam lectus sed, blandit augue.
                            Cras ullamcorper bibendum bibendum. Duis tincidunt urna non pretium porta.
                            Nam condimentum vitae ligula vel ornare. Phasellus at semper turpis.
                        </div>
                        <div styleName="unlock-button">
                            <HollowButton
                                clickFunction={() => {
                                    this.onClickUnlockDfv(currentIdea);
                                }}
                                textLabel={this.props.getLabel('label_unlock_dfv', "", {
                                    CURRENCY: '',
                                    COST: getFormattedCurrency(currentIdea.cost.dfvCost, 'USD')
                                })}
                                disableButton={
                                    checkWithBudget(currentIdea.cost.dfvCost, this.props.metrics) &&  (this.props.uiState.get('dfvButtonLoading') === false)
                                    ?   false
                                    :   true
                                }
                            />
                        </div>
                    </div>
                );
        }
    }

    renderButton = (myStyles, currentIdea) => {
        if(this.props.userState.get('currentPhaseKey') === 'ideate') {
            return (
                <div className={css(myStyles.buttonsContainer)} styleName="buttons-container">
                    <div className={css(myStyles.shortlistDesc)} styleName="shortlist-desc">
                        {
                            currentIdea.isNormalIdea
                            ?   this.props.getLabel('label_shortlist_idea_desc')
                            :   this.props.getLabel('label_remove_shortlist_idea_desc')
                        }
                    </div>
                    <div styleName="action-button">
                        <FilledButton
                            clickFunction={() => {
                                this.onClickShortListIdea(currentIdea);
                            }}
                            textLabel={
                                currentIdea.isNormalIdea
                                ?   this.props.getLabel('label_shortlist_this_idea')
                                :   this.props.getLabel('label_remove_from_shortlist')
                            }
                        />
                    </div>
                </div>
            );
        } else if(this.props.userState.get('currentPhaseKey') === 'prototype') {
            if(
                currentIdea.isShortlistedIdea === false
                && currentIdea.isShortlistedPrototype === true
            ) {
                return null;
            }
            return (
                <div className={css(myStyles.buttonsContainer)} styleName="buttons-container">
                    <div className={css(myStyles.shortlistDesc)} styleName="shortlist-desc">
                        {this.props.getLabel('label_prototype_idea_desc')}
                    </div>
                    {
                        checkWithBudget(currentIdea.cost.prototypeCost, this.props.metrics)
                        ?   null
                        :   <InsufficientFundsBar />
                    }
                    <div styleName="action-button-container">
                        <div styleName="action-button">
                            <HollowButton
                                clickFunction={() => {
                                    this.onClickMayBeLaterPrototype(currentIdea);
                                }}
                                textLabel={this.props.getLabel('label_may_be_later')}
                            />
                        </div>
                        <div styleName="action-button">
                            <FilledButton
                                clickFunction={() => {
                                    this.onClickPrototypeIdea(currentIdea);
                                }}
                                textLabel={this.props.getLabel('label_build_prototype', "", {
                                    CURRENCY: '',
                                    COST: getFormattedCurrency(currentIdea.cost.prototypeCost, 'USD')
                                })}
                                disableButton={checkWithBudget(currentIdea.cost.prototypeCost, this.props.metrics) ? false : true}
                            />
                        </div>
                    </div>
                </div>
            );
        } else if(this.props.userState.get('currentPhaseKey') === 'test') {
            if(
                currentIdea.isShortlistedIdea === false
                && currentIdea.isShortlistedForExecution === true
            ) {
                return (
                    <div className={css(myStyles.buttonsContainer)} styleName="buttons-container">
                        <div styleName="action-button-container">
                            <div styleName="action-button">
                                <FilledButton
                                    clickFunction={() => {
                                        this.onClickRemoveFromExecutionList(currentIdea);
                                    }}
                                    textLabel={this.props.getLabel('label_remove_from_shortlist_execution')}
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <div className={css(myStyles.buttonsContainer)} styleName="buttons-container">
                    <div className={css(myStyles.shortlistDesc)} styleName="shortlist-desc">
                        {this.props.getLabel('label_execution_idea_direct', "", {
                            CURRENCY: '',
                            COST: getFormattedCurrency(currentIdea.cost.executionCost, 'USD')
                        })}
                    </div>
                    <div styleName="action-button-container">
                        <div styleName="action-button">
                            <HollowButton
                                clickFunction={() => {
                                    this.onClickMayBeLaterPrototype(currentIdea);
                                }}
                                textLabel={this.props.getLabel('label_may_be_later')}
                            />
                        </div>
                        <div styleName="action-button">
                            <FilledButton
                                clickFunction={() => {
                                    this.onClickMoveToExecutionIdea(currentIdea);
                                }}
                                textLabel={this.props.getLabel('label_shortlist_execution', "", {
                                    CURRENCY: '',
                                    COST: getFormattedCurrency(currentIdea.cost.executionCost, 'USD')
                                })}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderCloseIcon = (myStyles) => {
        return (
            <div styleName="close-button">
                <CloseIcon closeButtonClicked={this.onClickOfClosePopup} />
            </div>
        );
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const {ideas, userState, userPhasesState} = this.props;
        let currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userIdeate').get('activeIdeaId'));

        if(this.props.userState.get('currentPhaseKey') === 'ideate') {
            currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userIdeate').get('activeIdeaId'));
        } else if(this.props.userState.get('currentPhaseKey') === 'prototype') {
            currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userPrototype').get('activeIdeaId'));
        } else if(this.props.userState.get('currentPhaseKey') === 'test') {
            currentIdea = getCurrentIdea(ideas.get('ideasList'), userPhasesState.get('userTestPrototype').get('activeIdeaId'));
        }

        if(checkIfPresent(currentIdea)) {
            currentIdea = currentIdea.toJS();
            return (
                <div className={css(myStyles.validationContainer)} styleName="popup-container">
                    {this.renderCloseIcon(myStyles)}
                    {this.renderRefinedImage(myStyles, currentIdea)}
                    {this.renderBorder(myStyles)}
                    {this.renderRefinedTag(myStyles, currentIdea)}
                    {this.renderHeadingContent(myStyles, currentIdea)}
                    {
                        this.props.userState.get('currentPhaseKey') === 'test'
                        ?   this.renderDfvScoreWithTestReportBorder(myStyles, currentIdea)
                        :   this.renderDfvScoreBorder(myStyles, currentIdea)
                    }
                    {this.renderButton(myStyles, currentIdea)}
                </div>
            );
        }
        return null;

    }
}

export default applyWrappers(IdeaDfvRefinePopup, styles);