import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			borderTop: `1px solid ${skin.get('primaryColor')}`,
			borderBottom: `1px solid ${skin.get('primaryColor')}`,
			borderLeft: `1px solid ${skin.get('primaryColor')}`,
			background: '#fafbff',
			borderTopLeftRadius: '4px',
			borderBottomLeftRadius: '4px',
			boxShadow: `-3px 3px 8px 0 ${hexToRgbA(skin.get('grayColor1'), 0.25)}`
		},
		header: {
			borderBottom: `1px solid ${skin.get('grayColor4')}`
		},
		title: {
			...myTypography.subtitle
		},
		content: {
			...myTypography.body1
		},
		messageBubble: {
			boxShadow: `0 2px 25px 1px rgba(0, 0, 0, 0.4)`,
			color: skin.get('white')
			// background: skin.get('white')
		},
		positive: {
			background: '#52d59c'
		},
		negative: {
			background: '#ff5974'
		},
		neutral: {
			background: '#fb735a'
		},
		neutralTextColor: {
			backgroundColor: '#fb735a',
			color: skin.get('white')
		},
		headingImpact: {
			...myTypography.body1,
			fontWeight: 800,
			color: skin.get('white'),
			fontSize: '14px'
		},
		descImpact: {
			...myTypography.body1,
			color: skin.get('white')
		},
		singleLine: {
			background: skin.get('grayColor4'),
			opacity: 0.4
		},
		arrowTipPositive: {
			borderTop: `15px solid #52d59c`
		},
		arrowTipNegative: {
			borderTop: `15px solid #ff5974`
		},
		arrowTipNeutral: {
			borderTop: `15px solid #fb735a`
		},
		emptyEventText: {
			...myTypography.body1,
			color: skin.get('grayColor4')
		},
		eventCharName: {
			...myTypography.body2,
			color: skin.get('grayColor2'),
			lineHeight: 'normal',
			fontWeight: 600
		},
		eventCharRole: {
			...myTypography.body2,
			color: skin.get('grayColor2'),
			lineHeight: 'normal',
		}
	});
}

export default getSkin;