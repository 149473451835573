import { StyleSheet } from 'aphrodite';
import { typography } from 'util/styleUtil';

const getSkin = (theme) => {

    let globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');	
	const myTypography = typography(theme);

    return StyleSheet.create({
        buttonStyle: {
            backgroundImage: 'linear-gradient(to right, #e87ad9, #7d4fea)',
            borderRadius: '50px 50px',
            // boxShadow: `0 4px 4px 0 ${skin.get('grayColor5')}`,
            ':hover': {
                backgroundImage: skin.get('primaryVariantColor')
            },
            ...myTypography.body1,
            fontWeight: 600
        },
        buttonStyleWithoutShadow: {
            backgroundImage: 'linear-gradient(to right, #e87ad9, #7d4fea)',
            borderRadius: '50px 50px',
        },
        buttonText: {
            ...myTypography.button,
            color: skin.get('white')
        },
        hollowButton: {
            borderRadius: '50px 50px',
            color: skin.get('primaryColor')
        },
        hollowBackground: {
            backgroundColor: skin.get('white')
        }

    });
}

export default getSkin;