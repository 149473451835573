import React from 'react';
import styles from './executePopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import ColorHeading from 'commonComponents/colorHeading';
import FilledButton from 'commonComponents/buttons/filledButton';
import HollowButton from 'commonComponents/buttons/hollowButton';
import CloseIcon from 'svgComponents/closeIcon';
import {getFormattedCurrency} from 'util/currencyFormatter';
import ExecutePopupBackground from 'svgComponents/executePopupBackground';
import ExecutePopupHeaderImage from 'svgComponents/executePopupHeaderImage';
import ExecutePopupCostBudget from 'svgComponents/executePopupCostBudget';
import ExecutePopupWrongTick from 'svgComponents/executePopupWrongTick';
import ExecutePopupCorrectTick from 'svgComponents/executePopupCorrectTick';
import ExecutePopupBlob from 'svgComponents/executePopupBlob';
import ExecutePopupIdeaCard from 'commonComponents/executePopupIdeaCard';
import analyticsUtil from 'util/segmentUtil';
class ExecutePopup extends React.Component {

    getIdeasForExecution = () => {
        const ideasList = this.props.ideas.get('ideasList');
        const myIdeasList = ideasList.filter((eachIdea) => {
            return eachIdea.get('isShortlistedForExecution') === true
                    && eachIdea.get('isShortlistedPrototype') === false
                    && eachIdea.get('isNormalIdea') === false
                    && eachIdea.get('isExecuted') === false
        });
        return myIdeasList;
    }

    getCostForExecution = () => {
        const myIdeasList = this.getIdeasForExecution();
        let cost = 0;
        if(myIdeasList.size > 0) {
            myIdeasList.toJS().map((eachIdea) => {
                cost = cost + eachIdea.cost.executionCost;
                return 1;
            });
        }
        return cost;
    }

    getIdeasListForExecution = () => {
        const myIdeasList = this.getIdeasForExecution();
        let ideaIds = [];
        if(myIdeasList.size > 0) {
            myIdeasList.toJS().map((eachIdea) => {
                ideaIds.push(eachIdea.id)
                return 1;
            });
        }
        return ideaIds;
    }

    executeClicked = () => {
        analyticsUtil.track("EXECUTE_CLICKED",{ roundID: this.props.userState.get('currentRoundId') });
        this.props.setUIState({
			executeButtonLoading: true
		})
        this.props.postExecuteIdeas({
            ideaIds: this.getIdeasListForExecution(),
            roundId: this.props.userState.get('currentRoundId'),
            reducerAction: {
                type: 'UPDATE_ROUND',
                key: 'reportData',
                payload: {
                    id: this.props.userState.get('currentRoundId'),
                    isReportAvailable: true
                }
            }
        });
    }

    closePopupClicked = () => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "EXECUTE_POPUP_CLOSE" });
        this.props.setUIState({
			isOverlayOpen: false,
			popupComponent: ''
		})
    }

    renderCloseButton = () => {
        return(
            <div styleName="close-icon">
                <CloseIcon
                    closeButtonClicked = {() => this.closePopupClicked()}
                    {...this.props} 
                />
            </div>
        )
    }

    renderCornerLeftPopupBg = () => {
        return(
            <div styleName="left-images-execute-container">
                <img alt="" src={this.props.getImage('IMG_EXECUTE_PHASE_LEFT_CORNER')} width="100%" height="100%" />
            </div>
        )
    }

    renderCornerRightPopupBg = () => {
        return(
            <div styleName="right-images-execute-container">
                <img alt="" src={this.props.getImage('IMG_EXECUTE_PHASE_RIGHT_CORNER')} width="100%" height="100%" />
            </div>
        )
    }

    renderPopupHeader = (myStyles) => {
        // const headerLabel = this.props.getLabel('label_execute_popup', "", {
        //     PLACEHOLDER: this.props.userState.get('currentRoundId')
        // });
        return(
            <div styleName="popup-header">
                <div styleName="popup-header-background">
                    <ExecutePopupBackground />
                </div>
                <div styleName="popup-header-content">
                    <div styleName="header-image">
                        <ExecutePopupHeaderImage />
                    </div>
                    <div styleName="header-content">
                        <div className={css(myStyles.descStyle)} styleName="title">
                            {this.props.getLabel('label_execution_popup_heading_1')}
                        </div>
                        <div className={css(myStyles.descStyle)} styleName="title">
                            {this.props.getLabel('label_execution_popup_heading_quarter', '', {
                                ROUND_NUM: this.props.userState.get('currentRoundId')
                            })}
                        </div>
                    </div>
                </div>
                {/* <div styleName="desc-container">
                    {this.props.getLabel('label_execution_desc')}
                </div> */}
                {/* <div styleName="header-left-image">
                    <img alt="" src={this.props.getImage('IMG_EXECUTE_HEADER_LEFT')} width="100%" height="100%" />
                </div> */}
                {/* <div styleName="header-right-image">
                    <img alt="" src={this.props.getImage('IMG_EXECUTE_HEADER_RIGHT')} width="100%" height="100%" />
                </div> */}
            </div>
        )
    }

    renderBorderForHeader = (myStyles) => {
        return(
            <div className={css(myStyles.headerGradient)} styleName="header-border">

            </div>
        )
    }

    renderPopupContainer = (myStyles) => {

        const cost = this.getCostForExecution();
        const ideaCount = this.getIdeasListForExecution().length;
        const metrics = this.props.metrics.get('metrics').toJS();
        const budget = metrics.filter((metric) => metric.key === 'budget')[0];
        const ideasList = this.getIdeasForExecution().toJS();
        
        let disableButton = false;
        
        if(ideaCount > 0 && (cost > budget.value)) {
            disableButton = true;
        } else if(budget.value == 0) {
            disableButton = false;
        }

        let showZeroOneMessage = false;
        let showZeroOneMessageLabel = '';

        if(ideaCount === 0) {
            showZeroOneMessage = true;
            showZeroOneMessageLabel = 'label_zero_execution_list_help_text';
        } else if (ideaCount === 1) {
            showZeroOneMessage = true;
            showZeroOneMessageLabel = 'label_one_execution_list_help_text';
        }


        let executeLabel = 'label_move_next_round';

        if(ideaCount === 0) {
            executeLabel = 'label_move_next_round_zero_ideas';
        } else if(ideaCount > 0 && cost < budget.value) {
            executeLabel = 'label_move_next_round_cost_less_budget';
        } else if (budget.value <= 0) {
            executeLabel = 'label_move_next_round_budget_zero';
        } else if(ideaCount > 0 && cost > budget.value) {
            executeLabel = 'label_move_next_round_cost_greater_budget';
        }


        return(
            <div styleName="popup-content-container">
                <div styleName="popup-content-container-background">
                    {this.renderCornerLeftPopupBg()}
                    {this.renderCornerRightPopupBg()}
                </div>
                <div styleName="popup-content-container-content">
                    <div styleName="content-container-main">
                        <div styleName="execution-list-container">
                            <div styleName="main-heading">
                                <ColorHeading 
                                    title={this.props.getLabel('label_shortlisted_for_execution')} 
                                    isUppercase={false}
                                    fontSize="16px"
                                />
                            </div>
                            <div styleName="execution-content">
                                <ExecutePopupIdeaCard
                                    {...this.props}
                                    ideasList={ideasList}
                                />
                                {
                                    showZeroOneMessage
                                    ?   <div styleName="execution-count-help-text">
                                            <div styleName="execution-count-help-text-background">
                                                <ExecutePopupBlob type="NEUTRAL" />
                                            </div>
                                            <div className={css(myStyles.helpText)} styleName="execution-count-help-text-content">
                                                {this.props.getLabel(showZeroOneMessageLabel)}
                                            </div>
                                        </div>
                                    :   null
                                }
                            </div>
                        </div>
                        <div className={css(myStyles.borderLeft)} styleName="cost-budget-container">
                            <div styleName="main-heading">
                                <ColorHeading 
                                    title={this.props.getLabel('label_cost_vs_budget')} 
                                    isUppercase={false}
                                    fontSize="16px"
                                />
                            </div>
                            <div styleName="cost-budget-content">
                                <div styleName="cost-budget-image">
                                    <ExecutePopupCostBudget />
                                </div>
                                <div styleName="costs">
                                    <div styleName="budget-cost-container">
                                        <span className={css(myStyles.costBudgetHeadingStyle)}>
                                            {this.props.getLabel('label_execution_cost')}
                                        </span>
                                        <br/>
                                        <span className={css(myStyles.costBudgetStyle)}>
                                            {getFormattedCurrency(cost, 'USD')}
                                        </span>
                                    </div>
                                    <div styleName="budget-cost-container">
                                        <span className={css(myStyles.costBudgetHeadingStyle)}>
                                            {this.props.getLabel('label_budget_available')}
                                        </span>
                                        <br/>
                                        <span className={css(myStyles.costBudgetStyle)}>
                                            {getFormattedCurrency(budget.value, 'USD')}
                                        </span>
                                    </div>
                                </div>
                                <div styleName="execution-help-text-container">
                                    <div styleName="execution-help-text-top-image">
                                        {
                                            disableButton
                                            ?   <ExecutePopupWrongTick />
                                            :   <ExecutePopupCorrectTick />
                                        }
                                    </div>
                                    <div styleName="execution-help-text-background">
                                        {
                                            disableButton
                                            ?   <ExecutePopupBlob type="NEGATIVE" />
                                            :   <ExecutePopupBlob type="POSITIVE" />
                                        }
                                    </div>
                                    <div styleName="execution-help-text-content">
                                        <div
                                            className={disableButton ? css(myStyles.negativeColor) : css(myStyles.positiveColor)}
                                            styleName="execution-help-text"
                                            dangerouslySetInnerHTML={{__html: this.props.getLabel(executeLabel)}}
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div styleName="content-container-footer" className={css(myStyles.footerBorder)}>
                        <div styleName="footer-desc">
                            <div className={css(myStyles.proceedStyle)}>
                                {this.props.getLabel('label_sure_you_want_to_proceed')}
                            </div>
                        </div>
                        <div styleName="buttons-container">
                            <div style={{padding: '0 10px'}}>
                                <HollowButton 
                                    {...this.props} 
                                    clickFunction={() => this.closePopupClicked()}
                                    textLabel={this.props.getLabel('label_maybe_later')}	
                                />
                            </div>
                            <div style={{padding: '0 10px'}}>
                                <FilledButton 
                                    {...this.props} 
                                    disableButton={disableButton || (this.props.uiState.get('executeButtonLoading') === true)}
                                    clickFunction={() => this.executeClicked()}
                                    textLabel={this.props.getLabel('label_yes_execute')}	
                                />
                            </div>
                        </div>
                    </div>


                    {/* <div className={css(myStyles.costBudgetHeadingStyle)} styleName="budget-cost-heading-container">
                        {this.props.getLabel('label_execution_cost_heading')}
                    </div>
                    <div className={css(myStyles.costBudgetStyle)} styleName="budget-cost-container">
                        {`${this.props.getLabel('label_execution_cost')}: ${getFormattedCurrency(cost, 'USD')}`}
                        <br/>
                        {`${this.props.getLabel('label_budget_available')}: ${getFormattedCurrency(budget.value, 'USD')}`}
                    </div> 
                    <div className={css(myStyles.costStyle)} styleName="cost-container"
                        dangerouslySetInnerHTML={{__html: this.props.getLabel(executeLabel)}}
                    >
                    </div>  */}
                    {/* <div className={css(myStyles.descStyle)}>
                        {this.props.getLabel('label_sure_you_want_to_proceed')}
                    </div> */}
                    {/* <div style={{padding: '12px 0 16px 0'}}>
                        <FilledButton 
                            {...this.props} 
                            disableButton={disableButton}
                            clickFunction={() => this.executeClicked()}
                            textLabel={this.props.getLabel('label_yes_execute')}	
                        />
                    </div>
                    <HollowButton 
                        {...this.props} 
                        clickFunction={() => this.closePopupClicked()}
                        textLabel={this.props.getLabel('label_maybe_later')}	
                    /> */}
                </div>
            </div>
        )
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div className={css(myStyles.popupBg)} styleName="execute-popup-container">
                {this.renderCloseButton()}
                {this.renderPopupHeader(myStyles)}
                {/* {this.renderBorderForHeader(myStyles)} */}
                {this.renderPopupContainer(myStyles)}
            </div>
        );
    }
}

export default applyWrappers(ExecutePopup, styles);