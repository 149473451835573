/**
 * @author: Ujjwal Jain
 * @file: user.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({});

const user = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_USER_DATA'):
            return state.mergeDeep(initialState, Immutable.fromJS(action.payload));
        default:
            return state;
    }
};

export default user;