import React from 'react';
import styles from './userInterviewPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import PersonaList from 'commonComponents/personaList';
import FilledButton from 'commonComponents/buttons/filledButton';
import consts from 'constants/url';
import CloseIcon from 'svgComponents/closeIcon';
import ActionTickWithoutFill from 'svgComponents/actionTickWithoutFill';
import { filterObjectFromArray } from 'util/objectUtils';
import ColorHeading from 'commonComponents/colorHeading';
import { getFormattedCurrency } from 'util/currencyFormatter';
import {checkWithBudget, checkIfIEOrEdge} from 'util/utilFunctions';
import InsufficientFundsBar from 'commonComponents/insufficientFundsBar'
import analyticsUtil from 'util/segmentUtil';
class UserInterviewPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            personaSelectedCount: 0,
            totalCost: 0,
            showUserPersonaList: true,
            showUserLoaderState: false,
            showUserFeedbackReport: false
        }
    }

    getLevelBasedCost = (persona, levelId) => {
        let costValue = 0;

        persona.personaInterviews.map((personaInterview) => {
            if(personaInterview.level == levelId) {
                costValue = personaInterview.cost;
            }
        });

        return costValue;
    }

    getPersonaInterviewLevel = (persona) => {
        switch(persona.noOfInterviewTaken) {
            case 0:
                return this.getLevelBasedCost(persona, 1); 
            case 1: 
                return this.getLevelBasedCost(persona, 2); 
            case 2: 
                return this.getLevelBasedCost(persona, 3); 
            case 3:
                break;
            default:
                break;
        }
    }

    personaIconClicked = (persona) => {
        this.props.setPersonaSelectedorDeselected(persona);
        
        const cost = this.getPersonaInterviewLevel(persona);

        if(!persona.isSelected) {
            this.setState({
                personaSelectedCount: this.state.personaSelectedCount + 1,
                totalCost: this.state.totalCost + cost
            });
        } else {
            this.setState({
                personaSelectedCount: this.state.personaSelectedCount > 0 ? this.state.personaSelectedCount - 1 : 0,
                totalCost: this.state.totalCost - cost 
            });
        }
    }

    showLoaderState = () => {
        const url = consts.get('PERSONA_INTERVIEW_TAKEN');
        const payload = {
            userState: this.props.userState,
            isInterview: true,
            personaList: this.props.personas.get('personaList')
        };
        this.setState({
            showUserPersonaList: false,
            showUserLoaderState: true,
            showUserFeedbackReport: false
        });
        // this.props.setUserObservePhaseState({
        //     atleastOneInterviewTaken: true
        // });

        /**Post request for storing button state */
        const actionUrl = consts.get('ACTION_LOG_API');
        const responsePayload = {
            roundId: this.props.userState.get('currentRoundId'),
            action: {
                type: 'SET_USER_OBSERVE_PHASE_STATE',
                payload: {
                    atleastOneInterviewTaken: true
                }
            }
        };

        this.props.postRequestActionLog(actionUrl, responsePayload);

        this.props.postRequestPersonaInterviewTaken(url, payload);
        setTimeout(() => {
            this.setState({
                showUserPersonaList: false,
                showUserLoaderState: false,
                showUserFeedbackReport: true
            });
        }, 5000);        
    }

    closeButtonClicked = (openBoard) => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "USER_INTERVIEW_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
            isProjectBoardOpen: openBoard,
        });

        this.setState({
            showUserPersonaList: false,
            showUserLoaderState: false,
            showUserFeedbackReport: false
        });

        this.props.resetAllPersonasSelected();
    }

    renderPopupBorder = (myStyles) => {
        return(
            <div className={css(myStyles.popupBorderTop)} styleName="user-interview-header-border">
            </div>
        )
    }

    renderPopupTitle = (myStyles) => {
        return(
            <div styleName="title-container">
                <div className={css(myStyles.popupTitle)} styleName="user-interview-title-container">
                    <ColorHeading
                        title={this.props.getLabel('label_user_interviews')}
                        isUppercase={false}
                    />
                </div>
                {
                    this.state.showUserPersonaList ?
                    <div className={css(myStyles.popupSubtitle)} styleName="user-interview-subtitle-container">
                        {this.props.getLabel('label_user_interviews_subtitle')}
                    </div>
                    : null
                }
            </div>
        )
    }

    renderPersonaListWithInstruction = (myStyles) => {
        const isDisabled = this.state.personaSelectedCount <= 0 || !checkWithBudget(this.state.totalCost, this.props.metrics)
                            ? true
                            : false;
        const conductCostLabel = this.props.getLabel('label_conduct_interview_withCost', "", {
            CURRENCY: '',
            COST: getFormattedCurrency(this.state.totalCost, 'USD')
        });


        
        let personaInterviewLevel = this.props.personas.get('personaList').toJS();
        personaInterviewLevel = personaInterviewLevel[0]['personaInterviews'].length;
                
        return(
            <div styleName="user-interview-instruction-persona-container">
                <div className={css(myStyles.interviewInstruction)} styleName="instruction-message">
                    {this.props.getLabel('label_user_interviews_instruction')}
                </div>
                <div className={css(myStyles.interviewInstructionNote)} styleName="instruction-message-note">
                    {this.props.getLabel('label_user_interviews_instruction_note', "", {
                        PLACEHOLDER: personaInterviewLevel
                    })}
                </div>
                <div className={css(myStyles.personaListBorder)} styleName="user-interview-persona-list-container">
                    <PersonaList
                        isCenterAligned={false} 
                        personaIconClicked = {this.personaIconClicked}
                        showPersonaReadState = {false}
                        isInfoRelated = {false}
                        isInterview={true}
                        isFromDashboard = {false}
                        showPersonaLevelAndCost = {true}
                        showPersonaName={true} 
                        {...this.props} 
                    />
                </div>
                {
                    checkWithBudget(this.state.totalCost, this.props.metrics)
                    ?   null
                    :   <InsufficientFundsBar
                            propStyles={{textAlign: 'left'}}
                        />
                }
                <FilledButton 
                    {...this.props} 
                    disableButton={isDisabled}
					clickFunction={() => this.showLoaderState()}
					textLabel={conductCostLabel}	
				/>
            </div>
        )
    }

    renderPersonaInterviewLoaderState = (myStyles) => {
        let isIEOrEdge = checkIfIEOrEdge();
        let image = isIEOrEdge ? "IMG_USER_INTERVIEW_BG" : "IMG_USER_INTERVIEW_GIF";
        let getLoadingLabel = this.props.getLabel('label_loading_ie');
        let loading = getLoadingLabel == "label_loading_ie" ? "Loading..." : getLoadingLabel ;
        return(
            <div styleName="user-interview-loader-container">
                <img src={this.props.getImage(image)} width="376px" height="264px" />
                { isIEOrEdge &&
                  loading
                }
            </div>
        )
    }

    renderPersonaFeedbackReport = (myStyles) => {
        const colors = this.props.skinGuide.get('globalProfiles').get('palette');        
        return(
            <div styleName="user-interview-loader-container">
                <div styleName="user-interview-subtitle-container check-icon-container">
                    <div styleName="action-tick-container">
                        <ActionTickWithoutFill
                            opacity="0.4"  
                            circleFill={colors.get('primaryColor')}
                            {...this.props} 
                        />
                    </div>
                </div>
                <div className={css(myStyles.interviewInstruction)} styleName="user-interview-feedback-container">
                    {this.props.getLabel('label_project_board_details')}
                </div>
                <div className={css(myStyles.interviewInstruction)} styleName="user-interview-feedback-container">
                    {this.props.getLabel('label_project_board_subtitle_details')}
                </div>
                <FilledButton 
                    {...this.props} 
					clickFunction={() => this.closeButtonClicked(true)}
					textLabel={this.props.getLabel('label_project_board')}	
                />
            </div>
        )
    }

    renderPopupRelatedContent = (myStyles) => {
        if (this.state.showUserPersonaList) {
            return this.renderPersonaListWithInstruction(myStyles);
        } else if (this.state.showUserLoaderState) {
            return this.renderPersonaInterviewLoaderState(myStyles);
        } else if(this.state.showUserFeedbackReport) {
            return this.renderPersonaFeedbackReport(myStyles);
        }
    }

    renderPopupContent = (myStyles) => {
        return(
            <div styleName="user-interview-content-container">
                {this.renderPopupTitle(myStyles)}
                {this.renderPopupRelatedContent(myStyles)}
            </div>
        )
    }

    renderBackgroundImage = () => {
        if(this.state.showUserPersonaList) {
            return(
                <div styleName="bakground-image-container">
                    <img src={this.props.getImage('IMG_USER_INTERVIEW_BG')} width="100%" height="100%" />
                </div>
            )
        }
    }

    renderCloseButton = () => {
    
        if(this.state.showUserPersonaList) {
            return(
                <div styleName="close-icon">
                    <CloseIcon
                        closeButtonClicked = {() => this.closeButtonClicked(false)}
                        {...this.props} 
                    />
                </div>
            )
        }
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div className={css(myStyles.popupBackground)} styleName="user-interview-popup-container">
                {this.renderCloseButton()}
                {this.renderPopupBorder(myStyles)}
                {this.renderPopupContent(myStyles)}
                {this.renderBackgroundImage()}
            </div>
        );
    }
}

export default applyWrappers(UserInterviewPopup, styles);