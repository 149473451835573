import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		validationContainer: {
			backgroundColor: skin.get('white'),
			borderRadius: '5px',
			boxShadow: `1px 1px 5px 0 #d5d8e2`
		},
		borderContainer: {
			background: `linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd)`,
			backdropFilter: `blur(10px)`,
			opacity: 0.5
		},
		headingMain: {
			...myTypography.subtitle,
			color: skin.get('grayColor1'),
			fontSize: '18px',
			fontWeight: 600
		},
		headingDesc: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		},
		metricValue: {
            ...myTypography.body1,
            fontSize: '15px',
            fontWeight: 600,
			color: skin.get('grayColor1')
        },
		buttonsContainer: {
			borderTop: `1px solid ${skin.get('grayColor6')}`
		},
		shortlistDesc: {
			...myTypography.body2,
			color: skin.get('grayColor1')
		}
	});
}

export default getSkin;