import kfetch from '../util/kfetch';
import consts from 'constants/url';

export const fetchLabelsData = () => (dispatch) => {

    kfetch(consts.get('LABELS_API'))
    .then((response) => {
        dispatch({
            type: 'INIT_LABELS_DATA',
            payload: response
        })
    })};
