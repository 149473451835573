import { combineReducers } from 'redux-immutable';

import phases from './phases/phases';
import questions from './questions/questions';
import userState from './userState/userState';
import personas from './personas/personas';
import user from './user/user';
import segments from './segments/segments';
import touchPoints from './touchPoints/touchPoints';
import rounds from './rounds/rounds';
import teamMembers from './teamMembers/teamMembers';
import labels from './labels/labels';
import colorProfiles from './colorProfiles/colorProfiles';
import images from './images/images';
import ideas from './ideas/ideas';
import uiState from './uiState/uiState';
import userPhasesState from './userPhasesState/userPhasesState';
import testQuadrants from './testQuadrants/testQuadrants';
import metrics from './metrics/metrics';
import events from './events/events';
import userEvents from './userEvents/userEvents';
import leaderboard from './leaderboard/leaderboard';
import storyline from './storyline/storyline';
import timer from './timer/timer';
import firebase from './firebase/firebase';
import additionalParams from './additionalParams/additionalParams';
// import labels from './labels/labels';

const app = combineReducers({
    phases,
    questions,
    userState,
    segments,
    touchPoints,
    personas,
    user,
    rounds,
    teamMembers,
    labels,
    colorProfiles,
    images,
    ideas,
    uiState,
    metrics,
    userPhasesState,
    testQuadrants,
    events,
    userEvents,
    leaderboard,
    timer,
    storyline,
    firebase,
    additionalParams
});

export default app;
