import React from 'react';
import styles from './event.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class Event extends React.Component {

    
    render() {
        // const myStyles = getSkin(this.props.skinGuide);
        const skin = this.props.skinGuide.get('globalProfiles').get('palette')
        return (
            <div styleName="event-container">
                <svg width="100%" height="100%" viewBox="0 0 24 24">
                    <g fill={skin.get('white')} fill-rule="nonzero">
                        <path d="M9.324 16.967c0-3.842 3.628-6.967 8.088-6.967a8.712 8.712 0 0 1 5.9 2.209.25.25 0 0 0 .411-.12A8.245 8.245 0 0 0 24 10c0-5.514-5.383-10-12-10S0 4.486 0 10a9.037 9.037 0 0 0 2.766 6.376l-1.419 4.731a.5.5 0 0 0 .681.6L7.5 19.271c.682.228 1.381.402 2.091.518a.251.251 0 0 0 .27-.349 6.047 6.047 0 0 1-.537-2.473zM9.5 12.25H6a.75.75 0 1 1 0-1.5h3.5a.75.75 0 1 1 0 1.5zm7-4.5H6a.75.75 0 0 1 0-1.5h10.5a.75.75 0 1 1 0 1.5z"/>
                        <path d="M17.412 11.5c-3.633 0-6.588 2.452-6.588 5.467 0 3.521 4.078 6.322 8.456 5.232l3.5 1.748a.5.5 0 0 0 .7-.591l-.9-3A4.908 4.908 0 0 0 24 16.967c0-3.015-2.955-5.467-6.588-5.467z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(Event, styles);