import environment from 'util/environment';
import {checkIfPresent} from 'util/utilFunctions';

const appTimerEnabled = () => {
    if(checkIfPresent(environment.REACT_APP_TIMER_ENABLED)) {
        if(environment.REACT_APP_TIMER_ENABLED === 'true') {
            return true;
        }
    }
    return false;
}

const appInterviewVideoEnabled = () => {
    if(checkIfPresent(environment.REACT_APP_ENABLE_INTERVIEW_VIDEO)) {
        if(environment.REACT_APP_ENABLE_INTERVIEW_VIDEO === 'true') {
            return true;
        }
    }
    return false;
}

const appSentryEnabled = () => {
    if(checkIfPresent(environment.REACT_APP_ENABLE_SENTRY)) {
        if(environment.REACT_APP_ENABLE_SENTRY === 'true') {
            return true;
        }
    }
    return true;
}

const getAppEnv = () => {
    if(checkIfPresent(environment.REACT_APP_ENV)) {
        return environment.REACT_APP_ENV;
    }
    return 'develop';
}

const getKalturaLink = () => {
    if(checkIfPresent(environment.REACT_APP_KALTURA_URL)) {
        return environment.REACT_APP_KALTURA_URL;
    }
    return 'https://cdnapisec.kaltura.com/p/2413672/sp/241367200/embedIframeJs/uiconf_id/42816162/partner_id/2413672';
}

export {
    appTimerEnabled,
    appInterviewVideoEnabled,
    appSentryEnabled,
    getAppEnv,
    getKalturaLink
};