import React from 'react';
import styles from './closeIcon.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';

class CloseIcon extends React.Component {

    closeButtonClicked = () => {
        this.props.closeButtonClicked();
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="main-component" onClick={this.closeButtonClicked}>
                <svg width="100%" height="100%" viewBox="0 0 28 28">
                    <defs>
                        <linearGradient id="close" x1="0%" y1="50%" y2="50%">
                            <stop offset="0%" stop-color="#E87AD9"/>
                            <stop offset="100%" stop-color="#7D4FEA"/>
                        </linearGradient>
                    </defs>
                    <g fill="none" fill-rule="nonzero">
                        <path fill="#FFF" d="M13.98 0C6.259 0 0 6.261 0 13.983c0 7.721 6.259 13.982 13.98 13.982 7.725 0 13.985-6.262 13.985-13.982C27.965 6.261 21.705 0 13.98 0z"/>
                        <path fill="url(#close)" d="M13.98 0C6.259 0 0 6.261 0 13.983c0 7.721 6.259 13.982 13.98 13.982 7.725 0 13.985-6.262 13.985-13.982C27.965 6.261 21.705 0 13.98 0zm6.012 17.769l-2.227 2.224s-3.523-3.78-3.786-3.78c-.259 0-3.783 3.78-3.783 3.78l-2.228-2.224s3.784-3.472 3.784-3.781c0-.314-3.784-3.787-3.784-3.787l2.228-2.229s3.553 3.782 3.783 3.782c.232 0 3.786-3.782 3.786-3.782l2.227 2.229s-3.785 3.523-3.785 3.787c0 .251 3.785 3.781 3.785 3.781z"/>
                    </g>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(CloseIcon, styles);