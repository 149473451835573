/**
 * @author: Ujjwal Jain
 * @file: firebase.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({
    credentials: {},
    isFirebaseCredentialsAvailable: false
});

const firebase = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_FIREBASE_DATA'):
            return state.set('credentials', Immutable.fromJS(action.payload))
                        .set('isFirebaseCredentialsAvailable', true);
        default:
            return state;
    }
};

export default firebase;