import React from 'react';
import styles from './preExecutePopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import FilledButton from 'commonComponents/buttons/filledButton';
import HollowButton from 'commonComponents/buttons/hollowButton';
import CloseIcon from 'svgComponents/closeIcon';
import {getFormattedCurrency} from 'util/currencyFormatter';
import analyticsUtil from 'util/segmentUtil';
class PreExecutePopup extends React.Component {

    onClickOfClosePopup = () => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "PRE_EXECUTE_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
        });
    }

    onClickExecuteIdea = () => {
        this.props.setUIState({
            isOverlayOpen: true,
            popupComponent: 'EXECUTE_POPUP'
        });
    }

    getIdeasForExecution = () => {
		const ideasList = this.props.ideas.get('ideasList');
		const myIdeasList = ideasList.filter((eachIdea) => {
			return eachIdea.get('isShortlistedForExecution') === true
					&& eachIdea.get('isShortlistedPrototype') === false
					&& eachIdea.get('isNormalIdea') === false
					&& eachIdea.get('isExecuted') === false
		});
		return myIdeasList;
    }
    
    getCostForExecution = () => {
        const myIdeasList = this.getIdeasForExecution();
        let cost = 0;
        if(myIdeasList.size > 0) {
            myIdeasList.toJS().map((eachIdea) => {
                cost = cost + eachIdea.cost.executionCost;
                return 1;
            });
        }
        return cost;
    }

    renderBorder = (myStyles) => {
        return (
            <div className={css(myStyles.borderContainer)} styleName="border-container"></div>
        );
    }

    renderHeadingContent = (myStyles, ideaCount, cost) => {
        let heading1;
        let heading2;
        if(ideaCount == 0){
            heading1 = 'label_0_preExecution_heading1';
            heading2 = 'label_0_preExecution_heading2';
        }else if(ideaCount == 1){
            heading1 = 'label_1_preExecution_heading1';
            heading2 = 'label_1_preExecution_heading2';
        }
        return (
            <div styleName="heading-container">
                <div className={css(myStyles.headingMain)} styleName="heading-main">
                    {this.props.getLabel(heading1)}
                </div>
                <div className={css(myStyles.headingDesc)} styleName="heading-desc">
                    {this.props.getLabel(heading2)}
                </div>
                <div className={css(myStyles.headingDesc)} styleName="heading-desc">
                    {this.props.getLabel('label_preExecution_idea_shortlisted')} 
                    <span className={css(myStyles.metricValue)} styleName="metric-value">
                        {ideaCount}
                    </span>
                    {this.props.getLabel('label_preExecution_cost_forExecution')}
                    <span className={css(myStyles.metricValue)} styleName="metric-value">
                        {cost}
                    </span> 
                </div>
            </div>
        );
    }

    renderButton = (myStyles) => {
        return (
            <div className={css(myStyles.buttonsContainer)} styleName="buttons-container">
                <div styleName="buttons-content">
                    <div styleName="action-button">
                        <HollowButton
                            clickFunction={() => {
                                this.onClickOfClosePopup();
                            }}
                            textLabel={this.props.getLabel('label_add_more_ideas')}
                        />
                    </div>
                    <div styleName="action-button">
                        <FilledButton
                            clickFunction={() => {
                                this.onClickExecuteIdea();
                            }}
                            textLabel={this.props.getLabel('label_continue_execute')}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderCloseIcon = (myStyles) => {
        return (
            <div styleName="close-button">
                <CloseIcon closeButtonClicked={this.onClickOfClosePopup} />
            </div>
        );
    }
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const ideaCount = this.getIdeasForExecution().size;
        let cost = this.getCostForExecution();
        cost = getFormattedCurrency(cost, 'USD');
        return (
            <div className={css(myStyles.validationContainer)} styleName="popup-container">
                {this.renderCloseIcon(myStyles)}
                {this.renderBorder(myStyles)}
                {this.renderHeadingContent(myStyles,ideaCount,cost)}
                {this.renderButton(myStyles)}
            </div>
        );
    }
}

export default applyWrappers(PreExecutePopup, styles);