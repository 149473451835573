import React from 'react';
import styles from './budget.module.sass';
import getSkin from './skin.js';
import applyWrappers from 'util/ComponentWrapper';

class Budget extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skin = this.props.skinGuide.get('globalProfiles').get('palette');
        return (
            <div styleName="budget-container">
                <svg width="25" height="21" viewBox="0 0 25 21">
                    <path fill={skin.get('white')} fillRule="nonzero" d="M3.5 21h18a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3h-3.9a.25.25 0 0 1-.248-.219l-.144-1.153A3 3 0 0 0 14.234 0h-3.468a3 3 0 0 0-2.977 2.628l-.144 1.153A.25.25 0 0 1 7.4 4H3.5a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3zM9.773 2.876A1 1 0 0 1 10.766 2h3.468a1 1 0 0 1 .993.876l.105.844a.25.25 0 0 1-.248.28H9.916a.25.25 0 0 1-.248-.28l.105-.844zM5.75 9.5a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-.75.75h-12a.75.75 0 0 1-.75-.75v-.5zm0 5.5a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-.75.75h-12a.75.75 0 0 1-.75-.75V15z" opacity=".296"/>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(Budget, styles);