import React from 'react';
import styles from './empathyMapQuestions.module.sass';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import { filterObjectFromArray, getQuestionResponseArray, getMinAndMaxValues } from 'util/objectUtils';
import EmptyChatSection from 'svgComponents/emptyChatSection';
import getColorPalette from './colorPalette';
import MessageIcon from 'svgComponents/messageIcon/MessageIcon';
import QuestionChatBox from 'commonComponents/questionChatBox/QuestionChatBox';
import consts from 'constants/url';
import HollowButton from 'commonComponents/buttons/hollowButton';
import ActionTickWithoutFill from 'svgComponents/actionTickWithoutFill';
import EmpathyMapFollowUpQuestion from '../empathyMapFollowUpQuestion/EmpathyMapFollowUpQuestion';
import MoveArrow from 'svgComponents/moveArrow/MoveArrow';
import { getFormattedCurrency } from 'util/currencyFormatter';
import {TweenMax} from 'gsap';
import {checkWithBudget} from 'util/utilFunctions';
import WalkthroughElement from 'commonComponents/walkthroughElement/WalkthroughElement';
import WalkthroughComponent from 'commonComponents/walkthroughComponent/WalkthroughComponent';
import InfoTooltip from 'commonComponents/infoTooltip';

class EmpathyMapQuestions extends React.Component {

    constructor(props) {
        super(props);
        this.completeContainer = React.createRef();
        this.state = {
            indexShown: -1,
            arrowShown: false,
            indexShownFollowup: -1,
            followUpWalkthroughSeen: false ,
            isWalkthoughSeen: false,
        }
    }

    componentDidMount() {
        this.scrollContainer();
    }

    scrollContainer = () => {                        
        TweenMax.to(this.completeContainer.current, 1, { scrollTop: this.completeContainer.current.scrollHeight });
    }

    askQuestionClicked = (segmentQuestions, question, userState) => {
        
        const url = consts.get('QUESTION_ASKED')
        const payload = {
            questionId: question.id,
            segmentId: segmentQuestions.get('id'),
            roundId: userState.get('currentRoundId')
        }

        this.props.setUserEmpathizePhaseState({
            isEmpathyQuestionAsked: true
		})

        // this.scrollContainer();
        this.props.postRequestQuestionForSegmentAsked(url, payload, this.scrollContainer);
        // this.scrollContainer();

    }

    pickResponseForEmpathyMapClicked = (response) => {
        const url = consts.get('ACTION_LOG_API');
        const responsePayload = {
            roundId: this.props.userState.get('currentRoundId'),
            action: {
                type: 'SET_RESPONSE_FOR_QUADRANT_FILLED',
                payload: { ...response }
            }
        };
        this.props.postRequestActionLog(url, responsePayload);
        
        this.props.setResponseClickedToFillQuadrant(response)
    }

    clickHandlerHollowButton = (question, index) => {
        this.props.setUserEmpathizePhaseState({
            isFollowUpQuestionsPopupOpen: true,
            activeQuestionSelected: question.questionId
        })

        if(index == this.state.indexShownFollowup) {
            this.setState({
                followUpWalkthroughSeen: true
            })
        }
    }

    renderFollowUpQuestionArrow = (index, question) => {
        if(this.state.indexShownFollowup === -1) {
            this.setState({
                indexShownFollowup: index,
            })
        }        

        if (index === this.state.indexShownFollowup && !this.state.followUpWalkthroughSeen) {
            return(
                <WalkthroughElement
                    arrowPosition = 'TOP'
                    walkthroughText = 'label_walkthrough_followup'
                    {...this.props} 
                />
            )
        }
    }

    renderAskFollowupQuestions = (index, question, myColors) => {
        if(question.hasFollowUp) {
            return(
                <div styleName="button-container">
                    <HollowButton 
                        clickFunction={() => this.clickHandlerHollowButton(question, index)}
                        textLabel={this.props.getLabel('label_ask_followup_questions')}
                    />
                    {/* {this.renderFollowUpQuestionArrow(index, question)} */}
                </div>
            )
        }
        return null;
    }

    doNothing = (response) => {
        return;
    }

    renderTickOrMoveToEmpathy = (questionOrResponse) => {
        if(questionOrResponse.isClicked) {
            return(
                <ActionTickWithoutFill 
                    circleFill='#000' 
                    opacity="1" 
                    {...this.props}
                />
            )
        }
        return(
            <MoveArrow />
        )
    }

    renderTooltip = (index) => {

        const myStyles = getSkin(this.props.skinGuide);

        if(this.state.showHoverTooltip) {
            return(
                <div styleName="tooltip-container-right" key={index} id={index}>
                    <div className={css(myStyles.tooltipArrowRight)} styleName="tooltip-arrow-left-right"></div>
                    <div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
                        dangerouslySetInnerHTML={{__html: "This doesn't belong to any quadrant"}}
                    ></div>
                </div>
            )
        }
    }

    renderQuestionToMoveToEmpathyMap = (questionOrResponse, myColors, index) => {
        
        if (questionOrResponse.quadrantId != null) {
            let actionTickBg = myColors.actionTickBgSegment;
            let clickHandler = this.pickResponseForEmpathyMapClicked;
            if(questionOrResponse.isClicked) {
                actionTickBg = myColors.actionTickBgSegmentWhite;
                clickHandler = this.doNothing;
            }
            return(
                <div 
                    styleName="action-tick-container" 
                    style={{cursor: questionOrResponse.isClicked ? 'default': 'pointer'}} 
                    className={css(actionTickBg)}
                    key={index} 
                    onClick={() => clickHandler(questionOrResponse)}>
                        <div styleName="action-tick">
                            {this.renderTickOrMoveToEmpathy(questionOrResponse)}
                        </div>
                </div>
            )
        } 
        return(
            <div 
                styleName="action-tick-container"
                style={{cursor: 'default', background: '#fff'}} >
                    <div styleName="action-tick">
                        <InfoTooltip
                            {...this.props} 
                            tooltipText={this.props.getLabel('label_response_no_emo_grid')}
                            alignTooltipKey="RIGHT"
                            notAllowed={true}
                        />
                    </div>
            </div>
        )
    }

    renderQuestionAndResponse = (questionsAndResponses, myColors) => {
       return questionsAndResponses.map((questionOrResponse, index) => {
           if(questionOrResponse.isResponse) {                              
                return (
                    <div key={index} styleName="question-response-container align-flex-left">
                        <div styleName="response-tick-container">
                            <QuestionChatBox
                                clickHandlerForIcon=''
                                isDisabled={false} 
                                clickHandlerForResponse=''
                                showQuestionIcon={false}
                                showSuggestions={false}
                                chatBoxDirection="left"
                                segmentQuestion = {questionOrResponse} 
                                {...this.props}
                            />
                            {this.renderQuestionToMoveToEmpathyMap(questionOrResponse, myColors, index)}
                            {this.renderWalkthroughArrow(index, questionOrResponse)}
                        </div>
                        {this.renderAskFollowupQuestions(index, questionOrResponse, myColors)}
                    </div>
                    
                )
            }
            return (
                <div key={index} styleName="question-response-container align-flex-right">
                    <QuestionChatBox
                        clickHandlerForIcon=''
                        isDisabled={false}
                        clickHandlerForResponse=''  
                        showQuestionIcon={false}
                        showSuggestions={false}
                        chatBoxDirection="right"
                        segmentQuestion = {questionOrResponse} 
                        {...this.props}
                    />
                </div>
            )
       })
    }
    
    renderWalkthroughArrow = (index, questionOrResponse) => {
        
        if(questionOrResponse.quadrantId != null && this.state.indexShown === -1) {
            this.setState({
                indexShown: index,
            })
        }

        if (index === this.state.indexShown && questionOrResponse.quadrantId != null && !questionOrResponse.isClicked) {
            return(
                <WalkthroughElement
                    arrowPosition = 'LEFT'
                    walkthroughText = 'label_walkthrough_empathy_response'
                    {...this.props} 
                />
            )
        }        
    }

    renderChatSection = (myStyles, myColors) => {
        const { segments } = this.props;
        const segmentQuestions = filterObjectFromArray(segments.get('segmentQuestions'), 'id', this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'));
        const questionsAndResponses = !segmentQuestions.get('shouldShowEmptyChatSection') ? getQuestionResponseArray(segmentQuestions.get('questions').toJS()) : []; 
    
        if(segmentQuestions.get('shouldShowEmptyChatSection')) {
            return(
                <div styleName="popup-chat-container-empty" ref={this.completeContainer}>
                    <EmptyChatSection {...this.props} />
                    <div className={css(myStyles.askQuestionsSubTitle1)} styleName="ask-question-container">
                        {this.props.getLabel('label_ask_first_question')}
                    </div>
                </div>
            )
        } else {
            return(
                <div styleName="popup-chat-container-text">
                    <div styleName="pop-up-chat-container" ref={this.completeContainer}>
                        {this.renderQuestionAndResponse(questionsAndResponses, myColors)}
                    </div>
                </div>
            )
        }
    }

    renderRange = (costRange) => {
        if(costRange.min == costRange.max) {
            return getFormattedCurrency(costRange.min, 'USD')
        } 
        return getFormattedCurrency(costRange.min, 'USD') + ' - ' + getFormattedCurrency(costRange.max, 'USD')
    }

    renderQuestionSectionTitle = (segmentQuestion) => {
        
        const myStyles = getSkin(this.props.skinGuide);
        const costRange = getMinAndMaxValues(segmentQuestion, 'cost'); 
        const { segments } = this.props;
        const segmentQuestions = filterObjectFromArray(segments.get('segmentQuestions'), 'id', this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'));
        
        return (
            <div styleName="ask-questions-title-container">
                <div className={css(myStyles.askQuestionsTitle)} styleName="ask-questions-title-cost display-to-start">
                    <MessageIcon {...this.props} />
                    {this.props.getLabel('label_choose_questions')}
                </div>
                <div className={css(myStyles.askQuestionsSubTitle)} styleName="ask-questions-title-cost display-to-end">
                    {this.props.getLabel('label_question_cost')} &nbsp;
                    <span className={css(myStyles.askQuestionsSubTitleCost)}>
                        {this.renderRange(costRange)}
                    </span>
                </div>
                {
                    segmentQuestions.get('shouldShowEmptyChatSection') === true
                    ?   <WalkthroughComponent
                            arrowType="BOTTOM"
                            propStyles={{
                                top: '-20px',
                                left: '43%'
                            }}
                            text= {this.props.getLabel('label_walkthrough_ask_question')}
                        />
                    :   null
                }
            </div>
        )
    }

    renderQuestionChatBox = (segmentQuestions) => {
       
        return segmentQuestions.get('questions').toJS().map((segmentQuestion, index) => {
            if(segmentQuestion.parentId == null) {
                let isDisabled = true;
                let clickHandler = this.doNothing;
                if(!segmentQuestion.isAsked && !this.props.userPhasesState.get('userEmpathize').get('isEmpathyQuestionAsked')) {
                    isDisabled = false;
                    clickHandler = this.askQuestionClicked;
                }
                if(!checkWithBudget(segmentQuestion.cost, this.props.metrics)) {
                    isDisabled = true;
                    clickHandler = this.doNothing;
                }

                return (
                    <QuestionChatBox
                        key={index}
                        isDisabled={isDisabled}
                        clickHandlerForIcon={() => clickHandler(segmentQuestions, segmentQuestion, this.props.userState)} 
                        clickHandlerForResponse=''
                        showQuestionIcon={true}
                        showSuggestions={true}
                        chatBoxDirection="right"
                        segmentQuestion = {segmentQuestion} 
                        {...this.props}
                    />
                );
            }
        });
    }

    renderQuestionsSection = (myStyles, myColors) => {
        const { segments } = this.props;
        const segmentList = filterObjectFromArray(segments.get('segmentList'), 'id', this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'));
        const segmentQuestions = filterObjectFromArray(segments.get('segmentQuestions'), 'id', this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'));

        if (this.props.userPhasesState.get('userEmpathize').get('isFollowUpQuestionsPopupOpen')) {
            return(
                <div styleName="popup-ask-questions-container">
                    <EmpathyMapFollowUpQuestion 
                        scrollContainer={this.scrollContainer}
                        segmentQuestions={segmentQuestions}
                        {...this.props} 
                    />
                </div>
            )
        }

        return(
            <div className={css(myColors.askQuestionsStyling)} styleName="popup-ask-questions-container">
                {this.renderQuestionSectionTitle(segmentQuestions)}
                <div styleName="ask-questions-chatbox-container">
                    {this.renderQuestionChatBox(segmentQuestions)}
                </div>
            </div>
        )
    }

    renderOverlayOnTopForIntroInitial = (myStyles) => {
        if (!this.props.userPhasesState.get('userEmpathize').get('isEmpathyInitialStateSeen')) {
            return(
                <div 
                    className={css(myStyles.introEmpathyBg)} 
                    style={{ filter: 'blur(4px)'}}
                    styleName="empathy-initial-intro-container"
                >
    
                </div>
            )
        }
        return null;
    }
    
    render() {
    
        const myStyles = getSkin(this.props.skinGuide);
        const { segments } = this.props;
        const segmentList = filterObjectFromArray(segments.get('segmentList'), 'id', this.props.userPhasesState.get('userEmpathize').get('activeOverlayWithTimerSegmentId'));
        const myColors = getColorPalette(this.props.skinGuide, segmentList.get('colorProfileId'));
        return(
            <div 
                styleName="popup-questions-container"
                style={{ filter: !this.props.userPhasesState.get('userEmpathize').get('isEmpathyInitialStateSeen') ? 'blur(4px)' : null}}

            >
                {this.renderOverlayOnTopForIntroInitial(myStyles)}
                <div className={css(myStyles.popupContentShadow)} styleName="popup-questions-content">
                    {this.renderChatSection(myStyles, myColors)}
                    {this.renderQuestionsSection(myStyles, myColors)}
                </div>
            </div>
        )
    }
}

export default applyWrappers(EmpathyMapQuestions, styles);