/**
 * @author: Ujjwal Jain
 * @file: metrics.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable, { update } from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey, onlyMergeListsBasedOnKey } from '../../util/objectUtils';
import { filterObjectFromArray } from 'util/objectUtils';

const initialState = Immutable.fromJS({
    metricInfo: [],
    metrics: [],
    isMetricsFetched: false
});

const metrics = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('INIT_METRICS_DATA'):    
            return state.set('metricInfo', mergeListsBasedOnKey(state.get('metricInfo'), action.payload.metricInfo, 'id'))
                        .set('metrics', mergeListsBasedOnKey(state.get('metrics'), formatMetrics(action.payload.metrics), 'key'))
                        .set('isMetricsFetched', true);
        case actionConsts.get('SET_UPDATED_METRICS'):            
            return state.set('metrics', mergeListsBasedOnKey(state.get('metrics'), action.payload, 'key'));
        default:
            return state;
    }
};

const formatMetrics = (list) => {
    list.map((eachData) => {
        eachData.diff = 0;
        return;
    });
    return list;
}

export default metrics;