/**
 * @author: Ujjwal Jain
 * @file: images.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({});


const images = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_IMAGES_FOR_STORYLINE'):
            return state.mergeDeep(action.payload);
        default:
            return state;
    }
};

export default images;