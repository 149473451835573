import applySkin from 'util/SkinProvider';
import applyLabel from 'util/LabelProvider';
import applyImages from 'util/ImagesProvider'
import CSSModules from 'react-css-modules';

const applyWrappers = (WrappedComponent, styles) => {
	return applyLabel(
			applyImages(
				applySkin(
					CSSModules(
						WrappedComponent,
						styles,
						{
							allowMultiple: true
						}
					)
				)
			)
		);
}

export default applyWrappers;