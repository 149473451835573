import React, { Component } from 'react';
import styles from './filledButton.module.sass';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class FilledButton extends Component {

    onClickOfFilledButton = () => {
        if(checkIfPresent(this.props.disableButton)) {
            if(this.props.disableButton === true) {
                return;
            } else {
                if(checkIfPresent(this.props.clickFunction)) {
                    this.props.clickFunction();
                }
            }
        } else {
            if(checkIfPresent(this.props.clickFunction)) {
                this.props.clickFunction();
            }
        }
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        let buttonShadowStyle = css(myStyles.buttonStyle);
        const isDisabled = checkIfPresent(this.props.disableButton)
            ? this.props.disableButton ? 1 : 0
            : 0;

        if(checkIfPresent(this.props.buttonShadow)) {
            if(this.props.buttonShadow) {
                buttonShadowStyle = css(myStyles.buttonStyle);
            } else {
                buttonShadowStyle = css(myStyles.buttonStyleWithoutShadow);
            }
        }
        
        return (
            <div
                className={buttonShadowStyle}
                styleName="button-style" onClick={this.onClickOfFilledButton}
                style={{
                    opacity: isDisabled ? 0.5 : 1,
                    cursor: isDisabled ? 'not-allowed' : 'pointer'
                }}
            >
                <div className={css(myStyles.buttonText)}>
                    {this.props.textLabel}
                </div>
            </div>
        );
    }
}

export default applyWrappers(FilledButton, styles);
