import React from 'react';
import styles from './reportGraph.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class ReportGraph extends React.Component {

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skin = this.props.skinGuide.get('globalProfiles').get('palette')
        let color = '#661AB8';
        if(checkIfPresent(this.props.svgColor)) {
            color = this.props.svgColor;
        }

        return (
            <div styleName="main-component">
                <svg width="100%" height="100%" viewBox="0 0 22 13">
                    <path fill={color} fill-rule="nonzero" d="M19.167 0c-.586 0-1.14.258-1.503.7a1.794 1.794 0 0 0-.362 1.57.228.228 0 0 1-.052.205l-3.122 3.507a.248.248 0 0 1-.242.074 1.954 1.954 0 0 0-1.828.49.243.243 0 0 1-.245.055l-2.087-.67a.235.235 0 0 1-.162-.184C9.423 4.79 8.537 4.1 7.535 4.166 6.533 4.232 5.754 5.032 5.75 6c0 .144.018.288.053.428a.226.226 0 0 1-.08.231l-2.78 2.234a.252.252 0 0 1-.251.034 1.966 1.966 0 0 0-2.235.492 1.793 1.793 0 0 0-.146 2.204c.463.685 1.338.995 2.151.762.814-.232 1.371-.952 1.371-1.77 0-.144-.017-.287-.051-.427a.226.226 0 0 1 .08-.23L6.64 7.722a.247.247 0 0 1 .25-.034 1.97 1.97 0 0 0 2.132-.388.246.246 0 0 1 .246-.056l2.086.669c.085.028.147.099.161.184.128.97 1.02 1.677 2.033 1.61 1.013-.067 1.796-.884 1.785-1.862 0-.248-.052-.493-.153-.72a.225.225 0 0 1 .04-.242l2.916-3.278a.246.246 0 0 1 .276-.062 1.971 1.971 0 0 0 2.099-.382 1.797 1.797 0 0 0 .429-2.015C20.645.453 19.944 0 19.167 0z"/>
                </svg>
            </div>
        );
    }
}

export default applyWrappers(ReportGraph, styles);