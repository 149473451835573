import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
        budgetTimerFont: {
			color: skin.get('white'),
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '18px'
		},
		
		upOnboardingArrow: {
			borderBottom: `20px solid white`,
			borderLeft: `20px solid transparent`,
			borderRight: `20px solid transparent`,
		},
		budgetFirstTimeText: {
			...myTypography.body1,
			color: skin.get('grayColor2'),
			textTransform: 'none'
		},
		valueSeparator: {
			backgroundColor: skin.get('grayColor5')
		},
		valueHeading: {
			...myTypography.body2,
			color: skin.get('grayColor4'),

		},
		valueNumber: {
			...myTypography.body1,
			fontSize: '20px',
			color: skin.get('grayColor1'),
			lineHeight: 'normal'
		},
		valueNumberDenominator: {
			...myTypography.body2,
			color: skin.get('grayColor2'),
		},
		red: {
			color: '#FF0000'
		},
		progressBarBack: {
			backgroundColor: hexToRgbA('#FF0000', 0.1)
		},
		progressBarFront: {
			backgroundColor: '#FF0000'
		},

		backgroundRed: {
			backgroundColor: '#ff5757'
		}
    });
}

export default getSkin;