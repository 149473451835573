import React, { Component } from 'react';
import styles from './infoIcon.module.sass';
import applyWrappers from 'util/ComponentWrapper';

class InfoIcon extends Component {

	render() {

		const skinGuide = this.props.skinGuide.get('globalProfiles').get('palette');
		return (
			<div styleName="main-component">
				<svg width="100%" height="100%" viewBox="0 0 17 17">
					<g fill="none" fillRule="evenodd" stroke={skinGuide.get('black')} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" opacity=".4" transform="translate(1 1)">
						<path d="M8.906 10.313h-.469a.938.938 0 0 1-.937-.938V7.031a.469.469 0 0 0-.469-.468h-.468M7.266 4.219a.234.234 0 1 0 0 .468.234.234 0 0 0 0-.468" opacity=".7"/>
						<circle cx="7.5" cy="7.5" r="7.031" opacity=".7"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default applyWrappers(InfoIcon, styles)
