import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		changeValueContainer: {
			border: `solid 1px ${skin.get('grayColor5')}`,
		},
		valueText: {
			...myTypography.body2,
			color: skin.get('white')
		},
		green: {
			backgroundColor: `rgba(117, 175, 92, 0.8)`
		},
		red: {
  			backgroundColor: `rgba(187, 67, 67, 0.8)`
		}
	});
}

export default getSkin;