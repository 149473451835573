import consts from 'constants/url';
import kfetch from '../util/kfetch';
import {checkIfPresent} from 'util/utilFunctions';
import setUIState from 'actions/uiState/actionCreators';

export const postPrototypeIdea = (payload) => (dispatch) => kfetch(consts.get('POST_PROTOTYPE_IDEA'), payload, 'POST')
.then((response) => {
        dispatch(payload.reducerAction);
        setTimeout(() => {
            dispatch(setUIState({
                ideaContainerLoadingStates: {
                    prototypeContent: false,
                    prototypeShortlist: false
                }
            }));
        }, 4000);
    if(checkIfPresent(response.metrics)) {
        dispatch({
            type: 'SET_UPDATED_METRICS',
            payload: response.metrics
        });
    }
});