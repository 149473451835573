import consts from 'constants/url';
import kfetch from '../util/kfetch';
import updateIdea from 'actions/ideas/actionCreators';
import {checkIfPresent} from 'util/utilFunctions';
import setUIState from 'actions/uiState/actionCreators';

export const postTestPrototype = (payload) => (dispatch) => kfetch(consts.get('POST_TEST_PROTOTYPE'), payload, 'POST')
    .then((response) => {
        dispatch(updateIdea({
                id: payload.ideaId,
                showTestReportLoading: true
            }
        ));
        setTimeout(() => {
            dispatch(updateIdea({
                    id: payload.ideaId,
                    isShortlistedPrototype: false,
                    isShortlistedForTesting: true,
                    isTestingDone: true,
                    testPrototypeResponse: response,
                    showTestReportLoading: false,
                    isProcessing: false
                }
            ));
            dispatch(setUIState({
                ideaContainerLoadingStates: {
                    testContent: false,
                    testShortlist: false,
                    executionShortlist: false
                }
            }));
        }, 4000);
        if(checkIfPresent(response.metrics)) {
            dispatch({
                type: 'SET_UPDATED_METRICS',
                payload: response.metrics
            });
        }
    });