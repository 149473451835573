import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
	let globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

	return StyleSheet.create({
		headerBox: {
			backgroundColor: skin.get('primaryColor'),
			borderRadius: '5px',
			boxShadow: `0 2px 5px 0 rgba(0, 0, 0, 0.32)`
		},
		lockedPhase: {
			color: hexToRgbA(skin.get('white'), 0.3),
			textTransform: 'uppercase',
			...myTypography.body1
		},
		unlockedPhase: {
			color: hexToRgbA(skin.get('white'), 1),
			textTransform: 'uppercase',
			...myTypography.body1,
			cursor: 'pointer',
			':hover': {
				fontWeight: 600
			}
		},
		selectedPhase: {
			backgroundColor: skin.get('white'),
			color: skin.get('primaryColor'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			...myTypography.body1,
			border: `1px solid ${skin.get('primaryColor')}`,
			borderRadius: '4px',
			fontWeight: 600,
			':hover': {
				color: skin.get('primaryColor')
			}
		},
		roundBorder: {
			borderRadius: '20px',
			border: `2px solid ${hexToRgbA(skin.get('white'), 0.3)}`
		},
		enabledRoundBorder: {
			borderRadius: '20px',
			border: `2px solid ${hexToRgbA(skin.get('white'), 0.3)}`,
			// color: hexToRgbA(skin.get('white'), 0.3),
			':hover': {
				border: `2px solid ${skin.get('white')}`,
				color: skin.get('primaryColor'),
				backgroundColor: skin.get('white')
			}
		},
		executeEnabled: {
			border: `2px solid ${skin.get('white')}`,
			color: skin.get('primaryColor'),
			backgroundColor: skin.get('white')
		},
		budgetTimerFont: {
			color: skin.get('white'),
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '18px'
		},
		teamLabel: {
			...myTypography.body1,
			color: hexToRgbA(skin.get('white'), 0.4)
		},
		eventContent: {
			borderLeft: `1px solid ${hexToRgbA(skin.get('white'), 0.2)}`
		},
		roleName: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '8px',
			color: hexToRgbA(skin.get('white'), 0.7)
		},
		tmName: {
			...myTypography.body1,
			fontWeight: 600,
			color: skin.get('white')
		},
		upOnboardingArrow: {
			borderBottom: `20px solid white`,
			borderLeft: `20px solid transparent`,
			borderRight: `20px solid transparent`,
		},
		budgetFirstTimeText: {
			...myTypography.body1,
			color: skin.get('grayColor2'),
			textTransform: 'none'
		},
		valueSeparator: {
			backgroundColor: skin.get('grayColor5')
		},
		valueHeading: {
			...myTypography.body2,
			color: skin.get('grayColor4'),

		},
		valueNumber: {
			...myTypography.body1,
			fontSize: '20px',
			color: skin.get('grayColor1'),
			lineHeight: 'normal'
		},
		valueNumberDenominator: {
			...myTypography.body2,
			color: skin.get('grayColor2'),
		},
		red: {
			color: '#FF0000'
		},
		progressBarBack: {
			backgroundColor: hexToRgbA('#FF0000', 0.1)
		},
		progressBarFront: {
			backgroundColor: '#FF0000'
		}
	});
}

export default getSkin;