/**
 * @author: Varun Kumar
 * @file: questions.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { mergeListsBasedOnKey } from 'util/objectUtils';
import { compareOnId } from 'util/utilFunctions';

const initialState = Immutable.fromJS({
    questionsList: [
        {
            id: 1,
            segmentId: 1,
            cost: '$10',
            question: 'Which device do you use for shopping?'
        },
        {
            id: 2,
            segmentId: 1,
            cost: '$20',
            question: 'Which device do you use for shopping?'
        },
        {
            id: 3,
            segmentId: 1,
            cost: '$15',
            question: 'Which device do you use for shopping?'
        },
        {
            id: 4,
            segmentId: 1,
            cost: '$40',
            question: 'Which device do you use for shopping?'
        },
        {
            id: 5,
            segmentId: 2,
            cost: '$10',
            question: '2 Which device do you use for shopping?'
        },
        {
            id: 6,
            segmentId: 2,
            cost: '$20',
            question: '2 Which device do you use for shopping?'
        },
        {
            id: 7,
            segmentId: 2,
            cost: '$15',
            question: '2 Which device do you use for shopping?'
        },
        {
            id: 8,
            segmentId: 2,
            cost: '$40',
            question: '2 Which device do you use for shopping?'
        },
        {
            id: 9,
            segmentId: 3,
            cost: '$10',
            question: '3 Which device do you use for shopping?'
        },
        {
            id: 10,
            segmentId: 3,
            cost: '$20',
            question: '3 Which device do you use for shopping?'
        },
        {
            id: 11,
            segmentId: 3,
            cost: '$15',
            question: '3 Which device do you use for shopping?'
        },
        {
            id: 12,
            segmentId: 3,
            cost: '$40',
            question: '3 Which device do you use for shopping?'
        },
        {
            id: 13,
            segmentId: 4,
            cost: '$10',
            question: '4 Which device do you use for shopping?'
        },
        {
            id: 14,
            segmentId: 4,
            cost: '$20',
            question: '4 Which device do you use for shopping?'
        },
        {
            id: 15,
            segmentId: 4,
            cost: '$15',
            question: '4 Which device do you use for shopping?'
        },
        {
            id: 16,
            segmentId: 4,
            cost: '$40',
            question: '4 Which device do you use for shopping?'
        },
    ]
});


const questions = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.GET_questions_DATA:
            return state.set(
                'questionsList',
                mergeListsBasedOnKey(state.get('questionsList'), action.payload.questions.sort(compareOnId), 'id')
            );
        default:
            return state;
    }
};

export default questions;