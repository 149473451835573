import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'util/styleUtil';

const getSkin = (theme) => {
    const globalProfiles = theme.get('globalProfiles');
	const skin = globalProfiles.get('palette');
	const myTypography = typography(theme);

    return StyleSheet.create({
		changeValueContainer: {
			backgroundColor: skin.get('grayColor5'),
		},
		valueText: {
			...myTypography.body2,
			color: skin.get('white')
		},
		red: {
			backgroundColor: '#bb4343'
		},
		green: {
			backgroundColor: '#75af5c'
		}
	});
}

export default getSkin;