import React, { Component } from 'react';
import styles from './loader.module.sass';
import applyWrappers from 'util/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

class Loader extends Component {

    render() {
        return (
            <div styleName="main-component">
                <svg width="38" height="38" viewBox="0 0 38 38" stroke={checkIfPresent(this.props.color) ? this.props.color : "#d44980"}>
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle stroke="e8eaf2" stroke-opacity=".1" cx="18" cy="18" r="18"/>
                            <path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(139.604 18 18)">
                                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}



export default applyWrappers(Loader, styles)
