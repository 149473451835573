import React from 'react';
import styles from './recommendation.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import ChatTalk from 'svgComponents/chatTalk';
import WalkthroughComponent from 'commonComponents/walkthroughComponent';
import {checkIfPresent} from 'util/utilFunctions';

class Recommendation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOnHover: false,
            isWalkthroughSeen: false
        }
    }

    getStyleForRec = (recLength) => {
        switch(this.props.alignRecKey) {
            case 'TOP':
                return {
                    top: `-${(recLength - 1)*(33)}px`,
                    left: 0,
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    boxShadow: `0px -1px 16px 0 rgba(0, 0, 0, 0.3)`,
                    flexDirection: 'column-reverse' 
                };
            case 'BOTTOM':
                return {
                    top: 0,
                    left: 0,
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                    boxShadow: `0px 1px 10px 0 rgba(0, 0, 0, 0.3)`,
                    flexDirection: 'column'
                };
            default:
                return {
                    top: 0,
                    left: 0,
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                    boxShadow: `0px 1px 10px 0 rgba(0, 0, 0, 0.3)`,
                    flexDirection: 'column'
                };
        }
    }

    setOnHoverState = () => {
        this.setState({
            isOnHover: true
        });
        if(this.state.isWalkthroughSeen === false) {
            this.setState({
                isWalkthroughSeen: true
            });
        }
    }

    unsetOnHoverState = () => {
        this.setState({
            isOnHover: false
        });
    }

    renderRecommendation = (myStyles, recommendation, index) => {
        const borderNone = this.state.isOnHover && this.props.recommendationList.length > 1 ? myStyles.recContainerHighlight : myStyles.recContainer;        return (
            <div key={index}
                className={css(borderNone)}
                styleName="single-recommendation"
            >
                <div styleName="rec-image">
                    <img alt="User" src={this.props.getImage(recommendation.image)} width="100%" height="100%" />
                    {/* {
                        checkIfPresent(recommendation.charName)
                        ?   <div styleName="rec-name">
                                {recommendation.charName}
                            </div>
                        :   null
                    } */}
                </div>
                <div
                    className={css(myStyles.recContent)} styleName="rec-content"
                    dangerouslySetInnerHTML={{__html: recommendation.text}}
                >
                </div>
                {
                    this.state.isOnHover && this.props.recommendationList.length > 1
                    ?   null
                    :   <div styleName="rec-talk-image">
                            <ChatTalk />
                        </div>
                }
            </div>
        );
    }

    renderSingleRecommendation = (myStyles, recommendationList) => {
        if(recommendationList.length > 0) {
            const firstRecommendation = recommendationList[0];
            return this.renderRecommendation(myStyles, firstRecommendation, 1);
        }
        return null;
    }

    renderAllRecommendations = (myStyles, recommendationList) => {
        if(recommendationList.length > 0) {
            const allRecs = recommendationList.map((singleRec, index) => {
                return this.renderRecommendation(myStyles, singleRec, index);
            });
            return (
                <div styleName="all-recs"
                    style={this.getStyleForRec(recommendationList.length)}
                >
                    {allRecs}
                </div>
            );
        }
        return null;
    }

    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const {recommendationList} = this.props;
        
        if(recommendationList.length === 0) {
            return null;
        }
        return (
            <div
                styleName="recommendation-container"
                onMouseEnter={() => {this.setOnHoverState();}}
                onMouseLeave={() => {this.unsetOnHoverState();}}
            >
                {this.renderSingleRecommendation(myStyles, recommendationList)}
                {
                    this.state.isOnHover && recommendationList.length > 1
                    ?   this.renderAllRecommendations(myStyles, recommendationList)
                    :   null
                }
                {
                    this.props.showWalkthrough === true && this.state.isWalkthroughSeen === false
                    ?   <WalkthroughComponent
                            arrowType="LEFT"
                            propStyles={{
                                top: '5px',
                                right: '-160px'
                            }}
                            text={this.props.getLabel('label_walkthrough_recommendation_hover')}
                        />
                    :   null
                }
            </div>
        );
    }
}

export default applyWrappers(Recommendation, styles);