import React from 'react';
import styles from './readPersonaPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import PersonaList from 'commonComponents/personaList';
import consts from 'constants/url';
import CloseIcon from 'svgComponents/closeIcon';
import Demographics from './demographics';
import PersonaInterview from './personaInterview';
import analyticsUtil from 'util/segmentUtil';
class ReadPersonaPopup extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            collapseBgInfo: this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('noOfInterviewTaken') == 0 ? false : true
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            collapseBgInfo: nextProps.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('noOfInterviewTaken') == 0 ? false : true
        })
    }

    renderPersonaInterviews = () => {
        const personaObject = this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject');
        let personaInterviews = personaObject.get('personaInterviews');
        if(personaInterviews.size > 0) {
            personaInterviews = personaInterviews.map((interview, index) => {
                if(interview.get('isLevelRevealed') == 1){
                    const shouldExpand = (index == (personaObject.get('noOfInterviewTaken') - 1)) ? true : false                    
                    return (
                        <div key={index} styleName="interview">
                           <PersonaInterview 
                                index={index} 
                                interview={interview}
                                shouldExpand={shouldExpand}
                                personaObject={personaObject}
                            /> 
                        </div>
                    );
                }
                else{
                    return null;
                }
            });
            return [personaInterviews];
        }
        else{
            return null;
        }
    }

    renderInterviewsSection = (myStyles) => {
        let interviewDone = false;
        let personaInterviews = this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('personaInterviews');
        personaInterviews.map((interview) => {
            if(interview.get('isLevelRevealed') == 1){
                interviewDone = true;
            }    
        }); 
        if(interviewDone){
            return (
                <div styleName="interview">
                    <p className={css(myStyles.experienceText)} styleName="experience">
                        {this.props.getLabel('label_interview_video')}
                    </p>
                    <div styleName="interview-container">
                        {this.renderPersonaInterviews()}
                    </div>
                </div>
            );
        }
        else{
            return null;
        }
    }

    calculateAllPersonasRead = () => {
        let allRead = true;
        this.props.personas.get('personaList').map((persona, index) => {
            if (persona.get('isInfoRead') === false) {
                allRead = false;
            }
            return;
        });
        return allRead;
    }

    personaIconClicked = (persona) => {
        const allPersonaRead = this.calculateAllPersonasRead();
        if(!allPersonaRead) {            
            const url = consts.get('PERSONA_INFO_READ');
            const payload = {
                personaId: persona.id,
                roundId: this.props.userState.get('currentRoundId')
            }
            this.props.postRequestPersonaInfoRead(url, payload);
        }
        this.props.setUserObservePhaseState({
			currentPersonaSelectedObject: persona
        });
        
        if(this.props.userPhasesState.get('userObserve').get('isPersonaInterviewPopup')) {
            // this.props.setNewInfoRevealedAsRead({
            //     persona: persona,
            //     isInterview: true
            // })
            const url = consts.get('ACTION_LOG_API');
            const responsePayload = {
                roundId: this.props.userState.get('currentRoundId'),
                action: {
                    type: 'PERSONA_NEW_INFO_READ',
                    payload: { 
                        persona: persona,
                        isInterview: true
                    }
                }
            };
            this.props.postRequestActionLog(url, responsePayload);
        }
    }

    closeButtonClicked = () => {
        analyticsUtil.track("SIDEBAR_POPUP_OPEN",{ popup: "READ_PERSONA_POPUP_CLOSE" });
        this.props.setUIState({
            isOverlayOpen: false,
            popupComponent: '',
            isUserBoardOpen: false,
        })

        this.props.setUserObservePhaseState({
            isPersonaInterviewPopup: false
        })
    }

    renderPersonaList = (myStyles) => {
        return(
            <div styleName="persona-list-container-popup">
                <PersonaList 
                    isCenterAligned={true} 
                    showPersonaReadState = {true}
                    isFromDashboard = {false}
                    isInfoRelated = {true}
                    isInterview={false}
                    personaIconClicked={this.personaIconClicked}
                    showPersonaLevelAndCost = {false}
                    showPersonaName={false}
                    {...this.props} 
                />
                <div className={css(myStyles.personaListBackground)} styleName="linear-gradient-div">
                </div>
            </div>
        )
    }

    toggleView = () => {
        this.setState({
            collapseBgInfo: !this.state.collapseBgInfo
        })
    }

    renderCollapsedOrExpandedBackground = (myStyles) => {
        const showDownArrow = !this.state.collapseBgInfo && this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('noOfInterviewTaken') > 0;
        if (this.state.collapseBgInfo) {
            return(
                <div styleName="title" className={css(myStyles.title)}>
                    <div onClick={this.toggleView} styleName="arrow-title-container" style={{alignItems: 'center'}}>
                        {
                            !this.state.collapseBgInfo 
                            ? 
                            <div className={css(myStyles.downArrow)} styleName="down-arrow"></div>
                            :
                            <div className={css(myStyles.sideArrow)} styleName="side-arrow"></div>

                        }
                        {this.props.getLabel('label_experience')}
                    </div>
                </div>
            )
        }
        return(
            <div styleName="bio">
                <div onClick={showDownArrow ? this.toggleView : ''} style={{display: 'flex', cursor: showDownArrow ? 'pointer' : 'default'}}>
                    {
                        showDownArrow ?
                            <div styleName="arrow-title-container">
                                <div className={css(myStyles.downArrow)} styleName="down-arrow"></div>
                            </div>
                        : null
                    }
                    <div 
                        dangerouslySetInnerHTML={{__html: this.props.getLabel('label_experience')}}
                        className={css(myStyles.experienceText)} 
                        styleName="experience"
                    >
                    </div>
                </div>
                <div
                    className={css(myStyles.bio)}
                    dangerouslySetInnerHTML={{__html: this.props.getLabel(this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('bgInfo'))}}
                >
                </div>
            </div>
        )
    }

    renderPersonaDetails = (myStyles) => {

        return(
            <div styleName="persona-details-container">
                <div styleName="width-100-container">
                    <div styleName="persona-name-title-container">
                        <div className={css(myStyles.personaNameStyle)} styleName="persona-name-title">
                            {this.props.getLabel(this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('name'))}
                        </div>
                        <div className={css(myStyles.borderTopStyle)} styleName="horizontal-line">
                        </div>
                    </div>
                    <div styleName="content">
                        <div className={css(myStyles.demographicsContainer)} styleName="persona-demographics-container">
                            <p styleName="demographics-text" className={css(myStyles.demographicsText)}>
                                {this.props.getLabel('label_demographics')}: 
                            </p>
                            <Demographics demographics={this.props.userPhasesState.get('userObserve').get('currentPersonaSelectedObject').get('demographics')}/>
                        </div>
                        <div  className={css(myStyles.aboutDemographicsContainer)} styleName="about-persona-demographics-container">
                            {this.renderInterviewsSection(myStyles)}
                            {this.renderCollapsedOrExpandedBackground(myStyles)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBackgroundImage = () => {
        return(
            <div styleName="bakground-image-container">
                <img src={this.props.getImage('IMG_READ_PERSONA_BG')} width="100%" height="100%" />
            </div>
        )
    }

    renderCloseButton = () => {
        return(
            <div styleName="close-icon">
                <CloseIcon
                    closeButtonClicked = {this.closeButtonClicked}
                    {...this.props} 
                />
            </div>
        )
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div className={css(myStyles.personaPopupBg)} styleName="persona-popup-container">
                {this.renderPersonaList(myStyles)}
                {this.renderPersonaDetails(myStyles)}
                {this.renderBackgroundImage()}
                {this.renderCloseButton()}
            </div>
        );
    }
}

export default applyWrappers(ReadPersonaPopup, styles);