/**
 * @author: Ujjwal Jain
 * @file: segments.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const initialState = Immutable.fromJS({
	target: 600000000,
    storylineIntro: {},
    baseValues: {},
    baseDefaultValues: {
        initialBudget: 50000,
        initialCr: 5,
        initialCreation: 0,
        initialExploration: 0,
        initialInnovation: 0,
        initialReflection: 0,
        initialRevenue: 5000000,
        targetCr: 10,
        targetRevenue: 600000000,
        initialTimer: 3600
    }
});

const storyline = (state = initialState, action = {}) => {
    switch (action.type) {
		case actionConsts.get('INIT_INTRO_DATA'):    
            return state.set('storylineIntro', action.payload);
        case actionConsts.get('INIT_INTRO_BASE_DATA'):    
            return state.set('baseValues', action.payload);
        default:
            return state;
    }
};


export default storyline;