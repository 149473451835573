import React from 'react';
import styles from './colorHeading.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'util/ComponentWrapper';
import TitleUnderline from 'svgComponents/titleUnderline';

class ColorHeading extends React.Component {
    
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div
                className={css(myStyles.popupHeaderStyle)}
                styleName="popup-header-title"
                style={{
                    textTransform: this.props.isUppercase ? 'uppercase' : 'none',
                    fontSize: this.props.fontSize ? this.props.fontSize : '16px',
                }}
            >
                {this.props.title}
                <div
                    styleName="title-underline-container"
                    style={{
                        bottom: this.props.imgBottom ? this.props.imgBottom : '-4px'
                    }}
                >
                    <TitleUnderline />
                </div>
            </div>
        );
    }
}

export default applyWrappers(ColorHeading, styles);