import actionConsts from 'constants/actions';

const updateEvent = (payload) => {
    return {
        type: actionConsts.get('UPDATE_EVENT'),
        payload
    };
};

export {
    updateEvent
};
